import React from 'react'
import { TextProps } from './types/Text.types'

function TextRH<T>(props: TextProps<T>): JSX.Element {
  const [max, min] = [props.max || 100, props.min || 0]
  const type = props.formState.errors[props.field]?.type ?? ''
  const message = (props.formState.errors[props.field]?.message as string) ?? ''
  const validClass = type && type !== 'custom' ? 'is-invalid' : ''

  return (
    <>
      <label title={props.title} htmlFor={props.id} className='form-label mb-2'>
        {props.label}
      </label>
      <input
        step={props.step}
        type={props.type || 'text'}
        required={Boolean(props.required)}
        className={`form-control ${props.inputClass || ''} ${props.validation ? validClass : ''}`}
        placeholder={props.placeholder}
        id={props.id}
        {...props.register(props.field, {
          required: props.required,
          max: {
            value: max,
            message: `The value ${props.field} cannot be longer than ${max} characters`,
          },
          min: {
            value: min,
            message: `The value ${props.field} cannot be less than ${min} characters`,
          },
          maxLength: {
            value: max,
            message: `The value ${props.field} cannot be longer than ${max} characters`,
          },
          minLength: {
            value: min,
            message: `The value ${props.field} cannot be less than ${min} characters`,
          },
        })}
      />

      {message && props.required ? (
        <div className='invalid-feedback d-block'>
          <small className='form-text text-muted'>{message}</small>
        </div>
      ) : null}
    </>
  )
}

export default TextRH
