import React from 'react'
import { ModificationStatus, StateType } from '../../../../store/models/root.interface'
import { connect } from 'react-redux'
import { ScrService } from '../../../../store/models/scraper/scr-service.interface'
import { bindActionCreators, Dispatch } from 'redux'
import {
  addScrService,
  clearSelectedScrService,
  editScrService,
  setScrServiceModificationState,
  setScrServiceSomeError,
} from '../../../../store/dispatch/scraper/scr-service.dispatch'
import { RequestUrlTypes } from '../../../../hooks/types/request.types'
import { useForm } from 'react-hook-form'
import { useHttpRequestWithBody } from '../../../../hooks/request.hook'
import { TabFormWrapperProps } from '../../../../common/components/types/TabFormWrapper.types'
import TabFormWrapper from '../../../../common/components/TabFormWrapper'
import RenderFormScrService from './components/RenderFormScrService'
import { useErrorHandlerHook } from '../../../../hooks/error-handler'
import { URL_SERVER_SCRAPER } from '../../../../config'
import { addNotification } from '../../../../store/dispatch/notifications.dispatch'
import { updateLoaderState } from '../../../../store/dispatch/root.dispatch'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setSomeError: bindActionCreators(setScrServiceSomeError, dispatch),
  add: bindActionCreators(addScrService, dispatch),
  edit: bindActionCreators(editScrService, dispatch),
  clearSelected: bindActionCreators(clearSelectedScrService, dispatch),
  setModificationState: bindActionCreators(setScrServiceModificationState, dispatch),
  updateLoader: bindActionCreators(updateLoaderState, dispatch),
})

const mapStateToProps = ({ scrService, scrCredential: { list } }: StateType) => ({
  option: scrService,
  credentials: list,
})

type PropsServicesFormComponent = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const schema = yup.object().shape({
  _id: yup.string().notRequired(),
  account: yup.string().required('Value password cannot be empty'),
  minPrice: yup.number().min(0).max(1000000).required('Value minPrice cannot be empty'),
  minCount: yup.number().integer().min(0).max(1000000).required('Value minCount cannot be empty'),
  maxPrice: yup.number().min(0).max(1000000).required('Value maxPrice cannot be empty'),
  country: yup.number().min(1).max(1000000).required('Value country cannot be empty'),
  name: yup.string().max(100).required('Value name cannot be empty'),
  step: yup.number().min(0.01).max(1000000).required('Value step cannot be empty'),
  status: yup.boolean().required(),
})

const ScrServiceForm: React.FC<PropsServicesFormComponent> = ({
  updateLoader,
  option,
  credentials,
  setSomeError,
  add,
  edit,
  setModificationState,
  clearSelected,
}) => {
  const { modificationState, selected, error } = option
  const isCreate: boolean = modificationState === ModificationStatus.Create
  const errorHandler = useErrorHandlerHook(setSomeError, 'Сервіси')
  const saveRequest = useHttpRequestWithBody<ScrService>({
    url: RequestUrlTypes.SCRAPER_SERVICE,
    base: URL_SERVER_SCRAPER,
    func: isCreate ? add : edit,
  })

  let item: ScrService | null = selected

  if (!item || isCreate) {
    item = new ScrService()
  }

  const form = useForm<ScrService>({ defaultValues: item, resolver: yupResolver(schema) })

  const saveForm = async (formState: ScrService): Promise<void> => {
    const uniqService = option.list.find((item) => {
      return (
        item.country === formState.country &&
        item.name === formState.name &&
        item.account === formState.account
      )
    })

    if (isCreate && uniqService) {
      errorHandler({ text: 'Даний сервіс присутній' })
      return
    }

    if (item) {
      try {
        await saveRequest({
          body: { ...formState },
          id: item?._id ? String(item?._id) : '',
        })

        addNotification('Сервіс', isCreate ? 'додано' : 'редаговано')
        setModificationState(ModificationStatus.None)
        clearSelected()
      } catch (e: any) {
        errorHandler({ text: e.message })
      } finally {
        updateLoader(false)
      }
    }
  }

  const resetForm = (): void => {
    setModificationState(ModificationStatus.None)
    form.reset()
  }

  const formOptions: TabFormWrapperProps<ScrService> = {
    form,
    error,
    title: 'слоту',
    isCreate,
    saveForm,
    resetForm,
  }

  return (
    <TabFormWrapper {...formOptions}>
      <RenderFormScrService form={form} credentials={credentials} />
    </TabFormWrapper>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ScrServiceForm)
