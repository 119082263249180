import React from 'react'
import { CustomRenderFormTgCurrenciesProps } from '../types/RenderFormTgCurrencies.types'
import TextRH from '../../../../../common/form-hook/Text'
import TypeheadSelectRH from '../../../../../common/form-hook/TypeheadSelect'

const RenderFormTgCurrencies: React.FC<CustomRenderFormTgCurrenciesProps> = ({ form, options }) => {
  return (
    <>
      <div className='form-group col-md-9'>
        <TypeheadSelectRH
          {...form}
          id='input_currency_a'
          field='currencyA'
          label='Валюта А'
          placeholder='Обрати валюту'
          options={options}
          labelKey='text'
          savedKey='value'
          value={form.watch('currencyA')}
          defaultSelected={form.watch('currencyA') ? form.watch('currencyA').toString() : ''}
        />
      </div>

      <div className='form-group col-md-3'>
        <TextRH {...form} type='number' id='input_rate' label='Ціна' field='rate' />
      </div>
    </>
  )
}

export default RenderFormTgCurrencies
