import React from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import OptionsTab from '../../../../common/components/Tab'
import { useHttpDeleteRequest } from '../../../../hooks/request.hook'
import { RequestUrlTypes } from '../../../../hooks/types/request.types'
import { updateCurrentPath, updateLoaderState } from '../../../../store/dispatch/root.dispatch'
import { addNotification } from '../../../../store/dispatch/notifications.dispatch'
import { StateType, ModificationStatus } from '../../../../store/models/root.interface'
import { connect } from 'react-redux'
import { OptionTypeDetails } from '../../../../common/components/types/Tab.types'
import { useErrorHandlerHook } from '../../../../hooks/error-handler'
import GsmOptionsServiceList from './GsmOptionsServiceList'
import { URL_SERVER_GSM } from '../../../../config'
import { GsmOptionType } from '../../types/GsmOptions.types'
import TabCardBodyGsmOptions from '../TabCardBodyGsmOptions'
import { GsmOptionsService } from '../../../../store/models/gsm/gsm-options-service.interface'
import {
  clearSelectedGsmOptionsService,
  removeGsmOptionsService,
  setGsmOptionsServicesModificationState,
  setGsmOptionsServicesSomeError,
} from '../../../../store/dispatch/gsm/gsm-options-service.dispatch'
import GsmOptionsServiceForm from './GsmOptionsServiceForm'
import CardHeaderGsmOptionsService from './components/CardHeaderGsmOptionsService'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  remove: bindActionCreators(removeGsmOptionsService, dispatch),
  clearSelected: bindActionCreators(clearSelectedGsmOptionsService, dispatch),
  setModificationState: bindActionCreators(setGsmOptionsServicesModificationState, dispatch),
  setSomeError: bindActionCreators(setGsmOptionsServicesSomeError, dispatch),
  updateCurrentPath: bindActionCreators(updateCurrentPath, dispatch),
  addNotification: bindActionCreators(addNotification, dispatch),
  setLoader: bindActionCreators(updateLoaderState, dispatch),
})

const mapStateToProps = ({ gsmOptionsService }: StateType) => ({
  options: gsmOptionsService,
})

type PropsPage = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    renderTabHeader: JSX.Element
    type: GsmOptionType
  }

const GsmOptionsServiceTab: React.FC<PropsPage> = ({
  options,
  remove,
  clearSelected,
  setLoader,
  setSomeError,
  renderTabHeader,
  setModificationState,
  type,
}) => {
  const errorHandler = useErrorHandlerHook(setSomeError, 'Опції сервісів')
  const deleteRequest = useHttpDeleteRequest({
    url: RequestUrlTypes.GSM_OPTIONS_SERVICE,
    base: URL_SERVER_GSM,
    func: remove,
  })

  const onRemove = async () => {
    if (!options.selected) {
      return
    }

    try {
      await deleteRequest({ id: options.selected._id })
      setModificationState(ModificationStatus.None)
      addNotification('Опції', ' видалено')
      clearSelected()
    } catch (e: any) {
      errorHandler({ text: e.message })
    } finally {
      setLoader(false)
    }
  }

  const details: OptionTypeDetails<GsmOptionsService> = {
    ...options,
    onRemove,
    setModificationState,
    headerTitle: 'Список сервісів',
    cardFormClass: 'col-xl-5 col-lg-5',
    cardTopClass: 'col-xl-7 col-lg-7',
    cardWrapperClass: 'row mt-5 flex-row-reverse',
  }

  if (type !== GsmOptionType.SERVICE) return <></>

  return (
    <OptionsTab
      details={details}
      renderForm={<GsmOptionsServiceForm />}
      topList={<GsmOptionsServiceList />}
      renderTabHeader={renderTabHeader}
      renderCardBody={TabCardBodyGsmOptions}
      cardHeader={CardHeaderGsmOptionsService}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(GsmOptionsServiceTab)
