import React from 'react'
import { connect } from 'react-redux'
import ListIsEmpty from '../../../../../common/components/ListIsEmpty'
import { StateType, ModificationStatus } from '../../../../../store/models/root.interface'
import { bindActionCreators, Dispatch } from 'redux'
import {
  changeSelectedTgOptionService,
  clearSelectedTgOptionService,
  setTgOptionServicesModificationState,
} from '../../../../../store/dispatch/tg/tg-options-service.dispatch'
import { TgOptionService } from '../../../../../store/models/tg/tg-options-service.interface'
import useFilteringListHook from '../../../../../hooks/filter-hook'
import { OptionsReceiver } from '../../../../../utils/option-receiver'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  clearSelected: bindActionCreators(clearSelectedTgOptionService, dispatch),
  changeSelected: bindActionCreators(changeSelectedTgOptionService, dispatch),
  setModificationState: bindActionCreators(setTgOptionServicesModificationState, dispatch),
})

const mapStateToProps = ({ tgOptionsService, tgOptionsCountry }: StateType) => ({
  options: tgOptionsService,
  countries: tgOptionsCountry.list,
})

type PropsComponent = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const RenderListTgOptionsService: React.FC<PropsComponent> = ({
  options: { list, selected, filters },
  countries,
  clearSelected,
  changeSelected,
  setModificationState,
}): JSX.Element => {
  list = useFilteringListHook(list, filters)

  const getTgOptionCountryName = OptionsReceiver.getValueByKey(countries, 'shortName')

  const onSelect = (_id: string): void => {
    if (selected && selected._id === _id) {
      clearSelected()
    } else {
      changeSelected(_id)
    }

    setModificationState(ModificationStatus.None)
  }

  if (!list.length) return <ListIsEmpty />

  const productElements = list.map((item: TgOptionService, i: number) => {
    const { _id, icon } = item
    const addition = icon ? ` ${icon}` : icon

    return (
      <tr
        data-id={_id}
        className={`table-row pl-2 ${selected?._id === _id ? 'table-primary' : ''}`}
        onClick={() => onSelect(_id || '')}
        key={`product_${item._id}`}
      >
        <th scope='row'>{++i}</th>
        <td data-id={item.shortName}>{item.shortName}</td>
        <td data-id={item.name}>{item.name?.toUpperCase() ?? '' + addition}</td>
        <td data-id={item.country}>{getTgOptionCountryName(item.country, 'name')}</td>
      </tr>
    )
  })

  return (
    <div className='table-responsive portlet'>
      <table className='table m-0'>
        <thead className='thead-light'>
          <tr>
            <th scope='col'>№</th>
            <th scope='col'>Скорочення</th>
            <th scope='col'>Назва</th>
            <th scope='col'>Країна</th>
          </tr>
        </thead>
        <tbody>{productElements}</tbody>
      </table>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(RenderListTgOptionsService)
