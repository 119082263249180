import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { useHttpDateLoader } from '../../../../hooks/request.hook'
import { RequestUrlTypes } from '../../../../hooks/types/request.types'
import { useErrorHandlerHook } from '../../../../hooks/error-handler'
import {
  getTgOptionServicesList,
  setTgOptionServiceSomeError,
} from '../../../../store/dispatch/tg/tg-options-service.dispatch'
import { DispatchTgOptionsServiceListProps } from './types/TgOptionsServiceList.types'
import RenderListTgOptionsService from './components/RenderListTgOptionsService'
import { TgOptionService } from '../../../../store/models/tg/tg-options-service.interface'
import { URL_SERVER_TG } from '../../../../config'
import TgOptionsServiceWrapper from './components/TgOptionsServiceWrapper'
const mapDispatchToProps = (dispatch: Dispatch): DispatchTgOptionsServiceListProps => ({
  setList: bindActionCreators(getTgOptionServicesList, dispatch),
  setSomeError: bindActionCreators(setTgOptionServiceSomeError, dispatch),
})

type PropsComponent = ReturnType<typeof mapDispatchToProps>

const TgOptionsList: React.FC<PropsComponent> = ({ setList, setSomeError }): JSX.Element => {
  const errorHandler = useErrorHandlerHook(setSomeError, 'Тг опції сервісів')
  useHttpDateLoader<TgOptionService[]>({
    url: RequestUrlTypes.TG_OPTION_SERVICE,
    base: URL_SERVER_TG,
    func: setList,
    errorHandler,
  })

  return (
    <TgOptionsServiceWrapper>
      <RenderListTgOptionsService />
    </TgOptionsServiceWrapper>
  )
}

export default connect(null, mapDispatchToProps)(TgOptionsList)
