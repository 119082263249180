import { Dispatch } from 'redux'
import { ModificationStatus } from '../../models/root.interface'
import {
  GsmAnalyticsOption,
  GsmAnalyticsOptionFilters,
} from '../../models/gsm/gsm-analytics.interface'
import {
  changeSelectedGsmAnalyticsOptionAction,
  clearGsmAnalyticsFiltersAction,
  clearSelectedGsmAnalyticsOptionAction,
  editGsmAnalyticsFiltersAction,
  getGsmAnalyticsAction,
  setGsmAnalyticsModificationStateAction,
  setGsmAnalyticsSomeErrorAction,
} from '../../actions/gsm/gsm-analytics.actions'

type ActionItem = GsmAnalyticsOption
type FilterItem = GsmAnalyticsOptionFilters

export const getGsmAnalytics = (data: ActionItem[]) => (dispatch: Dispatch) => {
  dispatch(getGsmAnalyticsAction(data))
}
export const changeSelectedGsmAnalyticsOption = (value: string) => (dispatch: Dispatch) => {
  dispatch(changeSelectedGsmAnalyticsOptionAction(value))
}
export const clearSelectedGsmAnalyticsOption = () => (dispatch: Dispatch) => {
  dispatch(clearSelectedGsmAnalyticsOptionAction())
}
export const setGsmAnalyticsModificationState =
  (value: ModificationStatus) => (dispatch: Dispatch) => {
    dispatch(setGsmAnalyticsModificationStateAction(value))
  }
export const setGsmAnalyticsSomeError = (value: string) => (dispatch: Dispatch) => {
  dispatch(setGsmAnalyticsSomeErrorAction(value))
}

export const editGsmAnalyticsFilters = (value: FilterItem) => (dispatch: Dispatch) => {
  dispatch(editGsmAnalyticsFiltersAction(value))
}
export const clearGsmAnalyticsFilters = () => (dispatch: Dispatch) => {
  dispatch(clearGsmAnalyticsFiltersAction())
}
