import { createContext, useState, useMemo } from 'react'
import { ScrRentalRateDiary } from '../../../../store/models/scraper/scr-rental-rate-diary.interface'

type ScrRentalRateDiaryContextTypes = {
  updateSelectedList: (data: ScrRentalRateDiary[]) => void
  selectedList: ScrRentalRateDiary[]
}

type ScrRentalRateDiaryContextProps = {
  children: JSX.Element | JSX.Element[]
}

export const ScrRentalRateDiaryContext = createContext<ScrRentalRateDiaryContextTypes>(
  {} as ScrRentalRateDiaryContextTypes,
)

export default function ScrRentalRateDiaryContextWrapper({
  children,
}: ScrRentalRateDiaryContextProps) {
  const [selectedList, setSelectedList] = useState<ScrRentalRateDiary[]>([])

  const updateSelectedList = (value: ScrRentalRateDiary[]) => {
    setSelectedList(() => value)
  }

  const value = useMemo(
    () => ({
      selectedList,
      updateSelectedList,
    }),
    [selectedList],
  )

  return (
    <ScrRentalRateDiaryContext.Provider value={value}>
      {children}
    </ScrRentalRateDiaryContext.Provider>
  )
}
