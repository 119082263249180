import React from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { TgPromoCode } from '../../../../../store/models/tg/tg-promo-code.interface'
import { URL_SERVER_TG } from '../../../../../config'
import {
  getPromoCodesList,
  setPromoCodesSomeError,
} from '../../../../../store/dispatch/tg/tg-promo-codes.dispatch'
import { useErrorHandlerHook } from '../../../../../hooks/error-handler'
import { useHttpDateLoader } from '../../../../../hooks/request.hook'
import { RequestUrlTypes } from '../../../../../hooks/types/request.types'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setList: bindActionCreators(getPromoCodesList, dispatch),
  setSomeError: bindActionCreators(setPromoCodesSomeError, dispatch),
})

type PropsComponent = ReturnType<typeof mapDispatchToProps> & {
  children?: JSX.Element | JSX.Element[]
}

const TgPartnerProgramWrapper: React.FC<PropsComponent> = ({
  setList,
  setSomeError,
  children,
}): JSX.Element => {
  const errorHandler = useErrorHandlerHook(setSomeError, 'Промо коди')
  useHttpDateLoader<TgPromoCode[]>({
    url: RequestUrlTypes.TG_PROMO_CODE,
    base: URL_SERVER_TG,
    func: setList,
    errorHandler,
  })

  return <>{children}</>
}

export default connect(null, mapDispatchToProps)(TgPartnerProgramWrapper)
