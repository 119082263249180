import { Dispatch } from 'react'
import { useDispatch } from 'react-redux'
import { addNotificationAction } from '../store/actions/notifications.action'

type LoaderHookProps = {
  text: string
  title?: string
}

export const useErrorHandlerHook = (
  setErrorFunc: (value: string, title?: string) => void,
  title = 'Default notification title',
): ((props: LoaderHookProps) => void) => {
  const dispatch: Dispatch<any> = useDispatch()

  return (props: LoaderHookProps): void => {
    setErrorFunc(props.text)
    dispatch(addNotificationAction(props.title || title, props.text))
  }
}
