import {
  IActionBase,
  ModificationStatus,
  ScrRentalRateDiaryState,
} from '../../models/root.interface'
import { scrRentalRateDiaryTypes } from '../../types/scraper/scr-rental-rate-diary.types'

type State = ScrRentalRateDiaryState

const initialState: State = {
  modificationState: ModificationStatus.None,
  selected: null,
  error: null,
  list: [],
}

export default (state: State = initialState, action: IActionBase): State => {
  switch (action.type) {
    case scrRentalRateDiaryTypes.GET_ITEMS_LIST: {
      return { ...state, error: null, list: action.value, selected: null }
    }
    case scrRentalRateDiaryTypes.CHANGE_ITEM_PENDING_EDIT: {
      const foundIndex: number = state.list.findIndex((pr) => pr._id === action.value)

      return { ...state, error: null, selected: state.list[foundIndex] }
    }
    case scrRentalRateDiaryTypes.CLEAR_ITEM_PENDING_EDIT: {
      return { ...state, error: null, selected: null }
    }
    case scrRentalRateDiaryTypes.SET_MODIFICATION_STATE_ITEM: {
      return { ...state, error: null, modificationState: action.value }
    }
    case scrRentalRateDiaryTypes.SET_SOME_ERROR_ITEM: {
      return { ...state, error: action.value }
    }
    default:
      return state
  }
}
