import React, { ChangeEvent } from 'react'
import { ISelectOption } from './types/Select.types'

function SelectField<T>(props: {
  setValue: (value: T) => void
  id: string
  value: T
  field: keyof T
  options: string[] | ISelectOption[]
  required?: boolean
  label?: string
  selectClass?: string
}): JSX.Element {
  const getterOptions: JSX.Element[] = props.options.map((option) => {
    const value = typeof option === 'string' ? option : option.value
    const text = typeof option === 'string' ? option : option.text

    return (
      <option key={value} value={value}>
        {text}
      </option>
    )
  })

  const onValueChanged = (event: ChangeEvent<HTMLSelectElement>): void => {
    props.setValue({ ...props.value, [props.field]: event.target.value })
  }

  return (
    <>
      {props.label ? <label htmlFor={props.id}>{props.label}</label> : null}
      <select
        value={String(props.value[props.field])}
        id={props.id}
        required={props.required}
        className={`form-control ${props.selectClass || ''}`}
        onChange={onValueChanged}
      >
        <option value=''>Choose...</option>
        {getterOptions}
      </select>
    </>
  )
}

export default SelectField
