import { Maybe } from 'yup'

export class ScrConfiguration {
  _id: Maybe<string | undefined>
  name = 'service-price-modifier'
  beforeSingleChanges = 0
  beforeMultiChanges = 0
  beforeProcess = 0
  numberCycles = 0
}
