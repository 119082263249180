import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { updateCurrentPath } from '../../store/dispatch/root.dispatch'
import TabHeaderTgOptions from './components/TabHeaderTgOptions'
import TgCurrenciesTab from './components/currencies/TgCurrenciesTab'
import { DispatchTgOptionsProps, TgOptionType } from './types/TgOptions.types'
import TgPartnerProgramsTab from './components/partner-programs/TgPartnerProgramsTab'
import TgPromCodesTab from './components/promo-codes/TgPromoCodesTab'
import TgBotWrapper from './components/TgBotWrapper'
import TgOptionsCountryTab from './components/countries/TgOptionsCountryTab'
import TgOptionsServiceTab from './components/services/TgOptionsServiceTab'
import TgInternalBankTab from './components/internal-banks/TgInternalBankTab'
import TgMailingTab from './components/mailing/TgMailingTab'

const mapDispatchToProps = (dispatch: Dispatch): DispatchTgOptionsProps => ({
  updateCurrentPath: bindActionCreators(updateCurrentPath, dispatch),
})

type PropsPage = ReturnType<typeof mapDispatchToProps>

const TgOptions: React.FC<PropsPage> = ({ updateCurrentPath }) => {
  const [type, setType] = useState<TgOptionType>(TgOptionType.COUNTRY)
  const details = { renderTabHeader: TabHeaderTgOptions({ type, setType }), type }

  useEffect(() => {
    updateCurrentPath('scraper', type)
  }, [type])

  return (
    <TgBotWrapper>
      <TgCurrenciesTab {...details} />
      <TgPartnerProgramsTab {...details} />
      <TgPromCodesTab {...details} />
      <TgOptionsCountryTab {...details} />
      <TgOptionsServiceTab {...details} />
      <TgInternalBankTab {...details} />
      <TgMailingTab {...details} />
    </TgBotWrapper>
  )
}

export default connect(null, mapDispatchToProps)(TgOptions)
