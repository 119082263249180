import React from 'react'
import './App.css'
import './styles/sb-admin-2.min.css'
import { BrowserRouter } from 'react-router-dom'
import Admin from './components/Admin/Admin'
import CustomLoader from './components/Loader/Loader'
import { connect } from 'react-redux'

declare global {
  interface Crypto {
    randomUUID: () => string
  }
}
const App: React.FC = () => {
  return (
    <div className='App' id='wrapper'>
      <CustomLoader />
      <BrowserRouter>
        <Admin />
      </BrowserRouter>
    </div>
  )
}

export default connect(null, null)(App)
