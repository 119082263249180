import { ModificationStatus } from '../../models/root.interface'
import {
  GsmOptionsService,
  GsmOptionsServiceFilters,
} from '../../models/gsm/gsm-options-service.interface'
import { gsmOptionsServiceTypes } from '../../types/gsm/gsm-options-service.types'

type ActionItem = GsmOptionsService
type FilterItem = GsmOptionsServiceFilters

export function getGsmOptionsServicesListAction(
  value: ActionItem[],
): IGetItemsActionType<ActionItem> {
  return { type: gsmOptionsServiceTypes.GET_ITEMS_LIST, value }
}

export function addGsmOptionsServiceAction(value: ActionItem): IAddItemActionType<ActionItem> {
  return { type: gsmOptionsServiceTypes.ADD_NEW_ITEM, value }
}

export function editGsmOptionsServiceAction(value: ActionItem): IEditItemActionType<ActionItem> {
  return { type: gsmOptionsServiceTypes.EDIT_ITEM, value }
}

export function removeGsmOptionsServiceAction(value: string): IRemoveItemActionType {
  return { type: gsmOptionsServiceTypes.REMOVE_ITEM, value }
}

export function changeSelectedGsmOptionsServiceAction(
  value: string,
): IChangeSelectedItemActionType {
  return { type: gsmOptionsServiceTypes.CHANGE_ITEM_PENDING_EDIT, value }
}

export function clearSelectedGsmOptionsServiceAction(): IClearSelectedItemActionType {
  return { type: gsmOptionsServiceTypes.CLEAR_ITEM_PENDING_EDIT }
}

export function setGsmOptionsServicesModificationStateAction(
  value: ModificationStatus,
): ISetModificationStateActionType {
  return { type: gsmOptionsServiceTypes.SET_ITEM_MODIFICATION_STATE, value: value }
}

export function setGsmOptionsServicesSomeErrorAction(value: string): ISetSomeErrorActionType {
  return { type: gsmOptionsServiceTypes.SET_ITEMS_SOME_ERROR, value: value }
}

export function editGsmOptionsServiceFiltersAction(
  value: FilterItem,
): IEditFiltersTypeActionType<FilterItem> {
  return { type: gsmOptionsServiceTypes.EDIT_ITEMS_FILTER, value }
}

export function clearGsmOptionsServiceFiltersAction(): IClearFiltersType {
  return { type: gsmOptionsServiceTypes.CLEAR_ITEMS_FILTERS }
}

interface IGetItemsActionType<T> {
  type: string
  value: T[]
}

interface IAddItemActionType<T> {
  type: string
  value: T
}

interface IEditItemActionType<T> {
  type: string
  value: T
}

interface IRemoveItemActionType {
  type: string
  value: string
}

interface IChangeSelectedItemActionType {
  type: string
  value: string
}

interface IClearSelectedItemActionType {
  type: string
}

interface ISetModificationStateActionType {
  type: string
  value: ModificationStatus
}

interface ISetSomeErrorActionType {
  type: string
  value: string
}

interface IEditFiltersTypeActionType<T> {
  type: string
  value: T
}
interface IClearFiltersType {
  type: string
}
