import React from 'react'
import { SelectProps } from './types/Select.types'

function SelectFieldRH<T>(props: SelectProps<T>): JSX.Element {
  const error = (props.formState.errors[props.field]?.message as string) || ''

  const getOptions: (JSX.Element | null)[] = props.options.map((option) => {
    const value = typeof option === 'string' ? option : option.value
    const text = typeof option === 'string' ? option : option.text

    return (
      <option key={value} value={value}>
        {text}
      </option>
    )
  })

  return (
    <>
      <label htmlFor={props.id} className='form-label mb-2'>
        {props.label}
      </label>
      <select
        className='form-control'
        id={props.id}
        value={props.selected}
        required={props.disabled ? false : Boolean(props.required)}
        disabled={props.disabled}
        {...props.register(props.field, {
          required: props.required,
        })}
      >
        <option value=''>Choose...</option>
        {getOptions}
      </select>

      {error && props.required ? <div className='invalid-feedback'>{error}</div> : null}
    </>
  )
}

export default SelectFieldRH
