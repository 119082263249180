import React from 'react'
import { connect } from 'react-redux'
import { ScrRentalRate } from '../../../../store/models/scraper/scr-rental-rate.interface'
import { bindActionCreators, Dispatch } from 'redux'
import {
  getRentalRatesList,
  setRentalRatesSomeError,
} from '../../../../store/dispatch/scraper/scr-rental-rate.dispatch'
import { RequestUrlTypes } from '../../../../hooks/types/request.types'
import { useHttpDateLoader } from '../../../../hooks/request.hook'
import RenderListRentalRate from './components/RenderListRentalRate'
import { useErrorHandlerHook } from '../../../../hooks/error-handler'
import { URL_SERVER_SCRAPER } from '../../../../config'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setList: bindActionCreators(getRentalRatesList, dispatch),
  setSomeError: bindActionCreators(setRentalRatesSomeError, dispatch),
})

type PropsRentalRateListComponent = ReturnType<typeof mapDispatchToProps>

const RentalRateList: React.FC<PropsRentalRateListComponent> = ({
  setSomeError,
  setList,
}): JSX.Element => {
  const errorHandler = useErrorHandlerHook(setSomeError, 'Оренда сервісів')
  useHttpDateLoader<ScrRentalRate[]>({
    url: RequestUrlTypes.SCRAPER_RENTAL_RATE,
    base: URL_SERVER_SCRAPER,
    func: setList,
    errorHandler,
  })

  return <RenderListRentalRate />
}
export default connect(null, mapDispatchToProps)(RentalRateList)
