import DateRH from '../../../../../common/form-hook/Date'
import React from 'react'
import { UseFormReturn } from 'react-hook-form/dist/types/form'
import { PartnerProgram } from '../../../../../store/models/tg/tg-partner-program.interface'

const RenderEndDate = (
  props: Pick<UseFormReturn<PartnerProgram>, 'watch' | 'register' | 'formState'>,
) => {
  const termination = props.watch('termination')
  if (!termination || (termination && termination === 'select-period')) return <></>

  return (
    <div className='form-group col-md-6'>
      <DateRH
        {...props}
        id='input_date'
        field='date'
        required='Value date cannot be empty'
        label='Дата'
        min={true}
      />
    </div>
  )
}

export default RenderEndDate
