import { Dispatch } from 'redux'
import { ModificationStatus } from '../../models/root.interface'
import {
  GsmOptionsService,
  GsmOptionsServiceFilters,
} from '../../models/gsm/gsm-options-service.interface'
import {
  addGsmOptionsServiceAction,
  changeSelectedGsmOptionsServiceAction,
  clearGsmOptionsServiceFiltersAction,
  clearSelectedGsmOptionsServiceAction,
  editGsmOptionsServiceAction,
  editGsmOptionsServiceFiltersAction,
  getGsmOptionsServicesListAction,
  removeGsmOptionsServiceAction,
  setGsmOptionsServicesModificationStateAction,
  setGsmOptionsServicesSomeErrorAction,
} from '../../actions/gsm/gsm-options-service.actions'

type ActionItem = GsmOptionsService
type FilterItem = GsmOptionsServiceFilters

export const getGsmOptionsServicesList = (data: ActionItem[]) => (dispatch: Dispatch) => {
  dispatch(getGsmOptionsServicesListAction(data))
}
export const addGsmOptionsService = (data: ActionItem) => (dispatch: Dispatch) => {
  dispatch(addGsmOptionsServiceAction(data))
}
export const editGsmOptionsService = (value: ActionItem) => (dispatch: Dispatch) => {
  dispatch(editGsmOptionsServiceAction(value))
}
export const removeGsmOptionsService = (value: string) => (dispatch: Dispatch) => {
  dispatch(removeGsmOptionsServiceAction(value))
}
export const changeSelectedGsmOptionsService = (value: string) => (dispatch: Dispatch) => {
  dispatch(changeSelectedGsmOptionsServiceAction(value))
}
export const clearSelectedGsmOptionsService = () => (dispatch: Dispatch) => {
  dispatch(clearSelectedGsmOptionsServiceAction())
}
export const setGsmOptionsServicesModificationState =
  (value: ModificationStatus) => (dispatch: Dispatch) => {
    dispatch(setGsmOptionsServicesModificationStateAction(value))
  }
export const setGsmOptionsServicesSomeError = (value: string) => (dispatch: Dispatch) => {
  dispatch(setGsmOptionsServicesSomeErrorAction(value))
}

export const editGsmOptionsServicesFilters = (value: FilterItem) => (dispatch: Dispatch) => {
  dispatch(editGsmOptionsServiceFiltersAction(value))
}
export const clearGsmOptionsServicesFilters = () => (dispatch: Dispatch) => {
  dispatch(clearGsmOptionsServiceFiltersAction())
}
