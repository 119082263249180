import React, { useEffect } from 'react'
import './styles/Services.css'
import { connect } from 'react-redux'
import { ModificationStatus, StateType } from '../../../../store/models/root.interface'
import { bindActionCreators, Dispatch } from 'redux'
import {
  clearSelectedScrService,
  removeScrService,
  setScrServiceModificationState,
  setScrServiceSomeError,
} from '../../../../store/dispatch/scraper/scr-service.dispatch'
import { addNotification } from '../../../../store/dispatch/notifications.dispatch'
import { updateCurrentPath, updateLoaderState } from '../../../../store/dispatch/root.dispatch'
import { ScrService } from '../../../../store/models/scraper/scr-service.interface'
import { RequestUrlTypes } from '../../../../hooks/types/request.types'
import { useHttpDeleteRequest } from '../../../../hooks/request.hook'
import OptionsTab from '../../../../common/components/Tab'
import { OptionTypeDetails } from '../../../../common/components/types/Tab.types'
import ScrServiceTabHeader from './components/ScrServiceTabHeader'
import TabCardHeaderService from './components/ScrServiceCardHeader'
import { useErrorHandlerHook } from '../../../../hooks/error-handler'
import { ScrapperOptionType, ScrapperTabCustomProps } from '../../types/ScraperOptions.type'
import { URL_SERVER_SCRAPER } from '../../../../config'
import ScrServiceForm from './ScrServiceForm'
import ScrServicesList from './ScrServicesList'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  clearSelected: bindActionCreators(clearSelectedScrService, dispatch),
  remove: bindActionCreators(removeScrService, dispatch),
  setModificationState: bindActionCreators(setScrServiceModificationState, dispatch),
  addNotification: bindActionCreators(addNotification, dispatch),
  updateCurrentPath: bindActionCreators(updateCurrentPath, dispatch),
  setSomeError: bindActionCreators(setScrServiceSomeError, dispatch),
  updateLoader: bindActionCreators(updateLoaderState, dispatch),
})

const mapStateToProps = ({ scrService, root }: StateType) => ({ scrService, path: root.page })

type PageProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  ScrapperTabCustomProps

const ScrServicesTab: React.FC<PageProps> = ({
  scrService,
  path,
  remove,
  updateLoader,
  setModificationState,
  clearSelected,
  updateCurrentPath,
  setSomeError,
  type,
  renderTabHeader,
}) => {
  const { list } = scrService
  const errorHandler = useErrorHandlerHook(setSomeError, 'Сервіси')
  const deleteRequest = useHttpDeleteRequest({
    url: RequestUrlTypes.SCRAPER_SERVICE,
    base: URL_SERVER_SCRAPER,
    func: remove,
  })

  const onRemove = async () => {
    const { selected } = scrService

    if (!selected) {
      return
    }

    try {
      await deleteRequest({ id: selected._id })
      clearSelected()
      setModificationState(ModificationStatus.None)
      addNotification('Сервіс', 'видалено')
    } catch (e: any) {
      errorHandler({ text: e.message })
    } finally {
      updateLoader(false)
    }
  }

  useEffect(() => {
    clearSelected()
    updateCurrentPath('services', 'list')
  }, [path.area])

  const details: OptionTypeDetails<ScrService> = {
    ...scrService,
    onRemove,
    setModificationState,
    headerTitle: 'Список слотів',
    bottomDescription: 'Список сервісів',
    cardFormClass: 'col-xl-5 col-lg-5',
    cardTopClass: 'col-xl-7 col-lg-7',
    cardWrapperClass: 'row mt-5 flex-row-reverse',
  }

  if (type !== ScrapperOptionType.SCR_SERVICE) return <></>

  return (
    <OptionsTab
      details={details}
      renderForm={<ScrServiceForm />}
      topList={<ScrServicesList />}
      cardHeader={TabCardHeaderService}
      renderTabHeader={<ScrServiceTabHeader list={list} renderTabHeader={renderTabHeader} />}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ScrServicesTab)
