import React from 'react'
import { connect } from 'react-redux'
import { ModificationStatus, StateType } from '../../store/models/root.interface'
import { bindActionCreators, Dispatch } from 'redux'
import { addNotification } from '../../store/dispatch/notifications.dispatch'
import { RequestUrlTypes } from '../../hooks/types/request.types'
import { useHttpRequestWithBody } from '../../hooks/request.hook'
import { useForm } from 'react-hook-form'
import { TabFormWrapperProps } from '../../common/components/types/TabFormWrapper.types'
import TabFormWrapper from '../../common/components/TabFormWrapper'
import RenderFormTgCustomers from './components/RenderFormTgCustomers'
import { useErrorHandlerHook } from '../../hooks/error-handler'
import { TgCustomer } from '../../store/models/tg/tg-customer.interface'
import {
  clearSelectedTgCustomer,
  editTgCustomer,
  setTgCustomersModificationState,
  setTgCustomersSomeError,
} from '../../store/dispatch/tg/tg-customers.dispatch'
import { URL_SERVER_TG } from '../../config'
import { updateLoaderState } from '../../store/dispatch/root.dispatch'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setSomeError: bindActionCreators(setTgCustomersSomeError, dispatch),
  clearSelected: bindActionCreators(clearSelectedTgCustomer, dispatch),
  setModificationState: bindActionCreators(setTgCustomersModificationState, dispatch),
  edit: bindActionCreators(editTgCustomer, dispatch),
  addNotification: bindActionCreators(addNotification, dispatch),
  setLoader: bindActionCreators(updateLoaderState, dispatch),
})

const mapStateToProps = ({ tgCustomers }: StateType) => ({ customers: tgCustomers })

type PropsComponent = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const TgCustomersForm: React.FC<PropsComponent> = ({
  customers,
  setSomeError,
  clearSelected,
  setLoader,
  setModificationState,
  edit,
  addNotification,
}) => {
  const { selected, error, modificationState } = customers
  const errorHandler = useErrorHandlerHook(setSomeError, 'Слот')
  const isCreate: boolean = modificationState === ModificationStatus.Create
  const saveRequest = useHttpRequestWithBody<TgCustomer>({
    url: RequestUrlTypes.TG_CUSTOMERS,
    func: edit,
    base: URL_SERVER_TG,
  })

  const item: TgCustomer | null = selected

  if (!item) return <></>

  const form = useForm<TgCustomer>({ defaultValues: item })

  const saveForm = async (formState: TgCustomer): Promise<void> => {
    if (item) {
      try {
        await saveRequest({
          body: {
            ...item,
            ...formState,
          },
          id: item?._id ? String(item?._id) : '',
        })

        addNotification('Повідомлення', isCreate ? 'Створено.' : 'Редаговано.')
        clearSelected()
        setModificationState(ModificationStatus.None)
      } catch (e: any) {
        errorHandler({ text: e.message })
      } finally {
        setLoader(false)
      }
    }
  }

  const resetForm = (): void => {
    setModificationState(ModificationStatus.None)
    form.reset()
  }

  const formOptions: TabFormWrapperProps<TgCustomer> = {
    form,
    error,
    title: 'слоту',
    isCreate,
    saveForm,
    resetForm,
  }

  return (
    <TabFormWrapper {...formOptions}>
      <RenderFormTgCustomers form={form} />
    </TabFormWrapper>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(TgCustomersForm)
