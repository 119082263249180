import React from 'react'
import { TabCardBodyProps } from './types/CardBody.types'
import ListIsEmpty from './ListIsEmpty'

const TabCardBody = ({ list }: TabCardBodyProps) => {
  if (!list) return <ListIsEmpty />

  return <div className='card-body'>{list}</div>
}

export default TabCardBody
