import { Dispatch } from 'redux'
import { ModificationStatus } from '../../models/root.interface'
import { TgInternalBank } from '../../models/tg/tg-internal-bank.interface'
import {
  addTgInternalBankAction,
  changeSelectedTgInternalBankAction,
  clearSelectedTgInternalBankAction,
  editTgInternalBankAction,
  getTgInternalBanksListAction,
  removeTgInternalBankAction,
  setTgInternalBanksModificationStateAction,
  setTgInternalBankSomeErrorAction,
} from '../../actions/tg/tg-internal-bank.action'
type ActionItem = TgInternalBank

export const getTgInternalBanksList = (data: ActionItem[]) => (dispatch: Dispatch) => {
  dispatch(getTgInternalBanksListAction(data))
}
export const addTgInternalBank = (data: ActionItem) => (dispatch: Dispatch) => {
  dispatch(addTgInternalBankAction(data))
}
export const editTgInternalBank = (value: ActionItem) => (dispatch: Dispatch) => {
  dispatch(editTgInternalBankAction(value))
}
export const removeTgInternalBank = (value: string) => (dispatch: Dispatch) => {
  dispatch(removeTgInternalBankAction(value))
}
export const changeSelectedTgInternalBank = (value: string) => (dispatch: Dispatch) => {
  dispatch(changeSelectedTgInternalBankAction(value))
}
export const clearSelectedTgInternalBank = () => (dispatch: Dispatch) => {
  dispatch(clearSelectedTgInternalBankAction())
}
export const setTgInternalBanksModificationState =
  (value: ModificationStatus) => (dispatch: Dispatch) => {
    dispatch(setTgInternalBanksModificationStateAction(value))
  }
export const setTgInternalBankSomeError = (value: string) => (dispatch: Dispatch) => {
  dispatch(setTgInternalBankSomeErrorAction(value))
}
