import { ModificationStatus } from '../../models/root.interface'
import { TgInternalBank } from '../../models/tg/tg-internal-bank.interface'
import { tgInternalBankTypes } from '../../types/tg/tg-internal-bank.types'

type ActionItem = TgInternalBank
export function getTgInternalBanksListAction(value: ActionItem[]): IGetItemsActionType<ActionItem> {
  return { type: tgInternalBankTypes.GET_TG_INTERNAL_BANK_LIST, value }
}
export function addTgInternalBankAction(value: ActionItem): IAddItemActionType<ActionItem> {
  return { type: tgInternalBankTypes.ADD_TG_INTERNAL_BANK_LIST, value }
}
export function editTgInternalBankAction(value: ActionItem): IEditItemActionType<ActionItem> {
  return { type: tgInternalBankTypes.EDIT_TG_INTERNAL_BANK_LIST, value }
}
export function removeTgInternalBankAction(value: string): IRemoveItemActionType {
  return { type: tgInternalBankTypes.REMOVE_TG_INTERNAL_BANK_LIST, value }
}
export function changeSelectedTgInternalBankAction(value: string): IChangeSelectedItemActionType {
  return { type: tgInternalBankTypes.CHANGE_TG_INTERNAL_BANK_PENDING_EDIT, value }
}
export function clearSelectedTgInternalBankAction(): IClearSelectedItemActionType {
  return { type: tgInternalBankTypes.CLEAR_TG_INTERNAL_BANK_PENDING_EDIT }
}
export function setTgInternalBanksModificationStateAction(
  value: ModificationStatus,
): ISetModificationStateActionType {
  return { type: tgInternalBankTypes.SET_TG_INTERNAL_BANK_MODIFICATION_STATE, value: value }
}
export function setTgInternalBankSomeErrorAction(value: string): ISetSomeErrorActionType {
  return { type: tgInternalBankTypes.SET_TG_INTERNAL_BANK_SOME_ERROR, value: value }
}
interface IGetItemsActionType<T> {
  type: string
  value: T[]
}

interface IAddItemActionType<T> {
  type: string
  value: T
}

interface IEditItemActionType<T> {
  type: string
  value: T
}

interface IRemoveItemActionType {
  type: string
  value: string
}

interface IChangeSelectedItemActionType {
  type: string
  value: string
}

interface IClearSelectedItemActionType {
  type: string
}

interface ISetModificationStateActionType {
  type: string
  value: ModificationStatus
}

interface ISetSomeErrorActionType {
  type: string
  value: string
}
