import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { ModificationStatus, StateType } from '../../../../store/models/root.interface'
import {
  clearSelectedRentalRate,
  removeRentalRate,
  setRentalRatesModificationState,
  setRentalRatesSomeError,
} from '../../../../store/dispatch/scraper/scr-rental-rate.dispatch'
import RentalRateList from './RentalRateList'
import { bindActionCreators, Dispatch } from 'redux'
import { updateCurrentPath, updateLoaderState } from '../../../../store/dispatch/root.dispatch'
import { addNotification } from '../../../../store/dispatch/notifications.dispatch'
import { RequestUrlTypes } from '../../../../hooks/types/request.types'
import RentalRateForm from './RentalRateForm'
import { useHttpDeleteRequest } from '../../../../hooks/request.hook'
import OptionsTab from '../../../../common/components/Tab'
import { OptionTypeDetails } from '../../../../common/components/types/Tab.types'
import { useErrorHandlerHook } from '../../../../hooks/error-handler'
import { URL_SERVER_SCRAPER } from '../../../../config'
import { ScrRentalRate } from '../../../../store/models/scraper/scr-rental-rate.interface'
import { ScrapperOptionType, ScrapperTabCustomProps } from '../../types/ScraperOptions.type'
import TabCardBodyScraper from '../ScrCardBody'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  clearSelected: bindActionCreators(clearSelectedRentalRate, dispatch),
  setModificationState: bindActionCreators(setRentalRatesModificationState, dispatch),
  remove: bindActionCreators(removeRentalRate, dispatch),
  updateCurrentPath: bindActionCreators(updateCurrentPath, dispatch),
  addNotification: bindActionCreators(addNotification, dispatch),
  setSomeError: bindActionCreators(setRentalRatesSomeError, dispatch),
  updateLoader: bindActionCreators(updateLoaderState, dispatch),
})

const mapStateToProps = ({ scrRentalRate, root }: StateType) => ({ scrRentalRate, path: root.page })

type PageProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  ScrapperTabCustomProps

const RentalRateTab: React.FC<PageProps> = ({
  scrRentalRate,
  updateLoader,
  addNotification,
  path,
  clearSelected,
  setModificationState,
  remove,
  updateCurrentPath,
  setSomeError,
  renderTabHeader,
  type,
}) => {
  const errorHandler = useErrorHandlerHook(setSomeError, 'Оренда сім карт')
  const deleteRequest = useHttpDeleteRequest({
    url: RequestUrlTypes.SCRAPER_RENTAL_RATE,
    base: URL_SERVER_SCRAPER,
    func: remove,
  })

  const onRemove = async () => {
    if (!scrRentalRate.selected) {
      return
    }

    try {
      await deleteRequest({ id: scrRentalRate.selected._id })
      addNotification('Оренда сім карт', 'видалено')
      setModificationState(ModificationStatus.None)
      clearSelected()
    } catch (e: any) {
      errorHandler({ text: e.message })
    } finally {
      updateLoader(false)
    }
  }

  useEffect(() => {
    clearSelected()
    updateCurrentPath('rental-rates', 'list')
  }, [path.area])

  const details: OptionTypeDetails<ScrRentalRate> = {
    ...scrRentalRate,
    onRemove,
    setModificationState,
    headerTitle: 'Список країн оренди',
    cardFormClass: 'col-xl-5 col-lg-5',
    cardTopClass: 'col-xl-7 col-lg-7',
    cardWrapperClass: 'row mt-5 flex-row-reverse',
  }

  if (type !== ScrapperOptionType.SCR_RENTAL_RATES) return <></>

  return (
    <OptionsTab
      details={details}
      renderForm={<RentalRateForm />}
      topList={<RentalRateList />}
      renderCardBody={TabCardBodyScraper}
      renderTabHeader={renderTabHeader}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(RentalRateTab)
