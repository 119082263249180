export type DispatchTgOptionsProps = {
  updateCurrentPath: (area: string, subArea: string) => void
}

export type TgTabCustomProps = {
  renderTabHeader: JSX.Element
  type: TgOptionType
}

export enum TgOptionType {
  COUNTRY = 'country',
  MAILING = 'mailing',
  SERVICE = 'service',
  CURRENCY = 'currency',
  PROMO_CODE = 'promo-code',
  PARTNER_PROGRAM = 'partner-program',
  INTERNAL_BANK = 'internal-bank',
}
