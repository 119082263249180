import { ScrService, ScrServiceFilters } from '../../models/scraper/scr-service.interface'
import { scrServiceTypes } from '../../types/scraper/scr-service.types'
import { ModificationStatus } from '../../models/root.interface'

type ActionItem = ScrService
type ItemFilters = ScrServiceFilters

export function getScrServiceAction(value: ActionItem[]): IGetItemsActionType<ActionItem> {
  return { type: scrServiceTypes.GET_ITEMS_LIST, value }
}

export function addScrServiceAction(value: ActionItem): IAddItemActionType<ActionItem> {
  return { type: scrServiceTypes.ADD_NEW_ITEM, value }
}

export function editScrServiceAction(value: ActionItem): IEditItemActionType<ActionItem> {
  return { type: scrServiceTypes.EDIT_ITEM, value }
}

export function removeScrServiceAction(value: string): IRemoveItemActionType {
  return { type: scrServiceTypes.REMOVE_ITEM, value }
}

export function changeSelectedScrServiceAction(value: string): IChangeSelectedItemActionType {
  return { type: scrServiceTypes.CHANGE_ITEM_PENDING_EDIT, value }
}

export function clearSelectedScrServiceAction(): IClearSelectedItemActionType {
  return { type: scrServiceTypes.CLEAR_ITEM_PENDING_EDIT }
}

export function setScrServiceModificationStateAction(
  value: ModificationStatus,
): ISetModificationStateActionType {
  return { type: scrServiceTypes.SET_ITEM_MODIFICATION_STATE, value }
}

export function setScrServiceSomeErrorAction(value: string): ISetSomeErrorActionType {
  return { type: scrServiceTypes.SET_ITEMS_SOME_ERROR, value }
}

export function editScrServiceFiltersAction(
  value: ItemFilters,
): IEditFiltersTypeActionType<ItemFilters> {
  return { type: scrServiceTypes.EDIT_ITEMS_FILTER, value }
}

export function clearScrServiceFiltersAction(): IClearFiltersType {
  return { type: scrServiceTypes.CLEAR_ITEMS_FILTERS }
}

interface IGetItemsActionType<T> {
  type: string
  value: T[]
}

interface IAddItemActionType<T> {
  type: string
  value: T
}

interface IEditItemActionType<T> {
  type: string
  value: T
}

interface IRemoveItemActionType {
  type: string
  value: string
}

interface IChangeSelectedItemActionType {
  type: string
  value: string
}

interface IClearSelectedItemActionType {
  type: string
}

interface ISetModificationStateActionType {
  type: string
  value: ModificationStatus
}

interface ISetSomeErrorActionType {
  type: string
  value: string
}

interface IEditFiltersTypeActionType<T> {
  type: string
  value: T
}
interface IClearFiltersType {
  type: string
}
