import React from 'react'
import { connect } from 'react-redux'
import { StateType } from '../../../../store/models/root.interface'
import { bindActionCreators, Dispatch } from 'redux'
import {
  clearScrServiceDiaryFilters,
  editScrServiceDiaryFilters,
} from '../../../../store/dispatch/scraper/scr-service-diary.dispatch'
import RenderScrServiceDiaryFilters from './components/RenderScrServiceDiaryFilters'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  editFilters: bindActionCreators(editScrServiceDiaryFilters, dispatch),
  clearFilters: bindActionCreators(clearScrServiceDiaryFilters, dispatch),
})

const mapStateToProps = ({ scrServiceDiary, scrOptions }: StateType) => ({
  filters: scrServiceDiary.filters,
  services: scrOptions.servicesList,
  countries: scrOptions.countriesList,
})

type PropsComponent = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const ScrServicesFilterForm: React.FC<PropsComponent> = (props): JSX.Element => {
  return (
    <>
      <div className='form-group col-md-12 p-0'>
        <h6 className='m-0 font-weight-bold text-green'>Фільтри:</h6>
      </div>
      <RenderScrServiceDiaryFilters {...props} />
      <button
        type='button'
        className='btn btn-danger ml-auto col-md-12 h-50 mt-auto mb-3'
        onClick={props.clearFilters}
      >
        Скинути
      </button>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ScrServicesFilterForm)
