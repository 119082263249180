export const scrConfigurationTypes = {
  GET_SCRAPER_CONFIGURATION: 'GET_SCRAPER_CONFIGURATION',
  ADD_SCRAPER_CONFIGURATION: 'ADD_SCRAPER_CONFIGURATION',
  EDIT_SCRAPER_CONFIGURATION: 'EDIT_SCRAPER_CONFIGURATION',
  REMOVE_SCRAPER_CONFIGURATION: 'REMOVE_SCRAPER_CONFIGURATION',
  CHANGE_SCRAPER_CONFIGURATION_PENDING_EDIT: 'CHANGE_SCRAPER_CONFIGURATION_PENDING_EDIT',
  CLEAR_SCRAPER_CONFIGURATION_PENDING_EDIT: 'CLEAR_SCRAPER_CONFIGURATION_PENDING_EDIT',
  SET_MODIFICATION_STATE_SCRAPER_CONFIGURATION: 'SET_MODIFICATION_STATE_SCRAPER_CONFIGURATION',
  SET_SOME_ERROR_SCRAPER_CONFIGURATION: 'SET_SOME_ERROR_SCRAPER_CONFIGURATION',
}
