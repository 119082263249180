import React from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { useErrorHandlerHook } from '../../../hooks/error-handler'
import { useHttpDateLoaderArray, useHttpGetRequest } from '../../../hooks/request.hook'
import { GsmOptionsCountry } from '../../../store/models/gsm/gsm-options-country.interface'
import { RequestUrlTypes } from '../../../hooks/types/request.types'
import { URL_SERVER_GSM } from '../../../config'
import { GsmOptionsService } from '../../../store/models/gsm/gsm-options-service.interface'
import {
  getGsmOptionsServicesList,
  setGsmOptionsServicesSomeError,
} from '../../../store/dispatch/gsm/gsm-options-service.dispatch'
import {
  getGsmOptionsCountriesList,
  setGsmOptionsCountriesSomeError,
} from '../../../store/dispatch/gsm/gsm-options-country.dispatch'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setListC: bindActionCreators(getGsmOptionsCountriesList, dispatch),
  setSomeErrorC: bindActionCreators(setGsmOptionsCountriesSomeError, dispatch),
  setListS: bindActionCreators(getGsmOptionsServicesList, dispatch),
  setSomeErrorS: bindActionCreators(setGsmOptionsServicesSomeError, dispatch),
})

type PropsComponent = ReturnType<typeof mapDispatchToProps> & {
  children?: JSX.Element | JSX.Element[]
}

const TgBotWrapper: React.FC<PropsComponent> = ({
  setListC,
  setListS,
  setSomeErrorC,
  setSomeErrorS,
  children,
}): JSX.Element => {
  const errorHandlerS = useErrorHandlerHook(setSomeErrorS, 'Gsm опції сервісів')
  const errorHandlerC = useErrorHandlerHook(setSomeErrorC, 'Gsm опції країн')

  const requestOptionsS = useHttpGetRequest<GsmOptionsService[]>({
    url: RequestUrlTypes.GSM_OPTIONS_SERVICE,
    base: URL_SERVER_GSM,
    func: setListS,
    errorHandler: errorHandlerS,
  })

  const requestOptionsC = useHttpGetRequest<GsmOptionsCountry[]>({
    url: RequestUrlTypes.GSM_OPTIONS_COUNTRY,
    base: URL_SERVER_GSM,
    func: setListC,
    errorHandler: errorHandlerC,
  })

  useHttpDateLoaderArray([requestOptionsS, requestOptionsC])

  return <>{children}</>
}

export default connect(null, mapDispatchToProps)(TgBotWrapper)
