import React from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import {
  getGsmOptionsCountriesList,
  setGsmOptionsCountriesSomeError,
} from '../../../../../store/dispatch/gsm/gsm-options-country.dispatch'
import {
  getGsmOptionsServicesList,
  setGsmOptionsServicesSomeError,
} from '../../../../../store/dispatch/gsm/gsm-options-service.dispatch'
import { useErrorHandlerHook } from '../../../../../hooks/error-handler'
import { useHttpDateLoaderArray, useHttpGetRequest } from '../../../../../hooks/request.hook'
import { GsmOptionsService } from '../../../../../store/models/gsm/gsm-options-service.interface'
import { RequestUrlTypes } from '../../../../../hooks/types/request.types'
import { URL_SERVER_GSM } from '../../../../../config'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setListCountries: bindActionCreators(getGsmOptionsCountriesList, dispatch),
  setListServices: bindActionCreators(getGsmOptionsServicesList, dispatch),
  setSomeErrorCountries: bindActionCreators(setGsmOptionsCountriesSomeError, dispatch),
  setSomeErrorServices: bindActionCreators(setGsmOptionsServicesSomeError, dispatch),
})

type PropsComponent = ReturnType<typeof mapDispatchToProps> & {
  children?: JSX.Element | JSX.Element[]
}

const GsmOptionsSenderWrapper: React.FC<PropsComponent> = ({
  children,
  setSomeErrorServices,
  setListServices,
}): JSX.Element => {
  const errorHandlerServices = useErrorHandlerHook(setSomeErrorServices, 'Gsm опції сервісів')

  const requestServices = useHttpGetRequest<GsmOptionsService[]>({
    url: RequestUrlTypes.GSM_OPTIONS_SERVICE,
    base: URL_SERVER_GSM,
    func: setListServices,
    errorHandler: errorHandlerServices,
  })

  useHttpDateLoaderArray([requestServices])

  return <>{children}</>
}

export default connect(null, mapDispatchToProps)(GsmOptionsSenderWrapper)
