import React from 'react'
import { ScrOptionsService } from '../../../../store/models/scraper/scr-options-service.interface'
import { ScrOptionsCountry } from '../../../../store/models/scraper/scr-options-country.interface'
import { ScrOptionsState, StateType } from '../../../../store/models/root.interface'
import { useSelector } from 'react-redux'
import { OptionsReceiver } from '../../../../utils/option-receiver'
import ListIsEmpty from '../../../../common/components/ListIsEmpty'

const ServicesOptionsList = (): JSX.Element => {
  const options: ScrOptionsState = useSelector((state: StateType) => state.scrOptions)
  const { countriesList, servicesList } = options

  const getCountryName = OptionsReceiver.getValueByKey(options.countriesList, 'shortName')
  const servicesOptionsList = (country: number): JSX.Element[] =>
    servicesList
      .filter((item) => item.country === country)
      .map((service: ScrOptionsService) => {
        return (
          <tr className={'table-row'} key={`order_${service.shortName}`}>
            <th scope='row'>{service.shortName}</th>
            <th scope='row'>{getCountryName(String(service.country), 'name')}</th>
            <td>{service.name}</td>
          </tr>
        )
      })

  if (!countriesList.length) {
    return <ListIsEmpty />
  }

  return (
    <div className='accordion' id='services-accordion'>
      {countriesList.map((item: ScrOptionsCountry) => {
        const services = servicesOptionsList(item.shortName)
        return (
          <div className='card' key={item._id}>
            <div className='card-header' id='headingOne'>
              <h2 className='mb-0'>
                <button
                  className='btn btn-link'
                  type='button'
                  data-toggle='collapse'
                  data-target={`#services-accordion-country-id-${item.shortName}`}
                  aria-expanded='true'
                  aria-controls='collapseOne'
                >
                  Країна сервісів {item.name}
                </button>
              </h2>
            </div>
            <div
              id={`services-accordion-country-id-${item.shortName}`}
              className='collapse show'
              aria-labelledby='headingOne'
              data-parent='#services-accordion'
            >
              <div className='card-body'>
                {services.length ? (
                  <div className='table-responsive portlet'>
                    <table className='table'>
                      <thead className='thead-light'>
                        <tr>
                          <th scope='col'>#</th>
                          <th scope='col'>Країна</th>
                          <th scope='col'>SMS сервіс</th>
                        </tr>
                      </thead>
                      <tbody>{services}</tbody>
                    </table>
                  </div>
                ) : (
                  <strong>
                    Список сервісів країни <em>{item.name.toUpperCase()}</em> наразі пустий
                  </strong>
                )}
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default ServicesOptionsList
