import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { useHttpDateLoader } from '../../../../hooks/request.hook'
import { RequestUrlTypes } from '../../../../hooks/types/request.types'
import { useErrorHandlerHook } from '../../../../hooks/error-handler'
import { URL_SERVER_TG } from '../../../../config'
import {
  getTgInternalBanksList,
  setTgInternalBankSomeError,
} from '../../../../store/dispatch/tg/tg-internal-bank.dispatch'
import { DispatchTgInternalBankListProps } from './types/TgInternalBankList.types'
import { TgInternalBank } from '../../../../store/models/tg/tg-internal-bank.interface'
import RenderListTgInternalBank from './components/RenderListTgInternalBank'

const mapDispatchToProps = (dispatch: Dispatch): DispatchTgInternalBankListProps => ({
  setList: bindActionCreators(getTgInternalBanksList, dispatch),
  setSomeError: bindActionCreators(setTgInternalBankSomeError, dispatch),
})

type PropsComponent = ReturnType<typeof mapDispatchToProps>

const TgInternalBankList: React.FC<PropsComponent> = ({ setList, setSomeError }): JSX.Element => {
  const errorHandler = useErrorHandlerHook(setSomeError, 'Внутрішні тг банки')
  useHttpDateLoader<TgInternalBank[]>({
    url: RequestUrlTypes.TG_INTERNAL_BANK,
    base: URL_SERVER_TG,
    func: setList,
    errorHandler,
  })

  return <RenderListTgInternalBank />
}

export default connect(null, mapDispatchToProps)(TgInternalBankList)
