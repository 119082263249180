import React from 'react'
import { connect } from 'react-redux'
import { StateType } from '../../../../../store/models/root.interface'
import { CompetingRate } from '../../../../../store/models/scraper/scr-rental-rate-diary.interface'

const mapStateToProps = ({ scrRentalRateDiary }: StateType) => ({
  scrRentalRateDiary,
})

type PropsServicesListComponent = ReturnType<typeof mapStateToProps>

const RenderScrRentalRateDiaryCompetitors: React.FC<PropsServicesListComponent> = ({
  scrRentalRateDiary,
}): JSX.Element => {
  const selected = scrRentalRateDiary.selected
  if (!selected?.competitors.length) return <></>
  const date = new Date(selected.updatedAt).toLocaleString('uk-UA', {
    day: 'numeric',
    month: 'short',
    hour: '2-digit',
    minute: '2-digit',
  })

  const productElements = selected.competitors.map((element: CompetingRate, index: number) => {
    return (
      <tr className='table-row' key={crypto.randomUUID()}>
        <td>{element.percent}</td>
        <td>{element.part}</td>
        <td>{element.full}</td>
      </tr>
    )
  })

  return (
    <div className='card shadow mb-4'>
      <div className='card-header py-3'>
        <h6 className='m-0 font-weight-bold text-green'></h6>
      </div>
      <div className='card-body row'>
        <div className='table-responsive portlet col-md-7 p-1 table-bordered'>
          <table className='table'>
            <thead className='thead-light'>
              <tr>
                <th scope='col'>Парметри</th>
                <th scope='col'>Значення</th>
              </tr>
            </thead>
            <tbody>
              <tr className='table-row'>
                <td>Старий %</td>
                <td>{selected.percent}</td>
              </tr>
              <tr className='table-row'>
                <td>Новий %</td>
                <td>{selected.newPercent}</td>
              </tr>
              <tr className='table-row'>
                <td>К-сть. слоті</td>
                <td>{selected.count}</td>
              </tr>
              <tr className='table-row'>
                <td>Мін. %</td>
                <td>{selected.minPercent}</td>
              </tr>
              <tr className='table-row'>
                <td>Мін. к-сть. слотів</td>
                <td>{selected.minCount}</td>
              </tr>
              <tr className='table-row'>
                <td>Зміна ціни</td>
                <td>{date}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className='table-responsive portlet col-md-5 p-1 table-bordered'>
          <table className='table'>
            <thead className='thead-light'>
              <tr>
                <th scope='col'>%</th>
                <th scope='col'>Часткова</th>
                <th scope='col'>Повна</th>
              </tr>
            </thead>
            <tbody>{productElements}</tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps, null)(RenderScrRentalRateDiaryCompetitors)
