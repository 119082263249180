export const gsmAnalyticsTypes = {
  GET_ITEMS_LIST: 'GET_GSM_ANALYTICS_LIST',
  CHANGE_ITEM_PENDING_EDIT: 'CHANGE_GSM_ANALYTICS_PENDING_EDIT',
  CLEAR_ITEM_PENDING_EDIT: 'CLEAR_GSM_ANALYTICS_PENDING_EDIT',
  SET_ITEM_MODIFICATION_STATE: 'SET_GSM_ANALYTICS_MODIFICATION_STATE',
  SET_ITEMS_SOME_ERROR: 'SET_GSM_ANALYTICS_SOME_ERROR',

  EDIT_ITEMS_FILTER: 'EDIT_SM_ANALYTICS_FILTER',
  CLEAR_ITEMS_FILTERS: 'CLEAR_SM_ANALYTICS_FILTERS',
}
