import React, { Dispatch } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { StateType } from '../../store/models/root.interface'
import { INotification } from '../../store/models/notification.interface'
import { removeNotificationAction } from '../../store/actions/notifications.action'

const Notifications: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch()
  const notifications: INotification[] = useSelector(
    (state: StateType) => state.notifications.notifications,
  )

  const closeNotification = (id: number) => {
    dispatch(removeNotificationAction(id))
  }

  const notificationList = notifications.map((notification) => {
    setTimeout(() => closeNotification(notification.id), 10000)

    return (
      <div className='toast show' key={`notification_${notification.id}`}>
        <div className='toast-header'>
          <i className='fas fa-fw fa-bell'></i>
          <strong className='mr-auto'>{notification.title}</strong>
          <small>
            {notification.date.toLocaleTimeString(navigator.language, {
              hour: '2-digit',
              minute: '2-digit',
            })}
          </small>
          <button
            type='button'
            className='ml-2 mb-1 close'
            data-dismiss='toast'
            aria-label='Close'
            onClick={() => closeNotification(notification.id)}
          >
            <span aria-hidden='true'>&times;</span>
          </button>
        </div>
        <div className='toast-body'>{notification.text}</div>
      </div>
    )
  })

  return <div className='toast-wrapper'>{notificationList}</div>
}

export default Notifications
