import { TgOptionUnits } from '../root.interface'
import { Maybe } from 'yup'

export type OfferDuration = {
  days: number
  months: number
  years: number
}

export type TerminationOptions = 'select-date' | 'select-period'

export class TgPromoCode {
  _id: Maybe<string | undefined>
  code = ''
  customers: string[] = []
  link = ''
  amount = 0
  unit: TgOptionUnits = TgOptionUnits.NUMERICAL
  services: string[] = []
  countries: string[] = []
  endDate = false
  termination?: TerminationOptions
  date?: string = ''
  duration?: OfferDuration
  maxUses?: number
}
