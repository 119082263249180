import React from 'react'
import TextRH from '../../../../../common/form-hook/Text'
import { CustomRenderFormTgOptionServiceProps } from '../types/RenderFormTgOptionService.types'
import TypeheadSelectRH from '../../../../../common/form-hook/TypeheadSelect'

const RenderFormTgOptionService: React.FC<CustomRenderFormTgOptionServiceProps> = ({
  form,
  services,
  countries,
}) => {
  const country = form.watch('country')

  services = services.filter((item) => {
    if (!country) return false
    const option = item.country

    return typeof option === 'object' ? option.shortName === country : false
  })

  return (
    <>
      <div className='form-group col-md-12'>
        <TextRH
          {...form}
          type='url'
          id='input_link'
          label='посилання'
          field='link'
          placeholder='Добавте посилання'
        />
      </div>
      <div className='form-group col-md-3'>
        <TypeheadSelectRH
          {...form}
          id='input_options_country'
          field='country'
          label='Країна'
          placeholder='Обрати країну'
          options={countries}
          labelKey='name'
          savedKey='shortName'
          value={country}
          defaultSelected={country ? country.toString() : ''}
        />
      </div>
      <div className='form-group col-md-5'>
        <TypeheadSelectRH
          {...form}
          id='input_options_service'
          field='shortName'
          label='Сервіс'
          placeholder={country ? 'Оберіть сервіс' : 'Оберіть спочатку країну'}
          options={services}
          labelKey='name'
          savedKey='shortName'
          value={form.watch('shortName')}
          defaultSelected={form.watch('shortName') ? form.watch('shortName').toString() : ''}
        />
      </div>
      <div className='form-group col-md-2'>
        <TextRH
          {...form}
          type='text'
          id='input_icon'
          placeholder='...'
          label='Іконки'
          field='icon'
        />
      </div>
    </>
  )
}

export default RenderFormTgOptionService
