import React from 'react'
import { CustomRenderFormConfigurationProps } from '../types/RenderFormConfiguration.types'
import SelectFieldRH from '../../../../../common/form-hook/Select'
import TextRH from '../../../../../common/form-hook/Text'
import { ScrOptionsState, StateType } from '../../../../../store/models/root.interface'
import { useSelector } from 'react-redux'

const RenderFormConfiguration: React.FC<CustomRenderFormConfigurationProps> = ({ form }) => {
  const options: ScrOptionsState = useSelector((state: StateType) => state.scrOptions)
  const { scrapersList } = options

  return (
    <>
      <div className='form-group col-md-4'>
        <SelectFieldRH {...form} id='input_name' label='Бот' field='name' options={scrapersList} />
      </div>
      <div className='form-group col-md-4'>
        <TextRH
          {...form}
          type='number'
          id='input_numberCycles'
          label='Кількість циклів без перезавантаження'
          field='numberCycles'
        />
      </div>
      <div className='form-group col-md-4'>
        <TextRH
          {...form}
          type='number'
          id='input_beforeSingleChanges'
          field='beforeSingleChanges'
          label='Затримка після зміни всіх сервісів країни'
        />
      </div>
      <div className='form-group col-md-4'>
        <TextRH
          {...form}
          type='number'
          id='input_beforeSingleChanges'
          field='beforeMultiChanges'
          label='Затримка після зміни одного сервісу'
        />
      </div>
      <div className='form-group col-md-4'>
        <TextRH
          {...form}
          type='number'
          id='input_beforeProcess'
          field='beforeProcess'
          label='Затримка після перезавантаження'
        />
      </div>
    </>
  )
}

export default RenderFormConfiguration
