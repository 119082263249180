import { IActionBase, ScSlotState, ModificationStatus } from '../../models/root.interface'
import { ScrSlot } from '../../models/scraper/scr-slot.interface'
import { scrSlotTypes } from '../../types/scraper/scr-slot.types'

type State = ScSlotState
type Element = ScrSlot

const initialState: State = {
  error: null,
  modificationState: ModificationStatus.None,
  selected: null,
  list: [],
}

export default (state: State = initialState, action: IActionBase): State => {
  switch (action.type) {
    case scrSlotTypes.GET_SLOTS: {
      return { ...state, error: null, list: action.value, selected: null }
    }
    case scrSlotTypes.ADD_SLOT: {
      return { ...state, error: null, list: [...state.list, action.value], selected: null }
    }
    case scrSlotTypes.EDIT_SLOT: {
      const foundIndex: number = state.list.findIndex((pr) => pr._id === action.value._id)
      const list: Element[] = state.list
      list[foundIndex] = action.value

      return { ...state, error: null, list }
    }
    case scrSlotTypes.REMOVE_SLOT: {
      return {
        ...state,
        error: null,
        list: state.list.filter((pr) => pr._id !== action.value),
        selected: null,
      }
    }
    case scrSlotTypes.CHANGE_SLOT_PENDING_EDIT: {
      const foundIndex: number = state.list.findIndex((pr) => pr._id === action.value)

      return { ...state, error: null, selected: state.list[foundIndex] }
    }
    case scrSlotTypes.CLEAR_SLOT_PENDING_EDIT: {
      return { ...state, error: null, selected: null }
    }
    case scrSlotTypes.SET_SLOT_MODIFICATION_STATE: {
      return { ...state, error: null, modificationState: action.value }
    }
    case scrSlotTypes.SET_SLOT_SOME_ERROR: {
      return { ...state, error: action.value }
    }
    default:
      return state
  }
}
