import React from 'react'
import { connect } from 'react-redux'
import { StateType, ModificationStatus } from '../../../../../store/models/root.interface'
import { bindActionCreators, Dispatch } from 'redux'
import {
  changeSelectedTgMailing,
  clearSelectedTgMailing,
  setTgMailingsModificationState,
} from '../../../../../store/dispatch/tg/tg-mailing.dispatch'
import { TgMailingMessage } from '../../../../../store/models/tg/tg-mailing.interface'
import ListIsEmpty from '../../../../../common/components/ListIsEmpty'
import { TgContentLanguages } from '../../../../../store/models/tg/tg-options-country.interface'
import { Maybe } from 'yup'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  clearSelected: bindActionCreators(clearSelectedTgMailing, dispatch),
  changeSelected: bindActionCreators(changeSelectedTgMailing, dispatch),
  setModificationState: bindActionCreators(setTgMailingsModificationState, dispatch),
})

const mapStateToProps = ({ tgMailing }: StateType) => ({
  options: tgMailing,
})

type PropsComponent = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const RenderListTgMailing: React.FC<PropsComponent> = ({
  options: { list, selected },
  clearSelected,
  changeSelected,
  setModificationState,
}): JSX.Element => {
  const onSelect = (message: TgMailingMessage): void => {
    if (!message._id) {
      return
    }

    if (selected && selected._id === message._id) {
      clearSelected()
    } else {
      changeSelected(message._id)
    }

    setModificationState(ModificationStatus.None)
  }

  const messageContent = (message: TgMailingMessage) => {
    const content = message.content

    if (!content) {
      return Object.keys(TgContentLanguages).map((key) => {
        return <ListIsEmpty key={key} />
      })
    }

    const keys = Object.keys(content) as Array<keyof typeof content>

    return (
      <tr className='table-row'>
        {keys.map((key) => {
          return (
            <th key={key} scope='row'>
              {content[key]}
            </th>
          )
        })}
      </tr>
    )
  }

  const dateFormatted = (date: Maybe<Date | undefined>): string => {
    if (!date) return '-'

    return new Date(date).toLocaleString('uk-UA', {
      day: 'numeric',
      month: 'short',
      hour: '2-digit',
      minute: '2-digit',
    })
  }

  if (!list.length) return <ListIsEmpty />

  return (
    <div className='accordion' id='accordionExample'>
      {list.map((message) => {
        return (
          <div className='accordion-item' key={message._id}>
            <h2 className='accordion-header' id='headingOne'>
              <button
                className='accordion-button pl-2'
                type='button'
                data-bs-toggle='collapse'
                data-bs-target={`#collapse_${message._id}`}
                aria-expanded='true'
                aria-controls='collapseOne'
                onClick={() => onSelect(message)}
              >
                <figure className='text-left p-0 m-0'>
                  <blockquote className='blockquote m-0 p-0'>
                    <p>
                      <small>{message.title}</small>
                    </p>
                  </blockquote>
                  <figcaption className='blockquote-footer m-0 p-0'>
                    час відправлення {dateFormatted(message.delay)}
                  </figcaption>
                </figure>
              </button>
            </h2>
            <div
              id={`collapse_${message._id}`}
              className='accordion-collapse collapse show'
              aria-labelledby='headingOne'
              data-bs-parent='#accordionExample'
            >
              <div className='accordion-body p-0'>
                <div className='table-responsive portlet'>
                  <table className='table'>
                    <thead className='thead-light'>
                      <tr>
                        <th scope='col'>Текст en</th>
                        <th scope='col'>Текст ua</th>
                        <th scope='col'>Текст ru</th>
                      </tr>
                    </thead>
                    <tbody>{messageContent(message)}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(RenderListTgMailing)
