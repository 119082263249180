import React from 'react'
import TextRH from '../../../../../common/form-hook/Text'
import PasswordRH from '../../../../../common/form-hook/Passwod'
import CheckboxRH from '../../../../../common/form-hook/Checkbox'
import { CustomRenderFormCredentialProps } from '../types/RenderFormCredential.type'

const RenderFormCredential: React.FC<CustomRenderFormCredentialProps> = ({ form }) => {
  return (
    <>
      <div className='form-row col-md-12'>
        <div className='form-group col-md-8'>
          <TextRH
            {...form}
            type='email'
            id='input_email'
            label='Логін'
            field='login'
            placeholder='Введіть логін'
          />
        </div>
        <div className='form-group col-md-2 d-flex justify-content-center'>
          <CheckboxRH {...form} id='checkbox_status' label='Активний' field='status' />
        </div>
        <div className='form-group col-md-2 d-flex'>
          <CheckboxRH {...form} id='checkbox_priority' label='В приорітеті' field='inPriority' />
        </div>
        <div className='form-group col-md-6'>
          <PasswordRH
            {...form}
            id='input_api-key'
            label='Апі ключ'
            field='apiKey'
            placeholder='Введіть ключ'
          />
        </div>
        <div className='form-group col-md-6'>
          <PasswordRH
            {...form}
            id='input_password'
            label='Пароль'
            field='password'
            placeholder='Введіть пароль'
          />
        </div>
      </div>
    </>
  )
}

export default RenderFormCredential
