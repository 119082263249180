import React from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import OptionsTab from '../../../../common/components/Tab'
import { useHttpDeleteRequest } from '../../../../hooks/request.hook'
import { RequestUrlTypes } from '../../../../hooks/types/request.types'
import { updateCurrentPath, updateLoaderState } from '../../../../store/dispatch/root.dispatch'
import { addNotification } from '../../../../store/dispatch/notifications.dispatch'
import { StateType, ModificationStatus } from '../../../../store/models/root.interface'
import { connect } from 'react-redux'
import { OptionTypeDetails } from '../../../../common/components/types/Tab.types'
import { useErrorHandlerHook } from '../../../../hooks/error-handler'
import { URL_SERVER_GSM } from '../../../../config'
import { GsmOptionType } from '../../types/GsmOptions.types'
import TabCardBodyGsmOptions from '../TabCardBodyGsmOptions'

import {
  clearSelectedGsmOptionsSender,
  removeGsmOptionsSender,
  setGsmOptionsSendersModificationState,
  setGsmOptionsSendersSomeError,
} from '../../../../store/dispatch/gsm/gsm-options-sender.dispatch'
import GsmOptionsSenderForm from './GsmOptionsSenderForm'
import GsmOptionsSenderWrapper from './components/GsmOptionsSenderWrapper'
import { GsmOptionsSender } from '../../../../store/models/gsm/gsm-options-sender.interface'
import GsmOptionsSenderList from './GsmOptionsSenderList'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  remove: bindActionCreators(removeGsmOptionsSender, dispatch),
  clearSelected: bindActionCreators(clearSelectedGsmOptionsSender, dispatch),
  setModificationState: bindActionCreators(setGsmOptionsSendersModificationState, dispatch),
  setSomeError: bindActionCreators(setGsmOptionsSendersSomeError, dispatch),
  updateCurrentPath: bindActionCreators(updateCurrentPath, dispatch),
  addNotification: bindActionCreators(addNotification, dispatch),
  updateLoader: bindActionCreators(updateLoaderState, dispatch),
})

const mapStateToProps = ({ gsmOptionsSender }: StateType) => ({
  options: gsmOptionsSender,
})

type PropsPage = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    renderTabHeader: JSX.Element
    type: GsmOptionType
  }

const GsmOptionsSenderTab: React.FC<PropsPage> = ({
  options,
  remove,
  clearSelected,
  updateLoader,
  setSomeError,
  renderTabHeader,
  setModificationState,
  type,
}) => {
  const errorHandler = useErrorHandlerHook(setSomeError, 'Опції відправників')
  const deleteRequest = useHttpDeleteRequest({
    url: RequestUrlTypes.GSM_OPTIONS_SENDER,
    base: URL_SERVER_GSM,
    func: remove,
  })

  const onRemove = async () => {
    const { selected } = options
    if (!selected) {
      return
    }

    try {
      await deleteRequest({ id: selected._id })
      setModificationState(ModificationStatus.None)
      addNotification('Опції', ' видалено')
      clearSelected()
    } catch (e: any) {
      errorHandler({ text: e.message })
    } finally {
      updateLoader(false)
    }
  }

  const details: OptionTypeDetails<GsmOptionsSender> = {
    ...options,
    onRemove,
    setModificationState,
    headerTitle: 'Список gsm відправників',
    cardFormClass: 'col-xl-5 col-lg-5',
    cardTopClass: 'col-xl-7 col-lg-7',
    cardWrapperClass: 'row mt-5 flex-row-reverse',
  }

  if (type !== GsmOptionType.SENDER) return <></>

  return (
    <GsmOptionsSenderWrapper>
      <OptionsTab
        details={details}
        renderForm={<GsmOptionsSenderForm />}
        topList={<GsmOptionsSenderList />}
        renderTabHeader={renderTabHeader}
        renderCardBody={TabCardBodyGsmOptions}
      />
    </GsmOptionsSenderWrapper>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(GsmOptionsSenderTab)
