import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { StateType } from '../../../store/models/root.interface'
import ListIsEmpty from '../../../common/components/ListIsEmpty'
import {
  changeSelectedGsmAnalyticsOption,
  clearSelectedGsmAnalyticsOption,
} from '../../../store/dispatch/gsm/gsm-analytics.dispatch'
import { OptionsReceiver } from '../../../utils/option-receiver'
import useFilteringListHook from '../../../hooks/filter-hook'
import {
  GsmAnalyticsOptionStatus,
  RenderGsmAnalyticsOption,
} from '../../../store/models/gsm/gsm-analytics.interface'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  clearSelected: bindActionCreators(clearSelectedGsmAnalyticsOption, dispatch),
  changeSelected: bindActionCreators(changeSelectedGsmAnalyticsOption, dispatch),
})

const mapStateToProps = ({ gsmAnalytics, gsmOptionsCountry }: StateType) => ({
  options: gsmAnalytics,
  country: gsmOptionsCountry.list,
})

type PropsComponent = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const RenderTableAnalytics: React.FC<PropsComponent> = ({
  options: { list, selected, filters },
  country,
  clearSelected,
  changeSelected,
}): JSX.Element => {
  const getCountryValue = OptionsReceiver.getValueByKey(country, 'shortName')
  let listByPhone = Array.from(new Map(list.map((item) => [item.phone, item])).values())

  listByPhone = useFilteringListHook(listByPhone, filters)

  const renderList = listByPhone.map(({ phone, country }) => {
    const option = new RenderGsmAnalyticsOption()
    const options = list.filter((item) => item.phone === phone)
    const soldOut = options.filter(({ status }) => {
      return status === GsmAnalyticsOptionStatus.STATUS_COMPLETED
    })

    option.phone = phone
    option.country = getCountryValue(country, 'name') || country
    option.earnings = soldOut.reduce((sum, { cost }) => sum + cost, 0)
    option.servicesSold = soldOut.length
    option.servicesSale = options.length

    return option
  })

  const onSelect = (phone: string): void => {
    if (selected && selected.phone === phone) {
      clearSelected()
    } else {
      changeSelected(phone)
    }
  }

  if (!list.length) return <ListIsEmpty />

  const productElements = renderList.map((item: RenderGsmAnalyticsOption) => {
    return (
      <tr
        className={`table-row pl-2 ${selected?.phone === item.phone ? 'table-primary' : ''}`}
        onClick={() => onSelect(item.phone)}
        key={`product_${item.phone}`}
      >
        <th>{item.phone}</th>
        <td>{item.country}</td>
        <td>{item.earnings} USD</td>
        <td>{item.servicesSold}</td>
        <td>{item.servicesSale}</td>
      </tr>
    )
  })

  return (
    <div className='table-responsive portlet'>
      <table className='table m-0'>
        <thead className='thead-light'>
          <tr>
            <th scope='col'>Телефон</th>
            <th scope='col'>Країна</th>
            <th scope='col'>Заробіток</th>
            <th scope='col'>Продано</th>
            <th scope='col'>У продажі</th>
          </tr>
        </thead>
        <tbody>{productElements}</tbody>
      </table>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(RenderTableAnalytics)
