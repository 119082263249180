import { ModificationStatus } from '../../models/root.interface'
import { tgOptionsCountryTypes } from '../../types/tg/tg-options-country.types'
import { TgOptionCountry } from '../../models/tg/tg-options-country.interface'

type ActionItem = TgOptionCountry
export function getTgOptionCountryAction(value: ActionItem[]): IGetItemsActionType<ActionItem> {
  return { type: tgOptionsCountryTypes.GET_TG_OPTIONS_COUNTRY_LIST, value }
}
export function addTgOptionCountryAction(value: ActionItem): IAddItemActionType<ActionItem> {
  return { type: tgOptionsCountryTypes.ADD_TG_OPTIONS_COUNTRY_LIST, value }
}
export function editTgOptionCountryAction(value: ActionItem): IEditItemActionType<ActionItem> {
  return { type: tgOptionsCountryTypes.EDIT_TG_OPTIONS_COUNTRY_LIST, value }
}
export function removeTgOptionCountryAction(value: string): IRemoveItemActionType {
  return { type: tgOptionsCountryTypes.REMOVE_TG_OPTIONS_COUNTRY_LIST, value }
}
export function changeSelectedTgOptionCountryAction(value: string): IChangeSelectedItemActionType {
  return { type: tgOptionsCountryTypes.CHANGE_TG_OPTIONS_COUNTRY_PENDING_EDIT, value }
}
export function clearSelectedTgOptionCountryAction(): IClearSelectedItemActionType {
  return { type: tgOptionsCountryTypes.CLEAR_TG_OPTIONS_COUNTRY_PENDING_EDIT }
}
export function setTgOptionCountriesModificationStateAction(
  value: ModificationStatus,
): ISetModificationStateActionType {
  return { type: tgOptionsCountryTypes.SET_TG_OPTIONS_COUNTRY_MODIFICATION_STATE, value: value }
}
export function setTgOptionCountrySomeErrorAction(value: string): ISetSomeErrorActionType {
  return { type: tgOptionsCountryTypes.SET_TG_OPTIONS_COUNTRY_SOME_ERROR, value: value }
}
interface IGetItemsActionType<T> {
  type: string
  value: T[]
}

interface IAddItemActionType<T> {
  type: string
  value: T
}

interface IEditItemActionType<T> {
  type: string
  value: T
}

interface IRemoveItemActionType {
  type: string
  value: string
}

interface IChangeSelectedItemActionType {
  type: string
  value: string
}

interface IClearSelectedItemActionType {
  type: string
}

interface ISetModificationStateActionType {
  type: string
  value: ModificationStatus
}

interface ISetSomeErrorActionType {
  type: string
  value: string
}
