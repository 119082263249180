import { ScrService, ScrServiceFilters } from '../../models/scraper/scr-service.interface'
import { Dispatch } from 'redux'
import {
  addScrServiceAction,
  changeSelectedScrServiceAction,
  clearScrServiceFiltersAction,
  clearSelectedScrServiceAction,
  editScrServiceAction,
  editScrServiceFiltersAction,
  getScrServiceAction,
  removeScrServiceAction,
  setScrServiceModificationStateAction,
  setScrServiceSomeErrorAction,
} from '../../actions/scraper/scr-service.action'
import { ModificationStatus } from '../../models/root.interface'

type ActionItem = ScrService
type ItemFilters = ScrServiceFilters

export const getScrService = (value: ActionItem[]) => (dispatch: Dispatch) => {
  dispatch(getScrServiceAction(value))
}

export const addScrService = (value: ActionItem) => (dispatch: Dispatch) => {
  dispatch(addScrServiceAction(value))
}

export const editScrService = (value: ActionItem) => (dispatch: Dispatch) => {
  dispatch(editScrServiceAction(value))
}

export const removeScrService = (value: string) => (dispatch: Dispatch) => {
  dispatch(removeScrServiceAction(value))
}

export const changeSelectedScrService = (value: string) => (dispatch: Dispatch) => {
  dispatch(changeSelectedScrServiceAction(value))
}

export const clearSelectedScrService = () => (dispatch: Dispatch) => {
  dispatch(clearSelectedScrServiceAction())
}

export const setScrServiceModificationState =
  (value: ModificationStatus) => (dispatch: Dispatch) => {
    dispatch(setScrServiceModificationStateAction(value))
  }

export const setScrServiceSomeError = (value: string) => (dispatch: Dispatch) => {
  dispatch(setScrServiceSomeErrorAction(value))
}
export const editScrServiceFilters = (value: ItemFilters) => (dispatch: Dispatch) => {
  dispatch(editScrServiceFiltersAction(value))
}

export const clearScrServicesFilters = () => (dispatch: Dispatch) => {
  dispatch(clearScrServiceFiltersAction())
}
