import { IActionBase, INotificationState } from '../models/root.interface'
import { notificationsTypes } from '../types/notifications.types'

const initialState: INotificationState = {
  notifications: [{ id: 1, date: new Date(), text: 'Hello Admin', title: 'Welcome' }],
}

export default (
  state: INotificationState = initialState,
  action: IActionBase,
): INotificationState => {
  switch (action.type) {
    case notificationsTypes.ADD_NOTIFICATION: {
      let maxId: number = Math.max.apply(Math, [...state.notifications.map((o) => o.id)])

      if (maxId === -Infinity) {
        maxId = 0
      }
      const newItem = {
        id: maxId + 1,
        date: new Date(),
        title: action.title,
        text: action.text,
      }
      return { ...state, notifications: [...state.notifications, newItem] }
    }
    case notificationsTypes.REMOVE_NOTIFICATION: {
      return {
        ...state,
        notifications: state.notifications.filter((Notification) => Notification.id !== action.id),
      }
    }
    default:
      return state
  }
}
