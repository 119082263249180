import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import {
  changeSelectedScraperConfiguration,
  clearSelectedScraperConfiguration,
  setScraperConfigurationsModificationState,
} from '../../../../../store/dispatch/scraper/scr-configuration.dispatch'
import { StateType, ModificationStatus } from '../../../../../store/models/root.interface'
import { ScrConfiguration } from '../../../../../store/models/scraper/scr-configuration.interface'
const mapDispatchToProps = (dispatch: Dispatch) => ({
  changeSelected: bindActionCreators(changeSelectedScraperConfiguration, dispatch),
  setModificationState: bindActionCreators(setScraperConfigurationsModificationState, dispatch),
  clearSelected: bindActionCreators(clearSelectedScraperConfiguration, dispatch),
})
const mapStateToProps = ({ scrConfiguration }: StateType) => ({ scrConfiguration })
type PageProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const ScraperConfigurationsList: React.FC<PageProps> = ({
  scrConfiguration: { list, selected },
  changeSelected,
  setModificationState,
  clearSelected,
}): JSX.Element => {
  const selectedItem = selected
  const rowSelectedClass = (id: string) => {
    return selectedItem && selectedItem._id === id ? 'selected' : ''
  }

  const onSelect = (value: ScrConfiguration): void => {
    if (selectedItem && selectedItem._id === value._id) {
      clearSelected()
    } else {
      changeSelected(value?._id || '')
    }

    setModificationState(ModificationStatus.None)
  }

  const productElements: (JSX.Element | null)[] = list.map((item) => {
    if (!item) {
      return null
    }

    return (
      <tr
        className={`table-row ${rowSelectedClass(item._id || '')}`}
        onClick={() => onSelect(item)}
        key={`product_${item._id}`}
      >
        <td>{item.name.toUpperCase()}</td>
        <td>{item.beforeProcess.toFixed(2)} сек.</td>
        <td>{item.beforeSingleChanges.toFixed(2)} сек.</td>
        <td>{item.beforeMultiChanges.toFixed(2)} сек.</td>
        <td>{item.numberCycles.toFixed(2)}</td>
      </tr>
    )
  })

  return (
    <div className='table-responsive portlet'>
      <table className='table'>
        <thead className='thead-light'>
          <tr>
            <th className='align-middle' scope='col'>
              <p className='p-0 m-0'>
                <small>Бот</small>
              </p>
            </th>
            <th className='align-middle' scope='col'>
              <p className='p-0 m-0'>
                <small>Затримка після перезавантаження (3с.)</small>
              </p>
            </th>
            <th className='align-middle' scope='col'>
              <p className='p-0 m-0'>
                <small>Затримка після зміни одного сервісу (2с.)</small>
              </p>
            </th>
            <th className='align-middle' scope='col'>
              <p className='p-0 m-0'>
                <small>Затримка після зміни всіх сервісів країни (60с.)</small>
              </p>
            </th>
            <th className='align-middle' scope='col'>
              <p className='p-0 m-0'>
                <small>Кількість циклів без перезавантаження (20)</small>
              </p>
            </th>
          </tr>
        </thead>
        <tbody>{productElements}</tbody>
      </table>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ScraperConfigurationsList)
