import { scrOptionsTypes } from '../../types/scraper/scr-options.types'
import { ScrOptionsCountry } from '../../models/scraper/scr-options-country.interface'
import { ScrOptionsService } from '../../models/scraper/scr-options-service.interface'

export function getCountriesOptionsAction(value: ScrOptionsCountry[]): IGetCountriesListActionType {
  return { type: scrOptionsTypes.GET_COUNTRIES_OPTIONS_LIST, value }
}
export function getServicesOptionsAction(value: ScrOptionsService[]): IGetServiceListActionType {
  return { type: scrOptionsTypes.GET_SERVICES_OPTIONS_LIST, value }
}

export function changeSelectedCountryOptionsAction(
  value: string,
): IChangeSelectedCountryActionType {
  return { type: scrOptionsTypes.CHANGE_COUNTRY_OPTIONS_PENDING_EDIT, value }
}

export function clearSelectedCountryOptionsAction(): IClearSelectedCountryActionType {
  return { type: scrOptionsTypes.CLEAR_COUNTRY_OPTIONS_PENDING_EDIT }
}

export function changeSelectedServiceOptionsFromListAction(
  value: string,
): IChangeSelectedServiceFromListActionType {
  return { type: scrOptionsTypes.CHANGE_SERVICE_OPTIONS_PENDING_EDIT, value }
}

export function clearSelectedServiceOptionsFromListAction(): IClearSelectedServiceFromListActionType {
  return { type: scrOptionsTypes.CLEAR_SERVICE_OPTIONS_PENDING_EDIT }
}

export function setOptionsSomeErrorAction(value: string): ISetSomeErrorActionType {
  return { type: scrOptionsTypes.SET_OPTIONS_SOME_ERROR, value: value }
}

interface IChangeSelectedCountryActionType {
  type: string
  value: string
}
interface IClearSelectedCountryActionType {
  type: string
}
interface IChangeSelectedServiceFromListActionType {
  type: string
  value: string
}
interface IClearSelectedServiceFromListActionType {
  type: string
}
interface IGetCountriesListActionType {
  type: string
  value: ScrOptionsCountry[]
}
interface IGetServiceListActionType {
  type: string
  value: ScrOptionsService[]
}
interface ISetSomeErrorActionType {
  type: string
  value: string
}
