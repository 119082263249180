import React from 'react'
import { connect, useSelector } from 'react-redux'
import RenderLineChartByPhone from './components/RenderLineChartByPhone'
import RenderTableAnalyticsByPhone from './components/RenderTableAnalyticsbyPhone'
import { StateType } from '../../store/models/root.interface'
import { GsmAnalyticsOptionStatus } from '../../store/models/gsm/gsm-analytics.interface'

const GsmAnalyticsTableByPhone: React.FC = (): JSX.Element => {
  const { list, selected } = useSelector((state: StateType) => state.gsmAnalytics)
  const listByPhone = list.filter((option) => option.phone === selected?.phone)
  const soldOut = listByPhone.filter(({ status }) => {
    return status === GsmAnalyticsOptionStatus.STATUS_COMPLETED
  })

  return (
    <div className='card shadow mb-4'>
      <div className='card-header py-3 px-1'>
        <h6 className='m-0 font-weight-bold text-green'>
          У продажі {listByPhone.length} зареєстровано {soldOut.length}
        </h6>
      </div>
      <div className='card-body p-0'>
        <RenderLineChartByPhone list={listByPhone} />
        <RenderTableAnalyticsByPhone list={listByPhone} />
      </div>
    </div>
  )
}

export default connect(null, null)(GsmAnalyticsTableByPhone)
