import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import RenderAnalyticsFilters from './components/RenderAnalyticsFilters'
import { StateType } from '../../store/models/root.interface'
import {
  clearGsmAnalyticsFilters,
  editGsmAnalyticsFilters,
} from '../../store/dispatch/gsm/gsm-analytics.dispatch'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  clearFilters: bindActionCreators(clearGsmAnalyticsFilters, dispatch),
  editFilters: bindActionCreators(editGsmAnalyticsFilters, dispatch),
})

const mapStateToProps = ({ gsmAnalytics }: StateType) => ({
  filters: gsmAnalytics.filters,
  options: gsmAnalytics.list,
})

type PropsComponent = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const GsmOptionsServiceFilters: React.FC<PropsComponent> = (props): JSX.Element => {
  const clearFilter = () => {
    props.editFilters({ ...props.filters, country: [] })
  }

  return (
    <>
      <div className='form-group col-md-12 p-0'>
        <h6 className='m-0 font-weight-bold text-green'>Фільтри:</h6>
      </div>
      <RenderAnalyticsFilters {...props} />
      <button
        type='button'
        className='btn btn-danger col-md-3 h-50 mt-auto mb-3 ml-auto'
        onClick={clearFilter}
      >
        Скинути
      </button>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(GsmOptionsServiceFilters)
