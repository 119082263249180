import { Dispatch } from 'redux'
import { ModificationStatus } from '../../models/root.interface'
import {
  changeSelectedCustomerAction,
  clearSelectedCustomerAction,
  editCustomerAction,
  getCustomersListAction,
  setCustomersModificationStateAction,
  setCustomersSomeErrorAction,
} from '../../actions/tg/tg-customers.action'
import { TgCustomer } from '../../models/tg/tg-customer.interface'

type ActionItem = TgCustomer

export const getTgCustomersList = (data: ActionItem[]) => (dispatch: Dispatch) => {
  dispatch(getCustomersListAction(data))
}
export const editTgCustomer = (value: ActionItem) => (dispatch: Dispatch) => {
  dispatch(editCustomerAction(value))
}
export const changeSelectedTgCustomer = (value: string) => (dispatch: Dispatch) => {
  dispatch(changeSelectedCustomerAction(value))
}
export const clearSelectedTgCustomer = () => (dispatch: Dispatch) => {
  dispatch(clearSelectedCustomerAction())
}
export const setTgCustomersModificationState =
  (value: ModificationStatus) => (dispatch: Dispatch) => {
    dispatch(setCustomersModificationStateAction(value))
  }
export const setTgCustomersSomeError = (value: string) => (dispatch: Dispatch) => {
  dispatch(setCustomersSomeErrorAction(value))
}
