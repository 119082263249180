import React from 'react'
import { connect } from 'react-redux'
import ListIsEmpty from '../../../../../common/components/ListIsEmpty'
import { StateType, ModificationStatus } from '../../../../../store/models/root.interface'
import { bindActionCreators, Dispatch } from 'redux'
import { TgOptionCountry } from '../../../../../store/models/tg/tg-options-country.interface'
import {
  changeSelectedTgOptionCountry,
  clearSelectedTgOptionCountry,
  setTgOptionCountryModificationState,
} from '../../../../../store/dispatch/tg/tg-options-country.dispatch'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  clearSelected: bindActionCreators(clearSelectedTgOptionCountry, dispatch),
  changeSelected: bindActionCreators(changeSelectedTgOptionCountry, dispatch),
  setModificationState: bindActionCreators(setTgOptionCountryModificationState, dispatch),
})

const mapStateToProps = ({ tgOptionsCountry }: StateType) => ({
  options: tgOptionsCountry,
})

type PropsComponent = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const RenderListTgOptionsCountry: React.FC<PropsComponent> = ({
  options: { list, selected },
  clearSelected,
  changeSelected,
  setModificationState,
}): JSX.Element => {
  const onSelect = (_id: string): void => {
    if (selected && selected._id === _id) {
      clearSelected()
    } else {
      changeSelected(_id)
    }

    setModificationState(ModificationStatus.None)
  }

  if (!list.length) return <ListIsEmpty />

  const productElements: JSX.Element[] = list.map((item: TgOptionCountry, i: number) => {
    const { _id, content, name, shortName, icon } = item
    const addition = icon ? ` ${icon}` : icon
    return (
      <tr
        className={`table-row pl-2 ${selected?._id === _id ? 'table-primary' : ''}`}
        onClick={() => onSelect(_id || '')}
        key={`product_${item._id}`}
      >
        <th scope='row'>{++i}</th>
        <td data-id={shortName}>{shortName}</td>
        <td data-id={name}>{name?.toUpperCase() ?? '' + addition}</td>
        <td>{item.priority}</td>
        <td className='pl-4'>
          <i className={`fas fa-solid ${content ? 'fa-plus' : 'fa-minus'}`}></i>
        </td>
      </tr>
    )
  })

  return (
    <div className='table-responsive portlet'>
      <table className='table m-0'>
        <thead className='thead-light'>
          <tr>
            <th scope='col'>№</th>
            <th scope='col'>Опція</th>
            <th scope='col'>Назва</th>
            <th scope='col'>Пріоритетність</th>
            <th scope='col'>Контент</th>
          </tr>
        </thead>
        <tbody>{productElements}</tbody>
      </table>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(RenderListTgOptionsCountry)
