import { Dispatch } from 'redux'
import { ModificationStatus } from '../../models/root.interface'
import {
  getPromoCodesListAction,
  addPromoCodeAction,
  editPromoCodeAction,
  removePromoCodeAction,
  changeSelectedPromoCodeAction,
  clearSelectedPromoCodeAction,
  setPromoCodesModificationStateAction,
  setPromoCodesSomeErrorAction,
} from '../../actions/tg/tg-promo-codes.action'
import { TgPromoCode } from '../../models/tg/tg-promo-code.interface'

type ActionItem = TgPromoCode

export const getPromoCodesList = (data: ActionItem[]) => (dispatch: Dispatch) => {
  dispatch(getPromoCodesListAction(data))
}
export const addPromoCode = (data: ActionItem) => (dispatch: Dispatch) => {
  dispatch(addPromoCodeAction(data))
}
export const editPromoCode = (value: ActionItem) => (dispatch: Dispatch) => {
  dispatch(editPromoCodeAction(value))
}
export const removePromoCode = (value: string) => (dispatch: Dispatch) => {
  dispatch(removePromoCodeAction(value))
}
export const changeSelectedPromoCode = (value: string) => (dispatch: Dispatch) => {
  dispatch(changeSelectedPromoCodeAction(value))
}
export const clearSelectedPromoCode = () => (dispatch: Dispatch) => {
  dispatch(clearSelectedPromoCodeAction())
}
export const setPromoCodesModificationState =
  (value: ModificationStatus) => (dispatch: Dispatch) => {
    dispatch(setPromoCodesModificationStateAction(value))
  }
export const setPromoCodesSomeError = (value: string) => (dispatch: Dispatch) => {
  dispatch(setPromoCodesSomeErrorAction(value))
}
