import { scrCredentialTypes } from '../../types/scraper/scr-credential.types'
import { ModificationStatus } from '../../models/root.interface'
import { ScrCredential } from '../../models/scraper/scr-credential.interface'

type ActionItem = ScrCredential
export function getScraperCredentialsAction(value: ActionItem[]): IGetItemsActionType<ActionItem> {
  return { type: scrCredentialTypes.GET_SCRAPER_CREDENTIAL, value }
}

export function addScraperCredentialAction(
  value: ActionItem | ActionItem[],
): IAddItemActionType<ActionItem | ActionItem[]> {
  return { type: scrCredentialTypes.ADD_SCRAPER_CREDENTIAL, value }
}

export function editScraperCredentialAction(
  value: ActionItem | ActionItem[],
): IEditItemActionType<ActionItem | ActionItem[]> {
  return { type: scrCredentialTypes.EDIT_SCRAPER_CREDENTIAL, value }
}

export function removeScraperCredentialAction(value: string): IRemoveItemActionType {
  return { type: scrCredentialTypes.REMOVE_SCRAPER_CREDENTIAL, value }
}

export function changeSelectedScraperCredentialAction(
  value: string,
): IChangeSelectedItemActionType {
  return { type: scrCredentialTypes.CHANGE_SCRAPER_CREDENTIAL_PENDING_EDIT, value }
}

export function clearSelectedScraperCredentialAction(): IClearSelectedItemActionType {
  return { type: scrCredentialTypes.CLEAR_SCRAPER_CREDENTIAL_PENDING_EDIT }
}

export function setScraperCredentialsModificationStateAction(
  value: ModificationStatus,
): ISetModificationStateActionType {
  return { type: scrCredentialTypes.SET_MODIFICATION_STATE_SCRAPER_CREDENTIAL, value }
}

export function setScraperCredentialSomeErrorAction(value: string): ISetSomeErrorActionType {
  return { type: scrCredentialTypes.SET_SOME_ERROR_SCRAPER_CREDENTIAL, value }
}

export function changeScraperCredentialStatusAction(): IChangeScraperCredentialStatusActionType {
  return { type: scrCredentialTypes.CHANGE_SCRAPER_CREDENTIAL_STATUS }
}

interface IGetItemsActionType<T> {
  type: string
  value: T[]
}

interface IAddItemActionType<T> {
  type: string
  value: T
}

interface IEditItemActionType<T> {
  type: string
  value: T
}

interface IRemoveItemActionType {
  type: string
  value: string
}

interface IChangeSelectedItemActionType {
  type: string
  value: string
}

interface IClearSelectedItemActionType {
  type: string
}

interface ISetModificationStateActionType {
  type: string
  value: ModificationStatus
}

interface ISetSomeErrorActionType {
  type: string
  value: string
}

interface IChangeScraperCredentialStatusActionType {
  type: string
}
