import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import {
  getScraperCredentialsList,
  setScraperCredentialsSomeError,
} from '../../../../store/dispatch/scraper/scr-credential.dispatch'
import { RequestUrlTypes } from '../../../../hooks/types/request.types'
import { useHttpDateLoader } from '../../../../hooks/request.hook'
import RenderListCredential from './components/RenderListCredential'
import { useErrorHandlerHook } from '../../../../hooks/error-handler'
import { ScrCredential } from '../../../../store/models/scraper/scr-credential.interface'
import { URL_SERVER_SCRAPER } from '../../../../config'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setList: bindActionCreators(getScraperCredentialsList, dispatch),
  setSomeError: bindActionCreators(setScraperCredentialsSomeError, dispatch),
})

type PropsScraperCredentialsListComponent = ReturnType<typeof mapDispatchToProps>

const ScraperCredentialsList: React.FC<PropsScraperCredentialsListComponent> = ({
  setList,
  setSomeError,
}): JSX.Element => {
  const errorHandler = useErrorHandlerHook(setSomeError, 'Запис бота')
  useHttpDateLoader<ScrCredential[]>({
    url: RequestUrlTypes.SCRAPER_CREDENTIAL,
    base: URL_SERVER_SCRAPER,
    func: setList,
    errorHandler,
  })

  return <RenderListCredential />
}

export default connect(null, mapDispatchToProps)(ScraperCredentialsList)
