import { Maybe } from 'yup'

export class ScrCredential {
  _id: Maybe<string | undefined>
  apiKey = ''
  login = ''
  password = ''
  status = false
  inPriority = false
}
