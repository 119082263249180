import { Dispatch } from 'redux'
import {
  addScraperConfigurationAction,
  changeSelectedScraperConfigurationAction,
  clearSelectedScraperConfigurationAction,
  editScraperConfigurationAction,
  getScraperConfigurationsAction,
  removeScraperConfigurationAction,
  setScraperConfigurationsModificationStateAction,
  setScraperConfigurationSomeErrorAction,
} from '../../actions/scraper/scr-configuration.action'
import { ModificationStatus } from '../../models/root.interface'
import { ScrConfiguration } from '../../models/scraper/scr-configuration.interface'

type ActionItem = ScrConfiguration

export const getScraperConfigurationsList = (value: ActionItem[]) => (dispatch: Dispatch) => {
  dispatch(getScraperConfigurationsAction(value))
}

export const addScraperConfiguration = (value: ActionItem) => (dispatch: Dispatch) => {
  dispatch(addScraperConfigurationAction(value))
}

export const editScraperConfiguration = (value: ActionItem) => (dispatch: Dispatch) => {
  dispatch(editScraperConfigurationAction(value))
}

export const removeScraperConfiguration = (value: string) => (dispatch: Dispatch) => {
  dispatch(removeScraperConfigurationAction(value))
}

export const clearSelectedScraperConfiguration = () => (dispatch: Dispatch) => {
  dispatch(clearSelectedScraperConfigurationAction())
}

export const changeSelectedScraperConfiguration = (value: string) => (dispatch: Dispatch) => {
  dispatch(changeSelectedScraperConfigurationAction(value))
}

export const setScraperConfigurationsModificationState =
  (value: ModificationStatus) => (dispatch: Dispatch) => {
    dispatch(setScraperConfigurationsModificationStateAction(value))
  }

export const setScraperConfigurationsSomeError = (value: string) => (dispatch: Dispatch) => {
  dispatch(setScraperConfigurationSomeErrorAction(value))
}
