import React from 'react'
import { useSelector } from 'react-redux'
import MultiSelectField from '../../../common/fields/MultiSelectField'
import { StateType } from '../../../store/models/root.interface'
import {
  GsmAnalyticsOption,
  GsmAnalyticsOptionFilters,
} from '../../../store/models/gsm/gsm-analytics.interface'

const RenderAnalyticsFilters = ({
  filters,
  editFilters,
  options,
}: {
  filters: GsmAnalyticsOptionFilters
  editFilters: (value: GsmAnalyticsOptionFilters) => void
  options: GsmAnalyticsOption[]
}): JSX.Element => {
  const optionsCountries = options.map((option) => option.country)
  let countries = useSelector((state: StateType) => {
    return state.gsmOptionsCountry.list
  })

  countries = countries.filter((country) => {
    return optionsCountries.includes(country?.shortName ?? '')
  })

  return (
    <>
      <div className='form-group col-md-9 pl-md-0'>
        <MultiSelectField
          id='input_country'
          field='country'
          label='Країна'
          placeholder='Оберіть країну'
          options={countries}
          labelKey='name'
          savedKey='shortName'
          setValue={editFilters}
          value={filters}
        />
      </div>
    </>
  )
}

export default RenderAnalyticsFilters
