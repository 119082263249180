import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { ModificationStatus, StateType } from '../../../../store/models/root.interface'
import { bindActionCreators, Dispatch } from 'redux'
import { addNotification } from '../../../../store/dispatch/notifications.dispatch'
import { updateCurrentPath, updateLoaderState } from '../../../../store/dispatch/root.dispatch'
import { RequestUrlTypes } from '../../../../hooks/types/request.types'
import { useHttpDeleteRequest } from '../../../../hooks/request.hook'
import {
  clearSelectedScraperConfiguration,
  removeScraperConfiguration,
  setScraperConfigurationsModificationState,
  setScraperConfigurationsSomeError,
} from '../../../../store/dispatch/scraper/scr-configuration.dispatch'
import { OptionTypeDetails } from '../../../../common/components/types/Tab.types'
import OptionsTab from '../../../../common/components/Tab'
import ConfigurationForm from './ConfigurationForm'
import ScraperConfigurationsList from './ConfigurationList'
import { useErrorHandlerHook } from '../../../../hooks/error-handler'
import { ScrConfiguration } from '../../../../store/models/scraper/scr-configuration.interface'
import { ScrapperOptionType, ScrapperTabCustomProps } from '../../types/ScraperOptions.type'
import { URL_SERVER_SCRAPER } from '../../../../config'
import CardBodyScrapper from '../ScrCardBody'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  remove: bindActionCreators(removeScraperConfiguration, dispatch),
  clearSelected: bindActionCreators(clearSelectedScraperConfiguration, dispatch),
  setModificationState: bindActionCreators(setScraperConfigurationsModificationState, dispatch),
  setSomeError: bindActionCreators(setScraperConfigurationsSomeError, dispatch),
  updateCurrentPath: bindActionCreators(updateCurrentPath, dispatch),
  addNotification: bindActionCreators(addNotification, dispatch),
  updateLoader: bindActionCreators(updateLoaderState, dispatch),
})

const mapStateToProps = ({ scrConfiguration, root }: StateType) => ({
  configuration: scrConfiguration,
  path: root.page,
})

type PropsScraperPage = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  ScrapperTabCustomProps

const ConfigurationTab: React.FC<PropsScraperPage> = ({
  path,
  configuration,
  remove,
  updateLoader,
  clearSelected,
  setModificationState,
  setSomeError,
  addNotification,
  renderTabHeader,
  type,
}) => {
  const errorHandler = useErrorHandlerHook(setSomeError, 'Конфігурація')
  const deleteRequest = useHttpDeleteRequest({
    url: RequestUrlTypes.SCRAPER_CONFIGURATION,
    base: URL_SERVER_SCRAPER,
    func: remove,
  })

  useEffect(() => {
    clearSelected()
  }, [path.area])

  const onRemove = async () => {
    const { selected } = configuration

    if (!selected) {
      return
    }

    try {
      await deleteRequest({ id: selected._id })
      addNotification('Конфігурація', `${selected.name} видалено`)
      clearSelected()
      setModificationState(ModificationStatus.None)
    } catch (e: any) {
      errorHandler({ text: e.message })
    } finally {
      updateLoader(false)
    }
  }

  const details: OptionTypeDetails<ScrConfiguration> = {
    ...configuration,
    onRemove,
    setModificationState,
    headerTitle: 'Список конфігурацій',
    cardFormClass: 'col-md-8 col-xl-8',
  }

  if (type !== ScrapperOptionType.SCR_CONFIGURATION) return <></>

  return (
    <OptionsTab
      details={details}
      renderForm={<ConfigurationForm />}
      topList={<ScraperConfigurationsList />}
      renderCardBody={CardBodyScrapper}
      renderTabHeader={renderTabHeader}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurationTab)
