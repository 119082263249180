import React, { useContext, useEffect } from 'react'
import { useErrorHandlerHook } from '../../../../../hooks/error-handler'
import { useHttpGetRequest } from '../../../../../hooks/request.hook'
import { ScrServiceDiary } from '../../../../../store/models/scraper/scr-service-diary.interface'
import { RequestUrlTypes } from '../../../../../hooks/types/request.types'
import { URL_SERVER_SCRAPER } from '../../../../../config'
import { bindActionCreators, Dispatch } from 'redux'
import {
  getScrServiceDiaryList,
  setScrServiceDiarySomeError,
} from '../../../../../store/dispatch/scraper/scr-service-diary.dispatch'
import { connect } from 'react-redux'
import { ScrServiceDiaryContext } from '../ScrServiceDiaryContext'
import { StateType } from '../../../../../store/models/root.interface'
import RenderLineChart, {
  Dataset,
  LineAtDataset,
  LineAtEvent,
  SaleData,
} from '../../RenderLineChart'
import { ScrCredential } from '../../../../../store/models/scraper/scr-credential.interface'
import { OptionsReceiver } from '../../../../../utils/option-receiver'
import RenderScrServiceDiaryCompetitors from './RenderScrServiceDiaryCompetitors'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setSomeError: bindActionCreators(setScrServiceDiarySomeError, dispatch),
  setList: bindActionCreators(getScrServiceDiaryList, dispatch),
})

const mapStateToProps = ({ scrServiceDiary, scrCredential }: StateType) => ({
  scrServiceDiary,
  scrCredential,
})

type PageProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

class LineChartDates {
  cred: ScrCredential = new ScrCredential()
  dates: string[] = []
}

const ScrServiceDiaryLineChartByService: React.FC<PageProps> = ({
  scrServiceDiary,
  scrCredential,
  setSomeError,
}) => {
  const context = useContext(ScrServiceDiaryContext)
  const getAccByLogin = OptionsReceiver.getValueByKey(scrCredential.list, 'login')
  const errorHandler = useErrorHandlerHook(setSomeError, 'Сервіс аналітика')

  const requestByService = useHttpGetRequest<ScrServiceDiary[]>({
    base: URL_SERVER_SCRAPER,
    func: context.updateListByService,
    errorHandler,
  })

  if (!scrServiceDiary.selected) return <></>

  useEffect(() => {
    const controller: AbortController = new AbortController()
    const signal: AbortSignal = controller.signal
    const path = `?service=${scrServiceDiary.selected?.service}&country=${scrServiceDiary.selected?.country}`
    const url = RequestUrlTypes.SCRAPER_SERVICE_DIARY_BY_SERVICE + path

    requestByService({ signal, url })()

    return () => {
      controller.abort()
    }
  }, [scrServiceDiary.selected])

  const getDate = (updatedAt: Date): string => {
    return new Date(updatedAt).toLocaleString('uk-UA', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
    })
  }

  let list = context.listByService
  list = list.sort((a, b) => {
    return new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime()
  })

  const lineDataset = new LineAtDataset()

  lineDataset.labels = list.map((option) => {
    return getDate(option.updatedAt)
  })

  lineDataset.labels = [...new Set(lineDataset.labels)]

  lineDataset.datasets = scrCredential.list.map((cred) => {
    const dataset = new Dataset()
    dataset.title = cred.login

    return dataset
  })

  const collection: LineChartDates[] = []
  for (const cred of scrCredential.list) {
    const data = new LineChartDates()
    data.cred = cred

    const sales = list.filter((option) => {
      return option.account === cred._id
    })

    data.dates = sales.map((option) => getDate(option.updatedAt))

    collection.push(data)
  }

  lineDataset.datasets = collection.map((lineChart) => {
    const dataset = new Dataset()
    dataset.title = lineChart.cred.login

    dataset.sales = lineDataset.labels.map((date) => {
      const serviceDiary = list.find((option) => {
        return getDate(option.updatedAt) === date && option.account === lineChart.cred._id
      })

      const sale = new SaleData()
      sale.label = date
      sale.value = serviceDiary?.newCost ?? 0

      return sale
    })

    return dataset
  })

  lineDataset.datasets = lineDataset.datasets.filter((dataset) => {
    return dataset.sales.some((option) => option.value)
  })

  const callBack = async (data: LineAtEvent): Promise<void> => {
    const serviceDiary = list.find((option) => {
      const date = getDate(option.updatedAt)
      const cred = getAccByLogin(data.label, '_id')

      return date === data.dataLabel && cred === option.account
    })

    if (!serviceDiary) return

    context.updateListByCost(serviceDiary)
  }

  return (
    <>
      <RenderLineChart
        {...lineDataset}
        title='Аналітика по змінам ціни по дням'
        callBack={callBack}
      />
      <RenderScrServiceDiaryCompetitors />
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ScrServiceDiaryLineChartByService)
