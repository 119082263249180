import { TabHeaderTgOptionsProps } from '../types/TabHeaderTgOptions.types'
import { TgOptionType } from '../types/TgOptions.types'

const TabHeaderTgOptions = ({ type, setType }: TabHeaderTgOptionsProps) => {
  const listOptions = [
    { value: TgOptionType.COUNTRY, text: 'Країни' },
    { value: TgOptionType.SERVICE, text: 'Сервіси' },
    { value: TgOptionType.CURRENCY, text: 'Валюти' },
    { value: TgOptionType.INTERNAL_BANK, text: 'Внутрішні банки' },
    { value: TgOptionType.PROMO_CODE, text: 'Промо коди' },
    { value: TgOptionType.PARTNER_PROGRAM, text: 'Партнерські програми' },
    { value: TgOptionType.MAILING, text: 'Розсилки' },
  ]

  const classOptionsBtn = (value: string): string => {
    const addition = type === value ? '-' : '-outline-'
    return `btn btn${addition}secondary`
  }

  return (
    <>
      <h1 className='h3 mb-2 text-gray-800'>Телеграм опції</h1>
      <div className='btn-group mb-4' role='group' aria-label='Basic outlined example'>
        {listOptions.map((option) => {
          const { value, text } = option

          return (
            <button
              key={value}
              type='button'
              className={classOptionsBtn(value)}
              onClick={() => setType(value)}
            >
              {text}
            </button>
          )
        })}
      </div>
    </>
  )
}

export default TabHeaderTgOptions
