import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import RenderTgOptionsServiceFilters from './components/RenderTgOptionsServiceFilters'
import {
  clearTgOptionServiceFilters,
  editTgOptionServiceFilters,
} from '../../../../store/dispatch/tg/tg-options-service.dispatch'
import { StateType } from '../../../../store/models/root.interface'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  clearFilters: bindActionCreators(clearTgOptionServiceFilters, dispatch),
  editFilters: bindActionCreators(editTgOptionServiceFilters, dispatch),
})

const mapStateToProps = ({ tgOptionsService, tgOptionsCountry }: StateType) => ({
  filters: tgOptionsService.filters,
  countries: tgOptionsCountry.list,
  services: tgOptionsService.list,
})

type PropsComponent = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const TgOptionsServiceFilters: React.FC<PropsComponent> = (props): JSX.Element => {
  return (
    <>
      <div className='form-group col-md-12 p-0'>
        <h6 className='m-0 font-weight-bold text-green'>Фільтри:</h6>
      </div>
      <RenderTgOptionsServiceFilters {...props} />
      <button
        type='button'
        className='btn btn-danger col-md-2 h-50 mt-auto mb-3'
        onClick={props.clearFilters}
      >
        Скинути
      </button>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(TgOptionsServiceFilters)
