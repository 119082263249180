import React from 'react'
import { connect } from 'react-redux'
import { StateType, ModificationStatus } from '../../../../../store/models/root.interface'
import { TgCurrency } from '../../../../../store/models/tg/tg-currency.interface'
import { bindActionCreators, Dispatch } from 'redux'
import {
  changeSelectedTgCurrency,
  clearSelectedTgCurrency,
  setTgCurrenciesModificationState,
} from '../../../../../store/dispatch/tg/tg-currencies.dispatch'
import ListIsEmpty from '../../../../../common/components/ListIsEmpty'
import { DispatchRenderListTgCurrenciesProps } from '../types/RenderListTgCurrencies.types'

const mapDispatchToProps = (dispatch: Dispatch): DispatchRenderListTgCurrenciesProps => ({
  clearSelected: bindActionCreators(clearSelectedTgCurrency, dispatch),
  changeSelected: bindActionCreators(changeSelectedTgCurrency, dispatch),
  setModificationState: bindActionCreators(setTgCurrenciesModificationState, dispatch),
})

const mapStateToProps = ({ tgCurrencies }: StateType) => ({
  currencies: tgCurrencies,
})

type PropsComponent = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const RenderListTgCurrencies: React.FC<PropsComponent> = ({
  currencies: { list, selected },
  clearSelected,
  changeSelected,
  setModificationState,
}): JSX.Element => {
  const onSelect = (_id: string): void => {
    if (selected && selected._id === _id) {
      clearSelected()
    } else {
      changeSelected(_id)
    }

    setModificationState(ModificationStatus.None)
  }

  if (!list || (list && !list.length)) return <ListIsEmpty />

  const currenciesList: JSX.Element[] = list.map((item: TgCurrency) => {
    return (
      <tr
        className={`table-row ${selected?._id === item._id ? 'table-primary' : ''}`}
        key={item._id}
        onClick={() => onSelect(item._id || '')}
      >
        <td scope='row'>{item._id}</td>
        <th>{item.currencyA}</th>
        <th>{item.nameCurrencyA}</th>
        <th>{item.currencyB}</th>
        <th>
          {item.rate.toFixed(2)} {item.currencyA} / 1 USD
        </th>
      </tr>
    )
  })

  return (
    <table className='table m-0'>
      <thead className='thead-light'>
        <tr>
          <th scope='col'>ID</th>
          <th scope='col'>Валюта А</th>
          <th scope='col'>Назва</th>
          <th scope='col'>Валюта B</th>
          <th scope='col'>Ціна</th>
        </tr>
      </thead>
      <tbody>{currenciesList}</tbody>
    </table>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(RenderListTgCurrencies)
