import React from 'react'
import { ModificationStatus } from '../../store/models/root.interface'
import { CardFormWrapperProps } from './types/CardFormWrapper.types'
const CardFormWrapper = <T,>({ modificationState, form, selected }: CardFormWrapperProps<T>) => {
  if (!form) return <></>

  return (
    <>
      {modificationState === ModificationStatus.Create ||
      (modificationState === ModificationStatus.Edit && selected)
        ? form
        : null}
    </>
  )
}

export default CardFormWrapper
