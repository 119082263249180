export const scrCredentialTypes = {
  GET_SCRAPER_CREDENTIAL: 'GET_SCRAPER_CREDENTIAL',
  ADD_SCRAPER_CREDENTIAL: 'ADD_SCRAPER_CREDENTIAL',
  EDIT_SCRAPER_CREDENTIAL: 'EDIT_SCRAPER_CREDENTIAL',
  REMOVE_SCRAPER_CREDENTIAL: 'REMOVE_SCRAPER_CREDENTIAL',
  CHANGE_SCRAPER_CREDENTIAL_PENDING_EDIT: 'CHANGE_SCRAPER_CREDENTIAL_PENDING_EDIT',
  CHANGE_SCRAPER_CREDENTIAL_STATUS: 'CHANGE_SCRAPER_CREDENTIAL_STATUS',
  CLEAR_SCRAPER_CREDENTIAL_PENDING_EDIT: 'CLEAR_SCRAPER_CREDENTIAL_PENDING_EDIT',
  SET_MODIFICATION_STATE_SCRAPER_CREDENTIAL: 'SET_MODIFICATION_STATE_SCRAPER_CREDENTIAL',
  SET_SOME_ERROR_SCRAPER_CREDENTIAL: 'SET_SOME_ERROR_SCRAPER_CREDENTIAL',
}
