import { IActionBase, ModificationStatus, TgCustomerState } from '../../models/root.interface'
import { TgCustomer } from '../../models/tg/tg-customer.interface'
import { tgCustomersTypes } from '../../types/tg/tg-customers.types'

type State = TgCustomerState
type IElement = TgCustomer

const initialState: State = {
  error: null,
  modificationState: ModificationStatus.None,
  selected: null,
  list: [],
}

export default (state: State = initialState, action: IActionBase): State => {
  switch (action.type) {
    case tgCustomersTypes.GET_TG_CUSTOMERS_LIST: {
      return { ...state, error: null, list: action.value, selected: null }
    }
    case tgCustomersTypes.ADD_TG_CUSTOMER: {
      return { ...state, error: null, list: [...state.list, action.value], selected: null }
    }
    case tgCustomersTypes.EDIT_TG_CUSTOMER: {
      const foundIndex: number = state.list.findIndex((pr) => pr._id === action.value._id)
      const list: IElement[] = state.list
      list[foundIndex] = action.value

      return { ...state, error: null, list }
    }
    case tgCustomersTypes.REMOVE_TG_CUSTOMER: {
      return {
        ...state,
        error: null,
        list: state.list.filter((pr) => pr._id !== action.value),
        selected: null,
      }
    }
    case tgCustomersTypes.CHANGE_TG_CUSTOMER_PENDING_EDIT: {
      const foundIndex: number = state.list.findIndex((pr) => pr._id === action.value)

      return { ...state, error: null, selected: state.list[foundIndex] }
    }
    case tgCustomersTypes.CLEAR_TG_CUSTOMER_PENDING_EDIT: {
      return { ...state, error: null, selected: null }
    }
    case tgCustomersTypes.SET_TG_CUSTOMER_MODIFICATION_STATE: {
      return { ...state, error: null, modificationState: action.value }
    }
    case tgCustomersTypes.SET_TG_CUSTOMER_SOME_ERROR: {
      return { ...state, error: action.value }
    }
    default:
      return state
  }
}
