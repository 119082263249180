import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { useHttpDateLoader } from '../../../../hooks/request.hook'
import { RequestUrlTypes } from '../../../../hooks/types/request.types'
import { useErrorHandlerHook } from '../../../../hooks/error-handler'
import RenderListTgOptionsCountry from './components/RenderListTgOptionsCountry'
import {
  getTgOptionCountriesList,
  setTgOptionCountrySomeError,
} from '../../../../store/dispatch/tg/tg-options-country.dispatch'
import { TgOptionCountry } from '../../../../store/models/tg/tg-options-country.interface'
import { URL_SERVER_TG } from '../../../../config'
import TgOptionsCountryWrapper from './components/TgOptionsCountryWrapper'
const mapDispatchToProps = (dispatch: Dispatch) => ({
  setList: bindActionCreators(getTgOptionCountriesList, dispatch),
  setSomeError: bindActionCreators(setTgOptionCountrySomeError, dispatch),
})

type PropsComponent = ReturnType<typeof mapDispatchToProps>
const TgOptionsCountryList: React.FC<PropsComponent> = ({ setList, setSomeError }): JSX.Element => {
  const errorHandler = useErrorHandlerHook(setSomeError, 'Тг опції')
  useHttpDateLoader<TgOptionCountry[]>({
    url: RequestUrlTypes.TG_OPTION_COUNTRY,
    base: URL_SERVER_TG,
    func: setList,
    errorHandler,
  })

  return (
    <TgOptionsCountryWrapper>
      <RenderListTgOptionsCountry />
    </TgOptionsCountryWrapper>
  )
}

export default connect(null, mapDispatchToProps)(TgOptionsCountryList)
