import { Dispatch } from 'redux'
import { ModificationStatus } from '../../models/root.interface'
import {
  addGsmOptionsCountryAction,
  changeSelectedGsmOptionsCountryAction,
  clearSelectedCountryAction,
  editGsmOptionsCountryAction,
  getGsmOptionsGsmOptionsCountriesListAction,
  removeGsmOptionsCountryAction,
  setGsmOptionsCountriesModificationStateAction,
  setGsmOptionsCountriesSomeErrorAction,
} from '../../actions/gsm/gsm-options-country.actions'
import { GsmOptionsCountry } from '../../models/gsm/gsm-options-country.interface'

type ActionItem = GsmOptionsCountry

export const getGsmOptionsCountriesList = (data: ActionItem[]) => (dispatch: Dispatch) => {
  dispatch(getGsmOptionsGsmOptionsCountriesListAction(data))
}
export const addGsmOptionsCountry = (data: ActionItem) => (dispatch: Dispatch) => {
  dispatch(addGsmOptionsCountryAction(data))
}
export const editGsmOptionsCountry = (value: ActionItem) => (dispatch: Dispatch) => {
  dispatch(editGsmOptionsCountryAction(value))
}
export const removeGsmOptionsCountry = (value: string) => (dispatch: Dispatch) => {
  dispatch(removeGsmOptionsCountryAction(value))
}
export const changeSelectedGsmOptionsCountry = (value: string) => (dispatch: Dispatch) => {
  dispatch(changeSelectedGsmOptionsCountryAction(value))
}
export const clearSelectedGsmOptionsCountry = () => (dispatch: Dispatch) => {
  dispatch(clearSelectedCountryAction())
}
export const setGsmOptionsCountriesModificationState =
  (value: ModificationStatus) => (dispatch: Dispatch) => {
    dispatch(setGsmOptionsCountriesModificationStateAction(value))
  }
export const setGsmOptionsCountriesSomeError = (value: string) => (dispatch: Dispatch) => {
  dispatch(setGsmOptionsCountriesSomeErrorAction(value))
}
