import React from 'react'
import { TextareaProps } from './types/Textarea.types'

function TextareaRH<T>(props: TextareaProps<T>): JSX.Element {
  const [max, min] = [props.max || 10000, props.min || 0]
  const error = (props.formState.errors[props.field]?.message as string) || ''

  return (
    <div>
      <label htmlFor={props.id} className='form-label mb-2'>
        {props.label}
      </label>

      <textarea
        style={{ fontSize: '.8rem' }}
        id={props.id}
        placeholder={props.placeholder || ''}
        className={`form-control ${props.inputClass}`}
        rows={props.rows ? props.rows : 8}
        {...props.register(props.field, {
          required: props.required || '',
          maxLength: {
            value: max,
            message: `The value ${props.field} cannot be longer than ${max} characters`,
          },
          minLength: {
            value: min,
            message: `The value ${props.field} cannot be less than ${min} characters`,
          },
        })}
      ></textarea>

      {error && props.required ? <div className='invalid-feedback'>{error}</div> : null}
    </div>
  )
}

export default TextareaRH
