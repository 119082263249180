import { Dispatch } from 'redux'
import { ModificationStatus } from '../../models/root.interface'
import { TgMailingMessage } from '../../models/tg/tg-mailing.interface'
import {
  addTgMailingAction,
  changeSelectedTgMailingAction,
  clearSelectedTgMailingAction,
  editTgMailingAction,
  getTgMailingListAction,
  removeTgMailingAction,
  setTgMailingModificationStateAction,
  setTgMailingSomeErrorAction,
} from '../../actions/tg/tg-mailing.action'

type ActionItem = TgMailingMessage

export const getTgMailingsList = (data: ActionItem[]) => (dispatch: Dispatch) => {
  dispatch(getTgMailingListAction(data))
}
export const addTgMailing = (value: ActionItem) => (dispatch: Dispatch) => {
  dispatch(addTgMailingAction(value))
}
export const editTgMailing = (value: ActionItem) => (dispatch: Dispatch) => {
  dispatch(editTgMailingAction(value))
}
export const removeTgMailing = (value: string) => (dispatch: Dispatch) => {
  dispatch(removeTgMailingAction(value))
}
export const changeSelectedTgMailing = (value: string) => (dispatch: Dispatch) => {
  dispatch(changeSelectedTgMailingAction(value))
}
export const clearSelectedTgMailing = () => (dispatch: Dispatch) => {
  dispatch(clearSelectedTgMailingAction())
}
export const setTgMailingsModificationState =
  (value: ModificationStatus) => (dispatch: Dispatch) => {
    dispatch(setTgMailingModificationStateAction(value))
  }
export const setTgMailingsSomeError = (value: string) => (dispatch: Dispatch) => {
  dispatch(setTgMailingSomeErrorAction(value))
}
