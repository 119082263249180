export const tgPartnerProgramTypes = {
  GET_TG_PARTNER_PROGRAMS_LIST: 'GET_TG_PARTNER_PROGRAMS_LIST',
  ADD_TG_PARTNER_PROGRAM: 'ADD_TG_PARTNER_PROGRAM',
  EDIT_TG_PARTNER_PROGRAM: 'EDIT_TG_PARTNER_PROGRAM',
  REMOVE_TG_PARTNER_PROGRAM: 'REMOVE_TG_PARTNER_PROGRAM',
  CHANGE_TG_PARTNER_PROGRAM_PENDING_EDIT: 'CHANGE_TG_PARTNER_PROGRAM_PENDING_EDIT',

  CLEAR_TG_PARTNER_PROGRAM_PENDING_EDIT: 'CLEAR_TG_PARTNER_PROGRAM_PENDING_EDIT',
  SET_TG_PARTNER_PROGRAM_MODIFICATION_STATE: 'SET_TG_PARTNER_PROGRAM_MODIFICATION_STATE',
  SET_TG_PARTNER_PROGRAM_SOME_ERROR: 'SET_TG_PARTNER_PROGRAM_SOME_ERROR',
}
