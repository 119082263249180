export const tgMailingTypes = {
  GET_TG_MESSAGES: 'GET_TG_MESSAGES',
  ADD_TG_MESSAGE: 'ADD_TG_MESSAGE',
  EDIT_TG_MESSAGE: 'EDIT_TG_MESSAGE',
  REMOVE_TG_MESSAGE: 'REMOVE_TG_MESSAGE',
  CLEAR_TG_MESSAGE_PENDING_EDIT: 'CLEAR_TG_MESSAGE_PENDING_EDIT',
  CHANGE_TG_MESSAGE_PENDING_EDIT: 'CHANGE_TG_MESSAGE_PENDING_EDIT',
  SET_TG_MESSAGE_MODIFICATION_STATE: 'SET_TG_MESSAGE_MODIFICATION_STATE',
  SET_TG_MESSAGE_SOME_ERROR: 'SET_TG_MESSAGE_SOME_ERROR',
}
