import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import {
  getPartnerProgramsList,
  setPartnerProgramsSomeError,
} from '../../../../store/dispatch/tg/tg-partner-program.dispatch'
import { useHttpDateLoader } from '../../../../hooks/request.hook'
import { RequestUrlTypes } from '../../../../hooks/types/request.types'
import RenderListTgPartnerPrograms from './components/RenderListTgPartnerPrograms'
import { PartnerProgram } from '../../../../store/models/tg/tg-partner-program.interface'
import { DispatchTgPartnerProgramsListProps } from './types/TgPartnerProgramsList.types'
import { useErrorHandlerHook } from '../../../../hooks/error-handler'
import { URL_SERVER_TG } from '../../../../config'
import TgPartnerProgramWrapper from './components/TgPartnerProgramWrapper'

const mapDispatchToProps = (dispatch: Dispatch): DispatchTgPartnerProgramsListProps => ({
  setList: bindActionCreators(getPartnerProgramsList, dispatch),
  setSomeError: bindActionCreators(setPartnerProgramsSomeError, dispatch),
})

type PropsComponent = ReturnType<typeof mapDispatchToProps>

const TgPartnerProgramsList: React.FC<PropsComponent> = ({
  setList,
  setSomeError,
}): JSX.Element => {
  const errorHandler = useErrorHandlerHook(setSomeError, 'Партнерська програма')
  useHttpDateLoader<PartnerProgram[]>({
    url: RequestUrlTypes.TG_PARTNER_PROGRAM,
    base: URL_SERVER_TG,
    func: setList,
    errorHandler,
  })

  return (
    <TgPartnerProgramWrapper>
      <RenderListTgPartnerPrograms />
    </TgPartnerProgramWrapper>
  )
}

export default connect(null, mapDispatchToProps)(TgPartnerProgramsList)
