import { INotification } from './notification.interface'
import { IUser } from './user.interface'
import { IAccount } from './account.interface'
import { ScrRentalRate } from './scraper/scr-rental-rate.interface'
import { TgMailingMessage } from './tg/tg-mailing.interface'
import { TgCurrency } from './tg/tg-currency.interface'
import { TgPromoCode } from './tg/tg-promo-code.interface'
import { PartnerProgram } from './tg/tg-partner-program.interface'
import { ISelectOption } from '../../common/fields/types/Select.types'
import { TgOptionService, TgOptionsServiceFilters } from './tg/tg-options-service.interface'
import { TgOptionCountry } from './tg/tg-options-country.interface'
import { TgInternalBank } from './tg/tg-internal-bank.interface'
import { TgCustomer } from './tg/tg-customer.interface'
import { GsmOptionsSender } from './gsm/gsm-options-sender.interface'
import { GsmOptionsCountry } from './gsm/gsm-options-country.interface'
import { GsmOptionsService, GsmOptionsServiceFilters } from './gsm/gsm-options-service.interface'
import { ScrOptionsCountry } from './scraper/scr-options-country.interface'
import { ScrOptionsService } from './scraper/scr-options-service.interface'
import { ScrSlot } from './scraper/scr-slot.interface'
import { ScrConfiguration } from './scraper/scr-configuration.interface'
import { ScrCredential } from './scraper/scr-credential.interface'
import { ScrService, ScrServiceFilters } from './scraper/scr-service.interface'
import { GsmAnalyticsOption, GsmAnalyticsOptionFilters } from './gsm/gsm-analytics.interface'
import { ScrRentalRateDiary } from './scraper/scr-rental-rate-diary.interface'
import {
  ScrServiceDiary,
  ScrServiceDiaryFilters,
  ScrServiceDiaryItem,
} from './scraper/scr-service-diary.interface'

export enum ModificationStatus {
  None = 0,
  Create = 1,
  Edit = 2,
  Remove = 3,
  Sim_analytics = 4,
}

export enum MediaTypes {
  VIDEO = 'video',
  AUDIO = 'audio',
  IMAGE = 'image',
  CONTENT = 'content',
}

export type CommonModel = {
  _id: string
}

export type RootStateType = {
  error: string | null
  page: {
    area: string
    subArea: string
  }
  loader: boolean
}

export type StateType = {
  root: RootStateType
  notifications: INotificationState
  users: IUserState
  account: IAccount
  scrService: ScrServiceState
  scrOptions: ScrOptionsState
  scrSlot: ScSlotState
  scrRentalRate: ScrRentalRateState
  scrCredential: ScrCredentialState
  scrConfiguration: ScrConfigurationState
  scrRentalRateDiary: ScrRentalRateDiaryState
  scrServiceDiary: ScrServiceDiaryState
  tgMailing: TgMailinState
  tgOptionsCountry: TgOptionsCountryState
  tgOptionsService: TgOptionsServiceState
  tgCurrencies: TgCurrenciesState
  tgPromoCodes: TgPromoCodesState
  tgPartnerPrograms: TgPartnerProgramsState
  tgInternalBank: TgInternalBankState
  tgCustomers: TgCustomerState
  gsmOptionsService: GsmOptionsServiceState
  gsmOptionsCountry: GsmOptionsCountryState
  gsmOptionsSender: GsmOptionsSenderState
  gsmAnalytics: GsmAnalyticsState
}

interface ICommonPageState<T> {
  error: string | null
  list: T[]
  selected: T | null
  modificationState: ModificationStatus
}

export type TgMailinState = ICommonPageState<TgMailingMessage>

export type TgCurrenciesState = ICommonPageState<TgCurrency> & {
  base: ISelectOption[]
}

export type TgPromoCodesState = ICommonPageState<TgPromoCode>

export type TgPartnerProgramsState = ICommonPageState<PartnerProgram>

export interface ScrOptionsState {
  error: string | null
  scrapersList: string[]
  prioritiesList: ISelectOption[]
  countriesList: ScrOptionsCountry[]
  servicesList: ScrOptionsService[]
  selectedCountry: ScrOptionsCountry | null
  selectedService: ScrOptionsService | null
}

export type TgOptionsCountryState = ICommonPageState<TgOptionCountry>

export type TgOptionsServiceState = ICommonPageState<TgOptionService> & {
  filters: TgOptionsServiceFilters
}
export type TgCustomerState = ICommonPageState<TgCustomer>

export type TgInternalBankState = ICommonPageState<TgInternalBank> & {
  base: string[]
}

export type GsmAnalyticsState = ICommonPageState<GsmAnalyticsOption> & {
  filters: GsmAnalyticsOptionFilters
}

export type GsmOptionsSenderState = ICommonPageState<GsmOptionsSender>
export type GsmOptionsServiceState = ICommonPageState<GsmOptionsService> & {
  filters: GsmOptionsServiceFilters
}
export type GsmOptionsCountryState = ICommonPageState<GsmOptionsCountry>

export type FiltersState<T> = Record<keyof T, string[] | string>

export type ScSlotState = ICommonPageState<ScrSlot>
export type ScrRentalRateState = ICommonPageState<ScrRentalRate>
export type ScrConfigurationState = ICommonPageState<ScrConfiguration>
export type ScrCredentialState = ICommonPageState<ScrCredential>
export type ScrRentalRateDiaryState = ICommonPageState<ScrRentalRateDiary>
export type ScrServiceDiaryState = {
  error: string | null
  list: ScrServiceDiaryItem[]
  selected: ScrServiceDiary | null
  modificationState: ModificationStatus
  filters: ScrServiceDiaryFilters
}

export type ScrServiceState = ICommonPageState<ScrService> & {
  filters: ScrServiceFilters
}

export interface IActionBase {
  type: string

  [prop: string]: any
}

export interface INotificationState {
  notifications: INotification[]
}

export interface IUserState {
  users: IUser[]
  admins: IUser[]
}

export enum TgOptionUnits {
  PERCENTAGE = 'percentage',
  NUMERICAL = 'numerical',
}
