import React from 'react'
import { connect } from 'react-redux'
import { ModificationStatus, StateType } from '../../../../store/models/root.interface'
import { bindActionCreators, Dispatch } from 'redux'
import { addNotification } from '../../../../store/dispatch/notifications.dispatch'
import {
  addScraperConfiguration,
  clearSelectedScraperConfiguration,
  editScraperConfiguration,
  setScraperConfigurationsModificationState,
  setScraperConfigurationsSomeError,
} from '../../../../store/dispatch/scraper/scr-configuration.dispatch'
import { RequestUrlTypes } from '../../../../hooks/types/request.types'
import { useForm } from 'react-hook-form'
import { useHttpRequestWithBody } from '../../../../hooks/request.hook'
import { TabFormWrapperProps } from '../../../../common/components/types/TabFormWrapper.types'
import TabFormWrapper from '../../../../common/components/TabFormWrapper'
import RenderFormConfiguration from './components/RenderFormConfiguration'
import { useErrorHandlerHook } from '../../../../hooks/error-handler'
import { ScrConfiguration } from '../../../../store/models/scraper/scr-configuration.interface'
import { URL_SERVER_SCRAPER } from '../../../../config'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { updateLoaderState } from '../../../../store/dispatch/root.dispatch'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setSomeError: bindActionCreators(setScraperConfigurationsSomeError, dispatch),
  clearSelected: bindActionCreators(clearSelectedScraperConfiguration, dispatch),
  setModificationState: bindActionCreators(setScraperConfigurationsModificationState, dispatch),
  add: bindActionCreators(addScraperConfiguration, dispatch),
  edit: bindActionCreators(editScraperConfiguration, dispatch),
  addNotification: bindActionCreators(addNotification, dispatch),
  updateLoader: bindActionCreators(updateLoaderState, dispatch),
})

const mapStateToProps = ({ scrConfiguration }: StateType) => ({ scrConfiguration })

type PropsConfigurationFormComponent = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const schema = yup.object<ScrConfiguration>().shape({
  _id: yup.string().notRequired(),
  name: yup.string().min(0).max(50).required('Value title cannot be empty'),
  beforeSingleChanges: yup.number().min(0).max(1000).required('Value textEn cannot be empty'),
  beforeMultiChanges: yup.number().min(0).max(1000).required('Value textUa cannot be empty'),
  beforeProcess: yup.number().min(0).max(1000).required('Value textRu cannot be empty'),
  numberCycles: yup.number().min(0).max(1000).required('Value textRu cannot be empty'),
})

const ConfigurationForm: React.FC<PropsConfigurationFormComponent> = ({
  scrConfiguration: { modificationState, selected, list, error },
  setSomeError,
  clearSelected,
  setModificationState,
  add,
  edit,
  addNotification,
  updateLoader,
}) => {
  const isCreate: boolean = modificationState === ModificationStatus.Create
  const errorHandler = useErrorHandlerHook(setSomeError, 'Конфігурація бота')
  const saveRequest = useHttpRequestWithBody<ScrConfiguration>({
    url: RequestUrlTypes.SCRAPER_CONFIGURATION,
    base: URL_SERVER_SCRAPER,
    func: isCreate ? add : edit,
  })

  let item: ScrConfiguration | null = selected

  if (!item || isCreate) {
    item = new ScrConfiguration()
  }

  const form = useForm<ScrConfiguration>({ defaultValues: item, resolver: yupResolver(schema) })

  const saveForm = async (formState: ScrConfiguration): Promise<void> => {
    const foundedItem = list.find((item) => item.name === formState.name)

    if (isCreate && foundedItem) {
      errorHandler({ text: 'Дана конфігурація вже існує' })
      return
    }

    if (item) {
      try {
        await saveRequest({
          body: {
            ...item,
            name: formState.name,
            numberCycles: formState.numberCycles,
            beforeMultiChanges: formState.beforeMultiChanges,
            beforeSingleChanges: formState.beforeSingleChanges,
            beforeProcess: formState.beforeProcess,
          },
          id: item._id,
        })

        const message = isCreate ? `${formState.name} створена` : `${formState.name} редагування`
        addNotification('Конфігурація', message)
        clearSelected()
        setModificationState(ModificationStatus.None)
      } catch (e: any) {
        errorHandler({ text: e.message })
      } finally {
        updateLoader(false)
      }
    }
  }

  const resetForm = (): void => {
    setModificationState(ModificationStatus.None)
    form.reset()
  }

  const formOptions: TabFormWrapperProps<ScrConfiguration> = {
    form,
    error,
    title: 'конфігурація',
    isCreate,
    saveForm,
    resetForm,
  }

  return (
    <TabFormWrapper {...formOptions}>
      <RenderFormConfiguration form={form} />
    </TabFormWrapper>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurationForm)
