import { IActionBase, ModificationStatus, ScrServiceState } from '../../models/root.interface'
import { scrServiceTypes } from '../../types/scraper/scr-service.types'
import { ScrService, ScrServiceFilters } from '../../models/scraper/scr-service.interface'

type State = ScrServiceState
type Element = ScrService

const filters: ScrServiceFilters = {
  name: [],
  country: [],
  account: '',
}

const initialState: State = {
  error: null,
  modificationState: ModificationStatus.None,
  selected: null,
  list: [],
  filters,
}

export default (state: State = initialState, action: IActionBase): State => {
  switch (action.type) {
    case scrServiceTypes.GET_ITEMS_LIST: {
      return { ...state, error: null, list: action.value, selected: null }
    }
    case scrServiceTypes.ADD_NEW_ITEM: {
      return { ...state, error: null, list: [...state.list, action.value], selected: null }
    }
    case scrServiceTypes.EDIT_ITEM: {
      const foundIndex: number = state.list.findIndex((pr) => pr._id === action.value._id)
      const list: Element[] = state.list
      list[foundIndex] = action.value

      return { ...state, error: null, list }
    }
    case scrServiceTypes.REMOVE_ITEM: {
      return {
        ...state,
        error: null,
        list: state.list.filter((pr) => pr._id !== action.value),
        selected: null,
      }
    }
    case scrServiceTypes.CHANGE_ITEM_PENDING_EDIT: {
      const foundIndex: number = state.list.findIndex((pr) => pr._id === action.value)

      return { ...state, error: null, selected: state.list[foundIndex] }
    }
    case scrServiceTypes.CLEAR_ITEM_PENDING_EDIT: {
      return { ...state, error: null, selected: null }
    }
    case scrServiceTypes.SET_ITEM_MODIFICATION_STATE: {
      return { ...state, error: null, modificationState: action.value }
    }
    case scrServiceTypes.SET_ITEMS_SOME_ERROR: {
      return { ...state, error: action.value }
    }
    case scrServiceTypes.EDIT_ITEMS_FILTER: {
      return { ...state, filters: action.value }
    }
    case scrServiceTypes.CLEAR_ITEMS_FILTERS: {
      return { ...state, filters }
    }
    default:
      return state
  }
}
