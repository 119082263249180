import ListOfServiceAccounts from './ListOfScrServiceAccounts'
import { ScrService } from '../../../../../store/models/scraper/scr-service.interface'
import SlotsList from '../../slot/SlotsList'
import { TabHeaderServiceProps } from '../types/TabHeaderService.types'
import { ScrapperTabCustomProps } from '../../../types/ScraperOptions.type'

const ScrServiceTabHeader = ({
  list,
  renderTabHeader,
}: TabHeaderServiceProps & Pick<ScrapperTabCustomProps, 'renderTabHeader'>) => {
  const hasAccount: boolean = list.some((service: ScrService) => service.account)

  return (
    <>
      {renderTabHeader}
      <SlotsList />
      {hasAccount && (
        <>
          <p className='mb-4'>Список акаунтів</p>
          <ListOfServiceAccounts />
        </>
      )}
    </>
  )
}

export default ScrServiceTabHeader
