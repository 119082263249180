export const gsmOptionsSenderTypes = {
  GET_ITEMS_LIST: 'GET_GSM_OPTIONS_SENDERS_LIST',
  ADD_NEW_ITEM: 'ADD_GSM_OPTIONS_SENDER',
  EDIT_ITEM: 'EDIT_GSM_OPTIONS_SENDER',
  REMOVE_ITEM: 'REMOVE_GSM_OPTIONS_SENDER',
  CHANGE_ITEM_PENDING_EDIT: 'CHANGE_GSM_OPTIONS_SENDER_PENDING_EDIT',

  CLEAR_ITEM_PENDING_EDIT: 'CLEAR_GSM_OPTIONS_SENDER_PENDING_EDIT',
  SET_ITEM_MODIFICATION_STATE: 'SET_GSM_OPTIONS_SENDER_MODIFICATION_STATE',
  SET_ITEMS_SOME_ERROR: 'SET_GSM_OPTIONS_SENDER_SOME_ERROR',
}
