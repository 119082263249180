import { GsmOptionType } from '../types/GsmOptions.types'

const TabHeaderGsmOptions = ({
  type,
  setType,
}: {
  type: string
  setType: (value: GsmOptionType) => void
}) => {
  const listOptions = [
    { value: GsmOptionType.COUNTRY, text: 'Країни' },
    { value: GsmOptionType.SERVICE, text: 'Сервіси' },
    { value: GsmOptionType.SENDER, text: 'Відправники' },
  ]

  const classOptionsBtn = (value: string): string => {
    const addition = type === value ? '-' : '-outline-'
    return `btn btn${addition}secondary`
  }

  return (
    <>
      <h1 className='h3 mb-2 text-gray-800'>Gsm модем опції</h1>
      <div className='btn-group mb-4' role='group' aria-label='Basic outlined example'>
        {listOptions.map((option) => {
          const { value, text } = option

          return (
            <button
              key={value}
              type='button'
              className={classOptionsBtn(value)}
              onClick={() => setType(value)}
            >
              {text}
            </button>
          )
        })}
      </div>
    </>
  )
}

export default TabHeaderGsmOptions
