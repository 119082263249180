import React from 'react'
import TextareaRH from '../../../../../common/form-hook/Textarea'
import { CustomRenderFormTgOptionCountryProps } from '../types/RenderFormTgOptionCountry.types'
import {
  TgContentLanguages,
  TgContentLanguagesValues,
} from '../../../../../store/models/tg/tg-options-country.interface'

const RenderContentFieldsTgOption = ({
  form,
}: Pick<CustomRenderFormTgOptionCountryProps, 'form'>) => {
  const languages = Object.values(TgContentLanguages) as Array<TgContentLanguagesValues>
  return (
    <>
      <div className='form-row row col-md-12'>
        {languages.map((lang) => {
          return (
            <div className='form-group col-md-4 my-2' key={lang}>
              <TextareaRH
                {...form}
                id='input_content'
                field={`content.${lang}`}
                label={`Додатковий Опис (${lang})`}
                rows={5}
                placeholder='Введіть значення'
              />
            </div>
          )
        })}
      </div>
    </>
  )
}

export default RenderContentFieldsTgOption
