import React, { useState } from 'react'
import { Link, Outlet } from 'react-router-dom'
import './LeftMenu.css'

const LeftMenu = () => {
  const [leftMenuVisibility, setLeftMenuVisibility] = useState(false)

  const changeLeftMenuVisibility = () => {
    setLeftMenuVisibility(!leftMenuVisibility)
  }

  const getCollapseClass = () => {
    return leftMenuVisibility ? '' : 'collapsed'
  }

  return (
    <>
      <div className='toggle-area'>
        <button
          className='btn btn-primary toggle-button'
          onClick={() => changeLeftMenuVisibility()}
        >
          <i className='fas fa-bolt'></i>
        </button>
      </div>

      <ul
        className={`navbar-nav bg-gradient-primary-green sidebar sidebar-dark accordion ${getCollapseClass()}`}
        id='collapseMenu'
      >
        <Link className='sidebar-brand d-flex align-items-center justify-content-center' to='/'>
          <div className='sidebar-brand-icon icon-green rotate-n-0'>
            {/* <i className="fas fa-bolt"></i>*/}
            <img src='/logo.jpg' alt='logo' className='page-logo' />
          </div>
          <div className='sidebar-brand-text mx-2'>
            ProfitSim <sup>Admin</sup>
          </div>
        </Link>

        <hr className='sidebar-divider my-0' />

        <li className='nav-item active'>
          <Link className='nav-link' to='/'>
            <i className='fas fa-fw fa-tachometer-alt'></i>
            <span>Дашборд</span>
          </Link>
        </li>

        <hr className='sidebar-divider' />
        <div className='sidebar-heading'>SMS hub</div>

        <li className='nav-item'>
          <Link className='nav-link' to={'/scraper-options'}>
            <i className='fas fa-fw fa-inbox'></i>
            <span>Опції</span>
          </Link>
        </li>

        <hr className='sidebar-divider' />

        <div className='sidebar-heading'>Admin</div>

        <li className='nav-item'>
          <Link className='nav-link' to={'/users'}>
            <i className='fas fa-fw fa-user'></i>
            <span>Користувачі</span>
          </Link>
        </li>

        <hr className='sidebar-divider' />

        <div className='sidebar-heading'>Telegram Bot</div>

        {/* <li className='nav-item'>
          <Link className='nav-link' to={'/mailing'}>
            <i className='fas fa-fw fa-list'></i>
            <span>Розсилки</span>
          </Link>
        </li> */}

        <li className='nav-item'>
          <Link className='nav-link' to={'/tg-options'}>
            <i className='fas fa-fw fa-inbox'></i>
            <span>Опції</span>
          </Link>
        </li>

        <li className='nav-item'>
          <Link className='nav-link' to={'/tg-customers'}>
            <i className='fas fa-fw fa-user'></i>
            <span>Користувачі</span>
          </Link>
        </li>

        <hr className='sidebar-divider' />

        <div className='sidebar-heading'>Gsm modem</div>

        <li className='nav-item'>
          <Link className='nav-link' to={'/gsm-options'}>
            <i className='fas fa-fw fa-inbox'></i>
            <span>Опції</span>
          </Link>
        </li>

        <li className='nav-item'>
          <Link className='nav-link' to={'/gsm-analytic'}>
            <i className='fas fa-fw fa-table-list'></i>
            <span>Аналітика</span>
          </Link>
        </li>

        <hr className='sidebar-divider d-none d-md-block' />
      </ul>
      <Outlet />
    </>
  )
}

export default LeftMenu
