import { Dispatch } from 'redux'
import {
  getCountriesOptionsAction,
  getServicesOptionsAction,
  setOptionsSomeErrorAction,
} from '../../actions/scraper/scr-option.action'
import { ScrOptionsCountry } from '../../models/scraper/scr-options-country.interface'
import { ScrOptionsService } from '../../models/scraper/scr-options-service.interface'

export interface IOptionsData {
  options: {
    countries: ScrOptionsCountry[]
    services: ScrOptionsService[]
  }
}
export const setOptionsSomeError = (value: string) => (dispatch: Dispatch) => {
  dispatch(setOptionsSomeErrorAction(value))
}
export const getOptionsList = (data: IOptionsData) => (dispatch: Dispatch) => {
  dispatch(getCountriesOptionsAction(data?.options?.countries ?? []))
  dispatch(getServicesOptionsAction(data?.options?.services ?? []))
}
