import React from 'react'
import { connect } from 'react-redux'
import { ModificationStatus, StateType } from '../../../../store/models/root.interface'
import { bindActionCreators, Dispatch } from 'redux'
import { addNotification } from '../../../../store/dispatch/notifications.dispatch'
import { useHttpRequestWithBody } from '../../../../hooks/request.hook'
import { RequestUrlTypes } from '../../../../hooks/types/request.types'
import { useForm } from 'react-hook-form'
import { TabFormWrapperProps } from '../../../../common/components/types/TabFormWrapper.types'
import { useErrorHandlerHook } from '../../../../hooks/error-handler'
import { URL_SERVER_GSM } from '../../../../config'
import TabFormWrapper from '../../../../common/components/TabFormWrapper'
import {
  addGsmOptionsService,
  clearSelectedGsmOptionsService,
  editGsmOptionsService,
  setGsmOptionsServicesModificationState,
  setGsmOptionsServicesSomeError,
} from '../../../../store/dispatch/gsm/gsm-options-service.dispatch'
import { GsmOptionsService } from '../../../../store/models/gsm/gsm-options-service.interface'
import RenderFormGsmOptionsService from './components/RenderFormGsmOptionsService'
import { updateLoaderState } from '../../../../store/dispatch/root.dispatch'
import * as yup from 'yup'
import { Maybe } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setSomeError: bindActionCreators(setGsmOptionsServicesSomeError, dispatch),
  clearSelected: bindActionCreators(clearSelectedGsmOptionsService, dispatch),
  setModificationState: bindActionCreators(setGsmOptionsServicesModificationState, dispatch),
  add: bindActionCreators(addGsmOptionsService, dispatch),
  edit: bindActionCreators(editGsmOptionsService, dispatch),
  addNotification: bindActionCreators(addNotification, dispatch),
  setLoader: bindActionCreators(updateLoaderState, dispatch),
})

const mapStateToProps = ({ gsmOptionsService, gsmOptionsCountry }: StateType) => ({
  options: gsmOptionsService,
  countries: gsmOptionsCountry.list,
})

type PropsComponent = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const schema = yup.object().shape({
  _id: yup.string().notRequired(),
  name: yup.string().min(0).max(100).required('Value name cannot be empty'),
  shortName: yup.string().notRequired(),
  country: yup.string().min(1).required('Value country cannot be empty'),
  cost: yup.number().min(0.01).max(1000).required('Value cost cannot be empty'),
  important: yup.boolean().required('It should be some boolean value'),
})

export class GsmOptionServiceFrom {
  _id: Maybe<string | undefined>
  shortName: Maybe<string | undefined>
  name = ''
  country = ''
  cost = 0
  important = false
}

const GsmOptionsServiceForm: React.FC<PropsComponent> = ({
  options,
  countries,
  setSomeError,
  clearSelected,
  addNotification,
  setModificationState,
  setLoader,
  add,
  edit,
}) => {
  const { selected, modificationState, error, list } = options
  const isCreate: boolean = modificationState === ModificationStatus.Create
  const errorHandler = useErrorHandlerHook(setSomeError, 'Тг опції відправник')
  const saveRequest = useHttpRequestWithBody<GsmOptionsService>({
    url: RequestUrlTypes.GSM_OPTIONS_SERVICE,
    base: URL_SERVER_GSM,
    func: isCreate ? add : edit,
  })

  const item: GsmOptionServiceFrom = new GsmOptionServiceFrom()

  if (selected && !isCreate) {
    item._id = selected?._id || ''
    item.name = selected.name
    item.cost = selected.cost
    item.important = selected.important

    if (typeof selected.country === 'object') {
      item.country = selected.country._id || ''
    } else {
      item.country = selected.country
    }
  }

  const form = useForm<GsmOptionServiceFrom>({ defaultValues: item, resolver: yupResolver(schema) })

  const getterShortName = (size = 2): string => {
    let [shortNames, shortName] = [[''], '']

    if (list.length) {
      shortNames = list.map((i) => i.shortName || '')
    }

    while (!shortName) {
      const string = Math.random().toString(36).substring(2).replace(/[0-9]/g, '')
      const start = Math.floor(Math.random() * (string.length - size + 1)) + size
      shortName = string.slice(start - size, start)
      const hasShortName = shortNames.includes(shortName)
      shortName = hasShortName ? '' : shortName
    }

    return shortName
  }

  const saveForm = async (formState: GsmOptionServiceFrom): Promise<void> => {
    const uniq = list.find((item) => {
      return item.country === formState.country && formState.name === item.name
    })

    if (isCreate && uniq) {
      errorHandler({ text: 'Дана опція існує' })
      return
    }

    const serviceFound = list.find((item) => formState.name === item.name)
    const shortName = serviceFound?.shortName ?? getterShortName()

    if (item) {
      try {
        await saveRequest({
          body: {
            ...item,
            ...formState,
            shortName,
          },
          id: item._id,
        })

        setModificationState(ModificationStatus.None)
        clearSelected()
        addNotification('Тг опції відправник', isCreate ? 'створено' : 'редаговано')
      } catch (e: any) {
        errorHandler({ text: e.message })
      } finally {
        setLoader(false)
      }
    }
  }

  const resetForm = (): void => {
    setModificationState(ModificationStatus.None)
    form.reset()
  }

  const formOptions: TabFormWrapperProps<GsmOptionServiceFrom> = {
    form,
    error,
    title: 'відправник',
    isCreate,
    saveForm,
    resetForm,
  }

  return (
    <TabFormWrapper {...formOptions}>
      <RenderFormGsmOptionsService form={form} countries={countries} />
    </TabFormWrapper>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(GsmOptionsServiceForm)
