import React from 'react'
import TypeheadSelectRH from '../../../../../common/form-hook/TypeheadSelect'
import { UseFormReturn } from 'react-hook-form/dist/types/form'
import { GsmOptionsService } from '../../../../../store/models/gsm/gsm-options-service.interface'
import TextRH from '../../../../../common/form-hook/Text'
import { GsmOptionsCountry } from '../../../../../store/models/gsm/gsm-options-country.interface'
import { GsmOptionSenderFrom } from '../GsmOptionsSenderForm'

const RenderFormGsmOptionSender: React.FC<{
  form: UseFormReturn<GsmOptionSenderFrom>
  services: GsmOptionsService[]
  countries: GsmOptionsCountry[]
}> = ({ form, services, countries }) => {
  const country = form.watch('country')

  if (country) {
    services = services.filter((item) => {
      return typeof item.country === 'object' && item.country._id === country
    })
  } else {
    services = []
  }

  const names = form?.watch('names')?.split(' ') || []

  const rows = names.map((name: string) => {
    return (
      <tr className='table-row pl-2' key={crypto.randomUUID()}>
        <td>{name}</td>
      </tr>
    )
  })

  return (
    <>
      <div className='form-row col-md-8'>
        <div className='form-group col-md-6'>
          <TypeheadSelectRH
            {...form}
            id='input_country'
            field='country'
            label='Країна'
            placeholder='Обрати країну'
            options={countries}
            labelKey='name'
            savedKey='_id'
            value={form.watch('country')}
            defaultSelected={form.watch('country') ? form.watch('country').toString() : ''}
          />
        </div>
        <div className='form-group col-md-6'>
          <TypeheadSelectRH
            {...form}
            id='input_service'
            field='service'
            label='Сервіс'
            placeholder={form.watch('country') ? 'Оберіть сервіс' : 'Оберіть спочатку країну'}
            options={services}
            labelKey='name'
            savedKey='_id'
            value={form.watch('service')}
            defaultSelected={form.watch('service') ? form.watch('service').toString() : ''}
          />
        </div>
        <div className='form-group col-md-12'>
          <TextRH
            {...form}
            type='text'
            id='input_sms_names'
            label='Імʼя відправника *записувати через пробіл*'
            field='names'
            placeholder='Введіть значення'
          />
        </div>
      </div>
      <div className='col-md-4 mt-4'>
        <table className='table table-bordered m-0'>
          <thead className='thead-light'>
            <tr>
              <th>Відправники</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    </>
  )
}

export default RenderFormGsmOptionSender
