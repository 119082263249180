import React, { Fragment } from 'react'
import TopCard from '../../common/components/TopCard'
import { IUser } from '../../store/models/user.interface'
import { connect } from 'react-redux'
import { StateType } from '../../store/models/root.interface'
import { bindActionCreators, Dispatch } from 'redux'
import { addAdmin, removeAdmin } from '../../store/dispatch/users.dispatch'
import { updateCurrentPath } from '../../store/dispatch/root.dispatch'

interface DispatchPagesProps {
  addAdmin: (value: IUser) => void
  removeAdmin: (value: IUser) => void
  updateCurrentPath: (area: string, subArea: string) => void
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  addAdmin: bindActionCreators(addAdmin, dispatch),
  removeAdmin: bindActionCreators(removeAdmin, dispatch),
  updateCurrentPath: bindActionCreators(updateCurrentPath, dispatch),
})

const mapStateToProps = ({ users: { users, admins } }: StateType) => ({ users, admins })

type PropsUsersPage = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const Users: React.FC<PropsUsersPage> = ({
  users,
  admins,
  addAdmin,
  removeAdmin,
  updateCurrentPath,
}) => {
  updateCurrentPath('user', 'list')

  const setUserAdmin = (user: IUser): void => {
    addAdmin(user)
  }

  const setUserNotAdmin = (admin: IUser): void => {
    removeAdmin(admin)
  }

  const userElements: JSX.Element[] = users.map((user) => {
    return (
      <tr className={'table-row'} key={`user_${user.id}`}>
        <th scope='row'>{user.id}</th>
        <td>{user.firstName}</td>
        <td>{user.lastName}</td>
        <td>{user.email}</td>
        <td>
          <button className='btn btn-success' onClick={() => setUserAdmin(user)}>
            Set admin
          </button>
        </td>
      </tr>
    )
  })

  const adminElements: JSX.Element[] = admins.map((admin) => {
    return (
      <tr className={'table-row'} key={`user_${admin.id}`}>
        <th scope='row'>{admin.id}</th>
        <td>{admin.firstName}</td>
        <td>{admin.lastName}</td>
        <td>{admin.email}</td>
        <td>
          <button className='btn btn-danger' onClick={() => setUserNotAdmin(admin)}>
            Revert admin
          </button>
        </td>
      </tr>
    )
  })

  return (
    <Fragment>
      <h1 className='h3 mb-2 text-gray-800'>Users</h1>
      <p className='mb-4'>Users here</p>

      <div className='row'>
        <TopCard title='ADMINS' text={admins.length.toString()} icon='user-tie' class='primary' />
        <TopCard title='USER' text={users.length.toString()} icon='user' class='danger' />
      </div>

      <div className='row'>
        <div className='col-xl-12 col-lg-12'>
          <div className='card shadow mb-4'>
            <div className='card-header py-3'>
              <h6 className='m-0 font-weight-bold text-green'>Admin List</h6>
              <div className='header-buttons'></div>
            </div>
            <div className='card-body'>
              <div className='table-responsive portlet'>
                <table className='table'>
                  <thead className='thead-light'>
                    <tr>
                      <th scope='col'>#</th>
                      <th scope='col'>First name</th>
                      <th scope='col'>Last name</th>
                      <th scope='col'>Email</th>
                      <th scope='col'>Admin</th>
                    </tr>
                  </thead>
                  <tbody>{adminElements}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-xl-12 col-lg-12'>
          <div className='card shadow mb-4'>
            <div className='card-header py-3'>
              <h6 className='m-0 font-weight-bold text-green'>User List</h6>
              <div className='header-buttons'></div>
            </div>
            <div className='card-body'>
              <div className='table-responsive portlet'>
                <table className='table'>
                  <thead className='thead-light'>
                    <tr>
                      <th scope='col'>#</th>
                      <th scope='col'>First name</th>
                      <th scope='col'>Last name</th>
                      <th scope='col'>Email</th>
                      <th scope='col'>Admin</th>
                    </tr>
                  </thead>
                  <tbody>{userElements}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Users)
