import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { ModificationStatus, StateType } from '../../../../store/models/root.interface'
import { bindActionCreators, Dispatch } from 'redux'
import { updateCurrentPath, updateLoaderState } from '../../../../store/dispatch/root.dispatch'
import { addNotification } from '../../../../store/dispatch/notifications.dispatch'
import {
  clearSelectedPartnerProgram,
  removePartnerProgram,
  setPartnerProgramsModificationState,
  setPartnerProgramsSomeError,
} from '../../../../store/dispatch/tg/tg-partner-program.dispatch'
import { TgOptionType, TgTabCustomProps } from '../../types/TgOptions.types'
import { useHttpDeleteRequest } from '../../../../hooks/request.hook'
import { RequestUrlTypes } from '../../../../hooks/types/request.types'
import { OptionTypeDetails } from '../../../../common/components/types/Tab.types'
import OptionsTab from '../../../../common/components/Tab'
import { PartnerProgram } from '../../../../store/models/tg/tg-partner-program.interface'
import TgPartnerProgramForm from './TgPartnerProgramForm'
import TgPartnerProgramsList from './TgPartnerProgramsList'
import { useErrorHandlerHook } from '../../../../hooks/error-handler'
import TabCardBodyTgOptions from '../TabCardBodyTgOptions'
import { URL_SERVER_TG } from '../../../../config'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  remove: bindActionCreators(removePartnerProgram, dispatch),
  clearSelected: bindActionCreators(clearSelectedPartnerProgram, dispatch),
  setModificationState: bindActionCreators(setPartnerProgramsModificationState, dispatch),
  setSomeError: bindActionCreators(setPartnerProgramsSomeError, dispatch),
  addNotification: bindActionCreators(addNotification, dispatch),
  updateCurrentPath: bindActionCreators(updateCurrentPath, dispatch),
  setLoader: bindActionCreators(updateLoaderState, dispatch),
})

const mapStateToProps = ({ tgPartnerPrograms, root }: StateType) => ({
  programs: tgPartnerPrograms,
  path: root.page,
})

type PropsComponent = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  TgTabCustomProps

const TgPartnerProgramsTab: React.FC<PropsComponent> = ({
  programs,
  setLoader,
  path,
  remove,
  clearSelected,
  setModificationState,
  addNotification,
  setSomeError,
  renderTabHeader,
  type,
}): JSX.Element => {
  const errorHandler = useErrorHandlerHook(setSomeError, 'Партнерські програми')
  const deleteRequest = useHttpDeleteRequest({
    url: RequestUrlTypes.TG_PARTNER_PROGRAM,
    base: URL_SERVER_TG,
    func: remove,
  })

  useEffect(() => {
    clearSelected()
    updateCurrentPath('tg-options', 'partner-programs-list')
  }, [path.area])

  const onRemove = async () => {
    const { selected } = programs

    if (!selected) {
      return
    }

    try {
      await deleteRequest({ id: selected._id })
      addNotification('Партнерська програма', `${selected.name} видалена`)
      clearSelected()
      setModificationState(ModificationStatus.None)
    } catch (e: any) {
      errorHandler({ text: e.message })
    } finally {
      setLoader(false)
    }
  }

  const details: OptionTypeDetails<PartnerProgram> = {
    ...programs,
    onRemove,
    setModificationState,
    headerTitle: 'Список партнерських програм',
    cardFormClass: 'col-xl-4 col-md-4',
    cardTopClass: 'col-xl-8 col-md-8',
    cardWrapperClass: 'row mt-5 flex-row-reverse',
  }

  if (type !== TgOptionType.PARTNER_PROGRAM) return <></>

  return (
    <OptionsTab
      details={details}
      renderForm={<TgPartnerProgramForm />}
      topList={<TgPartnerProgramsList />}
      renderTabHeader={renderTabHeader}
      renderCardBody={TabCardBodyTgOptions}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(TgPartnerProgramsTab)
