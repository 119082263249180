import { ModificationStatus } from '../../models/root.interface'
import { tgPromoCodesTypes } from '../../types/tg/tg-promo-codes.types'
import { TgPromoCode } from '../../models/tg/tg-promo-code.interface'

type ActionItem = TgPromoCode

export function getPromoCodesListAction(value: ActionItem[]): IGetItemsActionType<ActionItem> {
  return { type: tgPromoCodesTypes.GET_TG_PROMO_CODES_LIST, value }
}

export function addPromoCodeAction(value: ActionItem): IAddItemActionType<ActionItem> {
  return { type: tgPromoCodesTypes.ADD_TG_PROMO_CODE, value }
}

export function editPromoCodeAction(value: ActionItem): IEditItemActionType<ActionItem> {
  return { type: tgPromoCodesTypes.EDIT_TG_PROMO_CODE, value }
}

export function removePromoCodeAction(value: string): IRemoveItemActionType {
  return { type: tgPromoCodesTypes.REMOVE_TG_PROMO_CODE, value }
}

export function changeSelectedPromoCodeAction(value: string): IChangeSelectedItemActionType {
  return { type: tgPromoCodesTypes.CHANGE_TG_PROMO_CODE_PENDING_EDIT, value }
}

export function clearSelectedPromoCodeAction(): IClearSelectedItemActionType {
  return { type: tgPromoCodesTypes.CLEAR_TG_PROMO_CODE_PENDING_EDIT }
}

export function setPromoCodesModificationStateAction(
  value: ModificationStatus,
): ISetModificationStateActionType {
  return { type: tgPromoCodesTypes.SET_TG_PROMO_CODE_MODIFICATION_STATE, value: value }
}

export function setPromoCodesSomeErrorAction(value: string): ISetSomeErrorActionType {
  return { type: tgPromoCodesTypes.SET_TG_PROMO_CODE_SOME_ERROR, value: value }
}

interface IGetItemsActionType<T> {
  type: string
  value: T[]
}

interface IAddItemActionType<T> {
  type: string
  value: T
}

interface IEditItemActionType<T> {
  type: string
  value: T
}

interface IRemoveItemActionType {
  type: string
  value: string
}

interface IChangeSelectedItemActionType {
  type: string
  value: string
}

interface IClearSelectedItemActionType {
  type: string
}

interface ISetModificationStateActionType {
  type: string
  value: ModificationStatus
}

interface ISetSomeErrorActionType {
  type: string
  value: string
}
