import { Maybe } from 'yup'

export class TgInternalBank {
  _id: Maybe<string | undefined>
  name = ''
  key: Maybe<string | undefined> = ''
  currencies: string[] = []
  token = ''
  type: Maybe<string | undefined> = 'embedded'
}
