import { Dispatch } from 'redux'
import { ModificationStatus } from '../../models/root.interface'
import {
  addTgOptionCountryAction,
  changeSelectedTgOptionCountryAction,
  clearSelectedTgOptionCountryAction,
  editTgOptionCountryAction,
  getTgOptionCountryAction,
  removeTgOptionCountryAction,
  setTgOptionCountriesModificationStateAction,
  setTgOptionCountrySomeErrorAction,
} from '../../actions/tg/tg-options-country.action'
import { TgOptionCountry } from '../../models/tg/tg-options-country.interface'
type ActionItem = TgOptionCountry

export const getTgOptionCountriesList = (data: ActionItem[]) => (dispatch: Dispatch) => {
  dispatch(getTgOptionCountryAction(data))
}
export const addTgOptionCountry = (data: ActionItem) => (dispatch: Dispatch) => {
  dispatch(addTgOptionCountryAction(data))
}
export const editTgOptionCountry = (value: ActionItem) => (dispatch: Dispatch) => {
  dispatch(editTgOptionCountryAction(value))
}
export const removeTgOptionCountry = (value: string) => (dispatch: Dispatch) => {
  dispatch(removeTgOptionCountryAction(value))
}
export const changeSelectedTgOptionCountry = (value: string) => (dispatch: Dispatch) => {
  dispatch(changeSelectedTgOptionCountryAction(value))
}
export const clearSelectedTgOptionCountry = () => (dispatch: Dispatch) => {
  dispatch(clearSelectedTgOptionCountryAction())
}
export const setTgOptionCountryModificationState =
  (value: ModificationStatus) => (dispatch: Dispatch) => {
    dispatch(setTgOptionCountriesModificationStateAction(value))
  }
export const setTgOptionCountrySomeError = (value: string) => (dispatch: Dispatch) => {
  dispatch(setTgOptionCountrySomeErrorAction(value))
}
