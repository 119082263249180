import { IActionBase, TgCurrenciesState, ModificationStatus } from '../../models/root.interface'
import { tgCurrenciesTypes } from '../../types/tg/tg-currencies.types'
import { TgCurrency } from '../../models/tg/tg-currency.interface'

type State = TgCurrenciesState
type IElement = TgCurrency

const initialState: State = {
  error: null,
  modificationState: ModificationStatus.None,
  selected: null,
  list: [],
  base: [
    { value: 'AED', text: 'United Arab Emirates Dirham (AED)' },
    { value: 'AFN', text: 'Afghan Afghani (AFN)' },
    { value: 'ALL', text: 'Albanian Lek (ALL)' },
    { value: 'AMD', text: 'Armenian Dram (AMD)' },
    { value: 'ARS', text: 'Argentine Peso (ARS)' },
    { value: 'AUD', text: 'Australian Dollar (AUD)' },
    { value: 'AZN', text: 'Azerbaijani Manat (AZN)' },
    { value: 'BAM', text: 'Bosnia & Herzegovina Convertible Mark (BAM)' },
    { value: 'BDT', text: 'Bangladeshi Taka (BDT)' },
    { value: 'BGN', text: 'Bulgarian Lev (BGN)' },
    { value: 'BND', text: 'Brunei Dollar (BND)' },
    { value: 'BOB', text: 'Bolivian Boliviano (BOB)' },
    { value: 'BRL', text: 'Brazilian Real (BRL)' },
    { value: 'BYN', text: 'Belarusian ruble (BYN)' },
    { value: 'CAD', text: 'Canadian Dollar (CAD)' },
    { value: 'CHF', text: 'Swiss Franc (CHF)' },
    { value: 'CLP', text: 'Chilean Peso (CLP)' },
    { value: 'CNY', text: 'Chinese Renminbi Yuan (CNY)' },
    { value: 'COP', text: 'Colombian Peso (COP)' },
    { value: 'CRC', text: 'Costa Rican Colón (CRC)' },
    { value: 'CZK', text: 'Czech Koruna (CZK)' },
    { value: 'DKK', text: 'Danish Krone (DKK)' },
    { value: 'DOP', text: 'Dominican Peso (DOP)' },
    { value: 'DZD', text: 'Algerian Dinar (DZD)' },
    { value: 'EGP', text: 'Egyptian Pound (EGP)' },
    { value: 'ETB', text: 'Ethiopian Birr (ETB)' },
    { value: 'EUR', text: 'Euro (EUR)' },
    { value: 'GBP', text: 'British Pound (GBP)' },
    { value: 'GEL', text: 'Georgian Lari (GEL)' },
    { value: 'GTQ', text: 'Guatemalan Quetzal (GTQ)' },
    { value: 'HKD', text: 'Hong Kong Dollar (HKD)' },
    { value: 'HNL', text: 'Honduran Lempira (HNL)' },
    { value: 'HRK', text: 'Croatian Kuna (HRK)' },
    { value: 'HUF', text: 'Hungarian Forint (HUF)' },
    { value: 'IDR', text: 'Indonesian Rupiah (IDR)' },
    { value: 'ILS', text: 'Israeli New Sheqel (ILS)' },
    { value: 'INR', text: 'Indian Rupee (INR)' },
    { value: 'ISK', text: 'Icelandic Króna (ISK)' },
    { value: 'JMD', text: 'Jamaican Dollar (JMD)' },
    { value: 'JPY', text: 'Japanese Yen (JPY)' },
    { value: 'KES', text: 'Kenyan Shilling (KES)' },
    { value: 'KGS', text: 'Kyrgyzstani Som (KGS)' },
    { value: 'KRW', text: 'South Korean Won (KRW)' },
    { value: 'KZT', text: 'Kazakhstani Tenge (KZT)' },
    { value: 'LBP', text: 'Lebanese Pound (LBP)' },
    { value: 'LKR', text: 'Sri Lankan Rupee (LKR)' },
    { value: 'MAD', text: 'Moroccan Dirham (MAD)' },
    { value: 'MDL', text: 'Moldovan Leu (MDL)' },
    { value: 'MNT', text: 'Mongolian Tögrög (MNT)' },
    { value: 'MUR', text: 'Mauritian Rupee (MUR)' },
    { value: 'MVR', text: 'Maldivian Rufiyaa (MVR)' },
    { value: 'MXN', text: 'Mexican Peso (MXN)' },
    { value: 'MYR', text: 'Malaysian Ringgit (MYR)' },
    { value: 'MZN', text: 'Mozambican Metical (MZN)' },
    { value: 'NGN', text: 'Nigerian Naira (NGN)' },
    { value: 'NIO', text: 'Nicaraguan Córdoba (NIO)' },
    { value: 'NOK', text: 'Norwegian Krone (NOK)' },
    { value: 'NPR', text: 'Nepalese Rupee (NPR)' },
    { value: 'NZD', text: 'New Zealand Dollar (NZD)' },
    { value: 'PAB', text: 'Panamanian Balboa (PAB)' },
    { value: 'PEN', text: 'Peruvian Nuevo Sol (PEN)' },
    { value: 'PHP', text: 'Philippine Peso (PHP)' },
    { value: 'PKR', text: 'Pakistani Rupee (PKR)' },
    { value: 'PLN', text: 'Polish Złoty (PLN)' },
    { value: 'PYG', text: 'Paraguayan Guaraní (PYG)' },
    { value: 'QAR', text: 'Qatari Riyal (QAR)' },
    { value: 'RON', text: 'Romanian Leu (RON)' },
    { value: 'RSD', text: 'Serbian Dinar (RSD)' },
    { value: 'RUB', text: 'Russian Ruble (RUB)' },
    { value: 'SAR', text: 'Saudi Riyal	SAR (SAR)' },
    { value: 'SEK', text: 'Swedish Krona (SEK)' },
    { value: 'SGD', text: 'Singapore Dollar (SGD)' },
    { value: 'THB', text: 'Thai Baht (THB)' },
    { value: 'TJS', text: 'Tajikistani Somoni (TJS)' },
    { value: 'TRY', text: 'Turkish Lira (TRY)' },
    { value: 'TTD', text: 'Trinidad and Tobago Dollar (TTD)' },
    { value: 'TWD', text: 'New Taiwan Dollar (TWD)' },
    { value: 'TZS', text: 'Tanzanian Shilling (TZS)' },
    { value: 'UAH', text: 'Ukrainian Hryvnia (UAH)' },
    { value: 'UGX', text: 'Ugandan Shilling (UGX)' },
    { value: 'USD', text: 'United States Dollar (USD)' },
    { value: 'UYU', text: 'Uruguayan Peso (UYU)' },
    { value: 'UZS', text: 'Uzbekistani (UZS)' },
    { value: 'VND', text: 'Vietnamese (VND)' },
    { value: 'YER', text: 'Yemeni Rial (YER)' },
    { value: 'ZAR', text: 'South African Rand (ZAR)' },
  ],
}

export default (state: State = initialState, action: IActionBase): State => {
  switch (action.type) {
    case tgCurrenciesTypes.GET_TG_CURRENCIES_LIST: {
      return { ...state, error: null, list: action.value, selected: null }
    }
    case tgCurrenciesTypes.ADD_TG_CURRENCY: {
      return { ...state, error: null, list: [...state.list, action.value], selected: null }
    }
    case tgCurrenciesTypes.EDIT_TG_CURRENCY: {
      const foundIndex: number = state.list.findIndex((pr) => pr._id === action.value._id)
      const list: IElement[] = state.list
      list[foundIndex] = action.value

      return { ...state, error: null, list }
    }
    case tgCurrenciesTypes.REMOVE_TG_CURRENCY: {
      return {
        ...state,
        error: null,
        list: state.list.filter((pr) => pr._id !== action.value),
        selected: null,
      }
    }
    case tgCurrenciesTypes.CHANGE_TG_CURRENCY_PENDING_EDIT: {
      const foundIndex: number = state.list.findIndex((pr) => pr._id === action.value)

      return { ...state, error: null, selected: state.list[foundIndex] }
    }
    case tgCurrenciesTypes.CLEAR_TG_CURRENCY_PENDING_EDIT: {
      return { ...state, error: null, selected: null }
    }
    case tgCurrenciesTypes.SET_TG_CURRENCY_MODIFICATION_STATE: {
      return { ...state, error: null, modificationState: action.value }
    }
    case tgCurrenciesTypes.SET_TG_CURRENCY_SOME_ERROR: {
      return { ...state, error: action.value }
    }
    default:
      return state
  }
}
