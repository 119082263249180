import React from 'react'
import { useSelector } from 'react-redux'
import ListIsEmpty from '../../../common/components/ListIsEmpty'
import {
  GsmAnalyticsOption,
  GsmAnalyticsOptionStatus,
} from '../../../store/models/gsm/gsm-analytics.interface'
import { StateType } from '../../../store/models/root.interface'
import { OptionsReceiver } from '../../../utils/option-receiver'

const activationDate = (date: number): string => {
  if (!date) return '-'

  return new Date(date).toLocaleString('uk-UA', {
    day: 'numeric',
    month: 'short',
    hour: '2-digit',
    minute: '2-digit',
  })
}

const activationStatus = (stats: GsmAnalyticsOptionStatus): string => {
  const { STATUS_OK, STATUS_COMPLETED, STATUS_WAIT_CODE } = GsmAnalyticsOptionStatus
  const completed = [STATUS_OK, STATUS_COMPLETED]

  if (completed.includes(stats)) {
    return 'продано'
  }

  if (stats === STATUS_WAIT_CODE) {
    return 'очікуємо код'
  }

  return 'не продано'
}

const RenderTableAnalyticsByPhone = ({ list }: { list: GsmAnalyticsOption[] }): JSX.Element => {
  const countries = useSelector((state: StateType) => {
    return state.gsmOptionsCountry.list
  })

  const services = useSelector((state: StateType) => {
    return state.gsmOptionsService.list
  })

  const getCountryValue = OptionsReceiver.getValueByKey(countries, 'shortName')
  const getServiceValue = OptionsReceiver.getValueByKey(services, 'shortName')
  if (!list.length) return <ListIsEmpty />

  const productElements = list.map((item: GsmAnalyticsOption, i: number) => {
    return (
      <tr className='table-row pl-2' key={`product_${item._id || i}`} data-id={item._id || i}>
        <th scope='row'>{++i}</th>
        <td>{getServiceValue(item.service, 'name') || '-'}</td>
        <td>{getCountryValue(item.country, 'name') || '-'}</td>
        <td>{item.phone}</td>
        <td>{activationStatus(item.status)}</td>
        <td>{item.cost} USD</td>
        <td>{activationDate(item.activationId)}</td>
      </tr>
    )
  })

  return (
    <div className='table-responsive portlet mt-5'>
      <table className='table m-0'>
        <thead className='thead-light'>
          <tr>
            <th scope='col'>№</th>
            <th scope='col'>Сервіс</th>
            <th scope='col'>Країна</th>
            <th scope='col'>Телефон</th>
            <th scope='col'>Статус</th>
            <th scope='col'>Ціна</th>
            <th scope='col'>Активація</th>
          </tr>
        </thead>
        <tbody>{productElements}</tbody>
      </table>
    </div>
  )
}

export default RenderTableAnalyticsByPhone
