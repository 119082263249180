import { TabHeaderScraperOptionsTypes } from '../types/TabHeaderScraperOptions.types'
import { ScrapperOptionType } from '../types/ScraperOptions.type'

const ScrOptionsTabHeader = ({ type, setType }: TabHeaderScraperOptionsTypes) => {
  const listOptions = [
    { value: ScrapperOptionType.SCR_OPTIONS, text: 'Опції' },
    { value: ScrapperOptionType.SCR_SLOT, text: 'Слоти' },
    { value: ScrapperOptionType.SCR_SERVICE, text: 'SMS сервіси' },
    { value: ScrapperOptionType.SCR_CREDENTIAL, text: 'Облікові записи' },
    { value: ScrapperOptionType.SCR_CONFIGURATION, text: 'Конфігурація' },
    { value: ScrapperOptionType.SCR_RENTAL_RATES, text: 'Оренда сім карт' },
    { value: ScrapperOptionType.SCR_SERVICE_DIARY, text: 'Бот по зміні ціни' },
    { value: ScrapperOptionType.SCR_RENTAL_RATE_DIARY, text: 'Бот по зміні % ставки' },
  ]

  const classOptionsBtn = (value: string): string => {
    const addition = type === value ? '-' : '-outline-'
    return `btn btn${addition}secondary`
  }

  return (
    <>
      <h1 className='h3 mb-2 text-gray-800'>Sms hub опції</h1>
      <div className='btn-group mb-4' role='group' aria-label='Basic outlined example'>
        {listOptions.map((option) => {
          const { value, text } = option

          return (
            <button
              key={value}
              type='button'
              className={classOptionsBtn(value)}
              onClick={() => setType(value)}
            >
              {text}
            </button>
          )
        })}
      </div>
    </>
  )
}

export default ScrOptionsTabHeader
