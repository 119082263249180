import {
  ScrServiceDiaryFilters,
  ScrServiceDiaryItem,
} from '../../models/scraper/scr-service-diary.interface'
import { scrServiceDiaryTypes } from '../../types/scraper/scr-service-diary.types'
import { ModificationStatus } from '../../models/root.interface'

type ActionItem = ScrServiceDiaryItem
type ItemFilters = ScrServiceDiaryFilters

export function getScrServiceDiaryListAction(value: ActionItem[]): IGetItemsActionType<ActionItem> {
  return { type: scrServiceDiaryTypes.GET_ITEMS_LIST, value }
}

export function changeSelectedScrServiceDiaryAction(
  value: ActionItem,
): IChangeSelectedItemActionType<ActionItem> {
  return { type: scrServiceDiaryTypes.CHANGE_ITEM_PENDING_EDIT, value }
}

export function clearSelectedScrServiceDiaryAction(): IClearSelectedItemActionType {
  return { type: scrServiceDiaryTypes.CLEAR_ITEM_PENDING_EDIT }
}

export function setScrServiceDiaryModificationStateAction(
  value: ModificationStatus,
): ISetModificationStateActionType {
  return { type: scrServiceDiaryTypes.SET_MODIFICATION_STATE_ITEM, value }
}

export function setScrServiceDiarySomeErrorAction(value: string): ISetSomeErrorActionType {
  return { type: scrServiceDiaryTypes.SET_SOME_ERROR_ITEM, value: value }
}

export function editScrServiceDiaryFiltersAction(
  value: ItemFilters,
): IEditFiltersTypeActionType<ItemFilters> {
  return { type: scrServiceDiaryTypes.EDIT_ITEMS_FILTER, value }
}

export function clearScrServiceDiaryFiltersAction(): IClearFiltersType {
  return { type: scrServiceDiaryTypes.CLEAR_ITEMS_FILTERS }
}

interface IGetItemsActionType<T> {
  type: string
  value: T[]
}

interface IChangeSelectedItemActionType<T> {
  type: string
  value: T
}

interface IClearSelectedItemActionType {
  type: string
}

interface ISetModificationStateActionType {
  type: string
  value: ModificationStatus
}

interface ISetSomeErrorActionType {
  type: string
  value: string
}

interface IEditFiltersTypeActionType<T> {
  type: string
  value: T
}
interface IClearFiltersType {
  type: string
}
