import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import {
  getPromoCodesList,
  setPromoCodesSomeError,
} from '../../../../store/dispatch/tg/tg-promo-codes.dispatch'
import { TgPromoCode } from '../../../../store/models/tg/tg-promo-code.interface'
import RenderListTgPromoCodes from './components/RenderListTgPromoCodes'
import { useHttpDateLoader } from '../../../../hooks/request.hook'
import { RequestUrlTypes } from '../../../../hooks/types/request.types'
import { useErrorHandlerHook } from '../../../../hooks/error-handler'
import { URL_SERVER_TG } from '../../../../config'
import TgPromoCodesWrapper from './components/TgPromoCodesWrapper'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setList: bindActionCreators(getPromoCodesList, dispatch),
  setSomeError: bindActionCreators(setPromoCodesSomeError, dispatch),
})

type PropsComponent = ReturnType<typeof mapDispatchToProps>

const TgPromCodesList: React.FC<PropsComponent> = ({ setList, setSomeError }): JSX.Element => {
  const errorHandler = useErrorHandlerHook(setSomeError, 'Промо коди')
  useHttpDateLoader<TgPromoCode[]>({
    url: RequestUrlTypes.TG_PROMO_CODE,
    base: URL_SERVER_TG,
    func: setList,
    errorHandler,
  })

  return (
    <TgPromoCodesWrapper>
      <RenderListTgPromoCodes />
    </TgPromoCodesWrapper>
  )
}

export default connect(null, mapDispatchToProps)(TgPromCodesList)
