import React from 'react'
import { connect } from 'react-redux'
import mcc from 'mcc-mnc-list'
import { ModificationStatus, StateType } from '../../../../store/models/root.interface'
import { bindActionCreators, Dispatch } from 'redux'
import { addNotification } from '../../../../store/dispatch/notifications.dispatch'
import { useHttpRequestWithBody } from '../../../../hooks/request.hook'
import { RequestUrlTypes } from '../../../../hooks/types/request.types'
import { useForm } from 'react-hook-form'
import { TabFormWrapperProps } from '../../../../common/components/types/TabFormWrapper.types'
import { useErrorHandlerHook } from '../../../../hooks/error-handler'
import { URL_SERVER_GSM } from '../../../../config'
import { GsmOptionsCountry } from '../../../../store/models/gsm/gsm-options-country.interface'
import {
  addGsmOptionsCountry,
  clearSelectedGsmOptionsCountry,
  editGsmOptionsCountry,
  setGsmOptionsCountriesModificationState,
  setGsmOptionsCountriesSomeError,
} from '../../../../store/dispatch/gsm/gsm-options-country.dispatch'
import TabFormWrapper from '../../../../common/components/TabFormWrapper'
import RenderFormGsmOptionsCountry from './components/RenderFormGsmOptionsCountry'
import { updateLoaderState } from '../../../../store/dispatch/root.dispatch'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setSomeError: bindActionCreators(setGsmOptionsCountriesSomeError, dispatch),
  clearSelected: bindActionCreators(clearSelectedGsmOptionsCountry, dispatch),
  setModificationState: bindActionCreators(setGsmOptionsCountriesModificationState, dispatch),
  add: bindActionCreators(addGsmOptionsCountry, dispatch),
  edit: bindActionCreators(editGsmOptionsCountry, dispatch),
  addNotification: bindActionCreators(addNotification, dispatch),
  updateLoader: bindActionCreators(updateLoaderState, dispatch),
})

const mapStateToProps = ({ gsmOptionsCountry }: StateType) => ({
  options: gsmOptionsCountry,
})

type PropsComponent = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const schema = yup.object().shape({
  _id: yup.string().notRequired(),
  shortName: yup.string().notRequired(),
  name: yup.string().min(0).max(100).required('Value name cannot be empty'),
})

const GsmOptionsCountryForm: React.FC<PropsComponent> = ({
  options,
  setSomeError,
  clearSelected,
  setModificationState,
  addNotification,
  updateLoader,
  add,
}) => {
  const { modificationState, error, list } = options
  const bannedCountries = ['GE-AB', 'BY', 'RU']
  let mccCountries = Array.from(
    new Map(mcc.all().map((item) => [item['countryCode'], item])).values(),
  )
  mccCountries = mccCountries.filter(({ countryCode }) => !bannedCountries.includes(countryCode))
  mccCountries = mccCountries.filter(({ countryCode }) => countryCode)
  const errorHandler = useErrorHandlerHook(setSomeError, 'Тг опції країни')
  const saveRequest = useHttpRequestWithBody<GsmOptionsCountry>({
    url: RequestUrlTypes.GSM_OPTIONS_COUNTRY,
    base: URL_SERVER_GSM,
    func: add,
  })

  const item = new GsmOptionsCountry()

  const form = useForm<GsmOptionsCountry>({ defaultValues: item, resolver: yupResolver(schema) })

  const saveForm = async (formState: GsmOptionsCountry): Promise<void> => {
    const uniq = list.find((item) => item.name === formState.name)

    if (uniq) {
      errorHandler({ text: 'Дана опція існує' })
      return
    }

    const mccCountry = mccCountries.find((item) => item.countryName === formState.name)

    if (!mccCountry) {
      errorHandler({ text: 'Дана опція не існує' })
      return
    }

    if (item) {
      try {
        await saveRequest({
          body: {
            ...item,
            ...formState,
            shortName: mccCountry.countryCode,
          },
          id: item._id,
        })

        addNotification('Тг опції країни', 'створена')
        setModificationState(ModificationStatus.None)
        clearSelected()
      } catch (e: any) {
        errorHandler({ text: e.message })
      } finally {
        updateLoader(false)
      }
    }
  }

  const resetForm = (): void => {
    setModificationState(ModificationStatus.None)
    form.reset()
  }

  const formOptions: TabFormWrapperProps<GsmOptionsCountry> = {
    form,
    error,
    title: 'країни',
    isCreate: modificationState === ModificationStatus.Create,
    saveForm,
    resetForm,
  }

  return (
    <TabFormWrapper {...formOptions}>
      <RenderFormGsmOptionsCountry form={form} options={mccCountries} />
    </TabFormWrapper>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(GsmOptionsCountryForm)
