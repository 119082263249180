import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { useErrorHandlerHook } from '../../../../hooks/error-handler'
import {
  getGsmOptionsCountriesList,
  setGsmOptionsCountriesSomeError,
} from '../../../../store/dispatch/gsm/gsm-options-country.dispatch'
import RenderListGsmOptionsCountry from './components/RenderListGsmOptionsCountry'
import { URL_SERVER_GSM } from '../../../../config'
import { useHttpDateLoader } from '../../../../hooks/request.hook'
import { RequestUrlTypes } from '../../../../hooks/types/request.types'
import { GsmOptionsCountry } from '../../../../store/models/gsm/gsm-options-country.interface'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setList: bindActionCreators(getGsmOptionsCountriesList, dispatch),
  setSomeError: bindActionCreators(setGsmOptionsCountriesSomeError, dispatch),
})

type PropsComponent = ReturnType<typeof mapDispatchToProps>
const GsmOptionsCountryList: React.FC<PropsComponent> = ({
  setList,
  setSomeError,
}): JSX.Element => {
  const errorHandler = useErrorHandlerHook(setSomeError, 'Тг опції')
  useHttpDateLoader<GsmOptionsCountry[]>({
    url: RequestUrlTypes.GSM_OPTIONS_COUNTRY,
    base: URL_SERVER_GSM,
    func: setList,
    errorHandler,
  })

  return <RenderListGsmOptionsCountry />
}

export default connect(null, mapDispatchToProps)(GsmOptionsCountryList)
