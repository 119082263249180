import { IActionBase, ScrCredentialState, ModificationStatus } from '../../models/root.interface'
import { scrCredentialTypes } from '../../types/scraper/scr-credential.types'
import { ScrCredential } from '../../models/scraper/scr-credential.interface'

type State = ScrCredentialState
type Element = ScrCredential

const initialState: State = {
  error: null,
  modificationState: ModificationStatus.None,
  selected: null,
  list: [],
}

export default (state: State = initialState, action: IActionBase): State => {
  switch (action.type) {
    case scrCredentialTypes.GET_SCRAPER_CREDENTIAL: {
      return { ...state, error: null, list: action.value, selected: null }
    }
    case scrCredentialTypes.ADD_SCRAPER_CREDENTIAL: {
      if (Array.isArray(action.value)) {
        const list = state.list

        for (const value of action.value) {
          const foundIndex: number = state.list.findIndex((pr) => pr._id === value._id)
          if (foundIndex) {
            list[foundIndex] = value
          }
        }

        return {
          ...state,
          error: null,
          list,
          selected: null,
        }
      } else {
        return {
          ...state,
          error: null,
          list: [...state.list, action.value],
          selected: null,
        }
      }
    }
    case scrCredentialTypes.EDIT_SCRAPER_CREDENTIAL: {
      if (Array.isArray(action.value)) {
        const list = state.list

        for (const value of action.value) {
          const foundIndex: number = state.list.findIndex((pr) => pr._id === value._id)

          list[foundIndex] = value
        }

        return { ...state, error: null, list }
      } else {
        const list = state.list
        const foundIndex: number = state.list.findIndex((pr) => pr._id === action.value._id)
        list[foundIndex] = action.value

        return { ...state, error: null, list }
      }
    }
    case scrCredentialTypes.REMOVE_SCRAPER_CREDENTIAL: {
      return {
        ...state,
        error: null,
        list: state.list.filter((pr) => pr._id !== action.value),
        selected: null,
      }
    }
    case scrCredentialTypes.CHANGE_SCRAPER_CREDENTIAL_PENDING_EDIT: {
      const foundIndex: number = state.list.findIndex((pr) => pr._id === action.value)

      return { ...state, error: null, selected: state.list[foundIndex] }
    }
    case scrCredentialTypes.CLEAR_SCRAPER_CREDENTIAL_PENDING_EDIT: {
      return { ...state, error: null, selected: null, modificationState: ModificationStatus.None }
    }
    case scrCredentialTypes.SET_MODIFICATION_STATE_SCRAPER_CREDENTIAL: {
      return { ...state, error: null, modificationState: action.value }
    }
    case scrCredentialTypes.SET_SOME_ERROR_SCRAPER_CREDENTIAL: {
      return { ...state, error: action.value }
    }
    default:
      return state
  }
}
