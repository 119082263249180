import React from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { useErrorHandlerHook } from '../../../../../hooks/error-handler'
import { useHttpDateLoader } from '../../../../../hooks/request.hook'
import { RequestUrlTypes } from '../../../../../hooks/types/request.types'
import { URL_SERVER_GSM } from '../../../../../config'
import { GsmOptionsCountry } from '../../../../../store/models/gsm/gsm-options-country.interface'
import {
  getGsmOptionsCountriesList,
  setGsmOptionsCountriesSomeError,
} from '../../../../../store/dispatch/gsm/gsm-options-country.dispatch'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setList: bindActionCreators(getGsmOptionsCountriesList, dispatch),
  setSomeError: bindActionCreators(setGsmOptionsCountriesSomeError, dispatch),
})

type PropsComponent = ReturnType<typeof mapDispatchToProps> & {
  children?: JSX.Element | JSX.Element[]
}

const TgOptionsCountryWrapper: React.FC<PropsComponent> = ({
  children,
  setList,
  setSomeError,
}): JSX.Element => {
  const errorHandler = useErrorHandlerHook(setSomeError, 'Тг опції')
  useHttpDateLoader<GsmOptionsCountry[]>({
    url: RequestUrlTypes.GSM_OPTIONS_COUNTRY,
    base: URL_SERVER_GSM,
    func: setList,
    errorHandler,
  })

  return <>{children}</>
}

export default connect(null, mapDispatchToProps)(TgOptionsCountryWrapper)
