import React from 'react'
import { connect } from 'react-redux'
import { ModificationStatus, StateType } from '../../../../store/models/root.interface'
import { ScrRentalRate } from '../../../../store/models/scraper/scr-rental-rate.interface'
import {
  addRentalRate,
  clearSelectedRentalRate,
  editRentalRate,
  setRentalRatesModificationState,
  setRentalRatesSomeError,
} from '../../../../store/dispatch/scraper/scr-rental-rate.dispatch'
import { bindActionCreators, Dispatch } from 'redux'
import { addNotification } from '../../../../store/dispatch/notifications.dispatch'
import { RequestUrlTypes } from '../../../../hooks/types/request.types'
import { useHttpRequestWithBody } from '../../../../hooks/request.hook'
import { useForm } from 'react-hook-form'
import { TabFormWrapperProps } from '../../../../common/components/types/TabFormWrapper.types'
import TabFormWrapper from '../../../../common/components/TabFormWrapper'
import RenderFormRentalRate from './components/RenderFormRentalRate'
import { useErrorHandlerHook } from '../../../../hooks/error-handler'
import { URL_SERVER_SCRAPER } from '../../../../config'
import { updateLoaderState } from '../../../../store/dispatch/root.dispatch'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  clearSelected: bindActionCreators(clearSelectedRentalRate, dispatch),
  setModificationState: bindActionCreators(setRentalRatesModificationState, dispatch),
  add: bindActionCreators(addRentalRate, dispatch),
  edit: bindActionCreators(editRentalRate, dispatch),
  addNotification: bindActionCreators(addNotification, dispatch),
  setSomeError: bindActionCreators(setRentalRatesSomeError, dispatch),
  setLoader: bindActionCreators(updateLoaderState, dispatch),
})

const mapStateToProps = ({ scrRentalRate, scrCredential }: StateType) => ({
  scrRentalRate,
  credentials: scrCredential.list,
})

type PropsRentalRateFormComponent = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const schema = yup.object().shape({
  _id: yup.string().notRequired(),
  account: yup.string().required('Value account cannot be empty'),
  country: yup.number().min(1).required('Value country cannot be empty'),
  slot: yup.number().min(0).max(1000).required('Value slot cannot be empty'),
  minCount: yup.number().min(0).max(1000).required('Value minCount cannot be empty'),
  minPercent: yup.number().min(0).max(1000).required('Value minPercent cannot be empty'),
  status: yup.boolean().required(),
})

const RentalRateForm: React.FC<PropsRentalRateFormComponent> = ({
  scrRentalRate,
  setLoader,
  clearSelected,
  addNotification,
  setModificationState,
  add,
  edit,
  setSomeError,
  credentials,
}) => {
  const { modificationState, selected, error, list } = scrRentalRate
  const isCreate: boolean = modificationState === ModificationStatus.Create
  const errorHandler = useErrorHandlerHook(setSomeError, 'Оренда сім карт')
  const saveRequest = useHttpRequestWithBody<ScrRentalRate>({
    url: RequestUrlTypes.SCRAPER_RENTAL_RATE,
    base: URL_SERVER_SCRAPER,
    func: isCreate ? add : edit,
  })

  let item: ScrRentalRate | null = selected

  if (!item || isCreate) {
    item = new ScrRentalRate()
  }

  const form = useForm<ScrRentalRate>({ defaultValues: item, resolver: yupResolver(schema) })

  const resetForm = (): void => {
    setModificationState(ModificationStatus.None)
    form.reset()
  }

  const saveForm = async (formState: ScrRentalRate): Promise<void> => {
    const uniqSlot = list.find((item) => {
      return item.country === formState.country && item.account === formState.account
    })

    if (isCreate && uniqSlot) {
      errorHandler({ text: 'Наразі цей запис вже існує' })
      return
    }

    if (formState && formState.country) {
      try {
        await saveRequest({
          body: { ...formState },
          id: item?._id ? String(item?._id) : '',
        })
        setModificationState(ModificationStatus.None)
        clearSelected()
        addNotification('Оренда сім карт', isCreate ? 'створено' : 'редаговано')
      } catch (e: any) {
        errorHandler({ text: e.message })
      } finally {
        setLoader(false)
      }
    }
  }

  const formOptions: TabFormWrapperProps<ScrRentalRate> = {
    form,
    error,
    title: 'слоту оренди',
    isCreate,
    saveForm,
    resetForm,
  }

  return (
    <TabFormWrapper {...formOptions}>
      <RenderFormRentalRate form={form} credentials={credentials} />
    </TabFormWrapper>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(RentalRateForm)
