import React from 'react'
import TextHR from '../../../../../common/form-hook/Text'
import TypeheadSelectRH from '../../../../../common/form-hook/TypeheadSelect'
import { CustomRenderFormSlotsProps } from '../types/RenderFormSlots.types'
import { useSelector } from 'react-redux'
import { StateType } from '../../../../../store/models/root.interface'

const RenderFormSlots: React.FC<CustomRenderFormSlotsProps> = ({ form }) => {
  const state = useSelector((state: StateType) => state)
  const country = form.watch('country') || 0

  return (
    <>
      <div className='form-group col-md-6'>
        <TypeheadSelectRH
          {...form}
          id='input_country'
          field='country'
          label='Країна'
          placeholder='Обрати країну'
          options={state.scrOptions.countriesList}
          labelKey='name'
          savedKey='shortName'
          value={country}
          defaultSelected={String(country)}
        />
      </div>
      <div className='form-group col-md-6'>
        <TextHR
          {...form}
          type='number'
          id='input_amount'
          label='Кількість'
          field='amount'
          max={1000}
          min={0}
        />
      </div>
    </>
  )
}

export default RenderFormSlots
