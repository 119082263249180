import { IUserState, IActionBase } from '../models/root.interface'
import { userTypes } from '../types/users.types'

const initialState: IUserState = {
  users: [
    { id: 1, firstName: 'John', lastName: 'Smith', email: 'jsmith@em.pl' },
    { id: 2, firstName: 'Jannice', lastName: 'Bing', email: 'ohmy@fr.pl' },
  ],
  admins: [{ id: 3, firstName: 'Jannet', lastName: 'Crock', email: 'jcrock@em.pl' }],
}

export default (state: IUserState = initialState, action: IActionBase): IUserState => {
  switch (action.type) {
    case userTypes.ADD_ADMIN: {
      return {
        ...state,
        users: state.users.filter((x) => x.id !== action.user.id),
        admins: [...state.admins, action.user],
      }
    }
    case userTypes.REMOVE_ADMIN: {
      return {
        ...state,
        admins: state.admins.filter((x) => x.id !== action.user.id),
        users: [...state.users, action.user],
      }
    }
    default:
      return state
  }
}
