import React from 'react'
import { connect } from 'react-redux'
import {
  StateType,
  ModificationStatus,
  TgOptionUnits,
} from '../../../../../store/models/root.interface'
import { bindActionCreators, Dispatch } from 'redux'
import ListIsEmpty from '../../../../../common/components/ListIsEmpty'
import { addNotification } from '../../../../../store/dispatch/notifications.dispatch'
import {
  changeSelectedPartnerProgram,
  clearSelectedPartnerProgram,
  setPartnerProgramsModificationState,
} from '../../../../../store/dispatch/tg/tg-partner-program.dispatch'
import { TgPromoCode } from '../../../../../store/models/tg/tg-promo-code.interface'
import { PartnerProgram } from '../../../../../store/models/tg/tg-partner-program.interface'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  clearSelected: bindActionCreators(clearSelectedPartnerProgram, dispatch),
  changeSelected: bindActionCreators(changeSelectedPartnerProgram, dispatch),
  setModificationState: bindActionCreators(setPartnerProgramsModificationState, dispatch),
  notification: bindActionCreators(addNotification, dispatch),
})

const mapStateToProps = ({ tgPartnerPrograms }: StateType) => ({
  programs: tgPartnerPrograms,
})

type PropsComponent = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const RenderListTgPartnerPrograms: React.FC<PropsComponent> = ({
  programs: { list, selected },
  clearSelected,
  changeSelected,
  setModificationState,
  notification,
}): JSX.Element => {
  const onSelect = (_id: string): void => {
    if (selected && selected._id === _id) {
      clearSelected()
    } else {
      changeSelected(_id)
    }

    setModificationState(ModificationStatus.None)
  }

  if (!list.length) return <ListIsEmpty />

  const copyLink = async (link: string) => {
    try {
      await navigator.clipboard.writeText(link)
      notification('Link copied', link)
    } catch (e) {
      notification('Link not copied', link)
    }
  }

  const copyCode = async (code: string) => {
    try {
      await navigator.clipboard.writeText(code)
      notification('Code copied', code)
    } catch (e) {
      notification('Code not copied', code)
    }
  }

  const getPeriodDetails = ({
    date,
    duration,
    endDate,
  }: Pick<TgPromoCode, 'duration' | 'date' | 'endDate'>): string => {
    if (!endDate) return '--'
    const lastDate = date ? new Date(date) : new Date()

    lastDate.setDate(lastDate.getDate() + Number(duration?.days ?? 0))
    lastDate.setMonth(lastDate.getMonth() + Number(duration?.months ?? 0))
    lastDate.setFullYear(lastDate.getFullYear() + Number(duration?.years ?? 0))

    return new Date(lastDate).toLocaleString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    })
  }

  const getConditionDetails = ({
    condition,
    conditionValue,
  }: Pick<PartnerProgram, 'condition' | 'conditionValue'>) => {
    if (condition && conditionValue && condition === 'from-to') {
      return `поповнення від ${conditionValue.from} до ${conditionValue.to}`
    }

    if (condition && conditionValue && condition === 'only-to') {
      return `поповнення до ${conditionValue.to}`
    }

    if (condition && conditionValue && condition === 'only-from') {
      return `поповнення від ${conditionValue.from}`
    }

    return '--'
  }

  const dataList: JSX.Element[] = list.map((item) => {
    const unit = item.unit === TgOptionUnits.PERCENTAGE ? ' %' : ''
    const main = item.isMain ? 'table-warning' : ''
    let promoCode = '--'

    if (item?.promoCode && typeof item.promoCode === 'object') {
      promoCode = item.promoCode.code
    }

    return (
      <tr
        data-id={item._id}
        className={`table-row pl-2 ${selected?._id === item._id ? 'table-primary' : main}`}
        key={item._id}
        onClick={() => onSelect(item._id || '')}
      >
        <td title={item.name}>{item.name}</td>
        <td title={item.code}>
          <span onClick={() => copyCode(item.code)}>{item.code}</span>
          <button className='btn btn-sm btn-link p-0' onClick={() => copyLink(item.link)}>
            <i className='fas fa fa-copy ml-2'></i>
          </button>
        </td>
        <td title='значення'>{Number(item.amount).toFixed(2) + unit}</td>
        <td title='максимальна кількість користувачів'>{item.maxUses || '--'}</td>
        <td title='кількість використання'>{item.customers.length}</td>
        <td title='період'>{getPeriodDetails({ ...item })}</td>
        <td title='умова'>{getConditionDetails({ ...item })}</td>
        <td title='промо-код'>{promoCode}</td>
      </tr>
    )
  })

  return (
    <div className='table-responsive portlet'>
      <table className='table m-0'>
        <thead className='thead-light'>
          <tr>
            <th scope='col'>Назва</th>
            <th scope='col'>Код</th>
            <th scope='col'>Значення</th>
            <th scope='col'>Макс. к-сть к-чів</th>
            <th scope='col'>К-сть. вик.</th>
            <th scope='col'>Закінчення</th>
            <th scope='col'>Умова</th>
            <th scope='col'>Промо код</th>
          </tr>
        </thead>
        <tbody>{dataList}</tbody>
      </table>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(RenderListTgPartnerPrograms)
