import { UseFormReturn } from 'react-hook-form/dist/types/form'
import { PartnerProgram } from '../../../../../store/models/tg/tg-partner-program.interface'
import React from 'react'
import RenderFromToCondition from './RenderFromToCondition'
import SelectRH from '../../../../../common/form-hook/Select'
import RenderOnlyToCondition from './RenderOnlyToCondition'
import RenderOnlyFromCondition from './RenderOnlyFromCondition'

const RenderConditionVariations = (
  props: Pick<UseFormReturn<PartnerProgram>, 'watch' | 'register' | 'formState'>,
) => {
  if (!props.watch('presenceCondition')) return <></>

  const options = [
    { text: 'Сума поповнення від-до', value: 'from-to' },
    { text: 'Сума поповнення до', value: 'only-to' },
    { text: 'Сума поповнення від', value: 'only-from' },
  ]
  return (
    <>
      <div className='form-group col-md-6'>
        <SelectRH
          {...props}
          id='input_condition'
          label='Оберіть тип'
          field='condition'
          required='Value condition cannot be empty'
          options={options}
        />
      </div>
      <RenderFromToCondition {...props} />
      <RenderOnlyFromCondition {...props} />
      <RenderOnlyToCondition {...props} />
    </>
  )
}

export default RenderConditionVariations
