import { IActionBase, ScrOptionsState } from '../../models/root.interface'
import { scrOptionsTypes } from '../../types/scraper/scr-options.types'
import { ScrOptionsCountry } from '../../models/scraper/scr-options-country.interface'
import { ScrOptionsService } from '../../models/scraper/scr-options-service.interface'

type State = ScrOptionsState

const initialState: State = {
  scrapersList: ['service-price-modifier', 'rent-price-modifier'],
  prioritiesList: [
    { value: 'primary', text: 'Основний' },
    { value: 'secondary', text: 'Другорядний' },
  ],
  countriesList: [],
  servicesList: [],
  selectedService: null,
  selectedCountry: null,
  error: null,
}

export default (state: State = initialState, action: IActionBase): State => {
  switch (action.type) {
    case scrOptionsTypes.GET_COUNTRIES_OPTIONS_LIST: {
      const countriesList: ScrOptionsCountry[] = action.value.map(
        (item: ScrOptionsCountry, index: number) => {
          return { ...item, _id: item._id ? item._id : index++ }
        },
      )

      return { ...state, error: null, countriesList, selectedCountry: null }
    }
    case scrOptionsTypes.GET_SERVICES_OPTIONS_LIST: {
      const servicesList: ScrOptionsService[] = action.value.map(
        (item: ScrOptionsService, index: number) => {
          return { ...item, _id: item._id ? item._id : index++ }
        },
      )

      return { ...state, error: null, servicesList, selectedService: null }
    }
    case scrOptionsTypes.CHANGE_COUNTRY_OPTIONS_PENDING_EDIT: {
      const foundIndex = state.countriesList.findIndex((item) => item._id === action.value)

      return { ...state, error: null, selectedCountry: state.countriesList[foundIndex] }
    }
    case scrOptionsTypes.CLEAR_COUNTRY_OPTIONS_PENDING_EDIT: {
      return { ...state, error: null, selectedCountry: null }
    }
    case scrOptionsTypes.CHANGE_SERVICE_OPTIONS_PENDING_EDIT: {
      const foundIndex = state.servicesList.findIndex((item) => item._id === action.value)

      return { ...state, error: null, selectedService: state.servicesList[foundIndex] }
    }
    case scrOptionsTypes.CLEAR_SERVICE_OPTIONS_PENDING_EDIT: {
      return { ...state, error: null, selectedService: null }
    }
    case scrOptionsTypes.SET_OPTIONS_SOME_ERROR: {
      return { ...state, error: action.value }
    }
    default:
      return state
  }
}
