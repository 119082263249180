import React from 'react'
import { connect } from 'react-redux'
import { ModificationStatus, StateType } from '../../../../store/models/root.interface'
import { bindActionCreators, Dispatch } from 'redux'
import { addNotification } from '../../../../store/dispatch/notifications.dispatch'
import { useHttpRequestWithBody } from '../../../../hooks/request.hook'
import { RequestUrlTypes } from '../../../../hooks/types/request.types'
import { useForm } from 'react-hook-form'
import { TabFormWrapperProps } from '../../../../common/components/types/TabFormWrapper.types'
import TabFormWrapper from '../../../../common/components/TabFormWrapper'
import { useErrorHandlerHook } from '../../../../hooks/error-handler'
import {
  addTgOptionCountry,
  clearSelectedTgOptionCountry,
  editTgOptionCountry,
  setTgOptionCountryModificationState,
  setTgOptionCountrySomeError,
} from '../../../../store/dispatch/tg/tg-options-country.dispatch'
import {
  TgContentLanguages,
  TgContentLanguagesValues,
  TgOptionCountry,
  TgOptionCountryPriority,
} from '../../../../store/models/tg/tg-options-country.interface'
import RenderFormTgOptionCountry from './components/RenderFormTgOptionCountry'
import { URL_SERVER_TG } from '../../../../config'
import { updateLoaderState } from '../../../../store/dispatch/root.dispatch'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setSomeError: bindActionCreators(setTgOptionCountrySomeError, dispatch),
  clearSelected: bindActionCreators(clearSelectedTgOptionCountry, dispatch),
  setModificationState: bindActionCreators(setTgOptionCountryModificationState, dispatch),
  add: bindActionCreators(addTgOptionCountry, dispatch),
  edit: bindActionCreators(editTgOptionCountry, dispatch),
  addNotification: bindActionCreators(addNotification, dispatch),
  updateLoader: bindActionCreators(updateLoaderState, dispatch),
})

const mapStateToProps = ({ tgOptionsCountry, gsmOptionsCountry }: StateType) => ({
  options: tgOptionsCountry,
  countries: gsmOptionsCountry.list,
})

type PropsComponent = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const schema = yup.object().shape({
  _id: yup.string().notRequired(),
  shortName: yup.string().min(0).max(100).required('Value apiKey cannot be empty'),
  name: yup.string().notRequired(),
  content: yup
    .object({
      [TgContentLanguages.Ua]: yup.string().notRequired(),
      [TgContentLanguages.En]: yup.string().notRequired(),
      [TgContentLanguages.RU]: yup.string().notRequired(),
    })
    .notRequired(),
  priority: yup
    .mixed<TgOptionCountryPriority>()
    .oneOf(Object.values(TgOptionCountryPriority))
    .required('Value priority cannot be empty'),
  icon: yup.string().max(5).notRequired(),
})

const TgOptionsCountryForm: React.FC<PropsComponent> = ({
  options,
  countries,
  setSomeError,
  clearSelected,
  addNotification,
  updateLoader,
  setModificationState,
  add,
  edit,
}) => {
  const { selected, modificationState, error, list } = options
  const isCreate: boolean = modificationState === ModificationStatus.Create
  const errorHandler = useErrorHandlerHook(setSomeError, 'Тг опції країни')
  const saveRequest = useHttpRequestWithBody<TgOptionCountry>({
    url: RequestUrlTypes.TG_OPTION_COUNTRY,
    base: URL_SERVER_TG,
    func: isCreate ? add : edit,
  })

  let item: TgOptionCountry | null = selected

  if (!item || isCreate) {
    item = new TgOptionCountry()
  }

  const form = useForm<TgOptionCountry>({ defaultValues: item, resolver: yupResolver(schema) })

  const saveForm = async (formState: TgOptionCountry): Promise<void> => {
    const shortName = formState.shortName
    const uniq = list.find((item) => item.shortName === shortName)

    if (isCreate && uniq) {
      errorHandler({ text: 'Дана опція існує' })
      return
    }

    const optionObject = countries.find((item) => item.shortName === shortName)

    if (item) {
      try {
        await saveRequest({
          body: {
            ...item,
            ...formState,
            name: optionObject?.name || `Країна код ${formState.name}`,
          },
          id: item?._id ? String(item?._id) : '',
        })

        clearSelected()
        setModificationState(ModificationStatus.None)
        addNotification('Тг опції країни', isCreate ? 'додано' : 'редаговано')
      } catch (e: any) {
        errorHandler({ text: e.message })
      } finally {
        updateLoader(false)
      }
    }
  }

  const resetForm = (): void => {
    setModificationState(ModificationStatus.None)
    form.reset()
  }

  const formOptions: TabFormWrapperProps<TgOptionCountry> = {
    form,
    error,
    title: 'країну',
    isCreate,
    saveForm,
    resetForm,
  }

  return (
    <TabFormWrapper {...formOptions}>
      <RenderFormTgOptionCountry form={form} options={countries} isCreate={isCreate} />
    </TabFormWrapper>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(TgOptionsCountryForm)
