import React from 'react'
import './Loader.css'
import { RootStateType, StateType } from '../../store/models/root.interface'
import { useSelector } from 'react-redux'

const Loader = () => {
  const { loader }: RootStateType = useSelector((state: StateType) => state.root)

  return (
    <div className={`loader-container ${loader && 'enable'}`}>
      <div className='spinner'></div>
    </div>
  )
}

export default Loader
