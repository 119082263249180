import { IActionBase, ModificationStatus, TgInternalBankState } from '../../models/root.interface'
import { tgInternalBankTypes } from '../../types/tg/tg-internal-bank.types'
import { TgInternalBank } from '../../models/tg/tg-internal-bank.interface'

type State = TgInternalBankState
type IElement = TgInternalBank

const initialState: State = {
  error: null,
  modificationState: ModificationStatus.None,
  selected: null,
  list: [],
  base: [
    'Stripe',
    'Smart Glocal',
    'Unlimint',
    'Tranzzo',
    'Paykassma',
    'YooMoney',
    'Sberbank',
    'PSB',
    'Bank 131',
    'Payme',
    'CLICK',
    'LiqPay',
    'LeoGaming',
    'Cascad',
    'Portmone',
    'Paymega',
    'ECOMMPAY',
    'PayMaster',
    'Global Pay UZ',
    'iPay88',
    'PayBox.money',
    'Freedom Pay',
    'bill_line',
  ],
}

export default (state: State = initialState, action: IActionBase): State => {
  switch (action.type) {
    case tgInternalBankTypes.GET_TG_INTERNAL_BANK_LIST: {
      return { ...state, error: null, list: action.value, selected: null }
    }
    case tgInternalBankTypes.ADD_TG_INTERNAL_BANK_LIST: {
      return { ...state, error: null, list: [...state.list, action.value], selected: null }
    }
    case tgInternalBankTypes.EDIT_TG_INTERNAL_BANK_LIST: {
      const foundIndex: number = state.list.findIndex((pr) => pr._id === action.value._id)
      const list: IElement[] = state.list
      list[foundIndex] = action.value

      return { ...state, error: null, list }
    }
    case tgInternalBankTypes.REMOVE_TG_INTERNAL_BANK_LIST: {
      return {
        ...state,
        error: null,
        list: state.list.filter((pr) => pr._id !== action.value),
        selected: null,
      }
    }
    case tgInternalBankTypes.CHANGE_TG_INTERNAL_BANK_PENDING_EDIT: {
      const foundIndex: number = state.list.findIndex((pr) => pr._id === action.value)

      return { ...state, error: null, selected: state.list[foundIndex] }
    }
    case tgInternalBankTypes.CLEAR_TG_INTERNAL_BANK_PENDING_EDIT: {
      return { ...state, error: null, selected: null }
    }
    case tgInternalBankTypes.SET_TG_INTERNAL_BANK_MODIFICATION_STATE: {
      return { ...state, error: null, modificationState: action.value }
    }
    case tgInternalBankTypes.SET_TG_INTERNAL_BANK_SOME_ERROR: {
      return { ...state, error: action.value }
    }
    default:
      return state
  }
}
