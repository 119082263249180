import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { StateType } from '../../../store/models/root.interface'
import TopCard from '../../../common/components/TopCard'
import { editGsmAnalyticsFilters } from '../../../store/dispatch/gsm/gsm-analytics.dispatch'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  editFilters: bindActionCreators(editGsmAnalyticsFilters, dispatch),
})

const mapStateToProps = ({ gsmAnalytics }: StateType) => ({
  analytics: gsmAnalytics,
})

type PropsPage = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const FilterByModem: React.FC<PropsPage> = ({ analytics: { filters, list }, editFilters }) => {
  let modems = list.map((option) => option.modem)
  modems = Array.from(new Set(modems))

  const onSelect = (modem: string): void => {
    if (typeof filters.modem === 'string') return

    if (filters.modem.includes(modem)) {
      editFilters({
        ...filters,
        modem: filters.modem.filter((item) => item !== modem),
      })
    } else {
      filters.modem.push(modem)
      editFilters(filters)
    }
  }

  const activeClass = (modem: string): string => {
    return filters.modem.includes(modem) ? 'danger' : 'primary'
  }
  return (
    <div className='row'>
      <div className='col-xl-12 col-lg-12'>
        <div className='card shadow mb-4'>
          <div className='card-header py-3'>
            <h6 className='m-0 font-weight-bold text-green'>Список модемів</h6>
          </div>
          <div className='card-body row'>
            {modems.map((modem: string) => {
              const phones = list.filter((option) => {
                return option.modem === modem
              })

              return (
                <TopCard
                  key={modem}
                  title={`Номерів (${phones.length})`}
                  text={modem}
                  icon='server'
                  class={activeClass(modem)}
                  onClick={() => onSelect(modem)}
                />
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterByModem)
