import React from 'react'
import MultiSelectField from '../../../../../common/fields/MultiSelectField'
import SelectField from '../../../../../common/fields/SelectField'
import { ScrServiceFilters } from '../../../../../store/models/scraper/scr-service.interface'
import { ScrCredential } from '../../../../../store/models/scraper/scr-credential.interface'
import { ScrOptionsCountry } from '../../../../../store/models/scraper/scr-options-country.interface'
import { ScrOptionsService } from '../../../../../store/models/scraper/scr-options-service.interface'

const RenderScrServiceFilters = ({
  editFilters,
  filters,
  credentials,
  services,
  countries,
}: {
  filters: ScrServiceFilters
  editFilters: (value: ScrServiceFilters) => void
  credentials: ScrCredential[]
  services: ScrOptionsService[]
  countries: ScrOptionsCountry[]
}): JSX.Element => {
  services = Array.from(new Map(services.map((item) => [item['shortName'], item])).values())

  const credentialsOptions = credentials.map((item) => {
    return {
      value: item._id || '',
      text: item.login,
    }
  })

  return (
    <>
      <div className='form-group col-md-6 pl-md-0'>
        <MultiSelectField
          id='input_country'
          field='country'
          label='Країна'
          placeholder='Оберіть країну'
          options={countries}
          labelKey='name'
          savedKey='shortName'
          setValue={editFilters}
          value={filters}
        />
      </div>
      <div className='form-group col-md-6 pr-md-0'>
        <MultiSelectField
          id='input_service'
          field='name'
          label='Сервіс'
          placeholder='Оберіть сервіс'
          options={services}
          labelKey='name'
          savedKey='shortName'
          setValue={editFilters}
          value={filters}
        />
      </div>
      <div className='form-group col-md-6 pl-md-0'>
        <SelectField
          setValue={editFilters}
          id='input_account'
          field='account'
          label='Акаунт'
          value={filters}
          options={credentialsOptions}
        />
      </div>
    </>
  )
}

export default RenderScrServiceFilters
