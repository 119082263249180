import {
  IActionBase,
  TgPartnerProgramsState,
  ModificationStatus,
} from '../../models/root.interface'
import { tgPartnerProgramTypes } from '../../types/tg/tg-partner-programs.types'
import { PartnerProgram } from '../../models/tg/tg-partner-program.interface'

type State = TgPartnerProgramsState
type IElement = PartnerProgram

const initialState: State = {
  error: null,
  modificationState: ModificationStatus.None,
  selected: null,
  list: [],
}

export default (state: State = initialState, action: IActionBase): State => {
  switch (action.type) {
    case tgPartnerProgramTypes.GET_TG_PARTNER_PROGRAMS_LIST: {
      return { ...state, error: null, list: action.value, selected: null }
    }
    case tgPartnerProgramTypes.ADD_TG_PARTNER_PROGRAM: {
      return { ...state, error: null, list: [...state.list, action.value], selected: null }
    }
    case tgPartnerProgramTypes.EDIT_TG_PARTNER_PROGRAM: {
      const foundIndex: number = state.list.findIndex((pr) => pr._id === action.value._id)
      const list: IElement[] = state.list
      list[foundIndex] = action.value

      return { ...state, error: null, list }
    }
    case tgPartnerProgramTypes.REMOVE_TG_PARTNER_PROGRAM: {
      return {
        ...state,
        error: null,
        list: state.list.filter((pr) => pr._id !== action.value),
        selected: null,
      }
    }
    case tgPartnerProgramTypes.CHANGE_TG_PARTNER_PROGRAM_PENDING_EDIT: {
      const foundIndex: number = state.list.findIndex((pr) => pr._id === action.value)

      return { ...state, error: null, selected: state.list[foundIndex] }
    }
    case tgPartnerProgramTypes.CLEAR_TG_PARTNER_PROGRAM_PENDING_EDIT: {
      return { ...state, error: null, selected: null }
    }
    case tgPartnerProgramTypes.SET_TG_PARTNER_PROGRAM_MODIFICATION_STATE: {
      return { ...state, error: null, modificationState: action.value }
    }
    case tgPartnerProgramTypes.SET_TG_PARTNER_PROGRAM_SOME_ERROR: {
      return { ...state, error: action.value }
    }
    default:
      return state
  }
}
