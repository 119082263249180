import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import {
  getSlotsList,
  setSlotsSomeError,
} from '../../../../store/dispatch/scraper/scr-slot.dispatch'
import { RequestUrlTypes } from '../../../../hooks/types/request.types'
import RenderListSlots from './componets/RenderListSlots'
import { useHttpDateLoader } from '../../../../hooks/request.hook'
import { ScrSlot } from '../../../../store/models/scraper/scr-slot.interface'
import { useErrorHandlerHook } from '../../../../hooks/error-handler'
import { URL_SERVER_SCRAPER } from '../../../../config'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setList: bindActionCreators(getSlotsList, dispatch),
  setSomeError: bindActionCreators(setSlotsSomeError, dispatch),
})

type PropsComponent = ReturnType<typeof mapDispatchToProps>

const SlotsList: React.FC<PropsComponent> = ({ setList, setSomeError }): JSX.Element => {
  const errorHandler = useErrorHandlerHook(setSomeError, 'Слоти')
  useHttpDateLoader<ScrSlot[]>({
    url: RequestUrlTypes.SCRAPER_SLOT,
    base: URL_SERVER_SCRAPER,
    func: setList,
    errorHandler,
  })

  return <RenderListSlots />
}

export default connect(null, mapDispatchToProps)(SlotsList)
