import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { ModificationStatus, StateType } from '../../../../store/models/root.interface'
import {
  addScraperCredential,
  clearSelectedScraperCredential,
  editScraperCredential,
  setScraperCredentialsModificationState,
  setScraperCredentialsSomeError,
} from '../../../../store/dispatch/scraper/scr-credential.dispatch'
import { RequestTypes, RequestUrlTypes } from '../../../../hooks/types/request.types'
import { useForm } from 'react-hook-form'
import { usePostPutHttpRequest } from '../../../../hooks/request.hook'
import { TabFormWrapperProps } from '../../../../common/components/types/TabFormWrapper.types'
import TabFormWrapper from '../../../../common/components/TabFormWrapper'
import RenderFormCredential from './components/RenderFormCredential'
import { useErrorHandlerHook } from '../../../../hooks/error-handler'
import { ScrCredential } from '../../../../store/models/scraper/scr-credential.interface'
import { URL_SERVER_SCRAPER } from '../../../../config'
import { addNotification } from '../../../../store/dispatch/notifications.dispatch'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { updateLoaderState } from '../../../../store/dispatch/root.dispatch'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setSomeError: bindActionCreators(setScraperCredentialsSomeError, dispatch),
  clearSelected: bindActionCreators(clearSelectedScraperCredential, dispatch),
  setModificationState: bindActionCreators(setScraperCredentialsModificationState, dispatch),
  add: bindActionCreators(addScraperCredential, dispatch),
  edit: bindActionCreators(editScraperCredential, dispatch),
  addNotification: bindActionCreators(addNotification, dispatch),
  updateLoader: bindActionCreators(updateLoaderState, dispatch),
})

const mapStateToProps = ({ scrCredential }: StateType) => ({ scrCredential })

type PropsCredentialFormComponent = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const schema = yup.object().shape({
  _id: yup.string().notRequired(),
  apiKey: yup.string().min(0).max(100).required('Value apiKey cannot be empty'),
  login: yup.string().email().min(0).max(100).required('Value login cannot be empty'),
  password: yup.string().min(0).max(100).required('Value password cannot be empty'),
  status: yup.boolean().required(),
  inPriority: yup.boolean().required(),
})

const CredentialForm: React.FC<PropsCredentialFormComponent> = ({
  scrCredential: { selected, modificationState, list, error },
  setSomeError,
  clearSelected,
  setModificationState,
  addNotification,
  updateLoader,
  add,
  edit,
}) => {
  const isCreate: boolean = modificationState === ModificationStatus.Create
  const errorHandler = useErrorHandlerHook(setSomeError, 'Облікові записи')
  const saveRequest = usePostPutHttpRequest(isCreate ? add : edit)

  let item: ScrCredential | null = selected

  if (!item || isCreate) {
    item = new ScrCredential()
  }

  const form = useForm<ScrCredential>({ defaultValues: item, resolver: yupResolver(schema) })

  const saveForm = async (formState: ScrCredential): Promise<void> => {
    const duplicate = list.find((item) => {
      return item.login === formState.login
    })

    if (isCreate && duplicate) {
      errorHandler({ text: 'Даний запис вже існує' })
      return
    }

    if (item) {
      try {
        if (isCreate) {
          let anotherAcc = list.find((acc) => acc.inPriority === formState.inPriority)
          if (anotherAcc) {
            anotherAcc = { ...anotherAcc, inPriority: !formState.inPriority }

            await saveRequest({
              body: {
                data: [formState, anotherAcc],
              },
              method: RequestTypes.POST,
              base: URL_SERVER_SCRAPER,
              url: RequestUrlTypes.SCRAPER_CREDENTIAL_CREATE_AND_UPDATE_ANOTHER,
            })
          } else {
            await saveRequest({
              body: {
                ...formState,
              },
              method: RequestTypes.POST,
              url: RequestUrlTypes.SCRAPER_CREDENTIAL,
              base: URL_SERVER_SCRAPER,
            })
          }
        } else {
          let anotherAcc = list.find(
            (acc) => acc.inPriority === formState.inPriority && acc._id !== formState._id,
          )
          if (anotherAcc) {
            anotherAcc = { ...anotherAcc, inPriority: !formState.inPriority }

            await saveRequest({
              body: {
                data: [formState, anotherAcc],
              },
              method: RequestTypes.PUT,
              base: URL_SERVER_SCRAPER,
              url: RequestUrlTypes.SCRAPER_CREDENTIAL_UPDATE_MULTIPLE,
            })
          } else {
            await saveRequest({
              body: {
                ...formState,
              },
              method: RequestTypes.PUT,
              base: URL_SERVER_SCRAPER,
              url: RequestUrlTypes.SCRAPER_CREDENTIAL,
              id: item._id,
            })
          }
        }

        setModificationState(ModificationStatus.None)
        clearSelected()
        addNotification('Облікові записи', isCreate ? 'створено' : 'редаговано')
      } catch (e: any) {
        errorHandler({ text: e.message })
      } finally {
        updateLoader(false)
      }
    }
  }

  const resetForm = (): void => {
    setModificationState(ModificationStatus.None)
    form.reset()
  }

  const formOptions: TabFormWrapperProps<ScrCredential> = {
    form,
    error,
    title: 'слоту',
    isCreate,
    saveForm,
    resetForm,
  }

  return (
    <TabFormWrapper {...formOptions}>
      <RenderFormCredential form={form} />
    </TabFormWrapper>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(CredentialForm)
