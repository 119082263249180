import { IActionBase } from '../models/root.interface'
import { IAccount } from '../models/account.interface'
import { accountTypes } from '../types/account.types'

const initialState: IAccount = {
  email: 'admin@react-template.pl',
}

export default (state: IAccount = initialState, action: IActionBase): IAccount => {
  switch (action.type) {
    case accountTypes.LOG_IN: {
      return { ...state, email: action.email }
    }
    case accountTypes.LOG_OUT: {
      return { ...state, email: '' }
    }
    default:
      return state
  }
}
