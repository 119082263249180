import { IUser } from '../models/user.interface'
import { Dispatch } from 'redux'
import { addAdminAction, removeAdminAction } from '../actions/users.action'

export const addAdmin = (user: IUser) => (dispatch: Dispatch) => {
  dispatch(addAdminAction(user))
}

export const removeAdmin = (user: IUser) => (dispatch: Dispatch) => {
  dispatch(removeAdminAction(user))
}
