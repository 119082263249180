import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { useErrorHandlerHook } from '../../../../hooks/error-handler'
import {
  getGsmOptionsServicesList,
  setGsmOptionsServicesSomeError,
} from '../../../../store/dispatch/gsm/gsm-options-service.dispatch'
import RenderListGsmOptionsService from './components/RenderListGsmOptionsService'
import { URL_SERVER_GSM } from '../../../../config'
import { GsmOptionsService } from '../../../../store/models/gsm/gsm-options-service.interface'
import { useHttpDateLoader } from '../../../../hooks/request.hook'
import { RequestUrlTypes } from '../../../../hooks/types/request.types'
const mapDispatchToProps = (dispatch: Dispatch) => ({
  setList: bindActionCreators(getGsmOptionsServicesList, dispatch),
  setSomeError: bindActionCreators(setGsmOptionsServicesSomeError, dispatch),
})

type PropsComponent = ReturnType<typeof mapDispatchToProps>
const GsmOptionsServiceList: React.FC<PropsComponent> = ({
  setList,
  setSomeError,
}): JSX.Element => {
  const errorHandler = useErrorHandlerHook(setSomeError, 'Gsm опції сервісів')
  useHttpDateLoader<GsmOptionsService[]>({
    url: RequestUrlTypes.GSM_OPTIONS_SERVICE,
    base: URL_SERVER_GSM,
    func: setList,
    errorHandler,
  })

  return <RenderListGsmOptionsService />
}

export default connect(null, mapDispatchToProps)(GsmOptionsServiceList)
