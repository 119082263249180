import React from 'react'
import { CustomRenderFormTgCustomersProps } from '../types/RenderFormTgCustomers.types'
import TextRH from '../../../common/form-hook/Text'

const RenderFormTgCustomers: React.FC<CustomRenderFormTgCustomersProps> = ({ form }) => {
  return (
    <>
      <div className='form-group col-md-5'>
        <TextRH
          {...form}
          type='number'
          max={100000}
          min={-200}
          id='input_balance'
          label='Баланс'
          field='balance'
          required='Value amount cannot be empty'
          step={'any'}
        />
      </div>
    </>
  )
}

export default RenderFormTgCustomers
