import { rootTypes } from '../types/root.types'
import { TgOptionType } from '../../components/TgOptions/types/TgOptions.types'

export const updateCurrentPathAction = (
  area: string,
  subArea: string,
): IUpdateCurrentPathActionType => {
  return { type: rootTypes.UPDATE_CURRENT_PATH, area: area, subArea: subArea }
}

export const updateLoaderStateAction = (value: boolean): IUpdateLoaderStateActionType => {
  return { type: rootTypes.UPDATE_LOADER_STATE, value }
}

export const setSomeErrorAction = (value: string): ISetSomeErrorActionType => {
  return { type: rootTypes.SET_SOME_ERROR, value }
}

export const removeSomeErrorAction = (value: string): IRemoveSomeErrorActionType => {
  return { type: rootTypes.REMOVE_SOME_ERROR, value }
}

export function changeTypeTgOptionAction(value: TgOptionType): IChangeTypeListActionType {
  return { type: rootTypes.CHANGE_TG_OPTIONS_PENDING_TYPE, value }
}

interface IUpdateCurrentPathActionType {
  type: string
  area: string
  subArea: string
}

interface IUpdateLoaderStateActionType {
  type: string
  value: boolean
}

interface ISetSomeErrorActionType {
  type: string
  value: string
}

interface IRemoveSomeErrorActionType {
  type: string
  value: string
}

interface IChangeTypeListActionType {
  type: string
  value: TgOptionType
}
