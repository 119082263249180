export enum ScrapperOptionType {
  SCR_CONFIGURATION = 'scr-configuration',
  SCR_CREDENTIAL = 'scr-credential',
  SCR_SERVICE = 'scr-service',
  SCR_SLOT = 'scr-slot',
  SCR_RENTAL_RATES = 'scr-rental-rate',
  SCR_OPTIONS = 'scr-options',
  SCR_SERVICE_DIARY = 'scr-service-diary',
  SCR_RENTAL_RATE_DIARY = 'scr-rental-rate-diary',
}

export type ScrapperTabCustomProps = {
  renderTabHeader: JSX.Element
  type: ScrapperOptionType
}
