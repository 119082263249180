import React from 'react'
import { CardHeaderProps } from '../../../common/components/types/CardHeader.types'
import GsmAnalyticsFilters from '../GsmAnalyticsFilters'

const TabCardHeaderGsmAnalytics = <T,>({ headerTitle }: CardHeaderProps<T>) => {
  const headerClass = headerTitle ? '' : 'd-flex justify-content-end'

  return (
    <div className={`card-header py-3 pl-2 ${headerClass}`}>
      <div className='row m-0'>
        <GsmAnalyticsFilters />
      </div>
      {headerTitle && <h6 className='m-0 font-weight-bold text-green'>{headerTitle}</h6>}
    </div>
  )
}

export default TabCardHeaderGsmAnalytics
