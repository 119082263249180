import React from 'react'
import { connect } from 'react-redux'
import { ModificationStatus, StateType } from '../../../../store/models/root.interface'
import { ScrSlot } from '../../../../store/models/scraper/scr-slot.interface'
import { bindActionCreators, Dispatch } from 'redux'
import {
  addSlot,
  clearSelectedSlot,
  editSlot,
  setSlotsModificationState,
  setSlotsSomeError,
} from '../../../../store/dispatch/scraper/scr-slot.dispatch'
import { addNotification } from '../../../../store/dispatch/notifications.dispatch'
import { RequestUrlTypes } from '../../../../hooks/types/request.types'
import { useForm } from 'react-hook-form'
import { useHttpRequestWithBody } from '../../../../hooks/request.hook'
import TabFormWrapper from '../../../../common/components/TabFormWrapper'
import RenderFormSlots from './componets/RenderFormSlots'
import { TabFormWrapperProps } from '../../../../common/components/types/TabFormWrapper.types'
import { useErrorHandlerHook } from '../../../../hooks/error-handler'
import { URL_SERVER_SCRAPER } from '../../../../config'
import { updateLoaderState } from '../../../../store/dispatch/root.dispatch'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setSomeError: bindActionCreators(setSlotsSomeError, dispatch),
  clearSelected: bindActionCreators(clearSelectedSlot, dispatch),
  setModificationState: bindActionCreators(setSlotsModificationState, dispatch),
  add: bindActionCreators(addSlot, dispatch),
  edit: bindActionCreators(editSlot, dispatch),
  addNotification: bindActionCreators(addNotification, dispatch),
  updateLoader: bindActionCreators(updateLoaderState, dispatch),
})

const mapStateToProps = ({ scrSlot, scrCredential: { list } }: StateType) => ({
  scrSlot,
  credentials: list,
})

type PageProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const schema = yup.object().shape({
  _id: yup.string().notRequired(),
  country: yup.number().min(1).required('Value country cannot be empty'),
  amount: yup.number().min(1).max(1000).required('Value amount cannot be empty'),
})

const SlotsForm: React.FC<PageProps> = ({
  scrSlot,
  setSomeError,
  updateLoader,
  addNotification,
  clearSelected,
  setModificationState,
  add,
  edit,
}) => {
  const errorHandler = useErrorHandlerHook(setSomeError, 'Валюта')
  const { selected, modificationState, error, list } = scrSlot
  const isCreate: boolean = modificationState === ModificationStatus.Create
  const saveRequest = useHttpRequestWithBody<ScrSlot>({
    url: RequestUrlTypes.SCRAPER_SLOT,
    base: URL_SERVER_SCRAPER,
    func: isCreate ? add : edit,
  })

  let item: ScrSlot | null = selected

  if (!item || isCreate) {
    item = new ScrSlot()
  }

  const form = useForm<ScrSlot>({ defaultValues: item, resolver: yupResolver(schema) })

  const saveForm = async (formState: ScrSlot): Promise<void> => {
    const uniqSlot = list.find((item) => {
      return item.country === formState.country
    })

    if (isCreate && uniqSlot) {
      errorHandler({ text: 'Такий слот вже існує' })
      return
    }

    if (item) {
      try {
        await saveRequest({
          body: { ...formState },
          id: item?._id ? String(item?._id) : '',
        })

        addNotification('Слот', isCreate ? 'додано' : 'редаговано')
        setModificationState(ModificationStatus.None)
        clearSelected()
      } catch (e: any) {
        errorHandler({ text: e.message })
      } finally {
        updateLoader(false)
      }
    }
  }

  const resetForm = (): void => {
    setModificationState(ModificationStatus.None)
    form.reset()
  }

  const formOptions: TabFormWrapperProps<ScrSlot> = {
    form,
    error,
    title: 'одиницю валюти',
    isCreate,
    saveForm,
    resetForm,
  }

  return (
    <TabFormWrapper {...formOptions}>
      <RenderFormSlots form={form} />
    </TabFormWrapper>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(SlotsForm)
