import SelectRH from '../../../../../common/form-hook/Select'
import React from 'react'
import RenderDurationPeriod from './RenderDurationPeriod'
import RenderEndDate from './RenderEndDate'
import { UseFormReturn } from 'react-hook-form/dist/types/form'
import { TgPromoCode } from '../../../../../store/models/tg/tg-promo-code.interface'

const RenderSelectDuration = (
  props: Pick<UseFormReturn<TgPromoCode>, 'watch' | 'register' | 'formState'>,
) => {
  if (!props.watch('endDate')) return <></>

  const terminations = [
    { value: 'select-date', text: 'обрати дату' },
    { value: 'select-period', text: 'добавити період' },
  ]

  return (
    <>
      <div className='form-group col-md-6'>
        <SelectRH
          {...props}
          id='input_termination'
          label='Оберіть тип'
          field='termination'
          required='Value termination cannot be empty'
          options={terminations}
        />
      </div>

      <RenderDurationPeriod {...props} />
      <RenderEndDate {...props} />
    </>
  )
}

export default RenderSelectDuration
