import { OfferDuration, TerminationOptions, TgPromoCode } from './tg-promo-code.interface'
import { TgOptionUnits } from '../root.interface'
import { Maybe } from 'yup'

type ConditionFromTo = {
  from: number
  to: number
}

type ConditionOptions = 'from-to' | 'only-from' | 'only-to'

export class PartnerProgram {
  _id: Maybe<string | undefined>
  name = ''
  code = ''
  customers: string[] = []
  link = ''
  amount = 0
  unit: TgOptionUnits = TgOptionUnits.NUMERICAL
  isMain = false
  presenceCondition = false
  condition?: ConditionOptions
  conditionValue?: ConditionFromTo
  endDate = false
  maxUses?: number
  termination?: TerminationOptions
  promoCode?: Maybe<string | undefined | TgPromoCode>
  date?: string
  duration?: OfferDuration
}
