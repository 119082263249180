export const scrSlotTypes = {
  GET_SLOTS: 'GET_SLOTS',
  ADD_SLOT: 'ADD_SLOT',
  EDIT_SLOT: 'EDIT_SLOT',
  REMOVE_SLOT: 'REMOVE_SLOT',
  CHANGE_SLOT_PENDING_EDIT: 'CHANGE_SLOT_PENDING_EDIT',
  CLEAR_SLOT_PENDING_EDIT: 'CLEAR_SLOT_PENDING_EDIT',
  SET_SLOT_MODIFICATION_STATE: 'SET_SLOT_MODIFICATION_STATE',
  SET_SLOT_SOME_ERROR: 'SET_SLOT_SOME_ERROR',
}
