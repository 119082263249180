import React from 'react'
import TextRH from '../../../../../common/form-hook/Text'
import SelectRH from '../../../../../common/form-hook/Select'
import MultiSelectRH from '../../../../../common/form-hook/MultipleSelect'
import CheckboxRH from '../../../../../common/form-hook/Checkbox'
import RenderSelectDuration from './RenderSelectDuration'
import { UseFormReturn } from 'react-hook-form/dist/types/form'
import { TgPromoCode } from '../../../../../store/models/tg/tg-promo-code.interface'
import { TgOptionService } from '../../../../../store/models/tg/tg-options-service.interface'
import { TgOptionCountry } from '../../../../../store/models/tg/tg-options-country.interface'
import { TgOptionUnits } from '../../../../../store/models/root.interface'

const RenderFormTgPromoCode = ({
  form,
  promoCode,
  services,
  countries,
}: {
  form: UseFormReturn<TgPromoCode>
  promoCode: TgPromoCode
  services: TgOptionService[]
  countries: TgOptionCountry[]
}) => {
  const units = [
    {
      value: TgOptionUnits.PERCENTAGE,
      text: 'відсоткове (%)',
    },
    {
      value: TgOptionUnits.NUMERICAL,
      text: 'числове',
    },
  ]

  services = Array.from(new Map(services.map((item) => [item['shortName'], item])).values())

  return (
    <>
      {/*  media input  */}
      <div className='form-group col-md-6'>
        <TextRH
          {...form}
          type='text'
          id='input_code'
          label='Код'
          field='code'
          max={50}
          min={0}
          placeholder='Введіть значення'
          required='Value code cannot be empty'
        />
      </div>
      {/*  content textarea  */}
      <div className='form-group col-md-2'>
        <TextRH
          {...form}
          type='number'
          id='input_amount'
          label='Значення'
          field='amount'
          required='Value amount cannot be empty'
        />
      </div>
      <div className='form-group col-md-4'>
        <SelectRH
          {...form}
          id='input_unit'
          label='Одиниця виміру'
          field='unit'
          required='Value option cannot be empty'
          options={units}
        />
      </div>
      <div className='form-group col-md-6'>
        <MultiSelectRH
          {...form}
          id='input_services'
          field='services'
          label='Сервіси'
          placeholder='Обрати сервіси'
          options={services}
          labelKey='name'
          savedKey='shortName'
          value={form.watch('services')}
          defaultSelected={promoCode.services}
        />
      </div>

      <div className='form-group col-md-6'>
        <MultiSelectRH
          {...form}
          id='input_countries'
          field='countries'
          label='Країни'
          placeholder='Обрати країни'
          options={countries}
          labelKey='name'
          savedKey='shortName'
          value={form.watch('countries')}
          defaultSelected={promoCode.countries}
        />
      </div>
      <div className='form-group col-md-6'>
        <TextRH
          {...form}
          type='number'
          id='input_max_customers'
          title='Максимальна кількість користувачів'
          label='Макс. кількість користувачів'
          placeholder='Введіть...'
          field='maxUses'
        />
      </div>
      <div className='form-group col-md-6 mb-3 mt-auto'>
        <CheckboxRH
          {...form}
          id='checkbox_date_end'
          label='Додати дату закінчення'
          field='endDate'
        />
      </div>
      <RenderSelectDuration {...form} />
    </>
  )
}

export default RenderFormTgPromoCode
