import { Dispatch } from 'redux'
import { ModificationStatus } from '../../models/root.interface'
import {
  changeSelectedScrRentalRateDiaryAction,
  clearSelectedScrRentalRateDiaryAction,
  getScrRentalRateDiaryListAction,
  setScrRentalRateDiaryModificationStateAction,
  setScrRentalRateDiarySomeErrorAction,
} from '../../actions/scraper/scr-rental-rate-diary.action'
import { ScrRentalRateDiary } from '../../models/scraper/scr-rental-rate-diary.interface'

type ActionItem = ScrRentalRateDiary

export const getScrRentalRateDiaryList = (value: ActionItem[]) => (dispatch: Dispatch) => {
  dispatch(getScrRentalRateDiaryListAction(value))
}

export const changeSelectedScrRentalRateDiary = (value: string) => (dispatch: Dispatch) => {
  dispatch(changeSelectedScrRentalRateDiaryAction(value))
}

export const clearSelectedScrRentalRateDiary = () => (dispatch: Dispatch) => {
  dispatch(clearSelectedScrRentalRateDiaryAction())
}

export const setScrRentalRateDiaryModificationState =
  (value: ModificationStatus) => (dispatch: Dispatch) => {
    dispatch(setScrRentalRateDiaryModificationStateAction(value))
  }

export const setScrRentalRateDiarySomeError = (value: string) => (dispatch: Dispatch) => {
  dispatch(setScrRentalRateDiarySomeErrorAction(value))
}
