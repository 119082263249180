import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { updateCurrentPath } from '../../store/dispatch/root.dispatch'
import { ScrapperOptionType } from './types/ScraperOptions.type'
import ScrOptionsTabHeader from './components/ScrOptionsTabHeader'
import ScraperOptionsWrapper from './components/ScrOptionsWrapper'
import OptionsTab from './components/options/OptionsTab'
import ConfigurationTab from './components/configuration/ConfigurationTab'
import CredentialTab from './components/credential/CredentialTab'
import ServicesTab from './components/service/ScrServicesTab'
import SlotsTab from './components/slot/SlotsTab'
import RentalRateTab from './components/rental-rate/RentalRateTab'
import ScrServiceDiaryTab from './components/service-diary/ScrServiceDiaryTab'
import ScrRentalRateDiaryTab from './components/rental-rate-diary/ScrRentalRateDiaryTab'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateCurrentPath: bindActionCreators(updateCurrentPath, dispatch),
})

type PropsPage = ReturnType<typeof mapDispatchToProps>

const ScraperOptions: React.FC<PropsPage> = ({ updateCurrentPath }) => {
  const [type, setType] = useState<ScrapperOptionType>(ScrapperOptionType.SCR_OPTIONS)
  const details = { renderTabHeader: ScrOptionsTabHeader({ type, setType }), type }

  useEffect(() => {
    updateCurrentPath('scraper', type)
  }, [type])

  return (
    <ScraperOptionsWrapper>
      <OptionsTab {...details} />
      <ConfigurationTab {...details} />
      <CredentialTab {...details} />
      <ServicesTab {...details} />
      <SlotsTab {...details} />
      <RentalRateTab {...details} />
      <ScrServiceDiaryTab {...details} />
      <ScrRentalRateDiaryTab {...details} />
    </ScraperOptionsWrapper>
  )
}

export default connect(null, mapDispatchToProps)(ScraperOptions)
