import React from 'react'
import { ScrSlot } from '../../../../../store/models/scraper/scr-slot.interface'
import { StateType, ModificationStatus } from '../../../../../store/models/root.interface'
import TopCard from '../../../../../common/components/TopCard'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import {
  changeSelectedSlot,
  clearSelectedSlot,
  setSlotsModificationState,
} from '../../../../../store/dispatch/scraper/scr-slot.dispatch'
import { OptionsReceiver } from '../../../../../utils/option-receiver'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  clearSelected: bindActionCreators(clearSelectedSlot, dispatch),
  changeSelected: bindActionCreators(changeSelectedSlot, dispatch),
  setModificationState: bindActionCreators(setSlotsModificationState, dispatch),
})

const mapStateToProps = ({ scrSlot, scrCredential, scrOptions }: StateType) => ({
  scrSlot,
  credentials: scrCredential.list,
  countries: scrOptions.countriesList,
})

type PropsSlotsListComponent = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const RenderListSlots: React.FC<PropsSlotsListComponent> = ({
  scrSlot: { list, selected },
  countries,
  clearSelected,
  changeSelected,
  setModificationState,
}): JSX.Element => {
  const getCountryName = OptionsReceiver.getValueByKey(countries, 'shortName')
  const onSelect = (item: ScrSlot): void => {
    if (selected && selected._id === item._id) {
      clearSelected()
    } else {
      changeSelected(item._id || '')
    }

    setModificationState(ModificationStatus.None)
  }

  const selectedCardClass = (id: string) => (selected && selected._id === id ? 'danger' : 'primary')

  return (
    <div className='row'>
      {list.map((slot: ScrSlot) => {
        return (
          <TopCard
            key={slot._id}
            title={`СЛОТИ (${slot.amount})`}
            text={getCountryName(String(slot.country), 'name')}
            icon='server'
            class={selectedCardClass(slot._id || '')}
            onClick={() => onSelect(slot)}
          />
        )
      })}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(RenderListSlots)
