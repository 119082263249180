import React from 'react'
import { connect } from 'react-redux'
import ListIsEmpty from '../../../common/components/ListIsEmpty'
import { StateType } from '../../../store/models/root.interface'
import { GsmAnalyticsOptionStatus } from '../../../store/models/gsm/gsm-analytics.interface'

const mapStateToProps = ({ gsmAnalytics }: StateType) => ({
  options: gsmAnalytics,
})

type PropsComponent = ReturnType<typeof mapStateToProps>
const RenderTableAnalyticsByModem: React.FC<PropsComponent> = ({
  options: { list, filters },
}): JSX.Element => {
  let modems = list.map((option) => option.modem)
  modems = Array.from(new Set(modems))

  const filter = filters.modem

  if (typeof filter !== 'string' && filter.length) {
    modems = modems.filter((modem) => filter.includes(modem))
  }

  if (!modems.length)
    return (
      <div className='bg-white portlet shadow mb-4 rounded'>
        <ListIsEmpty />
      </div>
    )

  const productElements = modems.map((modem: string) => {
    const options = list.filter((option) => option.modem === modem)
    const sumPrices = options.reduce((prev, curr) => prev + curr.cost, 0)
    const completed = options.filter((option) => {
      return option.status === GsmAnalyticsOptionStatus.STATUS_COMPLETED
    })

    const listByPhone = Array.from(new Map(options.map((item) => [item.phone, item])).values())

    return (
      <tr className='table-row pl-2' key={`product_${modem}`}>
        <td>{modem}</td>
        <td>{listByPhone.length}</td>
        <td>{options.length}</td>
        <td>{Number(sumPrices / options.length).toFixed(2)} USD</td>
        <td>{sumPrices} USD</td>
        <td>{completed.length}</td>
      </tr>
    )
  })

  return (
    <div className='table-responsive portlet mb-4 shadow rounded'>
      <table className='table m-0 table-bordered'>
        <thead className='thead-light'>
          <tr>
            <th scope='col'>Модем</th>
            <th scope='col'>номерів</th>
            <th scope='col'>сервісів</th>
            <th scope='col'>Середня ціна</th>
            <th scope='col'>Заробіток</th>
            <th scope='col'>Активацій</th>
          </tr>
        </thead>
        <tbody>{productElements}</tbody>
      </table>
    </div>
  )
}

export default connect(mapStateToProps, null)(RenderTableAnalyticsByModem)
