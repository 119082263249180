import { scrRentalRateDiaryTypes } from '../../types/scraper/scr-rental-rate-diary.types'
import { ModificationStatus } from '../../models/root.interface'
import { ScrRentalRateDiary } from '../../models/scraper/scr-rental-rate-diary.interface'

type ActionItem = ScrRentalRateDiary

export function getScrRentalRateDiaryListAction(
  value: ActionItem[],
): IGetItemsActionType<ActionItem> {
  return { type: scrRentalRateDiaryTypes.GET_ITEMS_LIST, value }
}

export function changeSelectedScrRentalRateDiaryAction(
  value: string,
): IChangeSelectedItemActionType {
  return { type: scrRentalRateDiaryTypes.CHANGE_ITEM_PENDING_EDIT, value }
}

export function clearSelectedScrRentalRateDiaryAction(): IClearSelectedItemActionType {
  return { type: scrRentalRateDiaryTypes.CLEAR_ITEM_PENDING_EDIT }
}

export function setScrRentalRateDiaryModificationStateAction(
  value: ModificationStatus,
): ISetModificationStateActionType {
  return { type: scrRentalRateDiaryTypes.SET_MODIFICATION_STATE_ITEM, value }
}

export function setScrRentalRateDiarySomeErrorAction(value: string): ISetSomeErrorActionType {
  return { type: scrRentalRateDiaryTypes.SET_SOME_ERROR_ITEM, value: value }
}

interface IGetItemsActionType<T> {
  type: string
  value: T[]
}

interface IChangeSelectedItemActionType {
  type: string
  value: string
}

interface IClearSelectedItemActionType {
  type: string
}

interface ISetModificationStateActionType {
  type: string
  value: ModificationStatus
}

interface ISetSomeErrorActionType {
  type: string
  value: string
}
