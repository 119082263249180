import React from 'react'
import { connect } from 'react-redux'
import ListIsEmpty from '../../../../../common/components/ListIsEmpty'
import { StateType, ModificationStatus } from '../../../../../store/models/root.interface'
import { bindActionCreators, Dispatch } from 'redux'
import {
  changeSelectedGsmOptionsSender,
  clearSelectedGsmOptionsSender,
  setGsmOptionsSendersModificationState,
} from '../../../../../store/dispatch/gsm/gsm-options-sender.dispatch'
import { GsmOptionsSender } from '../../../../../store/models/gsm/gsm-options-sender.interface'
import { OptionsReceiver } from '../../../../../utils/option-receiver'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  clearSelected: bindActionCreators(clearSelectedGsmOptionsSender, dispatch),
  changeSelected: bindActionCreators(changeSelectedGsmOptionsSender, dispatch),
  setModificationState: bindActionCreators(setGsmOptionsSendersModificationState, dispatch),
})

const mapStateToProps = ({
  gsmOptionsSender,
  gsmOptionsCountry,
  gsmOptionsService,
}: StateType) => ({
  options: gsmOptionsSender,
  countries: gsmOptionsCountry.list,
  services: gsmOptionsService.list,
})

type PropsComponent = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const RenderListGsmOptionsSender: React.FC<PropsComponent> = ({
  options: { list, selected },
  countries,
  services,
  clearSelected,
  changeSelected,
  setModificationState,
}): JSX.Element => {
  const getGsmOptionCountryName = OptionsReceiver.getValueByKey(countries, 'shortName')
  const getGsmOptionServiceName = OptionsReceiver.getValueByKey(services, 'shortName')
  const getRowClass = (item: GsmOptionsSender) => {
    return selected?._id === item?._id ? 'table-primary' : ''
  }
  const onSelect = (item: GsmOptionsSender): void => {
    if (selected && selected._id === item?._id) {
      clearSelected()
    } else {
      changeSelected(item?._id || '')
    }

    setModificationState(ModificationStatus.None)
  }

  if (!list.length) return <ListIsEmpty />

  const productElements: JSX.Element[] = list.map((item: GsmOptionsSender, i) => {
    let service: string
    let country = 'name not found'
    let names = 'names not found'

    if (typeof item.smsNames === 'string') {
      names = item.smsNames
    }

    if (Array.isArray(item.smsNames)) {
      names = item.smsNames.join(' | ')
    }

    if (typeof item.service === 'object') {
      service = item.service.name

      if (typeof item.service.country === 'object') {
        country = item.service.country.name
      } else {
        country = getGsmOptionCountryName(item.service.country, 'name')
      }
    } else {
      service = getGsmOptionServiceName(String(item.service), 'name')
    }

    return (
      <tr className={getRowClass(item)} onClick={() => onSelect(item)} key={item._id}>
        <th scope='row'>{++i}</th>
        <td data-id={service}>{service}</td>
        <td data-id={country}>{country}</td>
        <td>{names}</td>
      </tr>
    )
  })

  return (
    <div className='table-responsive portlet'>
      <table className='table m-0'>
        <thead className='thead-light'>
          <tr>
            <th scope='col'>№</th>
            <th scope='col'>Сервіс</th>
            <th scope='col'>Країна</th>
            <th scope='col'>Відправник</th>
          </tr>
        </thead>
        <tbody>{productElements}</tbody>
      </table>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(RenderListGsmOptionsSender)
