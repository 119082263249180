import React from 'react'
import { CardHeaderProps } from '../../../../common/components/types/CardHeader.types'
import ScrServicesDiaryFilterForm from './ScrServicesDiaryFilterForm'

const ScrServiceDiaryCardHeader = <T,>({ headerTitle }: CardHeaderProps<T>) => {
  const headerClass = headerTitle ? '' : 'd-flex justify-content-end'

  return (
    <div className={`card-header py-3 pl-2 ${headerClass}`}>
      <div className='row m-0'>
        <ScrServicesDiaryFilterForm />
      </div>
      {headerTitle && <h6 className='m-0 font-weight-bold text-green'>{headerTitle}</h6>}
    </div>
  )
}

export default ScrServiceDiaryCardHeader
