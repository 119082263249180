import React from 'react'
import { connect } from 'react-redux'
import { ScrService } from '../../../../store/models/scraper/scr-service.interface'
import { bindActionCreators, Dispatch } from 'redux'
import {
  getScrService,
  setScrServiceSomeError,
} from '../../../../store/dispatch/scraper/scr-service.dispatch'
import { RequestUrlTypes } from '../../../../hooks/types/request.types'
import { useHttpDateLoader } from '../../../../hooks/request.hook'
import RenderListServices from './components/RenderListScrServices'
import { useErrorHandlerHook } from '../../../../hooks/error-handler'
import { URL_SERVER_SCRAPER } from '../../../../config'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setList: bindActionCreators(getScrService, dispatch),
  setSomeError: bindActionCreators(setScrServiceSomeError, dispatch),
})

type PageProps = ReturnType<typeof mapDispatchToProps>

const ScrServicesList: React.FC<PageProps> = ({ setList, setSomeError }): JSX.Element => {
  const errorHandler = useErrorHandlerHook(setSomeError, 'Сервіси')
  useHttpDateLoader<ScrService[]>({
    url: RequestUrlTypes.SCRAPER_SERVICE,
    base: URL_SERVER_SCRAPER,
    func: setList,
    errorHandler,
  })

  return <RenderListServices />
}

export default connect(null, mapDispatchToProps)(ScrServicesList)
