import React from 'react'
import { connect } from 'react-redux'
import { StateType } from '../../../../store/models/root.interface'
import { bindActionCreators, Dispatch } from 'redux'
import {
  clearScrServicesFilters,
  editScrServiceFilters,
} from '../../../../store/dispatch/scraper/scr-service.dispatch'
import RenderScrServiceFilters from './components/RenderScrServiceFilters'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  editFilters: bindActionCreators(editScrServiceFilters, dispatch),
  clearFilters: bindActionCreators(clearScrServicesFilters, dispatch),
})

const mapStateToProps = ({ scrService, scrCredential, scrOptions }: StateType) => ({
  filters: scrService.filters,
  credentials: scrCredential.list,
  services: scrOptions.servicesList,
  countries: scrOptions.countriesList,
})

type PropsComponent = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const ScrServicesFilterForm: React.FC<PropsComponent> = (props): JSX.Element => {
  return (
    <>
      <div className='form-group col-md-12 p-0'>
        <h6 className='m-0 font-weight-bold text-green'>Фільтри:</h6>
      </div>
      <RenderScrServiceFilters {...props} />
      <button
        type='button'
        className='btn btn-danger ml-auto col-md-2 h-50 mt-auto mb-3'
        onClick={props.clearFilters}
      >
        Скинути
      </button>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ScrServicesFilterForm)
