import { IUser } from '../models/user.interface'
import { userTypes } from '../types/users.types'

export const addAdminAction = (user: IUser): IAddAdminActionType => {
  return { type: userTypes.ADD_ADMIN, user: user }
}

export const removeAdminAction = (user: IUser): IRemoveAdminActionType => {
  return { type: userTypes.REMOVE_ADMIN, user: user }
}

interface IAddAdminActionType {
  type: string
  user: IUser
}

interface IRemoveAdminActionType {
  type: string
  user: IUser
}
