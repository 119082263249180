import { FiltersState } from '../root.interface'
import { Maybe } from 'yup'

export class ScrService {
  _id: Maybe<string | undefined>
  minPrice = 0
  minCount = 0
  maxPrice = 0
  country = 0
  name = ''
  status = true
  step = 0.01
  account = ''
}

export type ScrServiceFilters = FiltersState<Pick<ScrService, 'name' | 'country' | 'account'>>
