import { notificationsTypes } from '../types/notifications.types'

export function addNotificationAction(title: string, text: string): IAddNotificationActionType {
  return { type: notificationsTypes.ADD_NOTIFICATION, text: text, title: title }
}

export function removeNotificationAction(id: number): IRemoveNotificationActionType {
  return { type: notificationsTypes.REMOVE_NOTIFICATION, id: id }
}

interface IAddNotificationActionType {
  type: string
  text: string
  title: string
}
interface IRemoveNotificationActionType {
  type: string
  id: number
}
