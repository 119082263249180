import React from 'react'
import { DateInputProps } from './types/Date.types'

function DateRH<T>(props: DateInputProps<T>): JSX.Element {
  const error = (props.formState.errors[props.field]?.message as string) || ''
  let min = ''

  const getTimeZoneOffsetInMs = (time: number): string => {
    const timeZone = new Date().getTimezoneOffset() * -60 * 1000
    const date = new Date(time + timeZone)

    return date.toISOString().slice(0, 19)
  }

  if (props.min) {
    const date = new Date(Date.now() + 60000)

    min = getTimeZoneOffsetInMs(date.getTime()).slice(0, -3)
  }

  return (
    <>
      <label htmlFor={props.id} className='form-label mb-2'>
        {props.label}
      </label>
      <input
        type='datetime-local'
        className={`form-control ${props.inputClass || ''}`}
        id={props.id}
        min={min}
        max={props.max || ''}
        {...props.register(props.field, {
          required: props.required || '',
          valueAsDate: true,
        })}
      />
      {error && props.required ? <div className='invalid-feedback'>{error}</div> : null}
    </>
  )
}

export default DateRH
