export const tgCustomersTypes = {
  GET_TG_CUSTOMERS_LIST: 'GET_TG_CUSTOMERS_LIST',
  ADD_TG_CUSTOMER: 'ADD_TG_CUSTOMER',
  EDIT_TG_CUSTOMER: 'EDIT_TG_CUSTOMER',
  REMOVE_TG_CUSTOMER: 'REMOVE_TG_CUSTOMER',
  CHANGE_TG_CUSTOMER_PENDING_EDIT: 'CHANGE_TG_CUSTOMER_PENDING_EDIT',

  CLEAR_TG_CUSTOMER_PENDING_EDIT: 'CLEAR_TG_CUSTOMER_PENDING_EDIT',
  SET_TG_CUSTOMER_MODIFICATION_STATE: 'SET_TG_CUSTOMER_MODIFICATION_STATE',
  SET_TG_CUSTOMER_SOME_ERROR: 'SET_TG_CUSTOMER_SOME_ERROR',
}
