import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/bootstrap/dist/js/bootstrap.bundle'
import '@fortawesome/fontawesome-free/css/all.min.css'
import { Provider } from 'react-redux'
import store from './store/store'

const element = document.getElementById('root')

if (element) {
  const root = ReactDOM.createRoot(element)
  root.render(
    <Provider store={store}>
      <App />
    </Provider>,
  )

  serviceWorker.unregister()
}
