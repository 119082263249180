import { FiltersState } from '../root.interface'
import { Maybe } from 'yup'

export class TgOptionService {
  _id: Maybe<string | undefined>
  shortName = ''
  name: Maybe<string | undefined> = ''
  country = ''
  icon: Maybe<string | undefined> = ''
  link: Maybe<string | undefined>
}

export type TgOptionsServiceFilters = FiltersState<Pick<TgOptionService, 'shortName' | 'country'>>
