// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace OptionsReceiver {
  const getObjectIds = <T>(array: T[], key: string): Record<string, T> =>
    array.reduce((obj, item) => {
      return Object.assign(obj, { [getNestedValue(item, key)]: item })
    }, {})

  const getNestedValue = (obj: any, path: string): any => {
    const keys = path.split('.')
    let value = obj

    for (const key of keys) {
      if (Array.isArray(value)) {
        value = value[parseInt(key, 10)]
      } else {
        value = value[key]
      }
    }
    return value
  }

  export const getModelByKey = <T>(list: T[], key: string) => {
    const objIds = getObjectIds(list, key)

    return (value: string): T | undefined => {
      return objIds[value]
    }
  }

  export const getValueByKey = <T>(list: T[], modelKey: string) => {
    const objIds = getObjectIds(list, modelKey)

    return (value: string | number, key: string): string => {
      const data = objIds[String(value)]
      if (data) {
        return getNestedValue(data, key) || 'not found'
      }

      return 'not found'
    }
  }

  export const uniqModels = <T>(list: T[], func: (item: T) => string | number): T[] => {
    return [...new Map(list.map((item) => [func(item), item])).values()]
  }

  export const uniqArray = <T>(list: T[]): T[] => {
    return [...new Set(list)]
  }
}
