import React from 'react'
import { connect } from 'react-redux'
import { ModificationStatus, StateType } from '../../../../store/models/root.interface'
import { bindActionCreators, Dispatch } from 'redux'
import { addNotification } from '../../../../store/dispatch/notifications.dispatch'
import { useHttpRequestWithBody } from '../../../../hooks/request.hook'
import { RequestUrlTypes } from '../../../../hooks/types/request.types'
import { useForm } from 'react-hook-form'
import { TabFormWrapperProps } from '../../../../common/components/types/TabFormWrapper.types'
import TabFormWrapper from '../../../../common/components/TabFormWrapper'
import { useErrorHandlerHook } from '../../../../hooks/error-handler'
import { URL_SERVER_TG } from '../../../../config'
import { TgInternalBank } from '../../../../store/models/tg/tg-internal-bank.interface'
import {
  addTgInternalBank,
  clearSelectedTgInternalBank,
  editTgInternalBank,
  setTgInternalBanksModificationState,
  setTgInternalBankSomeError,
} from '../../../../store/dispatch/tg/tg-internal-bank.dispatch'
import RenderFormTgInternalBank from './components/RenderFormTgInternalBank'
import { updateLoaderState } from '../../../../store/dispatch/root.dispatch'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setSomeError: bindActionCreators(setTgInternalBankSomeError, dispatch),
  clearSelected: bindActionCreators(clearSelectedTgInternalBank, dispatch),
  setModificationState: bindActionCreators(setTgInternalBanksModificationState, dispatch),
  add: bindActionCreators(addTgInternalBank, dispatch),
  edit: bindActionCreators(editTgInternalBank, dispatch),
  addNotification: bindActionCreators(addNotification, dispatch),
  updateLoader: bindActionCreators(updateLoaderState, dispatch),
})

const mapStateToProps = ({ tgInternalBank, tgCurrencies }: StateType) => ({
  options: tgInternalBank,
  currencies: tgCurrencies.list,
})

type PageProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const schema = yup.object().shape({
  _id: yup.string().notRequired(),
  name: yup.string().min(0).max(100).required('Value name cannot be empty'),
  currencies: yup
    .array()
    .of(yup.string().required())
    .min(1)
    .required('Value currencies cannot be empty'),
  token: yup.string().min(0).max(100).required('Value password cannot be empty'),
  type: yup.string().notRequired(),
  key: yup.string().notRequired(),
})

const TgInternalBankForm: React.FC<PageProps> = ({
  currencies,
  options,
  setSomeError,
  updateLoader,
  addNotification,
  clearSelected,
  setModificationState,
  add,
  edit,
}) => {
  const { selected, modificationState, error, list, base } = options
  const isCreate: boolean = modificationState === ModificationStatus.Create
  const errorHandler = useErrorHandlerHook(setSomeError, 'Валюта')
  const saveRequest = useHttpRequestWithBody<TgInternalBank>({
    url: RequestUrlTypes.TG_INTERNAL_BANK,
    base: URL_SERVER_TG,
    func: isCreate ? add : edit,
  })

  let item: TgInternalBank | null = selected

  if (!item || isCreate) {
    item = new TgInternalBank()
  }

  const form = useForm<TgInternalBank>({ defaultValues: item, resolver: yupResolver(schema) })

  const saveForm = async (formState: TgInternalBank): Promise<void> => {
    const uniq = list.find((item) => item.name === formState.name)

    if (isCreate && uniq) {
      errorHandler({ text: 'Current program exists' })
      return
    }

    if (item) {
      try {
        await saveRequest({
          body: {
            ...item,
            ...formState,
            key: formState.name.toLowerCase().replaceAll(' ', '_'),
          },
          id: item?._id ? String(item?._id) : '',
        })

        setModificationState(ModificationStatus.None)
        clearSelected()
        addNotification('Валюта', isCreate ? 'додано' : 'редаговано')
      } catch (e: any) {
        errorHandler({ text: e.message })
      } finally {
        updateLoader(false)
      }
    }
  }

  const resetForm = (): void => {
    setModificationState(ModificationStatus.None)
    form.reset()
  }

  const formOptions: TabFormWrapperProps<TgInternalBank> = {
    form,
    error,
    title: 'промо коду',
    isCreate,
    saveForm,
    resetForm,
  }

  return (
    <TabFormWrapper {...formOptions}>
      <RenderFormTgInternalBank form={form} option={item} banks={base} currencies={currencies} />
    </TabFormWrapper>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(TgInternalBankForm)
