import React from 'react'
import { StateType } from '../../../store/models/root.interface'
import { connect } from 'react-redux'
import RenderLineChart, { Dataset, LineAtDataset, SaleData } from './RenderLineChart'
import { ScrCredential } from '../../../store/models/scraper/scr-credential.interface'

const mapStateToProps = ({ scrCredential }: StateType) => ({
  scrCredential,
})

type LineChartOption = {
  updatedAt: Date
  account: string
}

type PropsSlotsListComponent<T extends LineChartOption> = ReturnType<typeof mapStateToProps> & {
  list: T[]
  title: string
}

class LineChartDates {
  cred: ScrCredential = new ScrCredential()
  dates: string[] = []
}

const ScrDiaryLineChart = <T extends LineChartOption>({
  title,
  list,
  scrCredential,
}: PropsSlotsListComponent<T>): JSX.Element => {
  const getDate = (updatedAt: Date): string => {
    return new Date(updatedAt).toLocaleString('uk-UA', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
  }

  list = list.sort((a, b) => {
    return new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime()
  })

  const lineDataset = new LineAtDataset()

  lineDataset.labels = list.map((option) => {
    return getDate(option.updatedAt)
  })

  lineDataset.labels = [...new Set(lineDataset.labels)]

  lineDataset.datasets = scrCredential.list.map((cred) => {
    const dataset = new Dataset()
    dataset.title = cred.login

    return dataset
  })

  const collection: LineChartDates[] = []
  for (const cred of scrCredential.list) {
    const data = new LineChartDates()
    data.cred = cred
    const sales = list.filter((option) => {
      return option.account === cred._id
    })

    data.dates = sales.map((option) => getDate(option.updatedAt))

    collection.push(data)
  }

  lineDataset.datasets = collection.map((test) => {
    const dataset = new Dataset()
    dataset.title = test.cred.login

    dataset.sales = lineDataset.labels.map((date) => {
      const sales = test.dates.filter((optionDate) => {
        return optionDate === date
      })

      const sale = new SaleData()
      sale.label = date
      sale.value = sales.length

      return sale
    })

    return dataset
  })

  lineDataset.datasets = lineDataset.datasets.filter((dataset) => {
    return dataset.sales.some((option) => option.value)
  })

  return <RenderLineChart {...lineDataset} title={title} />
}

export default connect(mapStateToProps, null)(ScrDiaryLineChart)
