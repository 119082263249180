import React from 'react'
import SelectRH from '../../../../../common/form-hook/Select'
import TypeheadSelectRH from '../../../../../common/form-hook/TypeheadSelect'
import TextRH from '../../../../../common/form-hook/Text'
import CheckboxRH from '../../../../../common/form-hook/Checkbox'
import { CustomRenderFormSingleServiceProps } from '../types/RenderFormScrService.types'
import { ScrOptionsService } from '../../../../../store/models/scraper/scr-options-service.interface'
import { StateType } from '../../../../../store/models/root.interface'
import { useSelector } from 'react-redux'

const RenderFormScrService: React.FC<CustomRenderFormSingleServiceProps> = ({
  form,
  credentials,
}) => {
  const options = useSelector((state: StateType) => state.scrOptions)
  const countriesList = options.countriesList
  const servicesList = options.servicesList

  const credentialsOptions = credentials.map((item) => {
    return {
      value: item._id || '',
      text: item.login,
    }
  })

  const services = servicesList.filter(
    (item, index, arr) => index === arr.findIndex((value) => value.name === item.name),
  )

  return (
    <>
      <div className='form-row col-md-12 col-xl-12'>
        <div className='form-group col-md-12'>
          <SelectRH
            {...form}
            id='input_account'
            label='Аккаунт'
            field='account'
            options={credentialsOptions}
          />
        </div>
        <div className='form-group col-md-6'>
          <TypeheadSelectRH
            {...form}
            id='input_country'
            field='country'
            label='Країна'
            placeholder='Обрати країну'
            options={countriesList}
            labelKey='name'
            savedKey='shortName'
            value={form.watch('country')}
            defaultSelected={form.watch('country') ? form.watch('country').toString() : ''}
          />
        </div>
        <div className='form-group col-md-6'>
          <TypeheadSelectRH
            {...form}
            id='input_service'
            field='name'
            label='Сервіс'
            placeholder={'Оберіть сервіс'}
            options={services}
            labelKey='name'
            savedKey='shortName'
            value={form.watch('name')}
            defaultSelected={form.watch('name')}
          />
        </div>
      </div>
      <div className='form-row col-md-12 col-xl-12'>
        <div className='form-group col-md-4'>
          <TextRH
            {...form}
            type='number'
            id='input_min_price'
            label='Мінімальна ціна'
            field='minPrice'
            step={0.01}
          />
        </div>
        <div className='form-group col-md-4'>
          <TextRH
            {...form}
            type='number'
            id='input_max_price'
            label='Максимальна ціна'
            field='maxPrice'
            step={0.1}
          />
        </div>
        <div className='form-group col-md-4'>
          <TextRH
            {...form}
            type='number'
            id='input_min_count'
            label='Мінімальна кількість'
            field='minCount'
            step={1}
          />
        </div>
        <div className='form-group col-md-4'>
          <TextRH {...form} type='number' id='input_step' label='Крок' field='step' step={0.01} />
        </div>
        <div className='form-group col-md-4 d-flex'>
          <CheckboxRH {...form} id='checkbox_active' label='Статус' field='status' />
        </div>
      </div>
    </>
  )
}

export default RenderFormScrService
