import { scrSlotTypes } from '../../types/scraper/scr-slot.types'
import { ScrSlot } from '../../models/scraper/scr-slot.interface'
import { ModificationStatus } from '../../models/root.interface'

type ActionItem = ScrSlot

export function getSlotsListAction(value: ActionItem[]): IGetItemsActionType<ActionItem> {
  return { type: scrSlotTypes.GET_SLOTS, value }
}

export function addSlotAction(value: ActionItem): IAddItemActionType<ActionItem> {
  return { type: scrSlotTypes.ADD_SLOT, value }
}

export function editSlotAction(value: ActionItem): IEditItemActionType<ActionItem> {
  return { type: scrSlotTypes.EDIT_SLOT, value }
}

export function removeSlotAction(value: string): IRemoveItemActionType {
  return { type: scrSlotTypes.REMOVE_SLOT, value }
}

export function changeSelectedSlotAction(value: string): IChangeSelectedItemActionType {
  return { type: scrSlotTypes.CHANGE_SLOT_PENDING_EDIT, value }
}

export function clearSelectedSlotAction(): IClearSelectedItemActionType {
  return { type: scrSlotTypes.CLEAR_SLOT_PENDING_EDIT }
}

export function setSlotsModificationStateAction(
  value: ModificationStatus,
): ISetModificationStateActionType {
  return { type: scrSlotTypes.SET_SLOT_MODIFICATION_STATE, value: value }
}

export function setSlotsSomeErrorAction(value: string): ISetSomeErrorActionType {
  return { type: scrSlotTypes.SET_SLOT_SOME_ERROR, value: value }
}

interface IGetItemsActionType<T> {
  type: string
  value: T[]
}

interface IAddItemActionType<T> {
  type: string
  value: T
}

interface IEditItemActionType<T> {
  type: string
  value: T
}

interface IRemoveItemActionType {
  type: string
  value: string
}

interface IChangeSelectedItemActionType {
  type: string
  value: string
}

interface IClearSelectedItemActionType {
  type: string
}

interface ISetModificationStateActionType {
  type: string
  value: ModificationStatus
}

interface ISetSomeErrorActionType {
  type: string
  value: string
}
