import { ModificationStatus } from '../../models/root.interface'
import { TgCurrency } from '../../models/tg/tg-currency.interface'
import { tgCurrenciesTypes } from '../../types/tg/tg-currencies.types'

type ActionItem = TgCurrency

export function getCurrenciesListAction(value: ActionItem[]): IGetItemsActionType<ActionItem> {
  return { type: tgCurrenciesTypes.GET_TG_CURRENCIES_LIST, value }
}

export function addCurrencyAction(value: ActionItem): IAddItemActionType<ActionItem> {
  return { type: tgCurrenciesTypes.ADD_TG_CURRENCY, value }
}

export function editCurrencyAction(value: ActionItem): IEditItemActionType<ActionItem> {
  return { type: tgCurrenciesTypes.EDIT_TG_CURRENCY, value }
}

export function removeCurrencyAction(value: string): IRemoveItemActionType {
  return { type: tgCurrenciesTypes.REMOVE_TG_CURRENCY, value }
}

export function changeSelectedCurrencyAction(value: string): IChangeSelectedItemActionType {
  return { type: tgCurrenciesTypes.CHANGE_TG_CURRENCY_PENDING_EDIT, value }
}

export function clearSelectedCurrencyAction(): IClearSelectedItemActionType {
  return { type: tgCurrenciesTypes.CLEAR_TG_CURRENCY_PENDING_EDIT }
}

export function setCurrenciesModificationStateAction(
  value: ModificationStatus,
): ISetModificationStateActionType {
  return { type: tgCurrenciesTypes.SET_TG_CURRENCY_MODIFICATION_STATE, value: value }
}

export function setCurrenciesSomeErrorAction(value: string): ISetSomeErrorActionType {
  return { type: tgCurrenciesTypes.SET_TG_CURRENCY_SOME_ERROR, value: value }
}

interface IGetItemsActionType<T> {
  type: string
  value: T[]
}

interface IAddItemActionType<T> {
  type: string
  value: T
}

interface IEditItemActionType<T> {
  type: string
  value: T
}

interface IRemoveItemActionType {
  type: string
  value: string
}

interface IChangeSelectedItemActionType {
  type: string
  value: string
}

interface IClearSelectedItemActionType {
  type: string
}

interface ISetModificationStateActionType {
  type: string
  value: ModificationStatus
}

interface ISetSomeErrorActionType {
  type: string
  value: string
}
