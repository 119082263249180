export const tgCurrenciesTypes = {
  GET_TG_CURRENCIES_LIST: 'GET_TG_CURRENCIES_LIST',
  ADD_TG_CURRENCY: 'ADD_TG_CURRENCY',
  EDIT_TG_CURRENCY: 'EDIT_TG_CURRENCY',
  REMOVE_TG_CURRENCY: 'REMOVE_TG_CURRENCY',
  CHANGE_TG_CURRENCY_PENDING_EDIT: 'CHANGE_TG_CURRENCY_PENDING_EDIT',

  CLEAR_TG_CURRENCY_PENDING_EDIT: 'CLEAR_TG_CURRENCY_PENDING_EDIT',
  SET_TG_CURRENCY_MODIFICATION_STATE: 'SET_TG_CURRENCY_MODIFICATION_STATE',
  SET_TG_CURRENCY_SOME_ERROR: 'SET_TG_CURRENCY_SOME_ERROR',
}
