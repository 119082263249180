import { Maybe } from 'yup'

export class ScrRentalRate {
  _id: Maybe<string | undefined>
  status = true
  country = 0
  slot = 0
  account = ''
  minCount = 0
  minPercent = 0
}
