export const tgOptionsServiceTypes = {
  GET_TG_OPTIONS_SERVICE_LIST: 'GET_TG_OPTIONS_SERVICE_LIST',
  ADD_TG_OPTIONS_SERVICE_LIST: 'ADD_TG_OPTIONS_SERVICE_LIST',
  EDIT_TG_OPTIONS_SERVICE_LIST: 'EDIT_TG_OPTIONS_SERVICE_LIST',
  REMOVE_TG_OPTIONS_SERVICE_LIST: 'REMOVE_TG_OPTIONS_SERVICE_LIST',
  CHANGE_TG_OPTIONS_SERVICE_PENDING_EDIT: 'CHANGE_TG_PENDING_EDIT',

  CLEAR_TG_OPTIONS_SERVICE_PENDING_EDIT: 'CLEAR_TG_OPTIONS_SERVICE_PENDING_EDIT',
  SET_TG_OPTIONS_SERVICE_MODIFICATION_STATE: 'SET_TG_OPTIONS_SERVICE_MODIFICATION_STATE',
  SET_TG_OPTIONS_SERVICE_SOME_ERROR: 'SET_TG_OPTIONS_SERVICE_SOME_ERROR',

  EDIT_ITEMS_FILTER: 'EDIT_TG_OPTIONS_SERVICES_FILTER',
  CLEAR_ITEMS_FILTERS: 'CLEAR_TG_OPTIONS_SERVICES_FILTERS',
}
