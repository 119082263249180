import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import {
  getTgCurrenciesList,
  setTgCurrenciesSomeError,
} from '../../../../store/dispatch/tg/tg-currencies.dispatch'
import { useHttpDateLoader } from '../../../../hooks/request.hook'
import { RequestUrlTypes } from '../../../../hooks/types/request.types'
import RenderListTgCurrencies from './components/RenderListTgCurrencies'
import { DispatchTgCurrenciesListProps } from './types/TgCurrenciesList.types'
import { TgCurrency } from '../../../../store/models/tg/tg-currency.interface'
import { useErrorHandlerHook } from '../../../../hooks/error-handler'
import { URL_SERVER_TG } from '../../../../config'
const mapDispatchToProps = (dispatch: Dispatch): DispatchTgCurrenciesListProps => ({
  setList: bindActionCreators(getTgCurrenciesList, dispatch),
  setSomeError: bindActionCreators(setTgCurrenciesSomeError, dispatch),
})

type PropsComponent = ReturnType<typeof mapDispatchToProps>

const TgCurrenciesList: React.FC<PropsComponent> = ({ setList, setSomeError }): JSX.Element => {
  const errorHandler = useErrorHandlerHook(setSomeError, 'Валюта')
  useHttpDateLoader<TgCurrency[]>({
    url: RequestUrlTypes.TG_CURRENCY,
    base: URL_SERVER_TG,
    func: setList,
    errorHandler,
  })

  return <RenderListTgCurrencies />
}

export default connect(null, mapDispatchToProps)(TgCurrenciesList)
