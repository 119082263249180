import { scrConfigurationTypes } from '../../types/scraper/scr-configuration.types'
import { ModificationStatus } from '../../models/root.interface'
import { ScrConfiguration } from '../../models/scraper/scr-configuration.interface'

type ActionItem = ScrConfiguration
export function getScraperConfigurationsAction(
  value: ActionItem[],
): IGetItemsActionType<ActionItem> {
  return { type: scrConfigurationTypes.GET_SCRAPER_CONFIGURATION, value }
}

export function addScraperConfigurationAction(value: ActionItem): IAddItemActionType<ActionItem> {
  return { type: scrConfigurationTypes.ADD_SCRAPER_CONFIGURATION, value }
}

export function editScraperConfigurationAction(value: ActionItem): IEditItemActionType<ActionItem> {
  return { type: scrConfigurationTypes.EDIT_SCRAPER_CONFIGURATION, value }
}

export function removeScraperConfigurationAction(value: string): IRemoveItemActionType {
  return { type: scrConfigurationTypes.REMOVE_SCRAPER_CONFIGURATION, value }
}

export function changeSelectedScraperConfigurationAction(
  value: string,
): IChangeSelectedItemActionType {
  return { type: scrConfigurationTypes.CHANGE_SCRAPER_CONFIGURATION_PENDING_EDIT, value }
}

export function clearSelectedScraperConfigurationAction(): IClearSelectedItemActionType {
  return { type: scrConfigurationTypes.CLEAR_SCRAPER_CONFIGURATION_PENDING_EDIT }
}

export function setScraperConfigurationsModificationStateAction(
  value: ModificationStatus,
): ISetModificationStateActionType {
  return { type: scrConfigurationTypes.SET_MODIFICATION_STATE_SCRAPER_CONFIGURATION, value }
}

export function setScraperConfigurationSomeErrorAction(value: string): ISetSomeErrorActionType {
  return { type: scrConfigurationTypes.SET_SOME_ERROR_SCRAPER_CONFIGURATION, value }
}
interface IGetItemsActionType<T> {
  type: string
  value: T[]
}

interface IAddItemActionType<T> {
  type: string
  value: T
}

interface IEditItemActionType<T> {
  type: string
  value: T
}

interface IRemoveItemActionType {
  type: string
  value: string
}

interface IChangeSelectedItemActionType {
  type: string
  value: string
}

interface IClearSelectedItemActionType {
  type: string
}

interface ISetModificationStateActionType {
  type: string
  value: ModificationStatus
}

interface ISetSomeErrorActionType {
  type: string
  value: string
}
