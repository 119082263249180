import React from 'react';
import { TabCardBodyProps } from '../../../common/components/types/CardBody.types';

const TabCardBodyAnalytics = ({ list }: TabCardBodyProps) => {
  if (!list) return <></>;

  return <div className="card-body p-0">{list}</div>;
};

export default TabCardBodyAnalytics;
