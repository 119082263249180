import React, { useState } from 'react'
import { PasswordProps } from './types/Passwod.types'

function PasswordRH<T>(props: PasswordProps<T>): JSX.Element {
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [max, min] = [props.max || 100, props.min || 0]
  const type = props.formState.errors[props.field]?.type ?? ''
  const message = (props.formState.errors[props.field]?.message as string) ?? ''
  const validClass = type && type !== 'custom' ? 'is-invalid' : ''

  const changeOutputPassword = () => {
    setShowPassword((prev) => !prev)
  }

  return (
    <div className='form-group'>
      <label htmlFor={props.id} className='form-label'>
        {props.label}
      </label>
      <div className='input-group'>
        <input
          type={showPassword ? 'text' : 'password'}
          className={`form-control ${props.inputClass || ''} ${props.validation ? validClass : ''}`}
          placeholder={props.placeholder || ''}
          required={Boolean(props.required)}
          id={props.id}
          {...props.register(props.field, {
            required: props.required || '',
            maxLength: {
              value: max,
              message: `The value ${props.field} cannot be longer than ${max} characters`,
            },
            minLength: {
              value: min,
              message: `The value ${props.field} cannot be less than ${min} characters`,
            },
          })}
        />
        <div className='input-group-append'>
          <a href={void 0} className='input-group-text' onClick={changeOutputPassword}>
            <i className={`fa ${showPassword ? 'fa-eye' : 'fa-eye-slash'}`} aria-hidden='true'></i>
          </a>
        </div>

        {message && props.required ? (
          <div className='invalid-feedback d-block m-0'>
            <small className='form-text text-danger'>{message}</small>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default PasswordRH
