import React from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import {
  getTgCurrenciesList,
  setTgCurrenciesSomeError,
} from '../../../../../store/dispatch/tg/tg-currencies.dispatch'
import { useErrorHandlerHook } from '../../../../../hooks/error-handler'
import { useHttpDateLoader } from '../../../../../hooks/request.hook'
import { RequestUrlTypes } from '../../../../../hooks/types/request.types'
import { TgCurrency } from '../../../../../store/models/tg/tg-currency.interface'
import { URL_SERVER_TG } from '../../../../../config'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setListTgCurrencies: bindActionCreators(getTgCurrenciesList, dispatch),
  setSomeErrorOptions: bindActionCreators(setTgCurrenciesSomeError, dispatch),
})

type PropsComponent = ReturnType<typeof mapDispatchToProps> & {
  children?: JSX.Element | JSX.Element[]
}

const TgInternalBankTabWrapper: React.FC<PropsComponent> = ({
  setListTgCurrencies,
  setSomeErrorOptions,
  children,
}): JSX.Element => {
  useHttpDateLoader<TgCurrency[]>({
    url: RequestUrlTypes.TG_CURRENCY,
    base: URL_SERVER_TG,
    func: setListTgCurrencies,
    errorHandler: useErrorHandlerHook(setSomeErrorOptions, 'Тг список валют'),
  })

  return <>{children}</>
}

export default connect(null, mapDispatchToProps)(TgInternalBankTabWrapper)
