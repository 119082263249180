import React from 'react'
import { TabCardBodyProps } from '../../../common/components/types/CardBody.types'
import ListIsEmpty from '../../../common/components/ListIsEmpty'

const TabCardBodyScraper = ({ list }: TabCardBodyProps) => {
  if (!list) return <ListIsEmpty />

  return <div className='card-body p-0'>{list}</div>
}

export default TabCardBodyScraper
