import React, { useContext } from 'react'
import { connect } from 'react-redux'
import { ScrapperTabCustomProps } from '../../../types/ScraperOptions.type'
import { bindActionCreators, Dispatch } from 'redux'
import { setScrServiceDiaryModificationState } from '../../../../../store/dispatch/scraper/scr-service-diary.dispatch'
import { addNotification } from '../../../../../store/dispatch/notifications.dispatch'
import { updateCurrentPath, updateLoaderState } from '../../../../../store/dispatch/root.dispatch'
import {
  getScrRentalRateDiaryList,
  setScrRentalRateDiarySomeError,
} from '../../../../../store/dispatch/scraper/scr-rental-rate-diary.dispatch'
import { useErrorHandlerHook } from '../../../../../hooks/error-handler'
import { useHttpDateLoader } from '../../../../../hooks/request.hook'
import { ScrRentalRateDiary } from '../../../../../store/models/scraper/scr-rental-rate-diary.interface'
import { RequestUrlTypes } from '../../../../../hooks/types/request.types'
import { URL_SERVER_SCRAPER } from '../../../../../config'
import { LineAtEvent } from '../../RenderLineChart'
import { ScrRentalRateDiaryContext } from '../ScrRentalRateDiaryContext'
import { OptionsReceiver } from '../../../../../utils/option-receiver'
import { StateType } from '../../../../../store/models/root.interface'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setModificationState: bindActionCreators(setScrServiceDiaryModificationState, dispatch),
  addNotification: bindActionCreators(addNotification, dispatch),
  updateCurrentPath: bindActionCreators(updateCurrentPath, dispatch),
  updateLoader: bindActionCreators(updateLoaderState, dispatch),
  setList: bindActionCreators(getScrRentalRateDiaryList, dispatch),
  setSomeError: bindActionCreators(setScrRentalRateDiarySomeError, dispatch),
})

const mapStateToProps = ({ scrRentalRateDiary, scrCredential }: StateType) => ({
  scrRentalRateDiary,
  scrCredential,
})

type PageProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  Pick<ScrapperTabCustomProps, 'renderTabHeader'>

const ScrRentalRateDiaryTabHeader: React.FC<PageProps> = ({
  scrRentalRateDiary,
  scrCredential,
  setList,
  setSomeError,
  renderTabHeader,
}) => {
  const getAccByLogin = OptionsReceiver.getValueByKey(scrCredential.list, 'login')
  const context = useContext(ScrRentalRateDiaryContext)
  const errorHandler = useErrorHandlerHook(setSomeError, 'Слоти')

  useHttpDateLoader<ScrRentalRateDiary[]>({
    url: RequestUrlTypes.SCRAPER_RENTAL_RATE_DIARY,
    base: URL_SERVER_SCRAPER,
    func: setList,
    errorHandler,
  })

  const callBack = (data: LineAtEvent): void => {
    const selected = scrRentalRateDiary.list.filter((option) => {
      const date = new Date(option.updatedAt).toLocaleString('uk-UA', {
        day: 'numeric',
        month: 'short',
        hour: '2-digit',
        minute: '2-digit',
      })

      const cred = getAccByLogin(data.label, '_id')

      return date === data.dataLabel && cred === option.account
    })

    context.updateSelectedList(selected)
  }

  return (
    <>
      {renderTabHeader}
      {/* <ScrServiceDiaryLineChart*/}
      {/*  callBack={callBack}*/}
      {/*  list={scrRentalRateDiary.list}*/}
      {/*  title='Аналітика по змінам відсотків по дням'*/}
      {/* />*/}
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ScrRentalRateDiaryTabHeader)
