import { ModificationStatus } from '../../models/root.interface'
import { GsmOptionsSender } from '../../models/gsm/gsm-options-sender.interface'
import { gsmOptionsSenderTypes } from '../../types/gsm/gsm-options-sender.types'

type ActionItem = GsmOptionsSender

export function getGsmOptionsSendersListAction(
  value: ActionItem[],
): IGetItemsActionType<ActionItem> {
  return { type: gsmOptionsSenderTypes.GET_ITEMS_LIST, value }
}

export function addGsmOptionsSenderAction(value: ActionItem): IAddItemActionType<ActionItem> {
  return { type: gsmOptionsSenderTypes.ADD_NEW_ITEM, value }
}

export function editGsmOptionsSenderAction(value: ActionItem): IEditItemActionType<ActionItem> {
  return { type: gsmOptionsSenderTypes.EDIT_ITEM, value }
}

export function removeGsmOptionsSenderAction(value: string): IRemoveItemActionType {
  return { type: gsmOptionsSenderTypes.REMOVE_ITEM, value }
}

export function changeSelectedGsmOptionsSenderAction(value: string): IChangeSelectedItemActionType {
  return { type: gsmOptionsSenderTypes.CHANGE_ITEM_PENDING_EDIT, value }
}

export function clearSelectedGsmOptionsSenderAction(): IClearSelectedItemActionType {
  return { type: gsmOptionsSenderTypes.CLEAR_ITEM_PENDING_EDIT }
}

export function setGsmOptionsSendersModificationStateAction(
  value: ModificationStatus,
): ISetModificationStateActionType {
  return { type: gsmOptionsSenderTypes.SET_ITEM_MODIFICATION_STATE, value: value }
}

export function setGsmOptionsSendersSomeErrorAction(value: string): ISetSomeErrorActionType {
  return { type: gsmOptionsSenderTypes.SET_ITEMS_SOME_ERROR, value: value }
}

interface IGetItemsActionType<T> {
  type: string
  value: T[]
}

interface IAddItemActionType<T> {
  type: string
  value: T
}

interface IEditItemActionType<T> {
  type: string
  value: T
}

interface IRemoveItemActionType {
  type: string
  value: string
}

interface IChangeSelectedItemActionType {
  type: string
  value: string
}

interface IClearSelectedItemActionType {
  type: string
}

interface ISetModificationStateActionType {
  type: string
  value: ModificationStatus
}

interface ISetSomeErrorActionType {
  type: string
  value: string
}
