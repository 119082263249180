import React from 'react'
import LeftMenu from '../LeftMenu/LeftMenu'
import TopMenu from '../TopMenu/TopMenu'
import { Route, Routes } from 'react-router-dom'
import Users from '../Users/Users'
import Notifications from '../../common/components/Notification'
import TgOptions from '../TgOptions/TgOptions'
import TgCustomers from '../TgCustomers/TgCustomers'
import GsmOptions from '../GsmOptions/GsmOptions'
import ScraperOptions from '../ScrapperOptions/ScraperOptions'
import GsmAnalyticsTab from '../GsmAnalytics/GsmAnalyticsTab'

const Admin = () => {
  return (
    <>
      <Notifications />
      <LeftMenu />
      <div id='content-wrapper' className='d-flex flex-column'>
        <div id='content'>
          <TopMenu />
          <div className='container-fluid'>
            <Routes>
              <Route path='/'>
                <Route path='users' element={<Users />} />
                <Route path='tg-options' element={<TgOptions />} />
                <Route path='tg-customers' element={<TgCustomers />} />
                <Route path='gsm-options' element={<GsmOptions />} />
                <Route path='gsm-analytic' element={<GsmAnalyticsTab />} />
                <Route path='scraper-options' element={<ScraperOptions />} />
              </Route>
            </Routes>
          </div>
        </div>
      </div>
    </>
  )
}

export default Admin
