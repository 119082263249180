import React, { useRef } from 'react'
import { Chart, InteractionItem } from 'chart.js'
import { getDatasetAtEvent, getElementAtEvent, Line } from 'react-chartjs-2'

export class SaleData {
  label = ''
  value = 0
}

export class Dataset {
  title = ''
  sales: SaleData[] = []
  borderColor = 'rgb(53, 162, 235)'
}

export class LineAtDataset {
  datasets: Dataset[] = []
  labels: string[] = []
}

export class LineAtEvent {
  label = ''
  dataLabel = ''
  dataValue = 0
}

const RenderLineChart = ({
  labels,
  datasets,
  title,
  callBack,
}: {
  title: string
  callBack?: (value: LineAtEvent) => void
} & LineAtDataset) => {
  const chartRef = useRef<Chart<'line', number[], string>>(null)

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: title,
      },
    },
  }

  const data = {
    labels,
    datasets: datasets.map((data) => {
      return {
        label: data.title,
        data: data.sales.map((el) => el.value),
        borderColor: data.borderColor,
        borderWidth: 2,
      }
    }),
  }

  const printDatasetAtEvent = (dataset: InteractionItem[], fortified: LineAtEvent) => {
    if (!dataset.length) return

    const datasetIndex = dataset[0].datasetIndex
    fortified.label = data.datasets[datasetIndex].label
  }
  const printElementAtEvent = (element: InteractionItem[], fortified: LineAtEvent) => {
    if (!element.length) return

    const { datasetIndex, index } = element[0]

    fortified.dataLabel = data.labels[index]
    fortified.dataValue = data.datasets[datasetIndex].data[index]
  }
  const onClick = (event: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    const { current: chart } = chartRef

    if (!chart) {
      return
    }

    if (!callBack) {
      return
    }

    const fortified = new LineAtEvent()
    printDatasetAtEvent(getDatasetAtEvent(chart, event), fortified)
    printElementAtEvent(getElementAtEvent(chart, event), fortified)

    callBack(fortified)
  }

  return <Line options={options} data={data} ref={chartRef} onClick={onClick} />
}
export default RenderLineChart
