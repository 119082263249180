export const rentalRatesTypes = {
  GET_RENTAL_RATES: 'GET_RENTAL_RATES',
  ADD_RENTAL_RATE: 'ADD_RENTAL_RATE',
  EDIT_RENTAL_RATE: 'EDIT_RENTAL_RATE',
  REMOVE_RENTAL_RATE: 'REMOVE_RENTAL_RATE',
  CHANGE_RENTAL_RATE_PENDING_EDIT: 'CHANGE_RENTAL_RATE_PENDING_EDIT',
  CLEAR_RENTAL_RATE_PENDING_EDIT: 'CLEAR_RENTAL_RATE_PENDING_EDIT',
  SET_RENTAL_RATE_MODIFICATION_STATE: 'SET_RENTAL_RATE_MODIFICATION_STATE',
  SET_RENTAL_RATE_SOME_ERROR: 'SET_RENTAL_RATE_SOME_ERROR',
}
