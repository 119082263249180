import React from 'react'
import { TabCardBottomProps } from './types/CardBottom.types'
const TabCardBottom = <T,>({ list, bottomDescription }: TabCardBottomProps<T>) => {
  if (!list) return <></>

  return (
    <div className='card card-bottom-list shadow mb-4'>
      <div className='card-header py-3'>
        <h6 className='m-0 font-weight-bold text-green'>{bottomDescription}</h6>
      </div>
      {list}
    </div>
  )
}

export default TabCardBottom
