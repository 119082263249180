export const tgOptionsCountryTypes = {
  GET_TG_OPTIONS_COUNTRY_LIST: 'GET_TG_OPTIONS_COUNTRY_LIST',
  ADD_TG_OPTIONS_COUNTRY_LIST: 'ADD_TG_OPTIONS_COUNTRY_LIST',
  EDIT_TG_OPTIONS_COUNTRY_LIST: 'EDIT_TG_OPTIONS_COUNTRY_LIST',
  REMOVE_TG_OPTIONS_COUNTRY_LIST: 'REMOVE_TG_OPTIONS_COUNTRY_LIST',
  CHANGE_TG_OPTIONS_COUNTRY_PENDING_EDIT: 'CHANGE_TG_PENDING_EDIT',

  CLEAR_TG_OPTIONS_COUNTRY_PENDING_EDIT: 'CLEAR_TG_OPTIONS_COUNTRY_PENDING_EDIT',
  SET_TG_OPTIONS_COUNTRY_MODIFICATION_STATE: 'SET_TG_OPTIONS_COUNTRY_MODIFICATION_STATE',
  SET_TG_OPTIONS_COUNTRY_SOME_ERROR: 'SET_TG_OPTIONS_COUNTRY_SOME_ERROR',
}
