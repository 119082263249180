import React, { useEffect } from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { OptionTypeDetails } from '../../../../common/components/types/Tab.types'
import OptionsTab from '../../../../common/components/Tab'
import { TgCurrency } from '../../../../store/models/tg/tg-currency.interface'
import { useHttpDeleteRequest } from '../../../../hooks/request.hook'
import { RequestUrlTypes } from '../../../../hooks/types/request.types'
import {
  clearSelectedTgCurrency,
  removeTgCurrency,
  setTgCurrenciesModificationState,
  setTgCurrenciesSomeError,
} from '../../../../store/dispatch/tg/tg-currencies.dispatch'
import { updateCurrentPath, updateLoaderState } from '../../../../store/dispatch/root.dispatch'
import { addNotification } from '../../../../store/dispatch/notifications.dispatch'
import { StateType, ModificationStatus } from '../../../../store/models/root.interface'
import { connect } from 'react-redux'
import TgCurrencyForm from './TgCurrencyForm'
import TgCurrenciesList from './TgCurrenciesList'
import { TgOptionType, TgTabCustomProps } from '../../types/TgOptions.types'
import { useErrorHandlerHook } from '../../../../hooks/error-handler'
import TabCardBodyTgOptions from '../TabCardBodyTgOptions'
import { URL_SERVER_TG } from '../../../../config'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  remove: bindActionCreators(removeTgCurrency, dispatch),
  clearSelected: bindActionCreators(clearSelectedTgCurrency, dispatch),
  setModificationState: bindActionCreators(setTgCurrenciesModificationState, dispatch),
  setSomeError: bindActionCreators(setTgCurrenciesSomeError, dispatch),
  updateCurrentPath: bindActionCreators(updateCurrentPath, dispatch),
  addNotification: bindActionCreators(addNotification, dispatch),
  updateLoader: bindActionCreators(updateLoaderState, dispatch),
})

const mapStateToProps = ({ tgCurrencies, root }: StateType) => ({
  currencies: tgCurrencies,
  path: root.page,
})

type PropsPage = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  TgTabCustomProps

const TgCurrenciesTab: React.FC<PropsPage> = ({
  currencies,
  path,
  remove,
  clearSelected,
  setModificationState,
  updateLoader,
  addNotification,
  setSomeError,
  renderTabHeader,
  type,
}) => {
  const errorHandler = useErrorHandlerHook(setSomeError, 'Валюта')
  const deleteRequest = useHttpDeleteRequest({
    url: RequestUrlTypes.TG_CURRENCY,
    base: URL_SERVER_TG,
    func: remove,
  })

  useEffect(() => {
    clearSelected()
  }, [path.area])

  const onRemove = async () => {
    const { selected } = currencies
    if (!selected) {
      return
    }

    try {
      await deleteRequest({ id: selected._id })
      setModificationState(ModificationStatus.None)
      addNotification('Валюту', `${selected.currencyA} видалено`)
      clearSelected()
    } catch (e: any) {
      errorHandler({ text: e.message })
    } finally {
      updateLoader(false)
    }
  }

  const details: OptionTypeDetails<TgCurrency> = {
    ...currencies,
    onRemove,
    setModificationState,
    headerTitle: 'Список валют',
    cardFormClass: 'col-xl-5 col-lg-5',
    cardTopClass: 'col-xl-7 col-lg-7',
    cardWrapperClass: 'row mt-5 flex-row-reverse',
  }

  if (type !== TgOptionType.CURRENCY) return <></>

  return (
    <OptionsTab
      details={details}
      renderForm={<TgCurrencyForm />}
      topList={<TgCurrenciesList />}
      renderTabHeader={renderTabHeader}
      renderCardBody={TabCardBodyTgOptions}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(TgCurrenciesTab)
