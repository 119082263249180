import { combineReducers, Reducer } from 'redux'
import { IActionBase, RootStateType, StateType } from '../models/root.interface'
import notificationReducer from './notification.reducer'
import userReducer from './users.reducer'
import accountReducer from './account.reducer'
import servicesReducer from './scraper/scr-service.reducer'
import slotsReducer from './scraper/scr-slot.reducer'
import rentalRateReducer from './scraper/scr-rental-rate.reducer'
import { rootTypes } from '../types/root.types'
import tgMailingReducer from './tg/tg-mailing.reducer'
import tgCurrenciesReducer from './tg/tg-currencies.reducer'
import TgPromoCodesReducer from './tg/tg-promo-codes.reducer'
import TgPartnerProgramReducer from './tg/tg-partner-program.reducer'
import tgOptionsCountryReducer from './tg/tg-options-country.reducer'
import TgOptionsServiceReducer from './tg/tg-options-service.reducer'
import TgInternalBanksReducer from './tg/tg-internal-banks.reducer'
import TgCustomersReducer from './tg/tg-customers.reducer'
import gsmOptionsCountryReducer from './gsm/gsm-options-country.reducer'
import gsmOptionsSenderReducer from './gsm/gsm-options-sender.reducer'
import gsmOptionsServiceReducer from './gsm/gsm-options-service.reducer'
import gsmAnalyticsReducer from './gsm/gsm-analytics.reducer'
import scrRentalRateDiaryReducer from './scraper/scr-rental-rate-diary.reducer'
import scrServiceDiaryReducer from './scraper/scr-service-diary.reducer'
import scrConfigurationReducer from './scraper/scr-configuration.reducer'
import scrCredentialReducer from './scraper/scr-credential.reducer'
import ScrOptionsReducer from './scraper/scr-options.reducer'

const initialState: RootStateType = {
  page: { area: 'home', subArea: '' },
  error: '',
  loader: false,
}

function rootReducer(state: RootStateType = initialState, action: IActionBase): RootStateType {
  switch (action.type) {
    case rootTypes.UPDATE_CURRENT_PATH:
      return { ...state, page: { area: action.area, subArea: action.subArea } }
    case rootTypes.UPDATE_LOADER_STATE:
      return { ...state, loader: action.value }
    case rootTypes.SET_SOME_ERROR:
      return { ...state, page: { area: action.area, subArea: action.subArea } }
    case rootTypes.REMOVE_SOME_ERROR:
      return { ...state, page: { area: action.area, subArea: action.subArea } }
    default:
      return state
  }
}

const rootReducers: Reducer<StateType> = combineReducers({
  root: rootReducer,
  notifications: notificationReducer,
  users: userReducer,
  account: accountReducer,
  scrService: servicesReducer,
  scrOptions: ScrOptionsReducer,
  scrSlot: slotsReducer,
  scrRentalRate: rentalRateReducer,
  scrCredential: scrCredentialReducer,
  scrConfiguration: scrConfigurationReducer,
  scrRentalRateDiary: scrRentalRateDiaryReducer,
  scrServiceDiary: scrServiceDiaryReducer,
  tgMailing: tgMailingReducer,
  tgOptionsCountry: tgOptionsCountryReducer,
  tgOptionsService: TgOptionsServiceReducer,
  tgCurrencies: tgCurrenciesReducer,
  tgPromoCodes: TgPromoCodesReducer,
  tgPartnerPrograms: TgPartnerProgramReducer,
  tgInternalBank: TgInternalBanksReducer,
  tgCustomers: TgCustomersReducer,
  gsmOptionsCountry: gsmOptionsCountryReducer,
  gsmOptionsSender: gsmOptionsSenderReducer,
  gsmOptionsService: gsmOptionsServiceReducer,
  gsmAnalytics: gsmAnalyticsReducer,
})

export default rootReducers
