import React, { Fragment } from 'react'
import TopCard from '../../../../../common/components/TopCard'
import { StateType } from '../../../../../store/models/root.interface'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import {
  changeSelectedScraperCredential,
  clearSelectedScraperCredential,
} from '../../../../../store/dispatch/scraper/scr-credential.dispatch'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  clearSelectedScraperCredential: bindActionCreators(clearSelectedScraperCredential, dispatch),
  changeSelected: bindActionCreators(changeSelectedScraperCredential, dispatch),
})

const mapStateToProps = ({ scrService, scrCredential }: StateType) => ({
  services: scrService.list,
  credentials: scrCredential.list,
})

type PropsListOfServiceAccountsComponent = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const ListOfScrServiceAccounts: React.FC<PropsListOfServiceAccountsComponent> = ({
  services,
  credentials,
}) => {
  const servicesAccounts = services.map((el) => el.account)
  const accountUniqIdsFromServices = Array.from(new Set(servicesAccounts)).filter((el) => el)

  return (
    <div className='row'>
      {accountUniqIdsFromServices.map((account, index) => {
        if (!account) return <Fragment key={index}></Fragment>

        const foundAccount = credentials.find((item) => {
          return String(item._id) === account
        })

        if (!foundAccount) return <Fragment key={index}></Fragment>

        return (
          <TopCard
            key={foundAccount._id}
            title={`EMAIL ID (${foundAccount._id})`}
            text={foundAccount.login.slice(0, 22) + '...'}
            additionText={foundAccount.apiKey || ''}
            icon='warehouse'
            class='primary'
            classWrapper='col-xl-4'
          />
        )
      })}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ListOfScrServiceAccounts)
