import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { useHttpDateLoader } from '../../../../hooks/request.hook'
import { RequestUrlTypes } from '../../../../hooks/types/request.types'
import { useErrorHandlerHook } from '../../../../hooks/error-handler'
import { URL_SERVER_TG } from '../../../../config'
import {
  getTgMailingsList,
  setTgMailingsSomeError,
} from '../../../../store/dispatch/tg/tg-mailing.dispatch'
import { TgMailingMessage } from '../../../../store/models/tg/tg-mailing.interface'
import RenderListTgMailing from './components/RenderListTgMailing'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setList: bindActionCreators(getTgMailingsList, dispatch),
  setSomeError: bindActionCreators(setTgMailingsSomeError, dispatch),
})

type PropsComponent = ReturnType<typeof mapDispatchToProps>
const TgMailingList: React.FC<PropsComponent> = ({ setList, setSomeError }): JSX.Element => {
  const errorHandler = useErrorHandlerHook(setSomeError, 'Тг опції')

  useHttpDateLoader<TgMailingMessage[]>({
    url: RequestUrlTypes.TG_MAILING,
    base: URL_SERVER_TG,
    func: setList,
    errorHandler,
  })

  return <RenderListTgMailing />
}

export default connect(null, mapDispatchToProps)(TgMailingList)
