import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { ModificationStatus, StateType } from '../../../../store/models/root.interface'
import { bindActionCreators, Dispatch } from 'redux'
import { addNotification } from '../../../../store/dispatch/notifications.dispatch'
import { updateCurrentPath, updateLoaderState } from '../../../../store/dispatch/root.dispatch'
import {
  clearSelectedScraperCredential,
  removeScraperCredential,
  setScraperCredentialsModificationState,
  setScraperCredentialsSomeError,
} from '../../../../store/dispatch/scraper/scr-credential.dispatch'
import ScraperCredentialsList from './CredentialList'
import { RequestUrlTypes } from '../../../../hooks/types/request.types'
import { useHttpDeleteRequest } from '../../../../hooks/request.hook'
import { OptionTypeDetails } from '../../../../common/components/types/Tab.types'
import OptionsTab from '../../../../common/components/Tab'
import CredentialForm from './CredentialForm'
import { useErrorHandlerHook } from '../../../../hooks/error-handler'
import { ScrCredential } from '../../../../store/models/scraper/scr-credential.interface'
import { ScrapperOptionType, ScrapperTabCustomProps } from '../../types/ScraperOptions.type'
import { URL_SERVER_SCRAPER } from '../../../../config'
import TabCardBodyScraper from '../ScrCardBody'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  remove: bindActionCreators(removeScraperCredential, dispatch),
  clearSelected: bindActionCreators(clearSelectedScraperCredential, dispatch),
  setModificationState: bindActionCreators(setScraperCredentialsModificationState, dispatch),
  setSomeError: bindActionCreators(setScraperCredentialsSomeError, dispatch),
  updateCurrentPath: bindActionCreators(updateCurrentPath, dispatch),
  addNotification: bindActionCreators(addNotification, dispatch),
  updateLoader: bindActionCreators(updateLoaderState, dispatch),
})

const mapStateToProps = ({ scrCredential, root }: StateType) => ({
  credential: scrCredential,
  path: root.page,
})

type PageProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  ScrapperTabCustomProps

const CredentialTab: React.FC<PageProps> = ({
  credential,
  path,
  remove,
  clearSelected,
  setModificationState,
  setSomeError,
  addNotification,
  renderTabHeader,
  updateLoader,
  type,
}) => {
  const errorHandler = useErrorHandlerHook(setSomeError, 'Облікові записи')
  const deleteRequest = useHttpDeleteRequest({
    url: RequestUrlTypes.SCRAPER_CREDENTIAL,
    base: URL_SERVER_SCRAPER,
    func: remove,
  })

  useEffect(() => {
    clearSelected()
  }, [path.area])

  const onRemove = async () => {
    const { selected } = credential

    if (!selected) {
      return
    }

    try {
      await deleteRequest({ id: selected._id })
      addNotification('Обліковий запис', 'видалено')
      clearSelected()
      setModificationState(ModificationStatus.None)
    } catch (e: any) {
      errorHandler({ text: e.message })
    } finally {
      updateLoader(false)
    }
  }

  const details: OptionTypeDetails<ScrCredential> = {
    ...credential,
    onRemove,
    setModificationState,
    headerTitle: 'Список облікових записів',
    cardFormClass: 'col-xl-5 col-lg-5',
    cardTopClass: 'col-xl-7 col-lg-7',
    cardWrapperClass: 'row mt-5 flex-row-reverse',
  }

  if (type !== ScrapperOptionType.SCR_CREDENTIAL) return <></>

  return (
    <OptionsTab
      details={details}
      renderForm={<CredentialForm />}
      topList={<ScraperCredentialsList />}
      renderCardBody={TabCardBodyScraper}
      renderTabHeader={renderTabHeader}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(CredentialTab)
