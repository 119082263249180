import React, { useContext } from 'react'
import {
  CompetingPrice,
  ScrServiceDiary,
} from '../../../../../store/models/scraper/scr-service-diary.interface'
import { ScrServiceDiaryContext } from '../ScrServiceDiaryContext'
import { OptionsReceiver } from '../../../../../utils/option-receiver'
import { useSelector } from 'react-redux'
import { StateType } from '../../../../../store/models/root.interface'

const RenderScrServiceDiaryCompetitors = (): JSX.Element => {
  const context = useContext(ScrServiceDiaryContext)
  const scrCredential = useSelector((state: StateType) => {
    return state.scrCredential
  })

  const getAccById = OptionsReceiver.getValueByKey(scrCredential.list, '_id')

  const list = context.listByCost
  if (!list.length) return <></>

  const competitors = list.reduce<CompetingPrice[]>((prev, curr) => {
    let data = [...prev, ...curr.competitors]
    data = data.sort((a, b) => a.price - b.price)

    return OptionsReceiver.uniqModels(data, (op) => op.price)
  }, [])

  const productElements = competitors.map((element: CompetingPrice, index: number) => {
    return (
      <tr className='table-row' key={crypto.randomUUID()}>
        <th scope='row'>{++index}</th>
        <td>{element.price}</td>
        <td>{element.quantity}</td>
      </tr>
    )
  })

  const columnByKey = (key: keyof ScrServiceDiary) => {
    return list.map((option) => {
      const value = String(option[key])
      return <td key={value}>{String(option[key])}</td>
    })
  }

  const columnByDate = () => {
    return list.map((option) => {
      const date = new Date(option.updatedAt).toLocaleString('uk-UA', {
        day: 'numeric',
        month: 'short',
        hour: '2-digit',
        minute: '2-digit',
      })

      return <td key={crypto.randomUUID()}>{date}</td>
    })
  }

  return (
    <div className='card shadow mb-4 mt-4'>
      <div className='card-header py-3'>
        <h6 className='m-0 font-weight-bold text-green'></h6>
      </div>
      <div className='card-body row'>
        <ol className='ml-3'>
          {list.map((option, index) => {
            return (
              <li key={index}>
                <p>
                  Акк {++index} - {getAccById(option.account, 'login')}
                </p>
              </li>
            )
          })}
        </ol>
        <div className='table-responsive portlet col-md-7 p-1 table-bordered'>
          <table className='table'>
            <thead className='thead-light'>
              <tr>
                <th scope='col'>Парметри</th>
                {list.map((option, index) => {
                  return (
                    <th scope='col' key={index}>
                      Акк {++index}
                    </th>
                  )
                })}
              </tr>
            </thead>
            <tbody>
              <tr className='table-row'>
                <td>Стара ціна</td>
                {columnByKey('cost')}
              </tr>
              <tr className='table-row'>
                <td>Нова ціна</td>
                {columnByKey('newCost')}
              </tr>
              <tr className='table-row'>
                <td>К-сть. слоті</td>
                {columnByKey('count')}
              </tr>
              <tr className='table-row'>
                <td>Мін. ціна</td>
                {columnByKey('minPrice')}
              </tr>
              <tr className='table-row'>
                <td>Макс. ціна</td>
                {columnByKey('maxPrice')}
              </tr>
              <tr className='table-row'>
                <td>Мін. к-сть. слотів</td>
                {columnByKey('minCount')}
              </tr>
              <tr className='table-row'>
                <td>Зміна ціни</td>
                {columnByDate()}
              </tr>
            </tbody>
          </table>
        </div>
        <div className='table-responsive portlet col-md-5 p-1 table-bordered'>
          <table className='table'>
            <thead className='thead-light'>
              <tr>
                <th scope='col'>№</th>
                <th scope='col'>Ціна</th>
                <th scope='col'>К-сть. слотів</th>
              </tr>
            </thead>
            <tbody>{productElements}</tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default RenderScrServiceDiaryCompetitors
