import React, { PropsWithChildren, ReactElement } from 'react'
import { ICardProperties } from '../types/TopCard.types'

const TopCard = (props: PropsWithChildren<ICardProperties>): ReactElement => {
  return (
    <div className={props.classWrapper ? props.classWrapper : 'col-xl-3 col-md-6 mb-4'}>
      <div
        className={`card border-left-${props.class} shadow h-100 py-2`}
        onClick={props.onClick}
        style={props.onClick && { cursor: 'pointer' }}
      >
        <div className='card-body'>
          <div className='row no-gutters align-items-center pr-1'>
            <div className='col mx-2 pl-1'>
              <div className='text-xs font-weight-bold text-green text-uppercase mb-1'>
                {props.title}
              </div>
              <div className='h5 mb-0 font-weight-bold text-gray-800'>{props.text}</div>
            </div>
            <div className='col-auto'>
              <i className={`fas fa-${props.icon} fa-2x text-gray-300`}></i>
            </div>
          </div>
          {props.additionText ? <p className='pt-1'>{props.additionText}</p> : <></>}
        </div>
      </div>
    </div>
  )
}

export default TopCard
