import { UseFormReturn } from 'react-hook-form/dist/types/form'
import { PartnerProgram } from '../../../../../store/models/tg/tg-partner-program.interface'
import TextRH from '../../../../../common/form-hook/Text'
import React from 'react'

const RenderFromToCondition = (
  props: Pick<UseFormReturn<PartnerProgram>, 'watch' | 'register' | 'formState'>,
) => {
  if (props.watch('condition') !== 'only-from') return <></>

  return (
    <>
      <div className='form-group col-md-3'>
        <TextRH
          {...props}
          type='number'
          step={0.1}
          max={1000000}
          id='input_condition_value_from'
          label='Значення з'
          field='conditionValue.from'
          required='Value condition value from cannot be empty'
          placeholder='0.00'
        />
      </div>
    </>
  )
}

export default RenderFromToCondition
