import { OptionTabProps } from '../../../common/components/types/Tab.types'
import TabCardBottom from '../../../common/components/CardBottom'
import TabInterrogativePopup from '../../../common/components/TabInterrogativePopup'
import TabCardHeader from '../../../common/components/CardHeader'
import TabCardBody from '../../../common/components/CardBody'
import CardFormWrapper from '../../../common/components/CardFormWrapper'

type OptionsTabAnalyticsProps<T> = OptionTabProps<T> & {
  renderBlockAboveCard?: JSX.Element | undefined
}
const OptionsTabAnalytics = <T,>({
  details,
  renderTabHeader,
  renderForm,
  topList,
  bottomList,
  renderBlockAboveCard = <></>,
  interrogativePopup = TabInterrogativePopup,
  cardHeader = TabCardHeader,
  renderCardBody = TabCardBody,
  cardFormWrapper = CardFormWrapper,
  renderCardBottom = TabCardBottom,
}: OptionsTabAnalyticsProps<T>) => {
  const cardWrapperClass = details?.cardWrapperClass ?? 'row mt-5'
  const cardTopClass = details?.cardTopClass ?? 'col-xl-12 col-lg-12'
  const cardFormClass = details?.cardFormClass ?? 'col-md-6'
  const cardBottomClass = details?.cardBottomClass ?? 'col-md-6'

  return (
    <>
      {renderTabHeader}
      <div className={cardWrapperClass}>
        <div className={cardTopClass}>
          {renderBlockAboveCard}
          <div className='card shadow mb-4'>
            <>{cardHeader(details)}</>
            <>{renderCardBody({ list: topList })}</>
          </div>
        </div>
        <div className={cardFormClass}>{cardFormWrapper({ ...details, form: renderForm })}</div>
        <div className={cardBottomClass}>{renderCardBottom({ ...details, list: bottomList })}</div>
      </div>
      {interrogativePopup(details)}
    </>
  )
}

export default OptionsTabAnalytics
