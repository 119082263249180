import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { StateType, ModificationStatus } from '../../../../../store/models/root.interface'
import {
  changeSelectedScrService,
  clearSelectedScrService,
  setScrServiceModificationState,
} from '../../../../../store/dispatch/scraper/scr-service.dispatch'
import { ScrService } from '../../../../../store/models/scraper/scr-service.interface'
import ListIsEmpty from '../../../../../common/components/ListIsEmpty'
import useFilteringListHook from '../../../../../hooks/filter-hook'
import { OptionsReceiver } from '../../../../../utils/option-receiver'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  changeSelected: bindActionCreators(changeSelectedScrService, dispatch),
  setModificationState: bindActionCreators(setScrServiceModificationState, dispatch),
  clearSelected: bindActionCreators(clearSelectedScrService, dispatch),
})

const mapStateToProps = ({ scrService, scrCredential, scrOptions, scrSlot }: StateType) => ({
  scrService: scrService,
  credentials: scrCredential.list,
  options: scrOptions,
  scrSlot: scrSlot.list,
})

type PropsServicesListComponent = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const RenderListScrServices: React.FC<PropsServicesListComponent> = ({
  scrSlot,
  options,
  scrService,
  credentials,
  changeSelected,
  setModificationState,
  clearSelected,
}): JSX.Element => {
  const getServiceName = OptionsReceiver.getValueByKey(options.servicesList, 'shortName')
  const getCountryName = OptionsReceiver.getValueByKey(options.countriesList, 'shortName')
  const getSlotByCountry = OptionsReceiver.getValueByKey(scrSlot, 'country')

  const { selected } = scrService
  const rowSelectedClass = (id: string) => (selected && selected._id === id ? 'selected' : '')

  const servicesList = useFilteringListHook(scrService.list, scrService.filters)

  const onSelect = (item: ScrService): void => {
    if (selected && selected._id === item._id) {
      clearSelected()
    } else {
      changeSelected(item._id || '')
    }

    setModificationState(ModificationStatus.None)
  }
  const getCredentialLogin = (account: string) => {
    const founded = credentials.find((item) => String(item._id) === account)

    return founded?.login || 'not found'
  }

  if (!servicesList.length) return <ListIsEmpty />

  const productElements = servicesList.map((service, index) => {
    return (
      <tr
        className={`table-row ${rowSelectedClass(service._id || '')}`}
        onClick={() => onSelect(service)}
        key={`product_${service._id}`}
      >
        <th scope='row'>{++index}</th>
        <td data-id={service.name}>{getServiceName(service.name, 'name').toUpperCase()}</td>
        <td data-id={service.country}>{getCountryName(service.country, 'name').toUpperCase()}</td>
        <td>{service.minPrice.toFixed(2)}</td>
        <td>{service?.maxPrice?.toFixed(2) ?? 0}</td>
        <td>{service?.minCount ?? 0}</td>
        <td>{getSlotByCountry(service.country, 'amount')}</td>
        <td>{service.step.toFixed(2)}</td>
        <td>
          <i className={`fas fa-solid ${service.status ? 'fa-plus' : 'fa-minus'}`}></i>
        </td>
        {service.account && <td>{getCredentialLogin(service.account)}</td>}
      </tr>
    )
  })

  return (
    <div className='table-responsive portlet'>
      <table className='table'>
        <thead className='thead-light'>
          <tr>
            <th scope='col'>№</th>
            <th scope='col'>Сервіс</th>
            <th scope='col'>Країна</th>
            <th scope='col'>Мін. ціна</th>
            <th scope='col'>Макс. ціна</th>
            <th scope='col'>Мін. к-сть.</th>
            <th scope='col'>Слоти</th>
            <th scope='col'>крок</th>
            <th scope='col'>Статус</th>
            {servicesList.some((item) => item.account) && <th scope='col'>Акаунт</th>}
          </tr>
        </thead>
        <tbody>{productElements}</tbody>
      </table>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(RenderListScrServices)
