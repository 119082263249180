import React from 'react'
import { connect } from 'react-redux'
import { StateType, ModificationStatus } from '../../../../../store/models/root.interface'
import { bindActionCreators, Dispatch } from 'redux'
import {
  changeSelectedScraperCredential,
  clearSelectedScraperCredential,
  setScraperCredentialsModificationState,
} from '../../../../../store/dispatch/scraper/scr-credential.dispatch'
import ListIsEmpty from '../../../../../common/components/ListIsEmpty'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  clearSelected: bindActionCreators(clearSelectedScraperCredential, dispatch),
  changeSelected: bindActionCreators(changeSelectedScraperCredential, dispatch),
  setModificationState: bindActionCreators(setScraperCredentialsModificationState, dispatch),
})

const mapStateToProps = ({ scrCredential }: StateType) => ({ credential: scrCredential })

type PropsScraperCredentialsListComponent = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const ScraperCredentialsList: React.FC<PropsScraperCredentialsListComponent> = ({
  credential: { list, selected },
  clearSelected,
  changeSelected,
  setModificationState,
}): JSX.Element => {
  const onSelect = (id: string): void => {
    if (selected && selected._id === id) {
      clearSelected()
    } else {
      changeSelected(id)
    }

    setModificationState(ModificationStatus.None)
  }

  if (!list.length) return <ListIsEmpty />

  const selectedClass = (id: string) => (selected && selected._id === id ? 'selected' : '')

  const data: (JSX.Element | null)[] = list.map((item, i) => {
    if (!item) {
      return null
    }

    return (
      <tr
        className={`table-row text-center ${selectedClass(item._id || '')}`}
        onClick={() => onSelect(item._id || '')}
        key={`credential_${item._id}`}
      >
        <td>{++i}</td>
        <td data-id={item.login}>{item.login}</td>
        <td data-id={item.apiKey}>{item.apiKey}</td>
        <td data-id={item.password}>{item.password}</td>
        <td>
          <i className={`fas fa-solid ${item.status ? 'fa-plus' : 'fa-minus'}`}></i>
        </td>
        <td>
          <i className={`fas fa-solid ${item.inPriority ? 'fa-plus' : 'fa-minus'}`}></i>
        </td>
      </tr>
    )
  })

  return (
    <div className='table-responsive portlet'>
      <table className='table'>
        <thead className='thead-light'>
          <tr className='text-center'>
            <th scope='col'>№</th>
            <th scope='col'>Логін</th>
            <th scope='col'>Апі ключ</th>
            <th scope='col'>Пароль</th>
            <th scope='col'>Активний</th>
            <th scope='col'>В приорітеті</th>
          </tr>
        </thead>
        <tbody>{data}</tbody>
      </table>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ScraperCredentialsList)
