import { GsmAnalyticsState, IActionBase, ModificationStatus } from '../../models/root.interface'
import { gsmAnalyticsTypes } from '../../types/gsm/gsm-analytics.types'
import { GsmAnalyticsOptionFilters } from '../../models/gsm/gsm-analytics.interface'

type State = GsmAnalyticsState

const filters: GsmAnalyticsOptionFilters = { country: [], modem: [] }

const initialState: State = {
  error: null,
  modificationState: ModificationStatus.Edit,
  selected: null,
  list: [],
  filters,
}

export default (state: State = initialState, action: IActionBase): State => {
  switch (action.type) {
    case gsmAnalyticsTypes.GET_ITEMS_LIST: {
      return { ...state, error: null, list: action.value, selected: null }
    }
    case gsmAnalyticsTypes.CHANGE_ITEM_PENDING_EDIT: {
      const foundIndex: number = state.list.findIndex((pr) => pr.phone === action.value)

      return { ...state, error: null, selected: state.list[foundIndex] }
    }
    case gsmAnalyticsTypes.CLEAR_ITEM_PENDING_EDIT: {
      return { ...state, error: null, selected: null }
    }
    case gsmAnalyticsTypes.SET_ITEM_MODIFICATION_STATE: {
      return { ...state, error: null, modificationState: action.value }
    }
    case gsmAnalyticsTypes.SET_ITEMS_SOME_ERROR: {
      return { ...state, error: action.value }
    }
    case gsmAnalyticsTypes.EDIT_ITEMS_FILTER: {
      return { ...state, filters: action.value }
    }
    case gsmAnalyticsTypes.CLEAR_ITEMS_FILTERS: {
      return { ...state, filters }
    }
    default:
      return state
  }
}
