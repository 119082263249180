const useFilteringListHook = <T, U extends Partial<Record<keyof T, string | string[]>>>(
  list: T[],
  filters: U,
) => {
  const filtersKeys = Object.keys(filters) as Array<keyof U>

  return filtersKeys.reduce<T[]>((acc, key) => {
    if (filters[key]) {
      return acc.filter((product) => {
        const filter = filters[key]
        const item = String(product[key as keyof T])

        if (!item) return false
        if (!filter || (Array.isArray(filter) && !filter.length)) return true

        if (Array.isArray(filter)) {
          return filter.includes(item)
        }

        return filter === item
      })
    }

    return acc
  }, list)
}

export default useFilteringListHook
