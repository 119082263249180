import React from 'react'
import { CheckboxInputProps } from './types/Checkbox.types'

function CheckboxRH<T>(props: CheckboxInputProps<T>): JSX.Element {
  const error = (props.formState.errors[props.field]?.message as string) || ''

  return (
    <div className={`mt-auto mb-2 ml-2 custom-control custom-switch ${props.wrapperClass || ''}`}>
      <input
        type='checkbox'
        id={props.id}
        className={`custom-control-input ${props.inputClass || ''}`}
        {...props.register(props.field, {
          required: props.required || '',
          disabled: props.disabled,
        })}
      />
      <label className='custom-control-label' htmlFor={props.id.toString()}>
        {props.label}
      </label>

      {error && props.required ? <div className='invalid-feedback'>{error}</div> : null}
    </div>
  )
}

export default CheckboxRH
