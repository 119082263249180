import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { RequestUrlTypes } from '../../hooks/types/request.types'
import { useHttpDateLoader } from '../../hooks/request.hook'
import { useErrorHandlerHook } from '../../hooks/error-handler'
import {
  getTgCustomersList,
  setTgCustomersSomeError,
} from '../../store/dispatch/tg/tg-customers.dispatch'
import { TgCustomer } from '../../store/models/tg/tg-customer.interface'
import RenderListTgCustomers from './components/RenderListTgCustomers'
import { URL_SERVER_TG } from '../../config'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setList: bindActionCreators(getTgCustomersList, dispatch),
  setSomeError: bindActionCreators(setTgCustomersSomeError, dispatch),
})

type PropsComponent = ReturnType<typeof mapDispatchToProps>

const TgCustomersList: React.FC<PropsComponent> = ({ setList, setSomeError }): JSX.Element => {
  const errorHandler = useErrorHandlerHook(setSomeError, 'Користувачі')
  useHttpDateLoader<TgCustomer[]>({
    url: RequestUrlTypes.TG_CUSTOMERS,
    base: URL_SERVER_TG,
    func: setList,
    errorHandler,
  })

  return <RenderListTgCustomers />
}

export default connect(null, mapDispatchToProps)(TgCustomersList)
