import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { GsmAnalyticsOption } from '../../../store/models/gsm/gsm-analytics.interface'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

class SalesByDate {
  date = ''
  sales: number[] = []
}

const RenderLineChartByPhone = ({ list }: { list: GsmAnalyticsOption[] }) => {
  list = list.sort((a, b) => {
    return new Date(b.activationId).getTime() - new Date(a.activationId).getTime()
  })

  const collection: SalesByDate[] = []

  for (const option of list) {
    if (!option.activationId) {
      continue
    }

    const item = new SalesByDate()
    const date = new Date(option.activationId).toLocaleString('uk-UA', {
      day: 'numeric',
      month: 'short',
    })

    const founded = collection.find((i) => i.date === date)

    if (founded) {
      founded.sales.push(option.activationId)
    } else {
      item.date = date
      item.sales.push(option.activationId)

      collection.push(item)
    }
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Аналітика ктивацій по дням',
      },
    },
  }

  const data = {
    labels: collection.map(({ date }) => date),
    datasets: [
      {
        label: '',
        data: collection.map(({ sales }) => sales.length),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  }

  return <Line options={options} data={data} />
}
export default RenderLineChartByPhone
