import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { ScrServiceDiaryItem } from '../../../../../store/models/scraper/scr-service-diary.interface'
import { ModificationStatus, StateType } from '../../../../../store/models/root.interface'
import { OptionsReceiver } from '../../../../../utils/option-receiver'
import {
  changeSelectedScrServiceDiary,
  clearSelectedScrServiceDiary,
  setScrServiceDiaryModificationState,
} from '../../../../../store/dispatch/scraper/scr-service-diary.dispatch'
import ListIsEmpty from '../../../../../common/components/ListIsEmpty'
import useFilteringListHook from '../../../../../hooks/filter-hook'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  changeSelected: bindActionCreators(changeSelectedScrServiceDiary, dispatch),
  setModificationState: bindActionCreators(setScrServiceDiaryModificationState, dispatch),
  clearSelected: bindActionCreators(clearSelectedScrServiceDiary, dispatch),
})

const mapStateToProps = ({ scrServiceDiary, scrCredential, scrOptions }: StateType) => ({
  scrServiceDiary,
  scrCredential: scrCredential.list,
  scrService: scrOptions.servicesList,
  scrCountry: scrOptions.countriesList,
})

type PropsServicesListComponent = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const RenderScrServiceDiaryList: React.FC<PropsServicesListComponent> = ({
  scrServiceDiary,
  scrService,
  scrCountry,
  changeSelected,
  setModificationState,
  clearSelected,
}): JSX.Element => {
  const getCountryByShortName = OptionsReceiver.getValueByKey(scrCountry, 'shortName')
  const getServiceByShortName = OptionsReceiver.getValueByKey(scrService, 'shortName')

  const selectedClass = (item: ScrServiceDiaryItem) => {
    const selected = scrServiceDiary.selected?._id === item?._id ? 'selected' : ''

    return `table-row ${selected}`
  }

  const onSelect = (item: ScrServiceDiaryItem): void => {
    if (scrServiceDiary.selected?._id === item?._id) {
      clearSelected()
      setModificationState(ModificationStatus.None)
    } else {
      changeSelected(item)
      setModificationState(ModificationStatus.Edit)
    }
  }

  if (!scrServiceDiary.list.length) return <ListIsEmpty />
  let servicesList = useFilteringListHook(scrServiceDiary.list, scrServiceDiary.filters)
  servicesList = OptionsReceiver.uniqModels(servicesList, (op) => op.service + String(op.country))

  const productElements = servicesList.map((element, index: number) => {
    return (
      <tr className={selectedClass(element)} key={element._id} onClick={() => onSelect(element)}>
        <th scope='row'>{++index}</th>
        <td data-id={element.service}>{getServiceByShortName(element.service, 'name')}</td>
        <td data-id={element.country}>{getCountryByShortName(element.country, 'name')}</td>
      </tr>
    )
  })

  return (
    <div className='table-responsive portlet'>
      <table className='table'>
        <thead className='thead-light'>
          <tr>
            <th scope='col'>№</th>
            <th scope='col'>Сервіс</th>
            <th scope='col'>Країна</th>
          </tr>
        </thead>
        <tbody>{productElements}</tbody>
      </table>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(RenderScrServiceDiaryList)
