import React, { useContext } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { ModificationStatus, StateType } from '../../../../../store/models/root.interface'
import { OptionsReceiver } from '../../../../../utils/option-receiver'
import { ScrRentalRateDiaryContext } from '../ScrRentalRateDiaryContext'
import ListIsEmpty from '../../../../../common/components/ListIsEmpty'
import { ScrRentalRateDiary } from '../../../../../store/models/scraper/scr-rental-rate-diary.interface'
import {
  changeSelectedScrRentalRateDiary,
  clearSelectedScrRentalRateDiary,
  setScrRentalRateDiaryModificationState,
} from '../../../../../store/dispatch/scraper/scr-rental-rate-diary.dispatch'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  changeSelected: bindActionCreators(changeSelectedScrRentalRateDiary, dispatch),
  setModificationState: bindActionCreators(setScrRentalRateDiaryModificationState, dispatch),
  clearSelected: bindActionCreators(clearSelectedScrRentalRateDiary, dispatch),
})

const mapStateToProps = ({ scrRentalRateDiary, scrCredential, scrOptions }: StateType) => ({
  scrRentalRateDiary,
  scrCredential: scrCredential.list,
  scrCountry: scrOptions.countriesList,
})

type PropsServicesListComponent = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const ScrRentalRateDiaryList: React.FC<PropsServicesListComponent> = ({
  scrRentalRateDiary,
  scrCredential,
  scrCountry,
  changeSelected,
  setModificationState,
  clearSelected,
}): JSX.Element => {
  const context = useContext(ScrRentalRateDiaryContext)
  const getCredById = OptionsReceiver.getValueByKey(scrCredential, '_id')
  const getCountryByShortName = OptionsReceiver.getValueByKey(scrCountry, 'shortName')

  const selectedClass = (item: ScrRentalRateDiary) => {
    const selected = scrRentalRateDiary.selected?._id === item?._id ? 'selected' : ''

    return `table-row ${selected}`
  }

  const onSelect = (item: ScrRentalRateDiary): void => {
    if (scrRentalRateDiary.selected?._id === item?._id) {
      clearSelected()
      setModificationState(ModificationStatus.None)
    } else {
      changeSelected(item._id || '')
      setModificationState(ModificationStatus.Edit)
    }
  }

  if (!context.selectedList.length) return <ListIsEmpty />

  const productElements = context.selectedList.map((element: ScrRentalRateDiary, index: number) => {
    const date = new Date(element.updatedAt).toLocaleString('uk-UA', {
      day: 'numeric',
      month: 'short',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    })

    return (
      <tr className={selectedClass(element)} key={element._id} onClick={() => onSelect(element)}>
        <th scope='row'>{++index}</th>
        <td data-id={element.country}>{getCountryByShortName(element.country, 'name')}</td>
        <td data-id={element.account}>{getCredById(element.account, 'login')}</td>
        <td data-id={element.updatedAt}>{date}</td>
      </tr>
    )
  })

  return (
    <div className='table-responsive portlet'>
      <table className='table'>
        <thead className='thead-light'>
          <tr>
            <th scope='col'>№</th>
            <th scope='col'>Країна</th>
            <th scope='col'>Аккаунт</th>
            <th scope='col'>Дата оновлення</th>
          </tr>
        </thead>
        <tbody>{productElements}</tbody>
      </table>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ScrRentalRateDiaryList)
