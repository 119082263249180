import { ScrRentalRate } from '../../models/scraper/scr-rental-rate.interface'
import { Dispatch } from 'redux'
import {
  addRentalRateAction,
  changeSelectedRentalRateAction,
  clearSelectedRentalRateAction,
  editRentalRateAction,
  getRentalRatesListAction,
  removeRentalRateAction,
  setRentalRatesModificationStateAction,
  setRentalRatesSomeErrorAction,
} from '../../actions/scraper/scr-rental-rate.action'
import { ModificationStatus } from '../../models/root.interface'

type ActionItem = ScrRentalRate

export const getRentalRatesList = (value: ActionItem[]) => (dispatch: Dispatch) => {
  dispatch(getRentalRatesListAction(value))
}

export const addRentalRate = (value: ActionItem) => (dispatch: Dispatch) => {
  dispatch(addRentalRateAction(value))
}

export const editRentalRate = (value: ActionItem) => (dispatch: Dispatch) => {
  dispatch(editRentalRateAction(value))
}

export const removeRentalRate = (value: string) => (dispatch: Dispatch) => {
  dispatch(removeRentalRateAction(value))
}

export const changeSelectedRentalRate = (value: string) => (dispatch: Dispatch) => {
  dispatch(changeSelectedRentalRateAction(value))
}

export const clearSelectedRentalRate = () => (dispatch: Dispatch) => {
  dispatch(clearSelectedRentalRateAction())
}

export const setRentalRatesModificationState =
  (value: ModificationStatus) => (dispatch: Dispatch) => {
    dispatch(setRentalRatesModificationStateAction(value))
  }

export const setRentalRatesSomeError = (value: string) => (dispatch: Dispatch) => {
  dispatch(setRentalRatesSomeErrorAction(value))
}
