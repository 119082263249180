import React from 'react'
import { UseFormReturn } from 'react-hook-form/dist/types/form'
import TypeheadSelectRH from '../../../../../common/form-hook/TypeheadSelect'
import { GsmOptionsCountry } from '../../../../../store/models/gsm/gsm-options-country.interface'
import TextRH from '../../../../../common/form-hook/Text'
import { GsmOptionServiceFrom } from '../GsmOptionsServiceForm'
import CheckboxRH from '../../../../../common/form-hook/Checkbox'

const RenderFormGsmOptionsService: React.FC<{
  form: UseFormReturn<GsmOptionServiceFrom>
  countries: GsmOptionsCountry[]
}> = ({ form, countries }) => {
  return (
    <>
      <div className='form-group col-md-5'>
        <TypeheadSelectRH
          {...form}
          id='input_country'
          field='country'
          label='Країна'
          placeholder='Обрати країну'
          options={countries}
          labelKey='name'
          savedKey='_id'
          value={form.watch('country')}
          defaultSelected={form.watch('country') ? form.watch('country').toString() : ''}
        />
      </div>
      <div className='form-group col-md-5'>
        <TextRH
          {...form}
          type='text'
          id='input_name'
          label='Назва сервісу'
          field='name'
          placeholder='Введіть значення'
        />
      </div>
      <div className='form-group col-md-2'>
        <TextRH
          {...form}
          type='number'
          id='input_cost'
          label='Ціна (USD)'
          field='cost'
          step={0.01}
        />
      </div>
      <div className='form-group col-md-4 d-flex'>
        <CheckboxRH {...form} id='checkbox_active' label='Важливий' field='important' />
      </div>
    </>
  )
}

export default RenderFormGsmOptionsService
