import React from 'react'
import SelectRH from '../../../../../common/form-hook/Select'
import TextRH from '../../../../../common/form-hook/Text'
import { CustomRenderFormTgOptionCountryProps } from '../types/RenderFormTgOptionCountry.types'
import { TgOptionCountryPriority } from '../../../../../store/models/tg/tg-options-country.interface'
import TypeheadSelectRH from '../../../../../common/form-hook/TypeheadSelect'
import RenderContentFieldsTgOption from './RenderContentFieldsTgOptionCountry'

const RenderFormTgOptionCountry: React.FC<CustomRenderFormTgOptionCountryProps> = ({
  form,
  options,
  isCreate,
}) => {
  const priorities = [
    { text: 'Висока', value: TgOptionCountryPriority.HIGH },
    { text: 'Середня', value: TgOptionCountryPriority.MEDIUM },
    { text: 'Низька', value: TgOptionCountryPriority.LOW },
  ]

  return (
    <>
      {/*  content textarea  */}
      <RenderContentFieldsTgOption form={form} />
      <div className='form-group col-md-5'>
        <TypeheadSelectRH
          {...form}
          id='input_short-name'
          field='shortName'
          label='Країна'
          placeholder='Обрати країну'
          options={options}
          labelKey='name'
          savedKey='shortName'
          value={form.watch('shortName')}
          disabled={!isCreate}
          defaultSelected={form.watch('shortName') ? form.watch('shortName').toString() : ''}
        />
      </div>
      <div className='form-group col-md-5'>
        <SelectRH
          {...form}
          id='input_priority'
          label='Пріоритетність'
          field='priority'
          required='Value priority cannot be empty'
          options={priorities}
        />
      </div>
      <div className='form-group col-md-2'>
        <TextRH {...form} type='text' id='input_icon' label='Іконки' field='icon' max={8} min={0} />
      </div>
    </>
  )
}

export default RenderFormTgOptionCountry
