export const gsmOptionsCountryTypes = {
  GET_ITEMS_LIST: 'GET_GSM_OPTIONS_COUNTRIES_LIST',
  ADD_NEW_ITEM: 'ADD_GSM_OPTIONS_COUNTRY',
  EDIT_ITEM: 'EDIT_GSM_OPTIONS_COUNTRY',
  REMOVE_ITEM: 'REMOVE_GSM_OPTIONS_COUNTRY',
  CHANGE_ITEM_PENDING_EDIT: 'CHANGE_GSM_OPTIONS_COUNTRY_PENDING_EDIT',

  CLEAR_ITEM_PENDING_EDIT: 'CLEAR_GSM_OPTIONS_COUNTRY_PENDING_EDIT',
  SET_ITEM_MODIFICATION_STATE: 'SET_GSM_OPTIONS_COUNTRY_MODIFICATION_STATE',
  SET_ITEMS_SOME_ERROR: 'SET_GSM_OPTIONS_COUNTRY_SOME_ERROR',
}
