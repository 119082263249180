import React from 'react'
import SelectRH from '../../../../../common/form-hook/Select'
import TextRH from '../../../../../common/form-hook/Text'
import { CustomRenderFormTgInternalBankProps } from '../types/RenderFormTgInternalBank.types'
import MultiSelectRH from '../../../../../common/form-hook/MultipleSelect'

const RenderFormTgInternalBank: React.FC<CustomRenderFormTgInternalBankProps> = ({
  form,
  option,
  currencies,
  banks,
}) => {
  return (
    <>
      <div className='form-group col-md-4'>
        <SelectRH {...form} id='input_name' label='Назва' field='name' options={banks} />
      </div>

      <div className='form-group col-md-8'>
        <MultiSelectRH
          {...form}
          id='input_currencies'
          field='currencies'
          label='Валюта'
          placeholder='Обрати валюти'
          options={currencies}
          labelKey='currencyA'
          savedKey='currencyA'
          value={form.watch('currencies')}
          defaultSelected={option.currencies}
        />
      </div>

      <div className='form-group col-md-12'>
        <TextRH {...form} type='text' id='input_token' label='Токен' field='token' />
      </div>
    </>
  )
}

export default RenderFormTgInternalBank
