import React from 'react'
import MultiSelectField from '../../../../../common/fields/MultiSelectField'
import {
  TgOptionService,
  TgOptionsServiceFilters,
} from '../../../../../store/models/tg/tg-options-service.interface'
import { TgOptionCountry } from '../../../../../store/models/tg/tg-options-country.interface'
const RenderTgOptionsServiceFilters = ({
  filters,
  editFilters,
  countries,
  services,
}: {
  filters: TgOptionsServiceFilters
  editFilters: (value: TgOptionsServiceFilters) => void
  countries: TgOptionCountry[]
  services: TgOptionService[]
}): JSX.Element => {
  services = Array.from(new Map(services.map((item) => [item['shortName'], item])).values())

  return (
    <>
      <div className='form-group col-md-5 pl-md-0'>
        <MultiSelectField
          id='input_country'
          field='country'
          label='Країна'
          placeholder='Оберіть країну'
          options={countries}
          labelKey='name'
          savedKey='shortName'
          setValue={editFilters}
          value={filters}
        />
      </div>
      <div className='form-group col-md-5'>
        <MultiSelectField
          id='input_service'
          field='shortName'
          label='Сервіс'
          placeholder='Оберіть сервіс'
          options={services}
          labelKey='name'
          savedKey='shortName'
          setValue={editFilters}
          value={filters}
        />
      </div>
    </>
  )
}

export default RenderTgOptionsServiceFilters
