import React from 'react'
import { CustomRenderFormRentalRateProps } from '../types/RenderFormRentalRate.types'
import TypeheadSelectRH from '../../../../../common/form-hook/TypeheadSelect'
import TextRH from '../../../../../common/form-hook/Text'
import SelectRH from '../../../../../common/form-hook/Select'
import CheckboxRH from '../../../../../common/form-hook/Checkbox'
import { ScrOptionsState, StateType } from '../../../../../store/models/root.interface'
import { useSelector } from 'react-redux'

const RenderFormRentalRate: React.FC<CustomRenderFormRentalRateProps> = ({ form, credentials }) => {
  const options: ScrOptionsState = useSelector((state: StateType) => state.scrOptions)
  const countriesList = options.countriesList
  const credentialsOptions = credentials.map((item) => {
    return {
      value: item._id || '',
      text: item.login,
    }
  })

  return (
    <>
      <div className='form-row col-md-12'>
        <div className='form-group col-md-6'>
          <SelectRH
            {...form}
            id='input_account'
            label='Аккаунт'
            field='account'
            options={credentialsOptions}
          />
        </div>
        <div className='form-group col-md-6'>
          <TypeheadSelectRH
            {...form}
            id='input_country'
            field='country'
            label='Країна'
            placeholder='Обрати країну'
            options={countriesList}
            labelKey='name'
            savedKey='shortName'
            value={form.watch('country')}
            defaultSelected={form.watch('country') ? form.watch('country').toString() : ''}
          />
        </div>
      </div>
      <div className='form-row col-md-12 col-xl-12'>
        <div className='form-group col-md-6'>
          <TextRH
            {...form}
            type='number'
            id='input_slot'
            label='К-сть. слотів'
            field='slot'
            step={0.1}
          />
        </div>
        <div className='form-group col-md-6'>
          <TextRH
            {...form}
            type='number'
            id='input_min_count'
            label='Мін. к-сть. слотів конкурента'
            field='minCount'
            step={1}
          />
        </div>
        <div className='form-group col-md-6'>
          <TextRH
            {...form}
            type='number'
            id='input_min_percent'
            label='Мін. відсоток'
            field='minPercent'
            step={1}
          />
        </div>
        <div className='form-group col-md-6 d-flex'>
          <CheckboxRH {...form} id='checkbox_active' label='Статус' field='status' />
        </div>
      </div>
    </>
  )
}

export default RenderFormRentalRate
