import { Typeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import { Controller, PathValue } from 'react-hook-form'
import { Option } from 'react-bootstrap-typeahead/types/types'
import { TypeheadSelectProps } from './types/TypeheadSelect.types'

function TypeheadSelectRH<T>(props: TypeheadSelectProps<T>): JSX.Element {
  const { defaultSelected, labelKey, options } = props
  const error: string = (props.formState.errors[props.field]?.message as string) || ''
  let defaultInputValue: string = defaultSelected || ''

  if (defaultInputValue && props.savedKey) {
    const option = options.find((i) => {
      const item = i[props.savedKey as keyof Option].toString()
      return item === props.defaultSelected
    })

    defaultInputValue = option ? option[props.labelKey as keyof Option] : ''
  }

  const onValueChanged = (e: any): void => {
    let value = e.target.value[0]

    if (props.savedKey && value) {
      value = value[props.savedKey]
    }

    props.setValue(props.field, value as PathValue<T, typeof props.field>)
  }

  return (
    <Controller
      control={props.control}
      name={props.field}
      rules={{
        onChange: onValueChanged,
      }}
      render={({ field }) => (
        <>
          <label htmlFor={props.id} className='form-label mb-2'>
            {props.label}
          </label>
          <Typeahead
            {...field}
            id={props.id}
            disabled={props.disabled}
            defaultInputValue={defaultInputValue}
            selected={props.options.filter((option) => {
              const savedKey = props?.savedKey as keyof Option
              return [props.value].includes(savedKey ? option[savedKey] : option)
            })}
            labelKey={labelKey}
            className='border rounded'
            options={options}
            placeholder={props.placeholder || ''}
          />

          {error ? <div className='invalid-feedback'>{error}</div> : null}
        </>
      )}
    />
  )
}

export default TypeheadSelectRH
