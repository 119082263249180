import { ModificationStatus } from '../../models/root.interface'
import { gsmAnalyticsTypes } from '../../types/gsm/gsm-analytics.types'
import {
  GsmAnalyticsOption,
  GsmAnalyticsOptionFilters,
} from '../../models/gsm/gsm-analytics.interface'

type ActionItem = GsmAnalyticsOption
type FilterItem = GsmAnalyticsOptionFilters
export function getGsmAnalyticsAction(value: ActionItem[]): IGetItemsActionType<ActionItem> {
  return { type: gsmAnalyticsTypes.GET_ITEMS_LIST, value }
}

export function changeSelectedGsmAnalyticsOptionAction(
  value: string,
): IChangeSelectedItemActionType {
  return { type: gsmAnalyticsTypes.CHANGE_ITEM_PENDING_EDIT, value }
}

export function clearSelectedGsmAnalyticsOptionAction(): IClearSelectedItemActionType {
  return { type: gsmAnalyticsTypes.CLEAR_ITEM_PENDING_EDIT }
}

export function setGsmAnalyticsModificationStateAction(
  value: ModificationStatus,
): ISetModificationStateActionType {
  return { type: gsmAnalyticsTypes.SET_ITEM_MODIFICATION_STATE, value: value }
}

export function setGsmAnalyticsSomeErrorAction(value: string): ISetSomeErrorActionType {
  return { type: gsmAnalyticsTypes.SET_ITEMS_SOME_ERROR, value: value }
}

export function editGsmAnalyticsFiltersAction(
  value: FilterItem,
): IEditFiltersTypeActionType<FilterItem> {
  return { type: gsmAnalyticsTypes.EDIT_ITEMS_FILTER, value }
}

export function clearGsmAnalyticsFiltersAction(): IClearFiltersType {
  return { type: gsmAnalyticsTypes.CLEAR_ITEMS_FILTERS }
}

interface IGetItemsActionType<T> {
  type: string
  value: T[]
}

interface IChangeSelectedItemActionType {
  type: string
  value: string
}

interface IClearSelectedItemActionType {
  type: string
}

interface ISetModificationStateActionType {
  type: string
  value: ModificationStatus
}

interface ISetSomeErrorActionType {
  type: string
  value: string
}

interface IEditFiltersTypeActionType<T> {
  type: string
  value: T
}

interface IClearFiltersType {
  type: string
}
