import { Maybe } from 'yup'

export enum TgOptionCountryPriority {
  HIGH = '1',
  MEDIUM = '2',
  LOW = '3',
}

export enum TgContentLanguages {
  En = 'en',
  Ua = 'ua',
  RU = 'ru',
}

export type TgContentLanguagesValues = (typeof TgContentLanguages)[keyof typeof TgContentLanguages]

export class TgOptionCountry {
  _id: Maybe<string | undefined>
  shortName = ''
  name: Maybe<string | undefined>
  priority: TgOptionCountryPriority = TgOptionCountryPriority.LOW
  content:
    | {
        [TgContentLanguages.Ua]?: Maybe<string | undefined>
        [TgContentLanguages.En]?: Maybe<string | undefined>
        [TgContentLanguages.RU]?: Maybe<string | undefined>
      }
    | null
    | undefined
  icon: Maybe<string | undefined>
}
