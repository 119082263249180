import React from 'react'
import { connect } from 'react-redux'
import { ModificationStatus, StateType } from '../../../../store/models/root.interface'
import { bindActionCreators, Dispatch } from 'redux'
import { addNotification } from '../../../../store/dispatch/notifications.dispatch'
import { PartnerProgram } from '../../../../store/models/tg/tg-partner-program.interface'
import {
  addPartnerProgram,
  clearSelectedPartnerProgram,
  editPartnerProgram,
  setPartnerProgramsModificationState,
  setPartnerProgramsSomeError,
} from '../../../../store/dispatch/tg/tg-partner-program.dispatch'
import { useHttpRequestWithBody } from '../../../../hooks/request.hook'
import { RequestUrlTypes } from '../../../../hooks/types/request.types'
import { useForm } from 'react-hook-form'
import { TabFormWrapperProps } from '../../../../common/components/types/TabFormWrapper.types'
import TabFormWrapper from '../../../../common/components/TabFormWrapper'
import RenderFormTgPartnerProgram from './components/RenderFormTgPartnerProgram'
import { useErrorHandlerHook } from '../../../../hooks/error-handler'
import { BOT_URL, URL_SERVER_TG } from '../../../../config'
import { updateLoaderState } from '../../../../store/dispatch/root.dispatch'
import { Maybe } from 'yup'
import { TgPromoCode } from '../../../../store/models/tg/tg-promo-code.interface'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setSomeError: bindActionCreators(setPartnerProgramsSomeError, dispatch),
  clearSelected: bindActionCreators(clearSelectedPartnerProgram, dispatch),
  setModificationState: bindActionCreators(setPartnerProgramsModificationState, dispatch),
  add: bindActionCreators(addPartnerProgram, dispatch),
  edit: bindActionCreators(editPartnerProgram, dispatch),
  addNotification: bindActionCreators(addNotification, dispatch),
  setLoader: bindActionCreators(updateLoaderState, dispatch),
})

const mapStateToProps = ({ tgPartnerPrograms, tgPromoCodes }: StateType) => ({
  options: tgPartnerPrograms,
  codes: tgPromoCodes.list,
})

type PageProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const TgPartnerProgramForm: React.FC<PageProps> = ({
  options,
  codes,
  setSomeError,
  clearSelected,
  setModificationState,
  add,
  edit,
  addNotification,
  setLoader,
}) => {
  const { selected, modificationState, error, list } = options
  const isCreate: boolean = modificationState === ModificationStatus.Create
  const errorHandler = useErrorHandlerHook(setSomeError, 'Валюта')
  const saveRequest = useHttpRequestWithBody<PartnerProgram>({
    url: RequestUrlTypes.TG_PARTNER_PROGRAM,
    base: URL_SERVER_TG,
    func: isCreate ? add : edit,
  })

  let item: PartnerProgram | null = selected

  if (!item || isCreate) {
    item = new PartnerProgram()
  }

  const form = useForm<PartnerProgram>({ defaultValues: item })

  const saveForm = async (formState: PartnerProgram): Promise<void> => {
    const uniq = list.find((option) => option.name === formState.name)
    const hasMain = list.find((option) => option.isMain)

    if (isCreate && hasMain && formState.isMain) {
      errorHandler({ text: `Основна програма вже встановлена (${hasMain.name})` })
      return
    }

    if (isCreate && uniq) {
      errorHandler({ text: 'Current program exists' })
      return
    }

    if (item) {
      const { name, termination } = formState
      let code = formState && formState.code ? formState.code : name
      code = code.replace(/ /g, '').toUpperCase()
      const link = `${BOT_URL}?start=program-${code}`

      let promoCode: Maybe<string | undefined | TgPromoCode>

      if (item.promoCode instanceof TgPromoCode) {
        promoCode = item.promoCode._id
      } else {
        promoCode = item.promoCode
      }

      try {
        await saveRequest({
          body: {
            ...item,
            ...formState,
            code,
            date: termination === 'select-date' ? formState.date : undefined,
            duration: termination === 'select-period' ? formState.duration : undefined,
            termination,
            link,
            promoCode,
          },
          id: item?._id ? String(item?._id) : '',
        })

        addNotification(
          'Партнерська програма',
          isCreate ? `${name} створена.` : `${name} редагована.`,
        )
        clearSelected()
        setModificationState(ModificationStatus.None)
      } catch (e: any) {
        errorHandler({ text: e.message })
      } finally {
        setLoader(false)
      }
    }
  }

  const resetForm = (): void => {
    setModificationState(ModificationStatus.None)
    form.reset()
  }

  const formOptions: TabFormWrapperProps<PartnerProgram> = {
    form,
    error,
    title: 'програми',
    isCreate,
    saveForm,
    resetForm,
  }

  return (
    <TabFormWrapper {...formOptions}>
      <RenderFormTgPartnerProgram form={form} codes={codes} />
    </TabFormWrapper>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(TgPartnerProgramForm)
