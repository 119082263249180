import { ModificationStatus } from '../../models/root.interface'
import { TgCustomer } from '../../models/tg/tg-customer.interface'
import { tgCustomersTypes } from '../../types/tg/tg-customers.types'

type ActionItem = TgCustomer

export function getCustomersListAction(value: ActionItem[]): IGetItemsActionType<ActionItem> {
  return { type: tgCustomersTypes.GET_TG_CUSTOMERS_LIST, value }
}

export function addCustomerAction(value: ActionItem): IAddItemActionType<ActionItem> {
  return { type: tgCustomersTypes.ADD_TG_CUSTOMER, value }
}

export function editCustomerAction(value: ActionItem): IEditItemActionType<ActionItem> {
  return { type: tgCustomersTypes.EDIT_TG_CUSTOMER, value }
}

export function removeCustomerAction(value: string): IRemoveItemActionType {
  return { type: tgCustomersTypes.REMOVE_TG_CUSTOMER, value }
}

export function changeSelectedCustomerAction(value: string): IChangeSelectedItemActionType {
  return { type: tgCustomersTypes.CHANGE_TG_CUSTOMER_PENDING_EDIT, value }
}

export function clearSelectedCustomerAction(): IClearSelectedItemActionType {
  return { type: tgCustomersTypes.CLEAR_TG_CUSTOMER_PENDING_EDIT }
}

export function setCustomersModificationStateAction(
  value: ModificationStatus,
): ISetModificationStateActionType {
  return { type: tgCustomersTypes.SET_TG_CUSTOMER_MODIFICATION_STATE, value: value }
}

export function setCustomersSomeErrorAction(value: string): ISetSomeErrorActionType {
  return { type: tgCustomersTypes.SET_TG_CUSTOMER_SOME_ERROR, value: value }
}

interface IGetItemsActionType<T> {
  type: string
  value: T[]
}

interface IAddItemActionType<T> {
  type: string
  value: T
}

interface IEditItemActionType<T> {
  type: string
  value: T
}

interface IRemoveItemActionType {
  type: string
  value: string
}

interface IChangeSelectedItemActionType {
  type: string
  value: string
}

interface IClearSelectedItemActionType {
  type: string
}

interface ISetModificationStateActionType {
  type: string
  value: ModificationStatus
}

interface ISetSomeErrorActionType {
  type: string
  value: string
}
