import React from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { useErrorHandlerHook } from '../../../../../hooks/error-handler'
import { useHttpDateLoaderArray, useHttpGetRequest } from '../../../../../hooks/request.hook'
import { RequestUrlTypes } from '../../../../../hooks/types/request.types'
import { URL_SERVER_TG } from '../../../../../config'
import {
  getTgOptionCountriesList,
  setTgOptionCountrySomeError,
} from '../../../../../store/dispatch/tg/tg-options-country.dispatch'
import { TgOptionCountry } from '../../../../../store/models/tg/tg-options-country.interface'
import { TgOptionService } from '../../../../../store/models/tg/tg-options-service.interface'
import {
  getTgOptionServicesList,
  setTgOptionServiceSomeError,
} from '../../../../../store/dispatch/tg/tg-options-service.dispatch'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setListServices: bindActionCreators(getTgOptionServicesList, dispatch),
  setSomeErrorServices: bindActionCreators(setTgOptionServiceSomeError, dispatch),
  setListCountries: bindActionCreators(getTgOptionCountriesList, dispatch),
  setSomeErrorCountries: bindActionCreators(setTgOptionCountrySomeError, dispatch),
})

type PropsComponent = ReturnType<typeof mapDispatchToProps> & {
  children?: JSX.Element | JSX.Element[]
}

const TgPromoCodesWrapper: React.FC<PropsComponent> = ({
  children,
  setSomeErrorServices,
  setListServices,
  setSomeErrorCountries,
  setListCountries,
}): JSX.Element => {
  const errorHandlerServices = useErrorHandlerHook(setSomeErrorServices, 'Gsm опції сервісів')

  const requestServices = useHttpGetRequest<TgOptionService[]>({
    url: RequestUrlTypes.TG_OPTION_SERVICE,
    base: URL_SERVER_TG,
    func: setListServices,
    errorHandler: errorHandlerServices,
  })

  const errorHandlerCountries = useErrorHandlerHook(setSomeErrorCountries, 'Gsm опції країн')

  const requestCountries = useHttpGetRequest<TgOptionCountry[]>({
    url: RequestUrlTypes.TG_OPTION_COUNTRY,
    base: URL_SERVER_TG,
    func: setListCountries,
    errorHandler: errorHandlerCountries,
  })

  useHttpDateLoaderArray([requestServices, requestCountries])

  return <>{children}</>
}

export default connect(null, mapDispatchToProps)(TgPromoCodesWrapper)
