import { IActionBase, ScrRentalRateState, ModificationStatus } from '../../models/root.interface'
import { ScrRentalRate } from '../../models/scraper/scr-rental-rate.interface'
import { rentalRatesTypes } from '../../types/scraper/scr-rental-rate.types'

type State = ScrRentalRateState
type Element = ScrRentalRate

const initialState: State = {
  error: null,
  modificationState: ModificationStatus.None,
  selected: null,
  list: [],
}

export default (state: State = initialState, action: IActionBase): State => {
  switch (action.type) {
    case rentalRatesTypes.GET_RENTAL_RATES: {
      return { ...state, error: null, list: action.value, selected: null }
    }
    case rentalRatesTypes.ADD_RENTAL_RATE: {
      return { ...state, error: null, list: [...state.list, action.value], selected: null }
    }
    case rentalRatesTypes.EDIT_RENTAL_RATE: {
      const foundIndex: number = state.list.findIndex((pr) => pr._id === action.value._id)
      const list: Element[] = state.list
      list[foundIndex] = action.value

      return { ...state, error: null, list }
    }
    case rentalRatesTypes.REMOVE_RENTAL_RATE: {
      return { ...state, error: null, list: state.list.filter((pr) => pr._id !== action.value) }
    }
    case rentalRatesTypes.CHANGE_RENTAL_RATE_PENDING_EDIT: {
      const foundIndex: number = state.list.findIndex((pr) => pr._id === action.value)

      return { ...state, error: null, selected: state.list[foundIndex] || null }
    }
    case rentalRatesTypes.CLEAR_RENTAL_RATE_PENDING_EDIT: {
      return { ...state, error: null, selected: null }
    }
    case rentalRatesTypes.SET_RENTAL_RATE_MODIFICATION_STATE: {
      return { ...state, error: null, modificationState: action.value }
    }
    case rentalRatesTypes.SET_RENTAL_RATE_SOME_ERROR: {
      return { ...state, error: action.value }
    }
    default:
      return state
  }
}
