import { Dispatch } from 'redux'
import {
  addScraperCredentialAction,
  changeScraperCredentialStatusAction,
  changeSelectedScraperCredentialAction,
  clearSelectedScraperCredentialAction,
  editScraperCredentialAction,
  getScraperCredentialsAction,
  removeScraperCredentialAction,
  setScraperCredentialsModificationStateAction,
  setScraperCredentialSomeErrorAction,
} from '../../actions/scraper/scr-credential.action'
import { ModificationStatus } from '../../models/root.interface'
import { ScrCredential } from '../../models/scraper/scr-credential.interface'

type ActionItem = ScrCredential

export const getScraperCredentialsList = (value: ActionItem[]) => (dispatch: Dispatch) => {
  dispatch(getScraperCredentialsAction(value))
}

export const addScraperCredential = (value: ActionItem | ActionItem[]) => (dispatch: Dispatch) => {
  dispatch(addScraperCredentialAction(value))
}

export const editScraperCredential = (value: ActionItem | ActionItem[]) => (dispatch: Dispatch) => {
  dispatch(editScraperCredentialAction(value))
}

export const removeScraperCredential = (value: string) => (dispatch: Dispatch) => {
  dispatch(removeScraperCredentialAction(value))
}

export const changeSelectedScraperCredential = (value: string) => (dispatch: Dispatch) => {
  dispatch(changeSelectedScraperCredentialAction(value))
}

export const clearSelectedScraperCredential = () => (dispatch: Dispatch) => {
  dispatch(clearSelectedScraperCredentialAction())
}

export const setScraperCredentialsModificationState =
  (value: ModificationStatus) => (dispatch: Dispatch) => {
    dispatch(setScraperCredentialsModificationStateAction(value))
  }

export const setScraperCredentialsSomeError = (value: string) => (dispatch: Dispatch) => {
  dispatch(setScraperCredentialSomeErrorAction(value))
}

export const changeScraperCredentialStatus = () => (dispatch: Dispatch) => {
  dispatch(changeScraperCredentialStatusAction())
}
