export const scrServiceTypes = {
  GET_ITEMS_LIST: 'GET_SCR_SERVICE',
  ADD_NEW_ITEM: 'ADD_SCR_SERVICE',
  EDIT_ITEM: 'EDIT_SCR_SERVICE',
  REMOVE_ITEM: 'REMOVE_SCR_SERVICE',
  CHANGE_ITEM_PENDING_EDIT: 'CHANGE_SCR_SERVICE_PENDING_EDIT',
  CLEAR_ITEM_PENDING_EDIT: 'CLEAR_SCR_SERVICE_PENDING_EDIT',
  SET_ITEM_MODIFICATION_STATE: 'SET_SCR_SERVICE_MODIFICATION_STATE',
  SET_ITEMS_SOME_ERROR: 'SET_SCR_SERVICE_SOME_ERROR',

  EDIT_ITEMS_FILTER: 'EDIT_SCR_SERVICE_FILTER',
  CLEAR_ITEMS_FILTERS: 'CLEAR_SCR_SERVICE_FILTERS',
}
