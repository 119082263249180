import { useEffect } from 'react'
import { TabFormWrapperProps } from './types/TabFormWrapper.types'

const TabFormWrapper = <T,>({
  form,
  isCreate,
  title,
  children,
  error,
  saveForm,
  resetForm,
}: TabFormWrapperProps<T>) => {
  const {
    handleSubmit,
    reset,
    formState: { isValid },
  } = form

  useEffect(() => {
    reset()
  }, [isCreate])

  return (
    <div className='card shadow mb-4'>
      <div className='card-header py-3'>
        <h6 className='m-0 font-weight-bold text-green'>
          {isCreate ? 'Створення' : 'Редагування'} {title}
        </h6>
      </div>
      <div className='card-body'>
        <form onSubmit={handleSubmit(saveForm)}>
          <div className='form-row  align-items-start'>{children}</div>
          <button type='reset' className='btn btn-danger' onClick={resetForm}>
            Скинути
          </button>
          <button type='submit' className={`btn btn-success left-margin ${isValid}`}>
            Зберегти
          </button>
          {error ? <div className='invalid-feedback d-block ml-0'>{error}</div> : null}
        </form>
      </div>
    </div>
  )
}

export default TabFormWrapper
