import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { StateType } from '../../../../store/models/root.interface'
import { bindActionCreators, Dispatch } from 'redux'
import { addNotification } from '../../../../store/dispatch/notifications.dispatch'
import { updateCurrentPath, updateLoaderState } from '../../../../store/dispatch/root.dispatch'
import OptionsTab from '../../../../common/components/Tab'
import { OptionTypeDetails } from '../../../../common/components/types/Tab.types'
import { ScrapperOptionType, ScrapperTabCustomProps } from '../../types/ScraperOptions.type'
import { ScrServiceDiary } from '../../../../store/models/scraper/scr-service-diary.interface'
import {
  getScrServiceDiaryList,
  setScrServiceDiaryModificationState,
  setScrServiceDiarySomeError,
} from '../../../../store/dispatch/scraper/scr-service-diary.dispatch'
import ScrServiceDiaryTabHeader from './components/ScrServiceDiaryTabHeader'
import ScrServiceDiaryContextWrapper from './ScrServiceDiaryContext'
import ScrCardBody from '../ScrCardBody'
import ScrServiceDiaryList from './ScrServiceDiaryList'
import ScrServiceDiaryCardHeader from './ScrServiceDiaryCardHeader'
import ScrServiceDiaryLineChartByService from './components/ScrServiceDiaryLineChartByService'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setModificationState: bindActionCreators(setScrServiceDiaryModificationState, dispatch),
  addNotification: bindActionCreators(addNotification, dispatch),
  updateCurrentPath: bindActionCreators(updateCurrentPath, dispatch),
  updateLoader: bindActionCreators(updateLoaderState, dispatch),
  setSomeError: bindActionCreators(setScrServiceDiarySomeError, dispatch),
  setList: bindActionCreators(getScrServiceDiaryList, dispatch),
})

const mapStateToProps = ({ scrServiceDiary, root }: StateType) => ({
  scrServiceDiary,
  path: root.page,
})

type PageProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  ScrapperTabCustomProps

const ScrServiceDiaryTab: React.FC<PageProps> = ({
  scrServiceDiary,
  path,
  setModificationState,
  updateCurrentPath,
  type,
  renderTabHeader,
}) => {
  useEffect(() => {
    updateCurrentPath('service-diary', 'list')
  }, [path.area])

  const details: OptionTypeDetails<ScrServiceDiary> = {
    ...scrServiceDiary,
    setModificationState,
    headerTitle: 'Список сервісів',
    bottomDescription: 'Список країн',
    cardFormClass: 'col-xl-8 col-lg-8',
    cardTopClass: 'col-xl-4 col-lg-4',
    cardWrapperClass: 'row mt-5 flex-row-reverse',
  }

  if (type !== ScrapperOptionType.SCR_SERVICE_DIARY) return <></>

  return (
    <ScrServiceDiaryContextWrapper>
      <OptionsTab
        details={details}
        renderForm={<ScrServiceDiaryLineChartByService />}
        topList={<ScrServiceDiaryList />}
        cardHeader={ScrServiceDiaryCardHeader}
        renderCardBody={ScrCardBody}
        renderTabHeader={<ScrServiceDiaryTabHeader renderTabHeader={renderTabHeader} />}
      />
    </ScrServiceDiaryContextWrapper>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ScrServiceDiaryTab)
