import React from 'react'
import MultiSelectField from '../../../../../common/fields/MultiSelectField'
import { ScrOptionsCountry } from '../../../../../store/models/scraper/scr-options-country.interface'
import { ScrOptionsService } from '../../../../../store/models/scraper/scr-options-service.interface'
import { ScrServiceDiaryFilters } from '../../../../../store/models/scraper/scr-service-diary.interface'
import { OptionsReceiver } from '../../../../../utils/option-receiver'

const RenderScrServiceDiaryFilters = ({
  editFilters,
  filters,
  services,
  countries,
}: {
  filters: ScrServiceDiaryFilters
  editFilters: (value: ScrServiceDiaryFilters) => void
  services: ScrOptionsService[]
  countries: ScrOptionsCountry[]
}): JSX.Element => {
  services = OptionsReceiver.uniqModels(services, (op) => op.shortName)

  return (
    <>
      <div className='form-group col-md-12 px-md-0'>
        <MultiSelectField
          id='input_country'
          field='country'
          label='Країна'
          placeholder='Оберіть країну'
          options={countries}
          labelKey='name'
          savedKey='shortName'
          setValue={editFilters}
          value={filters}
        />
      </div>
      <div className='form-group col-md-12 px-md-0'>
        <MultiSelectField
          id='input_service'
          field='service'
          label='Сервіс'
          placeholder='Оберіть сервіс'
          options={services}
          labelKey='name'
          savedKey='shortName'
          setValue={editFilters}
          value={filters}
        />
      </div>
    </>
  )
}

export default RenderScrServiceDiaryFilters
