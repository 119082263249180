import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import {
  getScraperConfigurationsList,
  setScraperConfigurationsSomeError,
} from '../../../../store/dispatch/scraper/scr-configuration.dispatch'
import { RequestUrlTypes } from '../../../../hooks/types/request.types'
import { useHttpDateLoader } from '../../../../hooks/request.hook'
import RenderListConfiguration from './components/RenderListConfiguration'
import { useErrorHandlerHook } from '../../../../hooks/error-handler'
import { ScrConfiguration } from '../../../../store/models/scraper/scr-configuration.interface'
import { URL_SERVER_SCRAPER } from '../../../../config'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setList: bindActionCreators(getScraperConfigurationsList, dispatch),
  setSomeError: bindActionCreators(setScraperConfigurationsSomeError, dispatch),
})

type PropsScraperConfigurationsListComponent = ReturnType<typeof mapDispatchToProps>

const ScraperConfigurationsList: React.FC<PropsScraperConfigurationsListComponent> = ({
  setList,
  setSomeError,
}): JSX.Element => {
  const errorHandler = useErrorHandlerHook(setSomeError, 'Конфігурація бота')
  useHttpDateLoader<ScrConfiguration[]>({
    url: RequestUrlTypes.SCRAPER_CONFIGURATION,
    base: URL_SERVER_SCRAPER,
    func: setList,
    errorHandler,
  })

  return <RenderListConfiguration />
}

export default connect(null, mapDispatchToProps)(ScraperConfigurationsList)
