import React from 'react'
import { connect } from 'react-redux'
import { ModificationStatus, StateType } from '../../../../store/models/root.interface'
import { bindActionCreators, Dispatch } from 'redux'
import { addNotification } from '../../../../store/dispatch/notifications.dispatch'
import { useHttpRequestWithBody } from '../../../../hooks/request.hook'
import { RequestUrlTypes } from '../../../../hooks/types/request.types'
import { useForm } from 'react-hook-form'
import { TabFormWrapperProps } from '../../../../common/components/types/TabFormWrapper.types'
import { useErrorHandlerHook } from '../../../../hooks/error-handler'
import { URL_SERVER_GSM } from '../../../../config'
import TabFormWrapper from '../../../../common/components/TabFormWrapper'
import {
  addGsmOptionsSender,
  clearSelectedGsmOptionsSender,
  editGsmOptionsSender,
  setGsmOptionsSendersModificationState,
  setGsmOptionsSendersSomeError,
} from '../../../../store/dispatch/gsm/gsm-options-sender.dispatch'
import RenderFormGsmOptionSender from './components/RenderFormGsmOptionsSender'
import { GsmOptionsSender } from '../../../../store/models/gsm/gsm-options-sender.interface'
import { updateLoaderState } from '../../../../store/dispatch/root.dispatch'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Maybe } from 'yup'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setSomeError: bindActionCreators(setGsmOptionsSendersSomeError, dispatch),
  clearSelected: bindActionCreators(clearSelectedGsmOptionsSender, dispatch),
  setModificationState: bindActionCreators(setGsmOptionsSendersModificationState, dispatch),
  add: bindActionCreators(addGsmOptionsSender, dispatch),
  edit: bindActionCreators(editGsmOptionsSender, dispatch),
  addNotification: bindActionCreators(addNotification, dispatch),
  setLoader: bindActionCreators(updateLoaderState, dispatch),
})

const mapStateToProps = ({
  gsmOptionsSender,
  gsmOptionsService,
  gsmOptionsCountry,
}: StateType) => ({
  options: gsmOptionsSender,
  services: gsmOptionsService.list,
  countries: gsmOptionsCountry.list,
})

type PropsComponent = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const schema = yup.object().shape({
  _id: yup.string().notRequired(),
  names: yup.string().min(0).max(100).required('Value names cannot be empty'),
  country: yup.string().min(1).required('Value country cannot be empty'),
  service: yup.string().min(1).required('Value service cannot be empty'),
})

export class GsmOptionSenderFrom {
  _id: Maybe<string | undefined>
  names = ''
  country = ''
  service = ''
}

const GsmOptionsSenderForm: React.FC<PropsComponent> = ({
  options,
  services,
  countries,
  clearSelected,
  setSomeError,
  setModificationState,
  setLoader,
  add,
  edit,
}) => {
  const { selected, modificationState, error, list } = options
  const isCreate: boolean = modificationState === ModificationStatus.Create
  const errorHandler = useErrorHandlerHook(setSomeError, 'Тг опції відправник')
  const saveRequest = useHttpRequestWithBody<GsmOptionsSender>({
    url: RequestUrlTypes.GSM_OPTIONS_SENDER,
    base: URL_SERVER_GSM,
    func: isCreate ? add : edit,
  })

  const item: GsmOptionSenderFrom = new GsmOptionSenderFrom()

  if (selected && !isCreate) {
    const names = selected.smsNames
    item._id = selected?._id || ''

    if (Array.isArray(names)) {
      item.names = names.join(' ')
    }

    if (typeof selected.service === 'object' && typeof selected.service.country === 'object') {
      item.country = selected.service.country._id || ''
      item.service = selected.service._id || ''
    }
  }

  const form = useForm<GsmOptionSenderFrom>({ defaultValues: item, resolver: yupResolver(schema) })

  const saveForm = async (formState: GsmOptionSenderFrom): Promise<void> => {
    const uniq = list.find((item) => item.service === formState.service)

    if (isCreate && uniq) {
      errorHandler({ text: 'Дана опція існує' })
      return
    }

    if (item && formState) {
      try {
        await saveRequest({
          body: {
            _id: item._id,
            service: formState.service,
            smsNames: formState.names.split(' '),
          },
          id: item._id,
        })

        clearSelected()
        setModificationState(ModificationStatus.None)
        addNotification('Тг опції відправник', isCreate ? 'створено' : 'редаговано')
      } catch (e: any) {
        errorHandler({ text: e.message })
      } finally {
        setLoader(false)
      }
    }
  }

  const resetForm = (): void => {
    setModificationState(ModificationStatus.None)
    form.reset()
  }

  const formOptions: TabFormWrapperProps<GsmOptionSenderFrom> = {
    form,
    error,
    title: 'відправник',
    isCreate,
    saveForm,
    resetForm,
  }

  return (
    <TabFormWrapper {...formOptions}>
      <RenderFormGsmOptionSender form={form} services={services} countries={countries} />
    </TabFormWrapper>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(GsmOptionsSenderForm)
