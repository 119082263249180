import React from 'react'
import { connect } from 'react-redux'
import { ModificationStatus, StateType } from '../../../../store/models/root.interface'
import { bindActionCreators, Dispatch } from 'redux'
import { TgCurrency } from '../../../../store/models/tg/tg-currency.interface'
import { addNotification } from '../../../../store/dispatch/notifications.dispatch'
import {
  addTgCurrency,
  clearSelectedTgCurrency,
  editTgCurrency,
  setTgCurrenciesModificationState,
  setTgCurrenciesSomeError,
} from '../../../../store/dispatch/tg/tg-currencies.dispatch'
import { useHttpRequestWithBody } from '../../../../hooks/request.hook'
import { RequestUrlTypes } from '../../../../hooks/types/request.types'
import { useForm } from 'react-hook-form'
import { TabFormWrapperProps } from '../../../../common/components/types/TabFormWrapper.types'
import TabFormWrapper from '../../../../common/components/TabFormWrapper'
import RenderFormTgCurrencies from './components/RenderFormTgCurrencies'
import { useErrorHandlerHook } from '../../../../hooks/error-handler'
import { URL_SERVER_TG } from '../../../../config'
import { updateLoaderState } from '../../../../store/dispatch/root.dispatch'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setSomeError: bindActionCreators(setTgCurrenciesSomeError, dispatch),
  clearSelected: bindActionCreators(clearSelectedTgCurrency, dispatch),
  setModificationState: bindActionCreators(setTgCurrenciesModificationState, dispatch),
  add: bindActionCreators(addTgCurrency, dispatch),
  edit: bindActionCreators(editTgCurrency, dispatch),
  addNotification: bindActionCreators(addNotification, dispatch),
  updateLoader: bindActionCreators(updateLoaderState, dispatch),
})

const mapStateToProps = ({ tgCurrencies }: StateType) => ({
  currencies: tgCurrencies,
})

type PropsComponent = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const schema = yup.object().shape({
  _id: yup.string().notRequired(),
  rate: yup.number().min(0).max(100).required('Value rate cannot be empty'),
  currencyA: yup.string().min(0).max(100).required('Value currencyA cannot be empty'),
  nameCurrencyA: yup.string().min(0).max(100).notRequired(),
  currencyB: yup.string().notRequired(),
})

const TgCurrencyForm: React.FC<PropsComponent> = ({
  currencies,
  setSomeError,
  updateLoader,
  clearSelected,
  addNotification,
  setModificationState,
  add,
  edit,
}): JSX.Element => {
  const { modificationState, selected, error, base, list } = currencies
  const isCreate: boolean = modificationState === ModificationStatus.Create
  const errorHandler = useErrorHandlerHook(setSomeError, 'Валюта')
  const saveRequest = useHttpRequestWithBody<TgCurrency>({
    url: RequestUrlTypes.TG_CURRENCY,
    base: URL_SERVER_TG,
    func: isCreate ? add : edit,
  })

  let item: TgCurrency | null = selected

  if (!item || isCreate) {
    item = new TgCurrency()
  }

  const form = useForm<TgCurrency>({ defaultValues: item, resolver: yupResolver(schema) })

  const saveForm = async (formState: TgCurrency): Promise<void> => {
    const { currencyA } = formState
    const uniq = list.find((item) => item.currencyA === currencyA)

    if (isCreate && uniq) {
      errorHandler({ text: 'Current slot exists' })
      return
    }

    if (formState.rate <= 0) {
      errorHandler({ text: 'Ставка не коректна' })
      return
    }

    if (item) {
      const { currencyA } = formState
      const name = base.find((item) => item.value === currencyA)

      try {
        await saveRequest({
          body: {
            ...item,
            ...formState,
            nameCurrencyA: name?.text ?? currencyA,
          },
          id: item?._id ? String(item?._id) : '',
        })

        clearSelected()
        setModificationState(ModificationStatus.None)
        addNotification('Валювата', isCreate ? 'створено' : 'редаговано')
      } catch (e: any) {
        errorHandler({ text: e.message })
      } finally {
        updateLoader(false)
      }
    }
  }

  const resetForm = (): void => {
    setModificationState(ModificationStatus.None)
    form.reset()
  }

  const formOptions: TabFormWrapperProps<TgCurrency> = {
    form,
    error,
    title: 'одиниці валюти',
    isCreate,
    saveForm,
    resetForm,
  }

  return (
    <TabFormWrapper {...formOptions}>
      <RenderFormTgCurrencies form={form} options={base} />
    </TabFormWrapper>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(TgCurrencyForm)
