import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { useHttpDateLoader } from '../../../../hooks/request.hook'
import { RequestUrlTypes } from '../../../../hooks/types/request.types'
import { useErrorHandlerHook } from '../../../../hooks/error-handler'
import { URL_SERVER_GSM } from '../../../../config'
import {
  getGsmOptionsSendersList,
  setGsmOptionsSendersSomeError,
} from '../../../../store/dispatch/gsm/gsm-options-sender.dispatch'
import { GsmOptionsSender } from '../../../../store/models/gsm/gsm-options-sender.interface'
import RenderListGsmOptionsSender from './components/RenderListGsmOptionsSender'
const mapDispatchToProps = (dispatch: Dispatch) => ({
  setList: bindActionCreators(getGsmOptionsSendersList, dispatch),
  setSomeError: bindActionCreators(setGsmOptionsSendersSomeError, dispatch),
})

type PropsComponent = ReturnType<typeof mapDispatchToProps>
const GsmOptionsSenderList: React.FC<PropsComponent> = ({ setList, setSomeError }): JSX.Element => {
  const errorHandler = useErrorHandlerHook(setSomeError, 'Gsm опції сервісів')
  useHttpDateLoader<GsmOptionsSender[]>({
    url: RequestUrlTypes.GSM_OPTIONS_SENDER,
    base: URL_SERVER_GSM,
    func: setList,
    errorHandler,
  })

  return <RenderListGsmOptionsSender />
}

export default connect(null, mapDispatchToProps)(GsmOptionsSenderList)
