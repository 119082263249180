import { Dispatch, useCallback, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { updateLoaderState } from '../store/dispatch/root.dispatch'
import {
  RequestTypes,
  useHttpDeleteRequestProps,
  useHttpDateLoaderProps,
  useHttpRequestWithBodyProps,
  useRequestsHookCallbackProps,
  useHttpGetRequestProps,
  useHttpGetRequestCallbackProps,
  usePostPutHttpRequestProps,
} from './types/request.types'
import { Maybe } from 'yup'

const useEffectOnce = (callback: () => void) => {
  const hasRunOnce = useRef(false)
  useEffect(() => {
    if (!hasRunOnce.current) {
      callback()
      hasRunOnce.current = true
    }
  }, [])
}

export const useRequestsHook = () =>
  useCallback(async ({ url, options, base = '' }: useRequestsHookCallbackProps) => {
    options.headers = {
      'Content-Type': 'application/json',
    }

    return fetch(base + url, options)
  }, [])

export const useHttpGetRequest = <T>({
  url,
  func,
  base,
  errorHandler,
}: useHttpGetRequestProps<T>) => {
  const request = useRequestsHook()
  const dispatch: Dispatch<any> = useDispatch()

  return (props: useHttpGetRequestCallbackProps) => async () => {
    let reqUrl = ''

    if (url) {
      reqUrl = url
    }

    if (props.url) {
      reqUrl = props.url
    }

    try {
      dispatch(updateLoaderState(true))
      const resp = await request({ url: reqUrl, options: { signal: props.signal }, base })
      const data = await resp.json()

      if (data.error) {
        throw new Error(data.message)
      } else {
        func((data && data.data) || [])
      }
    } catch (err: any) {
      errorHandler({ text: err.message })
    } finally {
      dispatch(updateLoaderState(false))
    }
  }
}

export const useHttpPostWithBodyRequest = <T>({
  url,
  func,
  base,
  errorHandler,
}: useHttpDateLoaderProps<T>) => {
  const request = useRequestsHook()
  const dispatch: Dispatch<any> = useDispatch()

  return async <D>({ signal, body }: { signal?: AbortSignal; body?: D }) => {
    try {
      dispatch(updateLoaderState(true))
      const resp = await request({
        url,
        options: { signal, body: JSON.stringify(body), method: RequestTypes.POST },
        base,
      })

      const data = await resp.json()

      if (data.error) {
        throw new Error(data.message)
      } else {
        func((data && data.data) || [])
      }
    } catch (err: any) {
      errorHandler({ text: err.message })
    } finally {
      dispatch(updateLoaderState(false))
    }
  }
}

export const useHttpDateLoader = <T>(options: useHttpDateLoaderProps<T>) => {
  const getListRequest = useHttpGetRequest(options)
  const dispatch: Dispatch<any> = useDispatch()

  useEffectOnce(() => {
    dispatch(updateLoaderState(true))
    const controller: AbortController = new AbortController()
    const signal: AbortSignal = controller.signal

    getListRequest({ signal })()

    return () => {
      controller.abort()
    }
  })
}

export const useHttpDateLoaderArray = (
  options: Array<({ signal }: useHttpGetRequestCallbackProps) => () => Promise<void>>,
) => {
  useEffectOnce(() => {
    const controller: AbortController = new AbortController()

    Promise.all(
      options.map((request) => {
        const signal: AbortSignal = controller.signal

        request({ signal })()
      }),
    ).catch()

    return () => {
      controller.abort()
    }
  })
}

export const useHttpRequestWithBody = <T, D = T>({
  url,
  func,
  base,
}: useHttpRequestWithBodyProps<T>) => {
  const request = useRequestsHook()
  const dispatch: Dispatch<any> = useDispatch()

  return async ({
    body,
    id,
    hasId,
  }: {
    body: D
    id: Maybe<string | undefined>
    hasId?: boolean
  }) => {
    id = hasId || id ? id : ''

    const method = id ? RequestTypes.PUT : RequestTypes.POST
    dispatch(updateLoaderState(true))

    const resp = await request({
      base,
      url: url + (id || ''),
      options: { method, body: JSON.stringify(body) },
    })

    const data = await resp.json()

    if (data.error) {
      throw new Error(data.message)
    } else {
      data && data.data && func(data.data)
    }
  }
}

export const usePostPutHttpRequest = (func: usePostPutHttpRequestProps) => {
  const request = useRequestsHook()
  const dispatch: Dispatch<any> = useDispatch()

  return async <T>({
    body,
    method,
    base,
    url,
    id,
  }: {
    body: T
    method: RequestTypes.PUT | RequestTypes.POST
    base: string
    url: string
    id?: Maybe<string | undefined>
  }) => {
    dispatch(updateLoaderState(true))

    const resp = await request({
      base,
      url: url + (id || ''),
      options: { method, body: JSON.stringify(body) },
    })

    const data = await resp.json()

    if (data.error) {
      throw new Error(data.message)
    } else {
      data && data.data && func(data.data)
    }
  }
}

export const useHttpDeleteRequest = <T, D = number>({
  url,
  func,
  base,
}: useHttpDeleteRequestProps<D>) => {
  const request = useRequestsHook()
  const dispatch = useDispatch<any>()

  return async ({ body, id }: { body?: T; id: Maybe<string | undefined> }) => {
    dispatch(updateLoaderState(true))

    const resp = await request({
      base,
      url: url + (id || ''),
      options: { method: RequestTypes.DELETE, body: JSON.stringify(body) },
    })

    const data = await resp.json()

    if (data.error) {
      throw new Error(data.message)
    } else {
      data && data.data && func(data.data)
    }
  }
}
