import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { OptionTypeDetails } from '../../common/components/types/Tab.types'
import { StateType } from '../../store/models/root.interface'
import GsmAnalyticsByPhone from './GsmAnalyticsTableByPhone'
import GsmAnalyticsTable from './GsmAnalyticsTable'
import TabCardBodyAnalytics from './components/TabCardBodyAnalytics'
import OptionsTabAnalytics from './components/OptionsTabAnalytics'
import RenderTableAnalyticsByModem from './components/RenderTableAnalyticsByModem'
import { setGsmAnalyticsModificationState } from '../../store/dispatch/gsm/gsm-analytics.dispatch'
import { GsmAnalyticsOption } from '../../store/models/gsm/gsm-analytics.interface'
import TabCardHeaderGsmAnalytics from './components/CardHeaderAnalytics'
import TabHeader from '../../common/components/TabHeader'
import FilterByModem from './components/FilterByModem'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setModificationState: bindActionCreators(setGsmAnalyticsModificationState, dispatch),
})

const mapStateToProps = ({ gsmAnalytics }: StateType) => ({
  options: gsmAnalytics,
})

type PropsPage = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const GsmAnalyticsTab: React.FC<PropsPage> = ({ options, setModificationState }) => {
  const details: OptionTypeDetails<GsmAnalyticsOption> = {
    ...options,
    setModificationState,
    headerTitle: `Список активних сімок ${options.list.length}`,
    cardFormClass: 'col-xl-6 col-lg-6',
    cardTopClass: 'col-xl-6 col-lg-6',
    cardWrapperClass: 'row flex-row-reverse',
  }

  return (
    <OptionsTabAnalytics
      details={details}
      renderBlockAboveCard={<RenderTableAnalyticsByModem />}
      renderForm={<GsmAnalyticsByPhone />}
      topList={<GsmAnalyticsTable />}
      renderTabHeader={<TabHeader title='Аналітика' additionBlock={<FilterByModem />} />}
      renderCardBody={TabCardBodyAnalytics}
      cardHeader={TabCardHeaderGsmAnalytics}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(GsmAnalyticsTab)
