import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { ModificationStatus, StateType } from '../../../../store/models/root.interface'
import SlotsList from './SlotsList'
import {
  clearSelectedSlot,
  removeSlot,
  setSlotsModificationState,
  setSlotsSomeError,
} from '../../../../store/dispatch/scraper/scr-slot.dispatch'
import { bindActionCreators, Dispatch } from 'redux'
import SlotsForm from './SlotsForm'
import { addNotification } from '../../../../store/dispatch/notifications.dispatch'
import { updateCurrentPath, updateLoaderState } from '../../../../store/dispatch/root.dispatch'
import { RequestUrlTypes } from '../../../../hooks/types/request.types'
import { useHttpDeleteRequest } from '../../../../hooks/request.hook'
import OptionsTab from '../../../../common/components/Tab'
import { ScrSlot } from '../../../../store/models/scraper/scr-slot.interface'
import { OptionTypeDetails } from '../../../../common/components/types/Tab.types'
import { useErrorHandlerHook } from '../../../../hooks/error-handler'
import CountiesOptionsList from '../options/CountiesOptionsList'
import { ScrapperOptionType, ScrapperTabCustomProps } from '../../types/ScraperOptions.type'
import { URL_SERVER_SCRAPER } from '../../../../config'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  remove: bindActionCreators(removeSlot, dispatch),
  clearSelected: bindActionCreators(clearSelectedSlot, dispatch),
  setModificationState: bindActionCreators(setSlotsModificationState, dispatch),
  addNotification: bindActionCreators(addNotification, dispatch),
  updateCurrentPath: bindActionCreators(updateCurrentPath, dispatch),
  setSomeError: bindActionCreators(setSlotsSomeError, dispatch),
  updateLoader: bindActionCreators(updateLoaderState, dispatch),
})

const mapStateToProps = ({ scrSlot, root }: StateType) => ({
  scrSlot,
  path: root.page,
})

type PageProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  ScrapperTabCustomProps

const SlotsTab: React.FC<PageProps> = ({
  scrSlot,
  path,
  remove,
  clearSelected,
  setModificationState,
  updateLoader,
  addNotification,
  updateCurrentPath,
  setSomeError,
  type,
  renderTabHeader,
}) => {
  const errorHandler = useErrorHandlerHook(setSomeError, 'Слот')
  const deleteRequest = useHttpDeleteRequest({
    url: RequestUrlTypes.SCRAPER_SLOT,
    base: URL_SERVER_SCRAPER,
    func: remove,
  })

  const onRemove = async () => {
    const { selected } = scrSlot

    if (!selected) {
      return
    }

    try {
      await deleteRequest({ id: selected._id })
      clearSelected()
      setModificationState(ModificationStatus.None)
      addNotification('Слот', 'видалено')
    } catch (e: any) {
      errorHandler({ text: e.message })
    } finally {
      updateLoader(false)
    }
  }

  useEffect(() => {
    clearSelected()
    updateCurrentPath('slot', 'list')
  }, [path.area])

  const details: OptionTypeDetails<ScrSlot> = {
    ...scrSlot,
    onRemove,
    setModificationState,
    headerTitle: 'Список слотів',
    bottomDescription: 'Список країн',
  }

  if (type !== ScrapperOptionType.SCR_SLOT) return <></>

  return (
    <OptionsTab
      details={details}
      renderForm={<SlotsForm />}
      bottomList={<CountiesOptionsList />}
      topList={<SlotsList />}
      renderTabHeader={renderTabHeader}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(SlotsTab)
