import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { RequestUrlTypes } from '../../../../hooks/types/request.types'
import { useHttpDateLoader } from '../../../../hooks/request.hook'
import { useErrorHandlerHook } from '../../../../hooks/error-handler'
import { URL_SERVER_SCRAPER } from '../../../../config'
import {
  getScrServiceDiaryList,
  setScrServiceDiarySomeError,
} from '../../../../store/dispatch/scraper/scr-service-diary.dispatch'
import { ScrServiceDiary } from '../../../../store/models/scraper/scr-service-diary.interface'
import RenderScrServiceDiaryList from './components/RenderScrServiceDiaryList'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setList: bindActionCreators(getScrServiceDiaryList, dispatch),
  setSomeError: bindActionCreators(setScrServiceDiarySomeError, dispatch),
})

type PageProps = ReturnType<typeof mapDispatchToProps>

const ScrServiceDiaryList: React.FC<PageProps> = ({ setList, setSomeError }): JSX.Element => {
  const errorHandler = useErrorHandlerHook(setSomeError, 'Сервіси 2')
  useHttpDateLoader<ScrServiceDiary[]>({
    url: RequestUrlTypes.SCRAPER_SERVICE_DIARY,
    base: URL_SERVER_SCRAPER,
    func: setList,
    errorHandler,
  })

  return <RenderScrServiceDiaryList />
}

export default connect(null, mapDispatchToProps)(ScrServiceDiaryList)
