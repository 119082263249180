import { Dispatch } from 'redux'
import { ModificationStatus } from '../../models/root.interface'
import {
  addPartnerProgramAction,
  changeSelectedPartnerProgramAction,
  clearSelectedPartnerProgramAction,
  editPartnerProgramAction,
  getPartnerProgramsListAction,
  removePartnerProgramAction,
  setPartnerProgramsModificationStateAction,
  setPartnerProgramsSomeErrorAction,
} from '../../actions/tg/tg-partner-program.action'
import { PartnerProgram } from '../../models/tg/tg-partner-program.interface'

type ActionItem = PartnerProgram

export const getPartnerProgramsList = (data: ActionItem[]) => (dispatch: Dispatch) => {
  dispatch(getPartnerProgramsListAction(data))
}
export const addPartnerProgram = (data: ActionItem) => (dispatch: Dispatch) => {
  dispatch(addPartnerProgramAction(data))
}
export const editPartnerProgram = (value: ActionItem) => (dispatch: Dispatch) => {
  dispatch(editPartnerProgramAction(value))
}
export const removePartnerProgram = (value: string) => (dispatch: Dispatch) => {
  dispatch(removePartnerProgramAction(value))
}
export const changeSelectedPartnerProgram = (value: string) => (dispatch: Dispatch) => {
  dispatch(changeSelectedPartnerProgramAction(value))
}
export const clearSelectedPartnerProgram = () => (dispatch: Dispatch) => {
  dispatch(clearSelectedPartnerProgramAction())
}
export const setPartnerProgramsModificationState =
  (value: ModificationStatus) => (dispatch: Dispatch) => {
    dispatch(setPartnerProgramsModificationStateAction(value))
  }
export const setPartnerProgramsSomeError = (value: string) => (dispatch: Dispatch) => {
  dispatch(setPartnerProgramsSomeErrorAction(value))
}
