import { Maybe } from 'yup'
import { TgContentLanguagesValues } from './tg-options-country.interface'

export class TgMailingMessage {
  _id: Maybe<string | undefined>
  title = ''
  content: Record<TgContentLanguagesValues, string> | undefined | null
  selfDestruct = true
  status: Maybe<boolean | undefined>
  delay: Maybe<Date | undefined> = new Date()
}
