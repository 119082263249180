import { ModificationStatus } from '../../models/root.interface'
import { PartnerProgram } from '../../models/tg/tg-partner-program.interface'
import { tgPartnerProgramTypes } from '../../types/tg/tg-partner-programs.types'

type ActionItem = PartnerProgram

export function getPartnerProgramsListAction(value: ActionItem[]): IGetItemsActionType<ActionItem> {
  return { type: tgPartnerProgramTypes.GET_TG_PARTNER_PROGRAMS_LIST, value }
}

export function addPartnerProgramAction(value: ActionItem): IAddItemActionType<ActionItem> {
  return { type: tgPartnerProgramTypes.ADD_TG_PARTNER_PROGRAM, value }
}

export function editPartnerProgramAction(value: ActionItem): IEditItemActionType<ActionItem> {
  return { type: tgPartnerProgramTypes.EDIT_TG_PARTNER_PROGRAM, value }
}

export function removePartnerProgramAction(value: string): IRemoveItemActionType {
  return { type: tgPartnerProgramTypes.REMOVE_TG_PARTNER_PROGRAM, value }
}

export function changeSelectedPartnerProgramAction(value: string): IChangeSelectedItemActionType {
  return { type: tgPartnerProgramTypes.CHANGE_TG_PARTNER_PROGRAM_PENDING_EDIT, value }
}

export function clearSelectedPartnerProgramAction(): IClearSelectedItemActionType {
  return { type: tgPartnerProgramTypes.CLEAR_TG_PARTNER_PROGRAM_PENDING_EDIT }
}

export function setPartnerProgramsModificationStateAction(
  value: ModificationStatus,
): ISetModificationStateActionType {
  return { type: tgPartnerProgramTypes.SET_TG_PARTNER_PROGRAM_MODIFICATION_STATE, value: value }
}

export function setPartnerProgramsSomeErrorAction(value: string): ISetSomeErrorActionType {
  return { type: tgPartnerProgramTypes.SET_TG_PARTNER_PROGRAM_SOME_ERROR, value: value }
}

interface IGetItemsActionType<T> {
  type: string
  value: T[]
}

interface IAddItemActionType<T> {
  type: string
  value: T
}

interface IEditItemActionType<T> {
  type: string
  value: T
}

interface IRemoveItemActionType {
  type: string
  value: string
}

interface IChangeSelectedItemActionType {
  type: string
  value: string
}

interface IClearSelectedItemActionType {
  type: string
}

interface ISetModificationStateActionType {
  type: string
  value: ModificationStatus
}

interface ISetSomeErrorActionType {
  type: string
  value: string
}
