import React from 'react'
import { connect } from 'react-redux'
import ListIsEmpty from '../../../../../common/components/ListIsEmpty'
import { StateType, ModificationStatus } from '../../../../../store/models/root.interface'
import { bindActionCreators, Dispatch } from 'redux'
import {
  changeSelectedGsmOptionsService,
  clearSelectedGsmOptionsService,
  setGsmOptionsServicesModificationState,
} from '../../../../../store/dispatch/gsm/gsm-options-service.dispatch'
import { GsmOptionsService } from '../../../../../store/models/gsm/gsm-options-service.interface'
import useFilteringListHook from '../../../../../hooks/filter-hook'
import { OptionsReceiver } from '../../../../../utils/option-receiver'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  clearSelected: bindActionCreators(clearSelectedGsmOptionsService, dispatch),
  changeSelected: bindActionCreators(changeSelectedGsmOptionsService, dispatch),
  setModificationState: bindActionCreators(setGsmOptionsServicesModificationState, dispatch),
})

const mapStateToProps = ({ gsmOptionsService, gsmOptionsCountry }: StateType) => ({
  options: gsmOptionsService,
  countries: gsmOptionsCountry.list,
})

type PropsComponent = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const RenderListGsmOptionsService: React.FC<PropsComponent> = ({
  options: { list, selected, filters },
  countries,
  clearSelected,
  changeSelected,
  setModificationState,
}): JSX.Element => {
  list = list.map((item) => {
    item.country = typeof item.country === 'object' ? item.country?._id ?? '' : item.country
    return item
  })

  list = useFilteringListHook(list, filters)

  const getName = OptionsReceiver.getValueByKey(countries, '_id')

  const onSelect = (_id: string): void => {
    if (selected && selected._id === _id) {
      clearSelected()
    } else {
      changeSelected(_id)
    }

    setModificationState(ModificationStatus.None)
  }

  if (!list.length) return <ListIsEmpty />

  const productElements = list.map((item: GsmOptionsService, i) => {
    let country: string

    if (typeof item.country === 'object') {
      country = item.country.name
    } else {
      country = getName(item.country, 'name')
    }

    return (
      <tr
        data-id={item._id}
        className={`table-row pl-2 ${selected?._id === item._id ? 'table-primary' : ''}`}
        onClick={() => onSelect(item._id || '')}
        key={`product_${item._id}`}
      >
        <th scope='row'>{++i}</th>
        <td data-id={item.name}>{item.name}</td>
        <td data-id={item.shortName}>{item.shortName}</td>
        <td data-id={country}>{country}</td>
        <td>{Number(item?.cost || 0).toFixed(2)} USD</td>
      </tr>
    )
  })

  return (
    <div className='table-responsive portlet'>
      <table className='table m-0'>
        <thead className='thead-light'>
          <tr>
            <th scope='col'>№</th>
            <th scope='col'>Назва</th>
            <th scope='col'>Коротка назва</th>
            <th scope='col'>Країна</th>
            <th scope='col'>Ціна</th>
          </tr>
        </thead>
        <tbody>{productElements}</tbody>
      </table>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(RenderListGsmOptionsService)
