import React from 'react'
import { RequestUrlTypes } from '../../../hooks/types/request.types'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import {
  getScraperCredentialsList,
  setScraperCredentialsSomeError,
} from '../../../store/dispatch/scraper/scr-credential.dispatch'
import {
  getOptionsList,
  IOptionsData,
  setOptionsSomeError,
} from '../../../store/dispatch/scraper/scr-options.dispatch'
import { useErrorHandlerHook } from '../../../hooks/error-handler'
import { DispatchSmsHubWrapperProps } from '../../../common/types/SmsHubWrapper.types'
import { useHttpDateLoaderArray, useHttpGetRequest } from '../../../hooks/request.hook'
import { ScrCredential } from '../../../store/models/scraper/scr-credential.interface'
import { URL_SERVER_SCRAPER } from '../../../config'

const mapDispatchToProps = (dispatch: Dispatch): DispatchSmsHubWrapperProps => ({
  setListCredentials: bindActionCreators(getScraperCredentialsList, dispatch),
  setListOptions: bindActionCreators(getOptionsList, dispatch),
  setSomeErrorCredentials: bindActionCreators(setScraperCredentialsSomeError, dispatch),
  setSomeErrorOptions: bindActionCreators(setOptionsSomeError, dispatch),
})

type PropsComponent = ReturnType<typeof mapDispatchToProps> & {
  children?: JSX.Element | JSX.Element[]
}

const ScrOptionsWrapper: React.FC<PropsComponent> = ({
  setListCredentials,
  setListOptions,
  setSomeErrorCredentials,
  setSomeErrorOptions,
  children,
}): JSX.Element => {
  const errorHandlerCredentials = useErrorHandlerHook(setSomeErrorCredentials, 'Облікові записи')
  const errorHandlerOptions = useErrorHandlerHook(setSomeErrorOptions, 'Опції')

  const requestCredentials = useHttpGetRequest<ScrCredential[]>({
    url: RequestUrlTypes.SCRAPER_CREDENTIAL,
    base: URL_SERVER_SCRAPER,
    func: setListCredentials,
    errorHandler: errorHandlerCredentials,
  })

  const requestOptions = useHttpGetRequest<IOptionsData>({
    url: RequestUrlTypes.SCRAPER_OPTIONS,
    base: URL_SERVER_SCRAPER,
    func: setListOptions,
    errorHandler: errorHandlerOptions,
  })

  useHttpDateLoaderArray([requestCredentials, requestOptions])

  return <>{children}</>
}

export default connect(null, mapDispatchToProps)(ScrOptionsWrapper)
