import { Dispatch } from 'redux'
import { ModificationStatus } from '../../models/root.interface'
import {
  changeSelectedScrServiceDiaryAction,
  clearScrServiceDiaryFiltersAction,
  clearSelectedScrServiceDiaryAction,
  editScrServiceDiaryFiltersAction,
  getScrServiceDiaryListAction,
  setScrServiceDiaryModificationStateAction,
  setScrServiceDiarySomeErrorAction,
} from '../../actions/scraper/scr-service-diary.action'
import {
  ScrServiceDiaryFilters,
  ScrServiceDiaryItem,
} from '../../models/scraper/scr-service-diary.interface'

type ActionItem = ScrServiceDiaryItem
type ItemFilters = ScrServiceDiaryFilters

export const getScrServiceDiaryList = (value: ActionItem[]) => (dispatch: Dispatch) => {
  dispatch(getScrServiceDiaryListAction(value))
}

export const changeSelectedScrServiceDiary = (value: ActionItem) => (dispatch: Dispatch) => {
  dispatch(changeSelectedScrServiceDiaryAction(value))
}

export const clearSelectedScrServiceDiary = () => (dispatch: Dispatch) => {
  dispatch(clearSelectedScrServiceDiaryAction())
}

export const setScrServiceDiaryModificationState =
  (value: ModificationStatus) => (dispatch: Dispatch) => {
    dispatch(setScrServiceDiaryModificationStateAction(value))
  }

export const setScrServiceDiarySomeError = (value: string) => (dispatch: Dispatch) => {
  dispatch(setScrServiceDiarySomeErrorAction(value))
}

export const editScrServiceDiaryFilters = (value: ItemFilters) => (dispatch: Dispatch) => {
  dispatch(editScrServiceDiaryFiltersAction(value))
}

export const clearScrServiceDiaryFilters = () => (dispatch: Dispatch) => {
  dispatch(clearScrServiceDiaryFiltersAction())
}
