import React from 'react'
import ListIsEmpty from '../../../../common/components/ListIsEmpty'
import { ScrOptionsCountry } from '../../../../store/models/scraper/scr-options-country.interface'
import { StateType } from '../../../../store/models/root.interface'
import { useSelector } from 'react-redux'

const CountiesOptionsList = (): JSX.Element => {
  const { countriesList } = useSelector((state: StateType) => state.scrOptions)

  if (!countriesList.length) {
    return <ListIsEmpty />
  }

  const orderList: JSX.Element[] = countriesList.map((country: ScrOptionsCountry) => {
    return (
      <tr className={'table-row'} key={`order_${country._id}`}>
        <td scope='row'>{country._id}</td>
        <th>{country.name}</th>
        <th>{country.shortName}</th>
      </tr>
    )
  })

  return (
    <div className='table-responsive portlet'>
      <table className='table'>
        <thead className='thead-light'>
          <tr>
            <th scope='col'>ID з бази даних</th>
            <th scope='col'>Країна</th>
            <th scope='col'>#</th>
          </tr>
        </thead>
        <tbody>{orderList}</tbody>
      </table>
    </div>
  )
}

export default CountiesOptionsList
