export const tgPromoCodesTypes = {
  GET_TG_PROMO_CODES_LIST: 'GET_TG_PROMO_CODES_LIST',
  ADD_TG_PROMO_CODE: 'ADD_TG_PROMO_CODE',
  EDIT_TG_PROMO_CODE: 'EDIT_TG_PROMO_CODE',
  REMOVE_TG_PROMO_CODE: 'REMOVE_TG_PROMO_CODE',
  CHANGE_TG_PROMO_CODE_PENDING_EDIT: 'CHANGE_TG_PROMO_CODE_PENDING_EDIT',

  CLEAR_TG_PROMO_CODE_PENDING_EDIT: 'CLEAR_TG_PROMO_CODE_PENDING_EDIT',
  SET_TG_PROMO_CODE_MODIFICATION_STATE: 'SET_TG_PROMO_CODE_MODIFICATION_STATE',
  SET_TG_PROMO_CODE_SOME_ERROR: 'SET_TG_PROMO_CODE_SOME_ERROR',
}
