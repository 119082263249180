import { Typeahead } from 'react-bootstrap-typeahead'
import React, { useState } from 'react'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import { MultiSelectProps } from './types/MultiSelect.types'
import { Controller, PathValue } from 'react-hook-form'
import { Option } from 'react-bootstrap-typeahead/types/types'

function MultiSelectRH<T>(props: MultiSelectProps<T>): JSX.Element {
  const initValue: Option[] = props.defaultSelected || []
  const [defaultValue, setDefaultValue] = useState<Option[]>(initValue)
  const error: string = (props.formState.errors[props.field]?.message as string) || ''

  let selected = defaultValue.length ? defaultValue : props.value

  if (props.savedKey) {
    const savedKey = props.savedKey as keyof Option
    selected = props.options.filter((e: Option) => selected.includes(e[savedKey]))
  }

  const onValueChanged = (value: Option[]): void => {
    let t: PathValue<Option, any> = value

    if (props.savedKey && value.length) {
      const savedKey = props.savedKey as keyof Option
      t = value.map((e) => e[savedKey])
    }

    setDefaultValue([])
    props.setValue(props.field, t)
  }

  return (
    <Controller
      control={props.control}
      name={props.field}
      rules={{
        required: props.required,
      }}
      render={({ field }) => (
        <>
          <label htmlFor={props.id} className='form-label mb-2'>
            {props.label}
          </label>
          <Typeahead
            {...field}
            id={props.id}
            labelKey={props.labelKey}
            selected={selected}
            className='border rounded'
            options={props.options}
            placeholder={props.placeholder || ''}
            onChange={onValueChanged}
            multiple
          />

          {error && props.required ? <div className='invalid-feedback'>{error}</div> : null}
        </>
      )}
    />
  )
}

export default MultiSelectRH
