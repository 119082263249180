import { Dispatch } from 'redux'
import { ModificationStatus } from '../../models/root.interface'
import {
  addGsmOptionsSenderAction,
  changeSelectedGsmOptionsSenderAction,
  clearSelectedGsmOptionsSenderAction,
  editGsmOptionsSenderAction,
  getGsmOptionsSendersListAction,
  removeGsmOptionsSenderAction,
  setGsmOptionsSendersModificationStateAction,
  setGsmOptionsSendersSomeErrorAction,
} from '../../actions/gsm/gsm-options-sender.actions'
import { GsmOptionsSender } from '../../models/gsm/gsm-options-sender.interface'

type ActionItem = GsmOptionsSender

export const getGsmOptionsSendersList = (data: ActionItem[]) => (dispatch: Dispatch) => {
  dispatch(getGsmOptionsSendersListAction(data))
}
export const addGsmOptionsSender = (data: ActionItem) => (dispatch: Dispatch) => {
  dispatch(addGsmOptionsSenderAction(data))
}
export const editGsmOptionsSender = (value: ActionItem) => (dispatch: Dispatch) => {
  dispatch(editGsmOptionsSenderAction(value))
}
export const removeGsmOptionsSender = (value: string) => (dispatch: Dispatch) => {
  dispatch(removeGsmOptionsSenderAction(value))
}
export const changeSelectedGsmOptionsSender = (value: string) => (dispatch: Dispatch) => {
  dispatch(changeSelectedGsmOptionsSenderAction(value))
}
export const clearSelectedGsmOptionsSender = () => (dispatch: Dispatch) => {
  dispatch(clearSelectedGsmOptionsSenderAction())
}
export const setGsmOptionsSendersModificationState =
  (value: ModificationStatus) => (dispatch: Dispatch) => {
    dispatch(setGsmOptionsSendersModificationStateAction(value))
  }
export const setGsmOptionsSendersSomeError = (value: string) => (dispatch: Dispatch) => {
  dispatch(setGsmOptionsSendersSomeErrorAction(value))
}
