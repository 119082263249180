import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import CountiesOptionsList from './CountiesOptionsList'
import ServicesOptionsList from './ServicesOptionsList'
import { bindActionCreators, Dispatch } from 'redux'
import { updateCurrentPath } from '../../../../store/dispatch/root.dispatch'
import { ScrapperOptionType, ScrapperTabCustomProps } from '../../types/ScraperOptions.type'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateCurrentPath: bindActionCreators(updateCurrentPath, dispatch),
})

type PropsOptionsPage = ReturnType<typeof mapDispatchToProps> & ScrapperTabCustomProps

const OptionsTab: React.FC<PropsOptionsPage> = ({ updateCurrentPath, type, renderTabHeader }) => {
  updateCurrentPath('options', 'list')

  if (type !== ScrapperOptionType.SCR_OPTIONS) return <></>

  return (
    <Fragment>
      {renderTabHeader}

      <div className='row'>
        <div className='col-xl-6 col-lg-6'>
          <div className='card shadow mb-4'>
            <div className='card-header py-3'>
              <h6 className='m-0 font-weight-bold text-green'>Список SMS сервісів</h6>
            </div>
            <div className='card-body p-0'>
              <ServicesOptionsList />
            </div>
          </div>
        </div>

        <div className='col-xl-6 col-lg-6'>
          <div className='card shadow mb-4'>
            <div className='card-header py-3'>
              <h6 className='m-0 font-weight-bold text-green'>Список країн</h6>
            </div>
            <div className='card-body p-0'>
              <CountiesOptionsList />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default connect(null, mapDispatchToProps)(OptionsTab)
