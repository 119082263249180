import React from 'react'
import { TabHeaderProps } from './types/TabHeader.types'

const TabHeader = ({ title, subTitle, additionBlock }: TabHeaderProps) => {
  return (
    <>
      <h1 className='h3 mb-2 text-gray-800'>{title}</h1>
      {subTitle ? <p className='mb-4'>{subTitle}</p> : null}
      {additionBlock ? additionBlock : null}
    </>
  )
}

export default TabHeader
