import { ModificationStatus } from '../../models/root.interface'
import {
  TgOptionService,
  TgOptionsServiceFilters,
} from '../../models/tg/tg-options-service.interface'
import { tgOptionsServiceTypes } from '../../types/tg/tg-options-service.types'

type ActionItem = TgOptionService
type FilterItem = TgOptionsServiceFilters

export function getTgOptionServiceAction(value: ActionItem[]): IGetItemsActionType<ActionItem> {
  return { type: tgOptionsServiceTypes.GET_TG_OPTIONS_SERVICE_LIST, value }
}
export function addTgOptionServiceAction(value: ActionItem): IAddItemActionType<ActionItem> {
  return { type: tgOptionsServiceTypes.ADD_TG_OPTIONS_SERVICE_LIST, value }
}
export function editTgOptionServiceAction(value: ActionItem): IEditItemActionType<ActionItem> {
  return { type: tgOptionsServiceTypes.EDIT_TG_OPTIONS_SERVICE_LIST, value }
}
export function removeTgOptionServiceAction(value: string): IRemoveItemActionType {
  return { type: tgOptionsServiceTypes.REMOVE_TG_OPTIONS_SERVICE_LIST, value }
}
export function changeSelectedTgOptionServiceAction(value: string): IChangeSelectedItemActionType {
  return { type: tgOptionsServiceTypes.CHANGE_TG_OPTIONS_SERVICE_PENDING_EDIT, value }
}
export function clearSelectedTgOptionServiceAction(): IClearSelectedItemActionType {
  return { type: tgOptionsServiceTypes.CLEAR_TG_OPTIONS_SERVICE_PENDING_EDIT }
}
export function setTgOptionServicesModificationStateAction(
  value: ModificationStatus,
): ISetModificationStateActionType {
  return { type: tgOptionsServiceTypes.SET_TG_OPTIONS_SERVICE_MODIFICATION_STATE, value: value }
}
export function setTgOptionServiceSomeErrorAction(value: string): ISetSomeErrorActionType {
  return { type: tgOptionsServiceTypes.SET_TG_OPTIONS_SERVICE_SOME_ERROR, value: value }
}

export function editTgOptionServiceFiltersAction(
  value: FilterItem,
): IEditFiltersTypeActionType<FilterItem> {
  return { type: tgOptionsServiceTypes.EDIT_ITEMS_FILTER, value }
}

export function clearTgOptionServiceFiltersAction(): IClearFiltersType {
  return { type: tgOptionsServiceTypes.CLEAR_ITEMS_FILTERS }
}

interface IGetItemsActionType<T> {
  type: string
  value: T[]
}

interface IAddItemActionType<T> {
  type: string
  value: T
}

interface IEditItemActionType<T> {
  type: string
  value: T
}

interface IRemoveItemActionType {
  type: string
  value: string
}

interface IChangeSelectedItemActionType {
  type: string
  value: string
}

interface IClearSelectedItemActionType {
  type: string
}

interface ISetModificationStateActionType {
  type: string
  value: ModificationStatus
}

interface ISetSomeErrorActionType {
  type: string
  value: string
}

interface IEditFiltersTypeActionType<T> {
  type: string
  value: T
}
interface IClearFiltersType {
  type: string
}
