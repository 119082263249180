import React from 'react'
import { connect } from 'react-redux'
import ListIsEmpty from '../../../../../common/components/ListIsEmpty'
import { StateType, ModificationStatus } from '../../../../../store/models/root.interface'
import { bindActionCreators, Dispatch } from 'redux'
import {
  changeSelectedGsmOptionsCountry,
  clearSelectedGsmOptionsCountry,
  setGsmOptionsCountriesModificationState,
} from '../../../../../store/dispatch/gsm/gsm-options-country.dispatch'
import { GsmOptionsCountry } from '../../../../../store/models/gsm/gsm-options-country.interface'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  clearSelected: bindActionCreators(clearSelectedGsmOptionsCountry, dispatch),
  changeSelected: bindActionCreators(changeSelectedGsmOptionsCountry, dispatch),
  setModificationState: bindActionCreators(setGsmOptionsCountriesModificationState, dispatch),
})

const mapStateToProps = ({ gsmOptionsCountry }: StateType) => ({
  options: gsmOptionsCountry,
})

type PropsComponent = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const RenderListGsmOptionsCountry: React.FC<PropsComponent> = ({
  options: { list, selected },
  clearSelected,
  changeSelected,
  setModificationState,
}): JSX.Element => {
  list = list.sort((a, b) => a.name.localeCompare(b.name))
  const onSelect = (_id: string): void => {
    if (selected && selected._id === _id) {
      clearSelected()
    } else {
      changeSelected(_id)
    }

    setModificationState(ModificationStatus.None)
  }

  if (!list.length) return <ListIsEmpty />

  const productElements: JSX.Element[] = list.map((item: GsmOptionsCountry, i: number) => {
    return (
      <tr
        data-id={item._id}
        className={`table-row pl-2 ${selected?._id === item._id ? 'table-primary' : ''}`}
        onClick={() => onSelect(item._id || '')}
        key={item._id}
      >
        <th scope='row'>{++i}</th>
        <td data-id={item.name}>{item.name}</td>
        <td data-id={item.shortName}>{item.shortName}</td>
      </tr>
    )
  })

  return (
    <div className='table-responsive portlet'>
      <table className='table m-0'>
        <thead className='thead-light'>
          <tr>
            <th scope='col'>№</th>
            <th scope='col'>Назва</th>
            <th scope='col'>Коротка назва</th>
          </tr>
        </thead>
        <tbody>{productElements}</tbody>
      </table>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(RenderListGsmOptionsCountry)
