import React from 'react'
import { connect } from 'react-redux'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { ModificationStatus, StateType } from '../../../../store/models/root.interface'
import { bindActionCreators, Dispatch } from 'redux'
import { addNotification } from '../../../../store/dispatch/notifications.dispatch'
import { useHttpRequestWithBody } from '../../../../hooks/request.hook'
import { RequestUrlTypes } from '../../../../hooks/types/request.types'
import { useForm } from 'react-hook-form'
import { TabFormWrapperProps } from '../../../../common/components/types/TabFormWrapper.types'
import TabFormWrapper from '../../../../common/components/TabFormWrapper'
import { useErrorHandlerHook } from '../../../../hooks/error-handler'
import { URL_SERVER_TG } from '../../../../config'
import { updateLoaderState } from '../../../../store/dispatch/root.dispatch'
import {
  addTgMailing,
  clearSelectedTgMailing,
  editTgMailing,
  setTgMailingsModificationState,
  setTgMailingsSomeError,
} from '../../../../store/dispatch/tg/tg-mailing.dispatch'
import { TgMailingMessage } from '../../../../store/models/tg/tg-mailing.interface'
import RenderFormTgMailing from './components/RenderFormTgMailing'
import { TgContentLanguages } from '../../../../store/models/tg/tg-options-country.interface'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setSomeError: bindActionCreators(setTgMailingsSomeError, dispatch),
  clearSelected: bindActionCreators(clearSelectedTgMailing, dispatch),
  setModificationState: bindActionCreators(setTgMailingsModificationState, dispatch),
  add: bindActionCreators(addTgMailing, dispatch),
  edit: bindActionCreators(editTgMailing, dispatch),
  addNotification: bindActionCreators(addNotification, dispatch),
  setLoader: bindActionCreators(updateLoaderState, dispatch),
})

const mapStateToProps = ({ tgMailing }: StateType) => ({
  options: tgMailing,
})

type PropsComponent = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const schema = yup.object<TgMailingMessage>().shape({
  _id: yup.string().notRequired(),
  title: yup.string().min(0).max(100).required('Value title cannot be empty'),
  content: yup
    .object({
      [TgContentLanguages.Ua]: yup.string().required('Value content ua cannot be empty'),
      [TgContentLanguages.En]: yup.string().required('Value content en cannot be empty'),
      [TgContentLanguages.RU]: yup.string().required('Value content ru cannot be empty'),
    })
    .notRequired(),
  selfDestruct: yup.boolean().required('Value selfDestruct cannot be empty'),
  status: yup.boolean().notRequired(),
  delay: yup.date().min(new Date(), 'Date cannot be in the past').notRequired(),
  // delay: yup
  //   .date()
  //   .min(new Date(), 'Date cannot be in the past')
  //   .when('hasDelay', {
  //     is: true,
  //     then: (schema) => schema.required('Value delay cannot be empty'),
  //   })
  //   .notRequired(),
})

const TgMailingForm: React.FC<PropsComponent> = ({
  options,
  setSomeError,
  clearSelected,
  addNotification,
  setLoader,
  setModificationState,
  add,
  edit,
}) => {
  const { selected, modificationState, error, list } = options
  const isCreate: boolean = modificationState === ModificationStatus.Create
  const errorHandler = useErrorHandlerHook(setSomeError, 'Тг опції розсилки')
  const saveRequest = useHttpRequestWithBody<TgMailingMessage>({
    url: RequestUrlTypes.TG_MAILING,
    base: URL_SERVER_TG,
    func: isCreate ? add : edit,
  })

  let item: TgMailingMessage | null = selected

  if (!item || isCreate) {
    item = new TgMailingMessage()
  }

  const form = useForm<TgMailingMessage>({ defaultValues: item, resolver: yupResolver(schema) })

  const saveForm = async (formState: TgMailingMessage): Promise<void> => {
    const uniq = list.find((item) => {
      return item.title === formState.title
    })

    if (isCreate && uniq) {
      errorHandler({ text: 'Дана опція існує' })
      return
    }

    if (item) {
      try {
        await saveRequest({
          body: {
            ...item,
            ...formState,
          },
          id: item?._id ? String(item?._id) : '',
        })

        clearSelected()
        setModificationState(ModificationStatus.None)
        addNotification('Тг опцію розислки', isCreate ? 'додано' : 'редаговано')
      } catch (e: any) {
        errorHandler({ text: e.message })
      } finally {
        setLoader(false)
      }
    }
  }

  const resetForm = (): void => {
    setModificationState(ModificationStatus.None)
    form.reset()
  }

  const formOptions: TabFormWrapperProps<TgMailingMessage> = {
    form,
    error,
    title: 'розсилку',
    isCreate,
    saveForm,
    resetForm,
  }

  return (
    <TabFormWrapper {...formOptions}>
      <RenderFormTgMailing form={form} />
    </TabFormWrapper>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(TgMailingForm)
