import React from 'react'
import { connect } from 'react-redux'
import {
  StateType,
  ModificationStatus,
  TgOptionUnits,
} from '../../../../../store/models/root.interface'
import { bindActionCreators, Dispatch } from 'redux'
import ListIsEmpty from '../../../../../common/components/ListIsEmpty'
import {
  changeSelectedPromoCode,
  clearSelectedPromoCode,
  setPromoCodesModificationState,
} from '../../../../../store/dispatch/tg/tg-promo-codes.dispatch'
import { addNotification } from '../../../../../store/dispatch/notifications.dispatch'
import { TgPromoCode } from '../../../../../store/models/tg/tg-promo-code.interface'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  clearSelected: bindActionCreators(clearSelectedPromoCode, dispatch),
  changeSelected: bindActionCreators(changeSelectedPromoCode, dispatch),
  setModificationState: bindActionCreators(setPromoCodesModificationState, dispatch),
  notification: bindActionCreators(addNotification, dispatch),
})

const mapStateToProps = ({ tgPromoCodes, root }: StateType) => ({
  codes: tgPromoCodes,
  path: root.page,
})

type PropsComponent = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const RenderListTgPromoCodes: React.FC<PropsComponent> = ({
  codes: { list, selected },
  clearSelected,
  changeSelected,
  setModificationState,
  notification,
}): JSX.Element => {
  const onSelect = (_id: string): void => {
    if (selected && selected._id === _id) {
      clearSelected()
    } else {
      changeSelected(_id)
    }

    setModificationState(ModificationStatus.None)
  }

  if (!list.length) return <ListIsEmpty />

  const copyLink = async (link: string) => {
    try {
      await navigator.clipboard.writeText(link)
      notification('Link copied', link)
    } catch (e) {
      notification('Link not copied', link)
    }
  }

  const copyCode = async (code: string) => {
    try {
      await navigator.clipboard.writeText(code)
      notification('Code copied', code)
    } catch (e) {
      notification('Code not copied', code)
    }
  }

  const getPeriodDetails = ({
    endDate,
    date,
    duration,
  }: Pick<TgPromoCode, 'endDate' | 'duration' | 'date'>): string => {
    if (!endDate) return '--'
    const lastDate = date ? new Date(date) : new Date()

    lastDate.setDate(lastDate.getDate() + Number(duration?.days ?? 0))
    lastDate.setMonth(lastDate.getMonth() + Number(duration?.months ?? 0))
    lastDate.setFullYear(lastDate.getFullYear() + Number(duration?.years ?? 0))

    return new Date(lastDate).toLocaleString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    })
  }

  const codesList: JSX.Element[] = list.map((item) => {
    const { _id } = item
    const unit = item.unit === TgOptionUnits.PERCENTAGE ? ' %' : ''
    const countries = item.countries.map((item): string => item.split('-')[1])
    const services = item.services.map((item): string => item.split('-')[1])

    return (
      <tr
        className={`table-row pl-2 ${selected?._id === _id ? 'table-primary' : ''}`}
        key={`order_${_id}`}
        onClick={() => onSelect(_id || '')}
      >
        <td title='код'>
          <strong onClick={() => copyCode(item.code)}>{item.code}</strong>
          <button className='btn btn-sm btn-link p-0' onClick={() => copyLink(item.link)}>
            <i className='fas fa fa-copy ml-2'></i>
          </button>
        </td>
        <td title='значення'>{Number(item.amount).toFixed(2) + unit}</td>
        <td title='максимальна кількість користувачів'>{item.maxUses || '--'}</td>
        <td title='кількість використання'>{item.customers.length}</td>
        <td title='країни'>{countries.join(', ') || '--'}</td>
        <td title='сервіси'>{services.join(', ') || '--'}</td>
        <td title='період'>{getPeriodDetails({ ...item })}</td>
      </tr>
    )
  })

  return (
    <div className='table-responsive portlet'>
      <table className='table m-0'>
        <thead className='thead-light'>
          <tr>
            <th scope='col'>Код</th>
            <th scope='col'>Значення</th>
            <th scope='col'>Макс. к-сть к-чів</th>
            <th title='кількість використання' scope='col'>
              К-сть. вик.
            </th>
            <th scope='col'>Країни</th>
            <th scope='col'>Сервіси</th>
            <th scope='col'>Закінчення</th>
          </tr>
        </thead>
        <tbody>{codesList}</tbody>
      </table>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(RenderListTgPromoCodes)
