import { Dispatch } from 'redux'
import { ModificationStatus } from '../../models/root.interface'
import {
  addTgOptionServiceAction,
  changeSelectedTgOptionServiceAction,
  clearSelectedTgOptionServiceAction,
  clearTgOptionServiceFiltersAction,
  editTgOptionServiceAction,
  editTgOptionServiceFiltersAction,
  getTgOptionServiceAction,
  removeTgOptionServiceAction,
  setTgOptionServicesModificationStateAction,
  setTgOptionServiceSomeErrorAction,
} from '../../actions/tg/tg-options-service.action'
import {
  TgOptionService,
  TgOptionsServiceFilters,
} from '../../models/tg/tg-options-service.interface'

type ActionItem = TgOptionService
type FilterItem = TgOptionsServiceFilters

export const getTgOptionServicesList = (data: ActionItem[]) => (dispatch: Dispatch) => {
  dispatch(getTgOptionServiceAction(data))
}
export const addTgOptionService = (data: ActionItem) => (dispatch: Dispatch) => {
  dispatch(addTgOptionServiceAction(data))
}
export const editTgOptionService = (value: ActionItem) => (dispatch: Dispatch) => {
  dispatch(editTgOptionServiceAction(value))
}
export const removeTgOptionService = (value: string) => (dispatch: Dispatch) => {
  dispatch(removeTgOptionServiceAction(value))
}
export const changeSelectedTgOptionService = (value: string) => (dispatch: Dispatch) => {
  dispatch(changeSelectedTgOptionServiceAction(value))
}
export const clearSelectedTgOptionService = () => (dispatch: Dispatch) => {
  dispatch(clearSelectedTgOptionServiceAction())
}
export const setTgOptionServicesModificationState =
  (value: ModificationStatus) => (dispatch: Dispatch) => {
    dispatch(setTgOptionServicesModificationStateAction(value))
  }
export const setTgOptionServiceSomeError = (value: string) => (dispatch: Dispatch) => {
  dispatch(setTgOptionServiceSomeErrorAction(value))
}

export const editTgOptionServiceFilters = (value: FilterItem) => (dispatch: Dispatch) => {
  dispatch(editTgOptionServiceFiltersAction(value))
}
export const clearTgOptionServiceFilters = () => (dispatch: Dispatch) => {
  dispatch(clearTgOptionServiceFiltersAction())
}
