import { Dispatch } from 'redux'
import {
  setSomeErrorAction,
  updateCurrentPathAction,
  updateLoaderStateAction,
} from '../actions/root.actions'

export const updateCurrentPath = (area: string, subArea: string) => (dispatch: Dispatch) => {
  dispatch(updateCurrentPathAction(area, subArea))
}

export const updateLoaderState = (state: boolean) => (dispatch: Dispatch) => {
  dispatch(updateLoaderStateAction(state))
}

export const setSomeError = (value: string) => (dispatch: Dispatch) => {
  dispatch(setSomeErrorAction(value))
}
