import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import {
  getGsmAnalytics,
  setGsmAnalyticsSomeError,
} from '../../store/dispatch/gsm/gsm-analytics.dispatch'
import { useErrorHandlerHook } from '../../hooks/error-handler'
import RenderTableAnalytics from './components/RenderTableAnalytics'
import { useHttpDateLoader } from '../../hooks/request.hook'
import { RequestUrlTypes } from '../../hooks/types/request.types'
import { URL_SERVER_GSM } from '../../config'
import { GsmAnalyticsOption } from '../../store/models/gsm/gsm-analytics.interface'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setList: bindActionCreators(getGsmAnalytics, dispatch),
  setSomeError: bindActionCreators(setGsmAnalyticsSomeError, dispatch),
})

type PropsComponent = ReturnType<typeof mapDispatchToProps>
const GsmAnalyticsTable: React.FC<PropsComponent> = ({ setList, setSomeError }): JSX.Element => {
  const errorHandler = useErrorHandlerHook(setSomeError, 'Gsm аналітика')

  useHttpDateLoader<GsmAnalyticsOption[]>({
    url: RequestUrlTypes.GSM_ANALYTICS,
    base: URL_SERVER_GSM,
    func: setList,
    errorHandler,
  })

  return <RenderTableAnalytics />
}

export default connect(null, mapDispatchToProps)(GsmAnalyticsTable)
