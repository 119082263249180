import { Dispatch } from 'redux'
import { addNotificationAction, removeNotificationAction } from '../actions/notifications.action'

export const addNotification = (title: string, text: string) => (dispatch: Dispatch) => {
  dispatch(addNotificationAction(title, text))
}

export const removeNotification = (id: number) => (dispatch: Dispatch) => {
  dispatch(removeNotificationAction(id))
}
