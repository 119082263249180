import { ScrSlot } from '../../models/scraper/scr-slot.interface'
import { Dispatch } from 'redux'
import {
  addSlotAction,
  changeSelectedSlotAction,
  clearSelectedSlotAction,
  editSlotAction,
  getSlotsListAction,
  removeSlotAction,
  setSlotsModificationStateAction,
  setSlotsSomeErrorAction,
} from '../../actions/scraper/scr-slot.action'
import { ModificationStatus } from '../../models/root.interface'

type ActionItem = ScrSlot

export const changeSelectedSlot = (value: string) => (dispatch: Dispatch) => {
  dispatch(changeSelectedSlotAction(value))
}

export const clearSelectedSlot = () => (dispatch: Dispatch) => {
  dispatch(clearSelectedSlotAction())
}

export const setSlotsModificationState = (value: ModificationStatus) => (dispatch: Dispatch) => {
  dispatch(setSlotsModificationStateAction(value))
}

export const setSlotsSomeError = (value: string) => (dispatch: Dispatch) => {
  dispatch(setSlotsSomeErrorAction(value))
}

export const getSlotsList = (data: ActionItem[]) => (dispatch: Dispatch) => {
  dispatch(getSlotsListAction(data))
}

export const addSlot = (value: ActionItem) => (dispatch: Dispatch) => {
  dispatch(addSlotAction(value))
}

export const editSlot = (value: ActionItem) => (dispatch: Dispatch) => {
  dispatch(editSlotAction(value))
}

export const removeSlot = (value: string) => (dispatch: Dispatch) => {
  dispatch(removeSlotAction(value))
}
