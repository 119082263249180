export enum RequestUrlTypes {
  SCRAPER_SERVICE = 'service/single/',
  SCRAPER_SLOT = 'slot/',
  SCRAPER_SERVICE_DIARY = 'service-diary?fields=updatedAt,account,_id,service,country',
  SCRAPER_SERVICE_DIARY_BY_IDS = 'service-diary/ids',
  SCRAPER_SERVICE_DIARY_BY_SERVICE = 'service-diary/',
  SCRAPER_RENTAL_RATE_DIARY = 'rental-rate-diary?fields=updatedAt,account,_id',
  SCRAPER_RENTAL_RATE = 'rental-rate/',
  SCRAPER_OPTIONS = 'options/',
  SCRAPER_CONFIGURATION = 'configuration/',
  SCRAPER_CREDENTIAL = 'credential/',
  SCRAPER_CREDENTIAL_CREATE_AND_UPDATE_ANOTHER = 'credential/create-and-update-another',
  SCRAPER_CREDENTIAL_UPDATE_MULTIPLE = 'credential/update-multiple',

  TG_CURRENCY = 'options/currency/',
  TG_CUSTOMERS = 'options/customer/',
  TG_OPTION_COUNTRY = 'sms-activations/options-country/',
  TG_OPTION_SERVICE = 'sms-activations/options-service/',
  TG_INTERNAL_BANK = 'transaction/options/internal-bank/',
  TG_PROMO_CODE = 'options/promo-code/',
  TG_PARTNER_PROGRAM = 'options/partner-program/',
  TG_MAILING = 'options/mailing/',

  UPLOAD_MEDIA = 's3/',

  GSM_OPTIONS_COUNTRY = 'countries/',
  GSM_OPTIONS_SERVICE = 'services/',
  GSM_ANALYTICS = 'services-options/analytics/',
  GSM_OPTIONS_SENDER = 'senders/',
}

export enum RequestTypes {
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

type useHttpRequestProps = {
  url: string
  base?: string
}

export type useHttpDateLoaderProps<T> = useHttpRequestProps & {
  func: (value: T) => void
  errorHandler: (props: { text: string; title?: string }) => void
}

export type useHttpGetRequestProps<T> = {
  url?: string
  base?: string
  func: (value: T) => void
  errorHandler: (props: { text: string; title?: string }) => void
}

export type useHttpGetRequestCallbackProps = {
  url?: string
  signal?: AbortSignal
}

export type useHttpRequestWithBodyProps<T> = useHttpRequestProps & {
  func: (value: T) => void
}

export type usePostPutHttpRequestProps = (value: any) => void

export type useHttpDeleteRequestProps<T> = useHttpRequestProps & {
  func: (value: T) => void
}

export type useRequestsHookCallbackProps = {
  url: string
  base?: string
  options: RequestInit
}
