import TextRH from '../../../../../common/form-hook/Text'
import React from 'react'
import { UseFormReturn } from 'react-hook-form/dist/types/form'
import { PartnerProgram } from '../../../../../store/models/tg/tg-partner-program.interface'
const RenderDurationPeriod = (
  props: Pick<UseFormReturn<PartnerProgram>, 'watch' | 'register' | 'formState'>,
) => {
  const termination = props.watch('termination')
  if (!termination || (termination && termination === 'select-date')) return <></>

  return (
    <>
      <div className='form-group col-md-2'>
        <TextRH
          {...props}
          type='number'
          id='input_duration_days'
          label='Дні'
          field='duration.days'
          placeholder='0'
          max={30}
          required='Value duration_days cannot be empty'
        />
      </div>
      <div className='form-group col-md-2'>
        <TextRH
          {...props}
          type='number'
          id='input_duration_months'
          label='Місяці'
          field='duration.months'
          placeholder='0'
          max={12}
          required='Value duration_months cannot be empty'
        />
      </div>
      <div className='form-group col-md-2'>
        <TextRH
          {...props}
          type='number'
          id='input_duration_years'
          label='Роки'
          field='duration.years'
          placeholder='0'
          required='Value duration_years cannot be empty'
        />
      </div>
    </>
  )
}

export default RenderDurationPeriod
