import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { StateType, ModificationStatus } from '../../../../store/models/root.interface'
import { bindActionCreators, Dispatch } from 'redux'
import { updateCurrentPath, updateLoaderState } from '../../../../store/dispatch/root.dispatch'
import {
  clearSelectedPromoCode,
  removePromoCode,
  setPromoCodesModificationState,
  setPromoCodesSomeError,
} from '../../../../store/dispatch/tg/tg-promo-codes.dispatch'
import { TgPromoCode } from '../../../../store/models/tg/tg-promo-code.interface'
import { addNotification } from '../../../../store/dispatch/notifications.dispatch'
import { TgOptionType, TgTabCustomProps } from '../../types/TgOptions.types'
import { useHttpDeleteRequest } from '../../../../hooks/request.hook'
import { RequestUrlTypes } from '../../../../hooks/types/request.types'
import { OptionTypeDetails } from '../../../../common/components/types/Tab.types'
import OptionsTab from '../../../../common/components/Tab'
import TgPromoCodeForm from './TgPromoCodeForm'
import TgPromCodesList from './TgPromoCodesList'
import { useErrorHandlerHook } from '../../../../hooks/error-handler'
import TabCardBodyTgOptions from '../TabCardBodyTgOptions'
import { URL_SERVER_TG } from '../../../../config'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  remove: bindActionCreators(removePromoCode, dispatch),
  clearSelected: bindActionCreators(clearSelectedPromoCode, dispatch),
  setModificationState: bindActionCreators(setPromoCodesModificationState, dispatch),
  setSomeError: bindActionCreators(setPromoCodesSomeError, dispatch),
  updateCurrentPath: bindActionCreators(updateCurrentPath, dispatch),
  addNotification: bindActionCreators(addNotification, dispatch),
  setLoader: bindActionCreators(updateLoaderState, dispatch),
})

const mapStateToProps = ({ tgPromoCodes, root }: StateType) => ({
  codes: tgPromoCodes,
  path: root.page,
})

type PropsComponent = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  TgTabCustomProps

const TgPromCodesTab: React.FC<PropsComponent> = ({
  codes,
  path,
  remove,
  setLoader,
  clearSelected,
  setModificationState,
  addNotification,
  setSomeError,
  renderTabHeader,
  type,
}): JSX.Element => {
  const errorHandler = useErrorHandlerHook(setSomeError, 'Промо код')
  const deleteRequest = useHttpDeleteRequest({
    url: RequestUrlTypes.TG_PROMO_CODE,
    base: URL_SERVER_TG,
    func: remove,
  })

  useEffect(() => {
    clearSelected()
  }, [path.area])

  const onRemove = async () => {
    const { selected } = codes
    if (!selected) {
      return
    }

    try {
      await deleteRequest({ id: selected._id })
      setModificationState(ModificationStatus.None)
      addNotification('Промо код', `${selected.code} видалено`)
      clearSelected()
    } catch (e: any) {
      errorHandler({ text: e.message })
    } finally {
      setLoader(false)
    }
  }

  const details: OptionTypeDetails<TgPromoCode> = {
    ...codes,
    onRemove,
    setModificationState,
    headerTitle: 'Список промо кодів',
    cardFormClass: 'col-xl-4 col-md-4',
    cardTopClass: 'col-xl-8 col-md-8',
    cardWrapperClass: 'row mt-5 flex-row-reverse',
  }

  if (type !== TgOptionType.PROMO_CODE) return <></>

  return (
    <OptionsTab
      details={details}
      renderForm={<TgPromoCodeForm />}
      topList={<TgPromCodesList />}
      renderTabHeader={renderTabHeader}
      renderCardBody={TabCardBodyTgOptions}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(TgPromCodesTab)
