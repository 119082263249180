import { IActionBase, ModificationStatus, ScrServiceDiaryState } from '../../models/root.interface'
import { scrServiceDiaryTypes } from '../../types/scraper/scr-service-diary.types'
import { ScrServiceDiaryFilters } from '../../models/scraper/scr-service-diary.interface'

type State = ScrServiceDiaryState

const filters: ScrServiceDiaryFilters = {
  country: [],
  service: '',
}

const initialState: State = {
  modificationState: ModificationStatus.None,
  selected: null,
  error: null,
  list: [],
  filters: { country: [], service: [] },
}

export default (state: State = initialState, action: IActionBase): State => {
  switch (action.type) {
    case scrServiceDiaryTypes.GET_ITEMS_LIST: {
      return { ...state, error: null, list: action.value, selected: null }
    }
    case scrServiceDiaryTypes.CHANGE_ITEM_PENDING_EDIT: {
      return { ...state, error: null, selected: action.value }
    }
    case scrServiceDiaryTypes.CLEAR_ITEM_PENDING_EDIT: {
      return { ...state, error: null, selected: null }
    }
    case scrServiceDiaryTypes.SET_MODIFICATION_STATE_ITEM: {
      return { ...state, error: null, modificationState: action.value }
    }
    case scrServiceDiaryTypes.SET_SOME_ERROR_ITEM: {
      return { ...state, error: action.value }
    }
    case scrServiceDiaryTypes.EDIT_ITEMS_FILTER: {
      return { ...state, filters: action.value }
    }
    case scrServiceDiaryTypes.CLEAR_ITEMS_FILTERS: {
      return { ...state, filters }
    }
    default:
      return state
  }
}
