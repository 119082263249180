import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { StateType } from '../../../../store/models/root.interface'
import { bindActionCreators, Dispatch } from 'redux'
import { addNotification } from '../../../../store/dispatch/notifications.dispatch'
import { updateCurrentPath, updateLoaderState } from '../../../../store/dispatch/root.dispatch'
import OptionsTab from '../../../../common/components/Tab'
import { OptionTypeDetails } from '../../../../common/components/types/Tab.types'
import { ScrapperOptionType, ScrapperTabCustomProps } from '../../types/ScraperOptions.type'
import { setScrServiceDiaryModificationState } from '../../../../store/dispatch/scraper/scr-service-diary.dispatch'
import ScrRentalRateDiaryTabHeader from './components/ScrRentalRateDiaryTabHeader'
import ScrRentalRateCardHeader from './ScrRentalRateCardHeader'
import ScrRentalRateDiaryContextWrapper from './ScrRentalRateDiaryContext'
import ScrCardBody from '../ScrCardBody'
import { ScrRentalRateDiary } from '../../../../store/models/scraper/scr-rental-rate-diary.interface'
import {
  getScrRentalRateDiaryList,
  setScrRentalRateDiarySomeError,
} from '../../../../store/dispatch/scraper/scr-rental-rate-diary.dispatch'
import RenderScrRentalRateDiaryCompetitors from './components/RenderScrRentalRateDiaryCompetitors'
import ScrRentalRateDiaryList from './components/ScrRentalRateDiaryList'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setModificationState: bindActionCreators(setScrServiceDiaryModificationState, dispatch),
  addNotification: bindActionCreators(addNotification, dispatch),
  updateCurrentPath: bindActionCreators(updateCurrentPath, dispatch),
  updateLoader: bindActionCreators(updateLoaderState, dispatch),
  setList: bindActionCreators(getScrRentalRateDiaryList, dispatch),
  setSomeError: bindActionCreators(setScrRentalRateDiarySomeError, dispatch),
})

const mapStateToProps = ({ scrRentalRateDiary, root }: StateType) => ({
  scrRentalRateDiary,
  path: root.page,
})

type PageProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  ScrapperTabCustomProps

const ScrRentalRateDiaryTab: React.FC<PageProps> = ({
  scrRentalRateDiary,
  path,
  setModificationState,
  updateCurrentPath,
  type,
  renderTabHeader,
}) => {
  useEffect(() => {
    updateCurrentPath('rental-rate-diary', 'list')
  }, [path.area])

  const details: OptionTypeDetails<ScrRentalRateDiary> = {
    ...scrRentalRateDiary,
    setModificationState,
    headerTitle: 'Список слотів',
    bottomDescription: 'Список країн',
    cardFormClass: 'col-xl-5 col-lg-5',
    cardTopClass: 'col-xl-7 col-lg-7',
    cardWrapperClass: 'row mt-5 flex-row-reverse',
  }

  if (type !== ScrapperOptionType.SCR_RENTAL_RATE_DIARY) return <></>

  return (
    <ScrRentalRateDiaryContextWrapper>
      <OptionsTab
        details={details}
        renderForm={<RenderScrRentalRateDiaryCompetitors />}
        topList={<ScrRentalRateDiaryList />}
        cardHeader={ScrRentalRateCardHeader}
        renderCardBody={ScrCardBody}
        renderTabHeader={<ScrRentalRateDiaryTabHeader renderTabHeader={renderTabHeader} />}
      />
    </ScrRentalRateDiaryContextWrapper>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ScrRentalRateDiaryTab)
