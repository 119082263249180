import { GsmOptionsSenderState, IActionBase, ModificationStatus } from '../../models/root.interface'
import { gsmOptionsSenderTypes } from '../../types/gsm/gsm-options-sender.types'
import { GsmOptionsSender } from '../../models/gsm/gsm-options-sender.interface'

type State = GsmOptionsSenderState
type Element = GsmOptionsSender

const initialState: State = {
  error: null,
  modificationState: ModificationStatus.None,
  selected: null,
  list: [],
}

export default (state: State = initialState, action: IActionBase): State => {
  switch (action.type) {
    case gsmOptionsSenderTypes.GET_ITEMS_LIST: {
      return { ...state, error: null, list: action.value, selected: null }
    }
    case gsmOptionsSenderTypes.ADD_NEW_ITEM: {
      return { ...state, error: null, list: [...state.list, action.value], selected: null }
    }
    case gsmOptionsSenderTypes.EDIT_ITEM: {
      const foundIndex: number = state.list.findIndex((pr) => pr._id === action.value._id)
      const list: Element[] = state.list
      list[foundIndex] = action.value

      return { ...state, error: null, list }
    }
    case gsmOptionsSenderTypes.REMOVE_ITEM: {
      return {
        ...state,
        error: null,
        list: state.list.filter((pr) => pr._id !== action.value),
        selected: null,
      }
    }
    case gsmOptionsSenderTypes.CHANGE_ITEM_PENDING_EDIT: {
      const foundIndex: number = state.list.findIndex((pr) => pr._id === action.value)

      return { ...state, error: null, selected: state.list[foundIndex] }
    }
    case gsmOptionsSenderTypes.CLEAR_ITEM_PENDING_EDIT: {
      return { ...state, error: null, selected: null }
    }
    case gsmOptionsSenderTypes.SET_ITEM_MODIFICATION_STATE: {
      return { ...state, error: null, modificationState: action.value }
    }
    case gsmOptionsSenderTypes.SET_ITEMS_SOME_ERROR: {
      return { ...state, error: action.value }
    }
    default:
      return state
  }
}
