import { StateType, ModificationStatus } from '../../../store/models/root.interface'
import { bindActionCreators, Dispatch } from 'redux'
import React from 'react'
import ListIsEmpty from '../../../common/components/ListIsEmpty'
import { connect } from 'react-redux'
import {
  changeSelectedTgCustomer,
  clearSelectedTgCustomer,
  setTgCustomersModificationState,
} from '../../../store/dispatch/tg/tg-customers.dispatch'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  clearSelected: bindActionCreators(clearSelectedTgCustomer, dispatch),
  changeSelected: bindActionCreators(changeSelectedTgCustomer, dispatch),
  setModificationState: bindActionCreators(setTgCustomersModificationState, dispatch),
})

const mapStateToProps = ({ tgCustomers }: StateType) => ({ customers: tgCustomers })

type PageProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const RenderListTgCustomers: React.FC<PageProps> = ({
  customers: { list, selected },
  clearSelected,
  changeSelected,
  setModificationState,
}): JSX.Element => {
  const onSelect = (_id: string): void => {
    if (selected && selected._id === _id) {
      clearSelected()
    } else {
      changeSelected(_id)
    }

    setModificationState(ModificationStatus.None)
  }

  const rowSelectedClass = (_id: string): string =>
    selected && selected._id === _id ? 'table-info' : ''

  if (!list.length) return <ListIsEmpty />

  const productElements: JSX.Element[] = list.map((item, index) => {
    if (!item) {
      return <></>
    }

    const defaultValue = 'значення відсутнє'

    return (
      <tr
        className={`table-row ${rowSelectedClass(item._id || '')}`}
        onClick={() => onSelect(item._id || '')}
        key={item._id}
      >
        <th scope='row'>{++index}</th>
        <td>{item.tgId}</td>
        <td>{item.partners}</td>
        <td>{item.partnerProgram || defaultValue}</td>
        <td>{item.promoCode || defaultValue}</td>
        <td>{item.balance}</td>
        <td>{item.rewards}</td>
      </tr>
    )
  })

  return (
    <div className='table-responsive portlet rounded-bottom'>
      <table className='table m-0'>
        <thead className='thead-dark'>
          <tr>
            <th scope='col'>№</th>
            <th scope='col'>Тг </th>
            <th scope='col'>К.сть Партерів</th>
            <th scope='col'>Партнерська програма</th>
            <th scope='col'>Промо код</th>
            <th scope='col'>Баланс</th>
            <th scope='col'>Винагорода</th>
          </tr>
        </thead>
        <tbody>{productElements}</tbody>
      </table>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(RenderListTgCustomers)
