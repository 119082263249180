import { tgMailingTypes } from '../../types/tg/tg-mailing.types'
import { ModificationStatus } from '../../models/root.interface'
import { TgMailingMessage } from '../../models/tg/tg-mailing.interface'

type ActionItem = TgMailingMessage

export function getTgMailingListAction(value: ActionItem[]): IGetItemsActionType<ActionItem> {
  return { type: tgMailingTypes.GET_TG_MESSAGES, value }
}

export function addTgMailingAction(value: ActionItem): IAddItemActionType<ActionItem> {
  return { type: tgMailingTypes.ADD_TG_MESSAGE, value }
}

export function editTgMailingAction(value: ActionItem): IEditItemActionType<ActionItem> {
  return { type: tgMailingTypes.EDIT_TG_MESSAGE, value }
}

export function removeTgMailingAction(value: string): IRemoveItemActionType {
  return { type: tgMailingTypes.REMOVE_TG_MESSAGE, value }
}

export function changeSelectedTgMailingAction(value: string): IChangeSelectedItemActionType {
  return { type: tgMailingTypes.CHANGE_TG_MESSAGE_PENDING_EDIT, value }
}

export function clearSelectedTgMailingAction(): IClearSelectedItemActionType {
  return { type: tgMailingTypes.CLEAR_TG_MESSAGE_PENDING_EDIT }
}

export function setTgMailingModificationStateAction(
  value: ModificationStatus,
): ISetModificationStateActionType {
  return { type: tgMailingTypes.SET_TG_MESSAGE_MODIFICATION_STATE, value: value }
}

export function setTgMailingSomeErrorAction(value: string): ISetSomeErrorActionType {
  return { type: tgMailingTypes.SET_TG_MESSAGE_SOME_ERROR, value: value }
}

interface IGetItemsActionType<T> {
  type: string
  value: T[]
}

interface IAddItemActionType<T> {
  type: string
  value: T
}

interface IEditItemActionType<T> {
  type: string
  value: T
}

interface IRemoveItemActionType {
  type: string
  value: string
}

interface IChangeSelectedItemActionType {
  type: string
  value: string
}

interface IClearSelectedItemActionType {
  type: string
}

interface ISetModificationStateActionType {
  type: string
  value: ModificationStatus
}

interface ISetSomeErrorActionType {
  type: string
  value: string
}
