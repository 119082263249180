import React from 'react'
import { CustomRenderFormTgMailingProps } from '../types/RenderFormTgMailing.types'
import TextRH from '../../../../../common/form-hook/Text'
import TextareaRH from '../../../../../common/form-hook/Textarea'
import CheckboxRH from '../../../../../common/form-hook/Checkbox'
import DateRH from '../../../../../common/form-hook/Date'
import {
  TgContentLanguages,
  TgContentLanguagesValues,
} from '../../../../../store/models/tg/tg-options-country.interface'

const RenderFormTgMailing: React.FC<CustomRenderFormTgMailingProps> = ({ form }) => {
  const languages = Object.values(TgContentLanguages) as Array<TgContentLanguagesValues>

  return (
    <>
      <div className='form-row col-md-12'>
        <div className='form-group col-md-12 my-2'>
          <TextRH
            {...form}
            type='text'
            id='input_title'
            label='Заголовок'
            field='title'
            placeholder='Введіть значення'
          />
        </div>
      </div>
      <div className='form-row col-md-12'>
        {languages.map((lang) => {
          return (
            <div className='form-group col-md-4 my-2' key={lang}>
              <TextareaRH
                {...form}
                id='input_content'
                field={`content.${lang}`}
                label={`Додатковий Опис (${lang})`}
                rows={15}
                placeholder='Введіть значення'
              />
            </div>
          )
        })}
      </div>
      <div className='form-row col-md-12'>
        <div className='form-group col-md-4 mb-3 mt-auto'>
          <CheckboxRH
            {...form}
            id='checkbox_self_destruct'
            label='Видалити після розіслання'
            field='selfDestruct'
          />
        </div>
        <div className='form-group col-md-3 mb-3 mt-auto'>
          <CheckboxRH {...form} id='checkbox_status' label='Статус' field='status' />
        </div>
        <div className='form-group col-md-5 mb-3 mt-auto'>
          <DateRH {...form} id='input_delay' field='delay' label='Дата відправлення' />
        </div>
      </div>
    </>
  )
}

export default RenderFormTgMailing
