import React from 'react'
import { connect } from 'react-redux'
import { StateType, ModificationStatus } from '../../../../../store/models/root.interface'
import { bindActionCreators, Dispatch } from 'redux'
import ListIsEmpty from '../../../../../common/components/ListIsEmpty'
import {
  changeSelectedTgInternalBank,
  clearSelectedTgInternalBank,
  setTgInternalBanksModificationState,
} from '../../../../../store/dispatch/tg/tg-internal-bank.dispatch'
import { TgInternalBank } from '../../../../../store/models/tg/tg-internal-bank.interface'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  clearSelected: bindActionCreators(clearSelectedTgInternalBank, dispatch),
  changeSelected: bindActionCreators(changeSelectedTgInternalBank, dispatch),
  setModificationState: bindActionCreators(setTgInternalBanksModificationState, dispatch),
})

const mapStateToProps = ({ tgInternalBank }: StateType) => ({
  options: tgInternalBank,
})

type PropsComponent = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const RenderListTgInternalBank: React.FC<PropsComponent> = ({
  options: { list, selected },
  clearSelected,
  changeSelected,
  setModificationState,
}): JSX.Element => {
  const onSelect = (_id: string): void => {
    if (selected && selected._id === _id) {
      clearSelected()
    } else {
      changeSelected(_id)
    }

    setModificationState(ModificationStatus.None)
  }

  if (!list || (list && !list.length)) return <ListIsEmpty />

  const optionsList: JSX.Element[] = list.map((item: TgInternalBank, index) => {
    return (
      <tr
        className={`table-row ${selected?._id === item._id ? 'table-primary' : ''}`}
        key={item._id}
        onClick={() => onSelect(item._id || '')}
      >
        <td scope='row'>{++index}</td>
        <th>{item.name}</th>
        <th>{item.currencies.join(', ')}</th>
        <th>{item.token}</th>
      </tr>
    )
  })

  return (
    <table className='table m-0'>
      <thead className='thead-light'>
        <tr>
          <th scope='col'>№</th>
          <th scope='col'>Назва</th>
          <th scope='col'>Валюта</th>
          <th scope='col'>Токен</th>
        </tr>
      </thead>
      <tbody>{optionsList}</tbody>
    </table>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(RenderListTgInternalBank)
