import { Typeahead } from 'react-bootstrap-typeahead'
import React from 'react'
import { Option } from 'react-bootstrap-typeahead/types/types'

function MultiSelectField<T>(props: {
  setValue: (value: T) => void
  value: T
  field: keyof T
  id: string
  options: Option[]
  required?: boolean
  label?: string
  labelKey?: string
  savedKey?: string
  placeholder?: string
  defaultSelected?: Option[]
  clearButton?: boolean
}) {
  const selected = props.value[props.field]
  let defaultOptions: Option[] = props?.defaultSelected ?? []

  if (props.labelKey) {
    defaultOptions = defaultOptions.map((i) => i[props.labelKey as keyof Option])
  }

  const onValueChanged = (selected: Option[]) => {
    let options: Option[] = selected

    if (props.savedKey) {
      options = selected.map((option) => {
        option = option[props.savedKey as keyof Option] || option

        return String(option)
      })
    }

    props.setValue({ ...props.value, [props.field]: options })
  }

  return (
    <>
      {props.label ? <label htmlFor={props.id}>{props.label}</label> : null}
      <Typeahead
        id={props.id}
        labelKey={props.labelKey}
        multiple
        clearButton={props.clearButton}
        className='border rounded'
        options={props.options}
        placeholder={props.placeholder || ''}
        selected={props.options.filter((option) => {
          option = option[props?.savedKey as keyof Option] || option

          return Array.isArray(selected) && selected.includes(String(option))
        })}
        onChange={onValueChanged}
        inputProps={{ required: props.required }}
        defaultInputValue={defaultOptions.join()}
      />
    </>
  )
}

export default MultiSelectField
