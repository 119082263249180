import { FiltersState } from '../root.interface'

export enum GsmAnalyticsOptionStatus {
  STATUS_CREATED = 'STATUS_CREATED',
  STATUS_WAIT_CODE = 'STATUS_WAIT_CODE',
  STATUS_WAIT_RETRY = 'STATUS_WAIT_RETRY',
  STATUS_CANCEL = 'STATUS_CANCEL',
  STATUS_OK = 'STATUS_OK',
  STATUS_COMPLETED = 'STATUS_COMPLETED',
  STATUS_CHANGE_PHONE = 'STATUS_CHANGE_PHONE',
}

export class GsmAnalyticsOption {
  _id?: string = ''
  country = ''
  service = ''
  phone = ''
  port = ''
  path = ''
  modem = ''
  status: GsmAnalyticsOptionStatus = GsmAnalyticsOptionStatus.STATUS_CREATED
  activationId = 0
  cost = 0
  repeat = 0
  codes: string[] = []
  inModem = true
  modemName?: string
}
export class RenderGsmAnalyticsOption {
  phone = ''
  country = ''
  earnings = 0
  servicesSold = 0
  servicesSale = 0
}

export type GsmAnalyticsOptionFilters = FiltersState<Pick<GsmAnalyticsOption, 'country' | 'modem'>>

type TypeStatusCode = {
  [K in number]: keyof typeof GsmAnalyticsOptionStatus
}

export const ServiceOptionStatusCode: TypeStatusCode = {
  0: 'STATUS_WAIT_CODE',
  1: 'STATUS_OK',
  2: 'STATUS_WAIT_RETRY',
  3: 'STATUS_CANCEL',
  4: 'STATUS_COMPLETED',
  5: 'STATUS_CHANGE_PHONE',
}
