import React from 'react'
import { connect } from 'react-redux'
import { ModificationStatus, StateType } from '../../../../store/models/root.interface'
import { bindActionCreators, Dispatch } from 'redux'
import { addNotification } from '../../../../store/dispatch/notifications.dispatch'
import { useHttpRequestWithBody } from '../../../../hooks/request.hook'
import { RequestUrlTypes } from '../../../../hooks/types/request.types'
import { useForm } from 'react-hook-form'
import { TabFormWrapperProps } from '../../../../common/components/types/TabFormWrapper.types'
import TabFormWrapper from '../../../../common/components/TabFormWrapper'
import { useErrorHandlerHook } from '../../../../hooks/error-handler'
import { TgOptionService } from '../../../../store/models/tg/tg-options-service.interface'
import {
  addTgOptionService,
  clearSelectedTgOptionService,
  editTgOptionService,
  setTgOptionServicesModificationState,
  setTgOptionServiceSomeError,
} from '../../../../store/dispatch/tg/tg-options-service.dispatch'
import RenderFormTgOptionService from './components/RenderFormTgOptionService'
import { URL_SERVER_TG } from '../../../../config'
import { updateLoaderState } from '../../../../store/dispatch/root.dispatch'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setSomeError: bindActionCreators(setTgOptionServiceSomeError, dispatch),
  clearSelected: bindActionCreators(clearSelectedTgOptionService, dispatch),
  setModificationState: bindActionCreators(setTgOptionServicesModificationState, dispatch),
  add: bindActionCreators(addTgOptionService, dispatch),
  edit: bindActionCreators(editTgOptionService, dispatch),
  addNotification: bindActionCreators(addNotification, dispatch),
  setLoader: bindActionCreators(updateLoaderState, dispatch),
})

const mapStateToProps = ({ tgOptionsService, gsmOptionsService, tgOptionsCountry }: StateType) => ({
  options: tgOptionsService,
  services: gsmOptionsService.list,
  countries: tgOptionsCountry.list,
})

type PropsComponent = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const schema = yup.object().shape({
  _id: yup.string().notRequired(),
  shortName: yup.string().min(0).max(100).required('Value apiKey cannot be empty'),
  country: yup.string().min(1).max(100).required('Value password cannot be empty'),
  icon: yup.string().max(8).notRequired(),
  link: yup.string().max(200).notRequired(),
  name: yup.string().notRequired(),
})

const TgOptionsServiceForm: React.FC<PropsComponent> = ({
  options,
  services,
  countries,
  setSomeError,
  setLoader,
  clearSelected,
  setModificationState,
  add,
  edit,
}) => {
  const { selected, modificationState, error, list } = options
  const isCreate: boolean = modificationState === ModificationStatus.Create
  const errorHandler = useErrorHandlerHook(setSomeError, 'Тг опції сервіс')
  const saveRequest = useHttpRequestWithBody<TgOptionService>({
    url: RequestUrlTypes.TG_OPTION_SERVICE,
    base: URL_SERVER_TG,
    func: isCreate ? add : edit,
  })

  let item: TgOptionService | null = selected

  if (!item || isCreate) {
    item = new TgOptionService()
  }

  const form = useForm<TgOptionService>({ defaultValues: item, resolver: yupResolver(schema) })

  const saveForm = async (formState: TgOptionService): Promise<void> => {
    const uniq = list.find((item) => {
      return item.shortName === formState.shortName && item.country === formState.country
    })

    if (isCreate && uniq) {
      errorHandler({ text: 'Дана опція існує' })
      return
    }

    const optionObject = services.find((item) => item.shortName === formState.shortName)

    if (item) {
      try {
        await saveRequest({
          body: {
            ...item,
            ...formState,
            name: optionObject?.name ?? `Сервіс код ${formState.shortName}`,
          },
          id: item?._id ? String(item?._id) : '',
        })

        clearSelected()
        setModificationState(ModificationStatus.None)
        addNotification('Тг опції сервіс', isCreate ? 'додавно' : 'створено')
      } catch (e: any) {
        errorHandler({ text: e.message })
      } finally {
        setLoader(false)
      }
    }
  }

  const resetForm = (): void => {
    setModificationState(ModificationStatus.None)
    form.reset()
  }

  const formOptions: TabFormWrapperProps<TgOptionService> = {
    form,
    error,
    title: 'сервіс',
    isCreate,
    saveForm,
    resetForm,
  }

  return (
    <TabFormWrapper {...formOptions}>
      <RenderFormTgOptionService form={form} services={services} countries={countries} />
    </TabFormWrapper>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(TgOptionsServiceForm)
