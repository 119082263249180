import { createContext, useState, useMemo } from 'react'
import { ScrServiceDiary } from '../../../../store/models/scraper/scr-service-diary.interface'

type ScrServiceDiaryContextTypes = {
  updateListByService: (data: ScrServiceDiary[]) => void
  listByService: ScrServiceDiary[]
  updateListByCost: (data: ScrServiceDiary) => void
  listByCost: ScrServiceDiary[]
}

type ScrServiceDiaryContextProps = {
  children: JSX.Element | JSX.Element[]
}

export const ScrServiceDiaryContext = createContext<ScrServiceDiaryContextTypes>(
  {} as ScrServiceDiaryContextTypes,
)

export default function ScrServiceDiaryContextWrapper({ children }: ScrServiceDiaryContextProps) {
  const [listByService, setListByService] = useState<ScrServiceDiary[]>([])
  const [listByCost, setListByCost] = useState<ScrServiceDiary[]>([])

  const updateListByService = (value: ScrServiceDiary[]) => {
    setListByService(() => value)
  }

  const updateListByCost = (value: ScrServiceDiary) => {
    const list = listByService.filter((option) => {
      return option.teamId === value.teamId
    })

    setListByCost(() => list)
  }

  const value = useMemo(
    (): ScrServiceDiaryContextTypes => ({
      listByCost,
      listByService,
      updateListByService,
      updateListByCost,
    }),
    [listByService, listByCost],
  )

  return <ScrServiceDiaryContext.Provider value={value}>{children}</ScrServiceDiaryContext.Provider>
}
