import { Dispatch } from 'redux'
import { ModificationStatus } from '../../models/root.interface'
import {
  addCurrencyAction,
  changeSelectedCurrencyAction,
  clearSelectedCurrencyAction,
  editCurrencyAction,
  getCurrenciesListAction,
  removeCurrencyAction,
  setCurrenciesModificationStateAction,
  setCurrenciesSomeErrorAction,
} from '../../actions/tg/tg-currencies.action'
import { TgCurrency } from '../../models/tg/tg-currency.interface'

type ActionItem = TgCurrency

export const getTgCurrenciesList = (data: ActionItem[]) => (dispatch: Dispatch) => {
  dispatch(getCurrenciesListAction(data))
}
export const addTgCurrency = (data: ActionItem) => (dispatch: Dispatch) => {
  dispatch(addCurrencyAction(data))
}
export const editTgCurrency = (value: ActionItem) => (dispatch: Dispatch) => {
  dispatch(editCurrencyAction(value))
}
export const removeTgCurrency = (value: string) => (dispatch: Dispatch) => {
  dispatch(removeCurrencyAction(value))
}
export const changeSelectedTgCurrency = (value: string) => (dispatch: Dispatch) => {
  dispatch(changeSelectedCurrencyAction(value))
}
export const clearSelectedTgCurrency = () => (dispatch: Dispatch) => {
  dispatch(clearSelectedCurrencyAction())
}
export const setTgCurrenciesModificationState =
  (value: ModificationStatus) => (dispatch: Dispatch) => {
    dispatch(setCurrenciesModificationStateAction(value))
  }
export const setTgCurrenciesSomeError = (value: string) => (dispatch: Dispatch) => {
  dispatch(setCurrenciesSomeErrorAction(value))
}
