export const tgInternalBankTypes = {
  GET_TG_INTERNAL_BANK_LIST: 'GET_TG_INTERNAL_BANK_LIST',
  ADD_TG_INTERNAL_BANK_LIST: 'ADD_TG_INTERNAL_BANK_LIST',
  EDIT_TG_INTERNAL_BANK_LIST: 'EDIT_TG_INTERNAL_BANK_LIST',
  REMOVE_TG_INTERNAL_BANK_LIST: 'REMOVE_TG_INTERNAL_BANK_LIST',
  CHANGE_TG_INTERNAL_BANK_PENDING_EDIT: 'CHANGE_TG_PENDING_EDIT',

  CLEAR_TG_INTERNAL_BANK_PENDING_EDIT: 'CLEAR_TG_INTERNAL_BANK_PENDING_EDIT',
  SET_TG_INTERNAL_BANK_MODIFICATION_STATE: 'SET_TG_INTERNAL_BANK_MODIFICATION_STATE',
  SET_TG_INTERNAL_BANK_SOME_ERROR: 'SET_TG_INTERNAL_BANK_SOME_ERROR',
}
