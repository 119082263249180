import React from 'react'
import TypeheadSelectRH from '../../../../../common/form-hook/TypeheadSelect'
import { CustomRenderFormGsmOptionCountryProps } from '../types/RenderFormTgOptionCountry.types'

const RenderFormGsmOptionsCountry: React.FC<CustomRenderFormGsmOptionCountryProps> = ({
  form,
  options,
}) => {
  return (
    <>
      <div className='form-group col-md-12'>
        <TypeheadSelectRH
          {...form}
          id='input_country'
          field='name'
          label='Країна'
          placeholder='Обрати країну'
          options={options}
          labelKey='countryName'
          savedKey='countryName'
          value={form.watch('name')}
          defaultSelected={form.watch('name') ? form.watch('name').toString() : ''}
        />
      </div>
    </>
  )
}

export default RenderFormGsmOptionsCountry
