import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { StateType, ModificationStatus } from '../../../../../store/models/root.interface'
import {
  changeSelectedRentalRate,
  clearSelectedRentalRate,
  setRentalRatesModificationState,
} from '../../../../../store/dispatch/scraper/scr-rental-rate.dispatch'
import { ScrRentalRate } from '../../../../../store/models/scraper/scr-rental-rate.interface'
import ListIsEmpty from '../../../../../common/components/ListIsEmpty'
import { OptionsReceiver } from '../../../../../utils/option-receiver'
const mapDispatchToProps = (dispatch: Dispatch) => ({
  clearSelectedRentalRate: bindActionCreators(clearSelectedRentalRate, dispatch),
  setRentalRatesModificationState: bindActionCreators(setRentalRatesModificationState, dispatch),
  changeSelectedRentalRate: bindActionCreators(changeSelectedRentalRate, dispatch),
})

const mapStateToProps = ({ scrRentalRate, scrCredential, scrOptions }: StateType) => ({
  scrRentalRate,
  credentials: scrCredential.list,
  countries: scrOptions.countriesList,
})

type PageProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const RenderListRentalRate: React.FC<PageProps> = ({
  countries,
  scrRentalRate,
  credentials,
  changeSelectedRentalRate,
  clearSelectedRentalRate,
}): JSX.Element => {
  const getCountryName = OptionsReceiver.getValueByKey(countries, 'shortName')
  const { list, selected } = scrRentalRate
  const rowSelectedClass = (id: string) => (selected && selected._id === id ? 'primary' : '')

  const onSelect = (rate: ScrRentalRate): void => {
    if (selected && selected._id === rate._id) {
      clearSelectedRentalRate()
    } else {
      changeSelectedRentalRate(rate._id || '')
    }

    setRentalRatesModificationState(ModificationStatus.None)
  }

  const getCredentialLogin = (account: string) => {
    const founded = credentials.find((item) => String(item._id) === account)

    return founded?.login || 'not found'
  }

  if (!list.length) return <ListIsEmpty />

  const productElements = list.map((rate, index) => {
    return (
      <tr
        className={`table-row table-${rowSelectedClass(rate._id || '')}`}
        onClick={() => onSelect(rate)}
        key={`product_${rate._id}`}
      >
        <th data-id={rate._id} scope='row'>
          {++index}
        </th>
        <td data-id={rate.country}>{getCountryName(String(rate.country), 'name').toUpperCase()}</td>
        <td>{rate.minCount}</td>
        <td>{rate.minPercent}</td>
        <td>{rate.slot}</td>
        <td>
          <i className={`fas fa-solid ${rate.status ? 'fa-plus' : 'fa-minus'}`}></i>
        </td>
        <td>{getCredentialLogin(rate?.account || '')}</td>
      </tr>
    )
  })

  return (
    <div className='table-responsive portlet'>
      <table className='table'>
        <thead className='thead-light'>
          <tr>
            <th scope='col'>№</th>
            <th scope='col'>Країна</th>
            <th scope='col'>Мін. к-сть.</th>
            <th scope='col'>Мін. відсоток</th>
            <th scope='col'>К-сть. слотів</th>
            <th scope='col'>Статус</th>
            {list.some((item) => item.account) && <th scope='col'>Акаунт</th>}
          </tr>
        </thead>
        <tbody>{productElements}</tbody>
      </table>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(RenderListRentalRate)
