import React from 'react'
import { ModificationStatus } from '../../store/models/root.interface'
import { CardHeaderProps } from './types/CardHeader.types'

const TabCardHeader = <T,>({ headerTitle, setModificationState, selected }: CardHeaderProps<T>) => {
  const headerClass = headerTitle ? '' : 'd-flex justify-content-end'
  const wrapperClass = headerTitle ? '' : 'position-static'

  return (
    <div className={`card-header py-3 pl-2${headerClass}`}>
      {headerTitle && <h6 className='m-0 font-weight-bold text-green'>{headerTitle}</h6>}
      <div className={`header-buttons ${wrapperClass}`}>
        <button
          disabled={Boolean(selected)}
          className='btn btn-success btn-green'
          onClick={() => setModificationState(ModificationStatus.Create)}
        >
          <i className='fas fa fa-plus'></i>
        </button>
        <button
          disabled={!selected}
          className='btn btn-success btn-blue'
          onClick={() => setModificationState(ModificationStatus.Edit)}
        >
          <i className='fas fa fa-pen'></i>
        </button>
        <button
          className='btn btn-success btn-red'
          onClick={() => setModificationState(ModificationStatus.Remove)}
        >
          <i className='fas fa fa-times'></i>
        </button>
      </div>
    </div>
  )
}

export default TabCardHeader
