import { ModificationStatus } from '../../models/root.interface'
import { GsmOptionsCountry } from '../../models/gsm/gsm-options-country.interface'
import { gsmOptionsCountryTypes } from '../../types/gsm/gsm-options-country.types'

type ActionItem = GsmOptionsCountry

export function getGsmOptionsGsmOptionsCountriesListAction(
  value: ActionItem[],
): IGetItemsActionType<ActionItem> {
  return { type: gsmOptionsCountryTypes.GET_ITEMS_LIST, value }
}

export function addGsmOptionsCountryAction(value: ActionItem): IAddItemActionType<ActionItem> {
  return { type: gsmOptionsCountryTypes.ADD_NEW_ITEM, value }
}

export function editGsmOptionsCountryAction(value: ActionItem): IEditItemActionType<ActionItem> {
  return { type: gsmOptionsCountryTypes.EDIT_ITEM, value }
}

export function removeGsmOptionsCountryAction(value: string): IRemoveItemActionType {
  return { type: gsmOptionsCountryTypes.REMOVE_ITEM, value }
}

export function changeSelectedGsmOptionsCountryAction(
  value: string,
): IChangeSelectedItemActionType {
  return { type: gsmOptionsCountryTypes.CHANGE_ITEM_PENDING_EDIT, value }
}

export function clearSelectedCountryAction(): IClearSelectedItemActionType {
  return { type: gsmOptionsCountryTypes.CLEAR_ITEM_PENDING_EDIT }
}

export function setGsmOptionsCountriesModificationStateAction(
  value: ModificationStatus,
): ISetModificationStateActionType {
  return { type: gsmOptionsCountryTypes.SET_ITEM_MODIFICATION_STATE, value: value }
}

export function setGsmOptionsCountriesSomeErrorAction(value: string): ISetSomeErrorActionType {
  return { type: gsmOptionsCountryTypes.SET_ITEMS_SOME_ERROR, value: value }
}

interface IGetItemsActionType<T> {
  type: string
  value: T[]
}

interface IAddItemActionType<T> {
  type: string
  value: T
}

interface IEditItemActionType<T> {
  type: string
  value: T
}

interface IRemoveItemActionType {
  type: string
  value: string
}

interface IChangeSelectedItemActionType {
  type: string
  value: string
}

interface IClearSelectedItemActionType {
  type: string
}

interface ISetModificationStateActionType {
  type: string
  value: ModificationStatus
}

interface ISetSomeErrorActionType {
  type: string
  value: string
}
