import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { updateCurrentPath } from '../../store/dispatch/root.dispatch'
import { GsmOptionType } from './types/GsmOptions.types'
import GsmOptionsServiceTab from './components/services/GsmOptionsServiceTab'
import GsmOptionsCountryTab from './components/countries/GsmOptionsCountryTab'
import GsmOptionsSenderTab from './components/senders/GsmOptionsSenderTab'
import TabHeaderGsmOptions from './components/TabHeaderGsmOptions'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateCurrentPath: bindActionCreators(updateCurrentPath, dispatch),
})

type PropsPage = ReturnType<typeof mapDispatchToProps>

const GsmOptions: React.FC<PropsPage> = ({ updateCurrentPath }) => {
  const [type, setType] = useState<GsmOptionType>(GsmOptionType.COUNTRY)
  const details = { renderTabHeader: TabHeaderGsmOptions({ type, setType }), type }

  useEffect(() => {
    updateCurrentPath('Gsm  options', type)
  }, [type])

  return (
    <>
      <GsmOptionsServiceTab {...details} />
      <GsmOptionsCountryTab {...details} />
      <GsmOptionsSenderTab {...details} />
    </>
  )
}

export default connect(null, mapDispatchToProps)(GsmOptions)
