import { ScrRentalRate } from '../../models/scraper/scr-rental-rate.interface'
import { rentalRatesTypes } from '../../types/scraper/scr-rental-rate.types'
import { ModificationStatus } from '../../models/root.interface'

type ActionItem = ScrRentalRate

export function getRentalRatesListAction(value: ActionItem[]): IGetItemsActionType<ActionItem> {
  return { type: rentalRatesTypes.GET_RENTAL_RATES, value }
}

export function addRentalRateAction(value: ActionItem): IAddItemActionType<ActionItem> {
  return { type: rentalRatesTypes.ADD_RENTAL_RATE, value }
}

export function editRentalRateAction(value: ActionItem): IEditItemActionType<ActionItem> {
  return { type: rentalRatesTypes.EDIT_RENTAL_RATE, value }
}

export function removeRentalRateAction(value: string): IRemoveItemActionType {
  return { type: rentalRatesTypes.REMOVE_RENTAL_RATE, value }
}

export function changeSelectedRentalRateAction(value: string): IChangeSelectedItemActionType {
  return { type: rentalRatesTypes.CHANGE_RENTAL_RATE_PENDING_EDIT, value }
}

export function clearSelectedRentalRateAction(): IClearSelectedItemActionType {
  return { type: rentalRatesTypes.CLEAR_RENTAL_RATE_PENDING_EDIT }
}

export function setRentalRatesModificationStateAction(
  value: ModificationStatus,
): ISetModificationStateActionType {
  return { type: rentalRatesTypes.SET_RENTAL_RATE_MODIFICATION_STATE, value }
}

export function setRentalRatesSomeErrorAction(value: string): ISetSomeErrorActionType {
  return { type: rentalRatesTypes.SET_RENTAL_RATE_SOME_ERROR, value: value }
}

interface IGetItemsActionType<T> {
  type: string
  value: T[]
}

interface IAddItemActionType<T> {
  type: string
  value: T
}

interface IEditItemActionType<T> {
  type: string
  value: T
}

interface IRemoveItemActionType {
  type: string
  value: string
}

interface IChangeSelectedItemActionType {
  type: string
  value: string
}

interface IClearSelectedItemActionType {
  type: string
}

interface ISetModificationStateActionType {
  type: string
  value: ModificationStatus
}

interface ISetSomeErrorActionType {
  type: string
  value: string
}
