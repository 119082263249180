import React from 'react'

const ListIsEmpty = (): JSX.Element => {
  return (
    <div className='row'>
      <img src='/empty-list.png' alt='list-is-empty' className='m-auto w-50' />
    </div>
  )
}

export default ListIsEmpty
