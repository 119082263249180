import React from 'react'
import Popup from 'reactjs-popup'
import { ModificationStatus } from '../../store/models/root.interface'
import { InterrogativePopupProps } from './types/TabInterrogativePopup.types'
const TabInterrogativePopup = <T,>({
  modificationState,
  setModificationState,
  onRemove,
  selected,
}: InterrogativePopupProps<T>) => {
  return (
    <Popup
      className='popup-modal'
      overlayStyle={{ background: '#0006' }}
      open={Boolean(modificationState === ModificationStatus.Remove && selected)}
      onClose={() => setModificationState(ModificationStatus.None)}
      closeOnDocumentClick
    >
      <div className='popup-modal bg-white'>
        <div className='popup-title position-static'>Ви впевнені?</div>
        <div className='popup-content p-0 m-0 h-100 bg-white'>
          <button
            type='button'
            className='btn btn-danger mt-3'
            disabled={!onRemove}
            onClick={onRemove}
          >
            Remove
          </button>
        </div>
      </div>
    </Popup>
  )
}

export default TabInterrogativePopup
