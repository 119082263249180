import React from 'react'
import { ScrapperTabCustomProps } from '../../../types/ScraperOptions.type'
import ScrDiaryLineChart from '../../ScrDiaryLineChart'
import { useSelector } from 'react-redux'
import { StateType } from '../../../../../store/models/root.interface'

const ScrServiceDiaryTabHeader = ({
  renderTabHeader,
}: Pick<ScrapperTabCustomProps, 'renderTabHeader'>) => {
  const scrServiceDiary = useSelector((state: StateType) => {
    return state.scrServiceDiary
  })

  return (
    <>
      {renderTabHeader}
      <ScrDiaryLineChart list={scrServiceDiary.list} title='Аналітика по змінам ціни по дням' />
    </>
  )
}

export default ScrServiceDiaryTabHeader
