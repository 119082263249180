import React from 'react'
import TextRH from '../../../../../common/form-hook/Text'
import CheckboxRH from '../../../../../common/form-hook/Checkbox'
import { CustomRenderFormTgPartnerProgramProps } from '../types/RenderFormTgPartnerProgram.types'
import RenderConditionVariations from './RenderConditionVariations'
import RenderEndDateVariations from './RenderEndDateVariations'
import SelectRH from '../../../../../common/form-hook/Select'
import TypeheadSelectRH from '../../../../../common/form-hook/TypeheadSelect'
import { TgOptionUnits } from '../../../../../store/models/root.interface'
const RenderFormTgPartnerProgram: React.FC<CustomRenderFormTgPartnerProgramProps> = ({
  form,
  codes,
}) => {
  const units = [
    {
      value: TgOptionUnits.PERCENTAGE,
      text: 'відсоткове (%)',
    },
    {
      value: TgOptionUnits.NUMERICAL,
      text: 'числове',
    },
  ]

  return (
    <>
      <div className='form-group col-md-12'>
        <TextRH
          {...form}
          type='text'
          id='input_name'
          label='Назва'
          field='name'
          max={50}
          min={0}
          placeholder='Введіть значення'
          required='Value name cannot be empty'
        />
      </div>
      <div className='form-group col-md-6'>
        <SelectRH
          {...form}
          id='input_unit'
          label='Одиниця виміру'
          field='unit'
          required='Value option cannot be empty'
          options={units}
        />
      </div>
      <div className='form-group col-md-2'>
        <TextRH
          {...form}
          type='number'
          id='input_amount'
          label='Значення'
          field='amount'
          required='Value amount cannot be empty'
        />
      </div>
      <div className='form-group col-md-4'>
        <TextRH
          {...form}
          type='number'
          id='input_max_customers'
          title='Максимальна кількість користувачів'
          label='Макс. к-сть кор.'
          field='maxUses'
        />
      </div>
      <div className='form-group col-md-6'>
        <TypeheadSelectRH
          {...form}
          id='input_code'
          field='promoCode'
          label='Промо код'
          placeholder='Обрати код'
          options={codes}
          labelKey='code'
          savedKey='_id'
          value={form.watch('promoCode')}
          defaultSelected={form.watch('promoCode')?.toString() ?? ''}
        />
      </div>
      <div className='form-group col-md-6'>
        <TextRH
          {...form}
          type='text'
          id='input_code'
          label='Код'
          field='code'
          max={50}
          min={0}
          placeholder='Введіть код'
        />
      </div>
      <div className='form-group col-md-12 mb-1'>
        <CheckboxRH
          {...form}
          id='checkbox_is_main'
          label='Зробити основною (може бути лише одна)'
          field='isMain'
        />
      </div>
      <div className='form-group col-md-12 mb-1'>
        <CheckboxRH
          {...form}
          id='checkbox_date_end'
          label='Добавити дату закінчення'
          field='endDate'
        />
      </div>
      <RenderEndDateVariations {...form} />
      <div className='form-group col-md-12 mb-1'>
        <CheckboxRH
          {...form}
          id='checkbox_presence_condition'
          label='Добавити умову'
          field='presenceCondition'
        />
      </div>
      <RenderConditionVariations {...form} />
    </>
  )
}

export default RenderFormTgPartnerProgram
