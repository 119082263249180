export const gsmOptionsServiceTypes = {
  GET_ITEMS_LIST: 'GET_GSM_OPTIONS_SERVICES_LIST',
  ADD_NEW_ITEM: 'ADD_GSM_OPTIONS_SERVICE',
  EDIT_ITEM: 'EDIT_GSM_OPTIONS_SERVICE',
  REMOVE_ITEM: 'REMOVE_GSM_OPTIONS_SERVICE',
  CHANGE_ITEM_PENDING_EDIT: 'CHANGE_GSM_OPTIONS_SERVICE_PENDING_EDIT',

  CLEAR_ITEM_PENDING_EDIT: 'CLEAR_GSM_OPTIONS_SERVICE_PENDING_EDIT',
  SET_ITEM_MODIFICATION_STATE: 'SET_GSM_OPTIONS_SERVICE_MODIFICATION_STATE',
  SET_ITEMS_SOME_ERROR: 'SET_GSM_OPTIONS_SERVICE_SOME_ERROR',

  EDIT_ITEMS_FILTER: 'EDIT_GSM_OPTIONS_SERVICE_FILTER',
  CLEAR_ITEMS_FILTERS: 'CLEAR_GSM_OPTIONS_SERVICE_FILTERS',
}
