import React from 'react'
import { connect } from 'react-redux'
import { ModificationStatus, StateType } from '../../../../store/models/root.interface'
import { bindActionCreators, Dispatch } from 'redux'
import { addNotification } from '../../../../store/dispatch/notifications.dispatch'
import {
  addPromoCode,
  clearSelectedPromoCode,
  editPromoCode,
  setPromoCodesModificationState,
  setPromoCodesSomeError,
} from '../../../../store/dispatch/tg/tg-promo-codes.dispatch'
import { TgPromoCode } from '../../../../store/models/tg/tg-promo-code.interface'
import { useHttpRequestWithBody } from '../../../../hooks/request.hook'
import { RequestUrlTypes } from '../../../../hooks/types/request.types'
import { useForm } from 'react-hook-form'
import { TabFormWrapperProps } from '../../../../common/components/types/TabFormWrapper.types'
import TabFormWrapper from '../../../../common/components/TabFormWrapper'
import RenderFormTgPromoCode from './components/RenderFormTgPromoCode'
import { useErrorHandlerHook } from '../../../../hooks/error-handler'
import { BOT_URL, URL_SERVER_TG } from '../../../../config'
import { OptionsReceiver } from '../../../../utils/option-receiver'
import { updateLoaderState } from '../../../../store/dispatch/root.dispatch'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setSomeError: bindActionCreators(setPromoCodesSomeError, dispatch),
  clearSelected: bindActionCreators(clearSelectedPromoCode, dispatch),
  setModificationState: bindActionCreators(setPromoCodesModificationState, dispatch),
  add: bindActionCreators(addPromoCode, dispatch),
  edit: bindActionCreators(editPromoCode, dispatch),
  addNotification: bindActionCreators(addNotification, dispatch),
  setLoader: bindActionCreators(updateLoaderState, dispatch),
})

const mapStateToProps = ({ tgPromoCodes, tgOptionsCountry, tgOptionsService }: StateType) => ({
  options: tgPromoCodes,
  services: tgOptionsService.list,
  countries: tgOptionsCountry.list,
})

type PageProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const TgPromoCodeForm: React.FC<PageProps> = ({
  options,
  services,
  countries,
  setSomeError,
  setLoader,
  clearSelected,
  addNotification,
  setModificationState,
  add,
  edit,
}) => {
  const getTgOptionCountryName = OptionsReceiver.getValueByKey(countries, 'shortName')
  const getTgOptionServiceName = OptionsReceiver.getValueByKey(countries, 'shortName')

  const { selected, modificationState, error, list } = options
  const isCreate: boolean = modificationState === ModificationStatus.Create
  const errorHandler = useErrorHandlerHook(setSomeError, 'Валюта')
  const saveRequest = useHttpRequestWithBody<TgPromoCode>({
    url: RequestUrlTypes.TG_PROMO_CODE,
    base: URL_SERVER_TG,
    func: isCreate ? add : edit,
  })

  let item: TgPromoCode | null = selected

  if (!item || isCreate) {
    item = new TgPromoCode()
  }

  const form = useForm<TgPromoCode>({ defaultValues: item })

  const saveForm = async (formState: TgPromoCode): Promise<void> => {
    const code = formState.code.replace(/ /g, '').toUpperCase()
    const uniq = list.find((item) => item.code === code)

    if (isCreate && uniq) {
      errorHandler({ text: 'Дана опція існує' })
      return
    }

    if (formState.amount <= 0) {
      errorHandler({ text: 'Ціна не коректна' })
      return
    }

    if (item) {
      const link = `${BOT_URL}?start=code-${code}`
      const { termination } = formState

      const services = formState.services.map((shortName) => {
        return shortName + '-' + getTgOptionServiceName(shortName, 'name')
      })

      const countries = formState.countries.map((shortName) => {
        return shortName + '-' + getTgOptionCountryName(shortName, 'name')
      })

      try {
        await saveRequest({
          body: {
            ...item,
            ...formState,
            date: termination === 'select-date' ? formState.date : undefined,
            duration: termination === 'select-period' ? formState.duration : undefined,
            code,
            services,
            countries,
            link,
          },
          id: item?._id ? String(item?._id) : '',
        })

        addNotification('Валюта', isCreate ? 'додано' : 'редаговано')
        clearSelected()
        setModificationState(ModificationStatus.None)
      } catch (e: any) {
        errorHandler({ text: e.message })
      } finally {
        setLoader(false)
      }
    }
  }

  const resetForm = (): void => {
    setModificationState(ModificationStatus.None)
    form.reset()
  }

  const formOptions: TabFormWrapperProps<TgPromoCode> = {
    form,
    error,
    title: 'промо коду',
    isCreate,
    saveForm,
    resetForm,
  }

  return (
    <TabFormWrapper {...formOptions}>
      <RenderFormTgPromoCode
        form={form}
        promoCode={item}
        services={services}
        countries={countries}
      />
    </TabFormWrapper>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(TgPromoCodeForm)
