import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { StateType } from '../../store/models/root.interface'
import { bindActionCreators, Dispatch } from 'redux'
import { addNotification } from '../../store/dispatch/notifications.dispatch'
import { updateCurrentPath } from '../../store/dispatch/root.dispatch'
import OptionsTab from '../../common/components/Tab'
import TabHeader from '../../common/components/TabHeader'
import { OptionTypeDetails } from '../../common/components/types/Tab.types'
import TgMessageForm from './TgCustomersForm'
import {
  clearSelectedTgCustomer,
  setTgCustomersModificationState,
} from '../../store/dispatch/tg/tg-customers.dispatch'
import { TgCustomer } from '../../store/models/tg/tg-customer.interface'
import TabCardHeaderCustomers from './components/CardHeaderCustomers'
import TgCustomersList from './TgCustomersList'
import TabCardBodyTgCustomers from './components/TabCardBodyTgCustomers'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  clearSelected: bindActionCreators(clearSelectedTgCustomer, dispatch),
  setModificationState: bindActionCreators(setTgCustomersModificationState, dispatch),
  addNotification: bindActionCreators(addNotification, dispatch),
  updateCurrentPath: bindActionCreators(updateCurrentPath, dispatch),
})

const mapStateToProps = ({ tgCustomers, root }: StateType) => ({
  customers: tgCustomers,
  path: root.page,
})

type PropsPage = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const TgCustomers: React.FC<PropsPage> = ({
  customers,
  path,
  clearSelected,
  setModificationState,
  updateCurrentPath,
}) => {
  useEffect(() => {
    clearSelected()
    updateCurrentPath('mealing', 'list')
  }, [path.area])

  const details: OptionTypeDetails<TgCustomer> = {
    ...customers,
    setModificationState,
    headerTitle: 'Користувачі',
    cardFormClass: 'col-xl-4 col-lg-4',
    cardTopClass: 'col-xl-8 col-lg-8',
    cardWrapperClass: 'row mt-5 flex-row-reverse',
  }

  return (
    <OptionsTab
      details={details}
      renderForm={<TgMessageForm />}
      topList={<TgCustomersList />}
      cardHeader={TabCardHeaderCustomers}
      renderCardBody={TabCardBodyTgCustomers}
      renderTabHeader={<TabHeader title='Список користувачів' />}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(TgCustomers)
